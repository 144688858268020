import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Protected from './Protected';
import Public from './Public';
import App from './App';

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path="/auth">
          <Public />
        </Route>
        <Route path="/app">
          <Protected component={App} />
        </Route>
        <Route exact path="/">
          <Redirect to={{ pathname: '/app/dashboard' }} />
        </Route>
      </Switch>
    </Router>
  );
};

export default AppRouter;
