import React, { Component } from 'react';
import { Form, Menu, Icon } from 'semantic-ui-react';
import moment from 'moment';
import 'moment/locale/fi';
import { registerLocale } from 'react-datepicker';
import fi from 'date-fns/locale/fi';
import { T } from '../../Common/Helpers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const styles = {
  angleArrow: {
    height: 'inherit',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: '40px',
  },
};

const DATE_FORMAT = 'l';
const MONTH = 'month';
const IS_WEEK = 'isoweek';
const CUSTOM = 'custom';

registerLocale('fi', fi);

class DateRangeSelection extends Component {
  constructor(props) {
    super(props);
    const userLang =
      navigator.language?.substring(0, 2) ||
      navigator.userLanguage?.substring(0, 2) ||
      'en';
    moment.locale(userLang);
  }

  render() {
    const { activeSubItem } = this.props;
    const userLang = moment.locale();

    return (
      <Form.Group>
        <Menu compact style={{ marginLeft: '5px' }}>
          <Menu.Item
            name="Month"
            content={T('Month')}
            active={activeSubItem === MONTH}
            onClick={() => this.props.setActiveMenu('activeSubItem', MONTH)}
          />
          <Menu.Item
            name="Week"
            content={T('Week')}
            active={activeSubItem === IS_WEEK}
            onClick={() => this.props.setActiveMenu('activeSubItem', IS_WEEK)}
          />
          <Menu.Item
            name="Day"
            content={T('Day')}
            active={activeSubItem === 'day'}
            onClick={() => this.props.setActiveMenu('activeSubItem', 'day')}
          />
          <Menu.Item
            name="Custom"
            content={T('Custom')}
            active={activeSubItem === CUSTOM}
            onClick={() => this.props.setActiveMenu('activeSubItem', CUSTOM)}
          />
        </Menu>
        <Icon
          style={styles.angleArrow}
          size="large"
          name="angle left"
          onClick={() => this.props.changeRange('subtract', activeSubItem)}
        />
        <Form.Field>
          <label>{T('Statistic from')}</label>
          <div className="date-input-wrapper">
            <DatePicker
              selected={
                moment(this.props.reportRange[activeSubItem].start).isValid()
                  ? moment(this.props.reportRange[activeSubItem].start).toDate()
                  : null
              }
              onChange={(date) => {
                if (date) {
                  const value = moment(date).format('L');
                  this.props.handleDateChange('start', value);
                }
              }}
              maxDate={new Date()}
              dateFormat="dd.MM.yyyy"
              className="semantic-ui-datepicker"
              calendarClassName="semantic-ui-calendar"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText={T('Select date')}
              locale={userLang}
            />
          </div>
        </Form.Field>
        <Form.Field>
          <label>{T('Statistic to')}</label>
          <div className="date-input-wrapper">
            <DatePicker
              selected={
                moment(this.props.reportRange[activeSubItem].end).isValid()
                  ? moment(this.props.reportRange[activeSubItem].end).toDate()
                  : null
              }
              onChange={(date) => {
                if (date) {
                  const value = moment(date).format('L');
                  this.props.handleDateChange('end', value);
                }
              }}
              maxDate={new Date()}
              dateFormat="dd.MM.yyyy"
              className="semantic-ui-datepicker"
              calendarClassName="semantic-ui-calendar"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText={T('Select date')}
              locale={userLang}
            />
          </div>
        </Form.Field>
        <Icon
          style={styles.angleArrow}
          size="large"
          name="angle right"
          onClick={() => this.props.changeRange('add', activeSubItem)}
        />
      </Form.Group>
    );
  }
}

export default DateRangeSelection;
