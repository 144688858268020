import axios from 'axios';
import FileSaver from 'file-saver';
import AEConfig from '../../Config/AEConfig';

import {
  FETCH_VOTINGS_WAITING,
  FETCH_VOTINGS_SUCCESS,
  FETCH_VOTINGS_ERROR,
  FETCH_VOTING_WAITING,
  FETCH_VOTING_SUCCESS,
  FETCH_VOTING_ERROR,
  FETCH_VOTING_RESET,
  ADD_VOTING_WAITING,
  ADD_VOTING_SUCCESS,
  ADD_VOTING_ERROR,
  ADD_VOTING_RESET,
  EDIT_VOTING_WAITING,
  EDIT_VOTING_SUCCESS,
  EDIT_VOTING_ERROR,
  EDIT_VOTING_RESET,
  DELETE_VOTING_WAITING,
  DELETE_VOTING_SUCCESS,
  DELETE_VOTING_ERROR,
  DELETE_VOTING_RESET,
  ADD_MORE_POLL_OPTION,
  ADD_MORE_POLL_OPTION_RESET,
  EDIT_POLL_OPTIONS_INIT,
  RESET_ALL_POLL_OPTIONS,
  DELETE_POLL_OPTION_WAITING,
  DELETE_POLL_OPTION_SUCCESS,
  DELETE_POLL_OPTION_ERROR,
  DELETE_POLL_OPTION_RESET,
  UPDATE_POLL_OPTION_IMAGE,
  FETCH_VOTING_REPORT_WAITING,
  FETCH_VOTING_REPORT_SUCCESS,
  FETCH_VOTING_REPORT_ERROR,
  FETCH_VOTING_REPORT_RESET,
} from '../Types';
import ErrorAction from './ErrorAction';

// const MODULE_ID = '26';
// const ADD_MODULE_ID = '25';

export const fetchVotings =
  ({
    authKey,
    module_id,
    ws_id,
    start,
    lang,
    all,
    paging,
    details,
    searchterm,
    column,
    sortOrder,
    lottery_id,
    lottery_list,
  }) =>
  (dispatch) => {
    dispatch({ type: FETCH_VOTINGS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    let url = `${AEConfig.BASE_URL}/votings?module_id=${module_id}&ws_id=${ws_id}&start=${start}&all=${all}&paging=${paging}&lang=${lang}&details=${details}`;
    if (searchterm) {
      url += `&searchterm=${searchterm}`;
    }
    if (column && sortOrder) {
      url += `&sort=${column}:${sortOrder}`;
    } else if (column) {
      url += `&sort=${column}`;
    }
    if (lottery_id) {
      url += `&lottery_id=${lottery_id}`;
    }

    if (lottery_list) {
      url += `&lottery_list=${lottery_list}`;
    }

    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_VOTINGS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_VOTINGS_ERROR)
          : FETCH_VOTINGS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchVotingReport =
  (authKey, module_id, ws_id, game_id, report_type, lang) => (dispatch) => {
    dispatch({ type: FETCH_VOTING_REPORT_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${AEConfig.BASE_URL}/votings/report/${game_id}?module_id=${module_id}&ws_id=${ws_id}&report_type=${report_type}&lang=${lang}`;
    axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        let fileName;
        try {
          fileName = response.headers['content-disposition']
            .split(';')[1]
            .split('=')[1];
        } catch {
          fileName = `lottery-report${new Date().getTime()}.${report_type}`;
        }
        FileSaver(blob, fileName);
        dispatch({
          type: FETCH_VOTING_REPORT_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_VOTING_REPORT_ERROR)
          : FETCH_VOTING_REPORT_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const addVoting =
  (authKey, data, module_id, ws_id, application_id) => (dispatch) => {
    dispatch({ type: ADD_VOTING_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${AEConfig.BASE_URL}/votings?module_id=${module_id}&ws_id=${ws_id}&application_id=${application_id}`;
    axios
      .post(url, data)
      .then((response) =>
        dispatch({
          type: ADD_VOTING_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_VOTING_ERROR)
          : ADD_VOTING_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchVoting = (id, authKey, module_id, ws_id) => (dispatch) => {
  dispatch({ type: FETCH_VOTING_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  const url = `${AEConfig.BASE_URL}/votings/${id}?module_id=${module_id}&ws_id=${ws_id}`;
  axios
    .get(url)
    .then((response) => {
      dispatch({
        type: FETCH_VOTING_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      });
    })
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_VOTING_ERROR)
        : FETCH_VOTING_ERROR;
      dispatch({ type, payload: error });
    });
};

export const editVoting =
  (authKey, id, data, module_id, ws_id) => (dispatch) => {
    dispatch({ type: EDIT_VOTING_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${AEConfig.BASE_URL}/votings/${id}?module_id=${module_id}&ws_id=${ws_id}`;
    axios
      .put(url, data)
      .then((response) =>
        dispatch({
          type: EDIT_VOTING_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_VOTING_ERROR)
          : EDIT_VOTING_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const deleteVoting = (authKey, id, module_id, ws_id) => (dispatch) => {
  dispatch({ type: DELETE_VOTING_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  const url = `${AEConfig.BASE_URL}/votings/${id}?module_id=${module_id}&ws_id=${ws_id}`;
  axios
    .delete(url)
    .then((response) =>
      dispatch({
        type: DELETE_VOTING_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
        id,
      })
    )
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, DELETE_VOTING_ERROR)
        : DELETE_VOTING_ERROR;
      dispatch({ type, payload: error });
    });
};

export const addMorePollOption = (data, reset) => (dispatch) => {
  if (reset) {
    dispatch({ type: ADD_MORE_POLL_OPTION_RESET, payload: data });
  } else {
    dispatch({ type: ADD_MORE_POLL_OPTION, payload: data });
  }
};

export const editPollOptionsInit = (data) => (dispatch) => {
  dispatch({ type: EDIT_POLL_OPTIONS_INIT, payload: data });
};

export const updatePollOptionImage =
  ({ url, key }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_POLL_OPTION_IMAGE,
      payload: { data: { url, key } },
    });
  };

export const resetPollOptions = () => (dispatch) => {
  dispatch({ type: RESET_ALL_POLL_OPTIONS });
};

export const deletePollOption =
  (authKey, voting_id, id, module_id, ws_id, parentkey) => (dispatch) => {
    dispatch({ type: DELETE_POLL_OPTION_WAITING });
    // only edit lottery has saved prizes otherwise its state
    if (id) {
      axios.defaults.headers.common['x-auth-key'] = authKey;
      const url = `${AEConfig.BASE_URL}/votings/${voting_id}/cmscontent/${id}?module_id=${module_id}&ws_id=${ws_id}`;
      axios
        .delete(url)
        .then((response) =>
          dispatch({
            type: DELETE_POLL_OPTION_SUCCESS,
            payload: {
              data: response.data,
              headers: JSON.parse(JSON.stringify(response?.headers || {})),
            },
            parentkey,
          })
        )
        .catch((error) => {
          const type = error.response
            ? ErrorAction(error.response.status, DELETE_POLL_OPTION_ERROR)
            : DELETE_POLL_OPTION_ERROR;
          dispatch({ type, payload: error });
        });
    } else {
      dispatch({ type: DELETE_POLL_OPTION_SUCCESS, parentkey });
    }
  };

export const resetEditVoting = () => ({ type: EDIT_VOTING_RESET });
export const resetAddVoting = () => ({ type: ADD_VOTING_RESET });
export const resetFetchVoting = () => ({ type: FETCH_VOTING_RESET });
export const resetDeleteVoting = () => ({ type: DELETE_VOTING_RESET });
export const resetDeletePollOption = () => ({ type: DELETE_POLL_OPTION_RESET });
export const resetFetchVotingReport = () => ({
  type: FETCH_VOTING_REPORT_RESET,
});
