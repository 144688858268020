import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Segment, Confirm } from 'semantic-ui-react';
import { Form, Select } from 'formsy-semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { T, localDate } from '../Common/Helpers';
import { getMultiLineText } from '../Common/Texts';
import { Spinner } from '../Common/Spinner';
import {
  fetchCardInstance,
  addCardInstance,
  resetAddCardInstance,
} from '../../Redux/actions';

const SAVE_CARD_CONFIRM_HEADER = 'Save Data';
//const SAVE_CARD_CONFIRM_TEXT = 'Do you want to save the card instance?';
const SAVE_CARD_CONFIRM_TEXT = 'Do you want to save the data';
const CARD_INSTANCE_ADD_SUCCESS = 'Data added successfully';
const INFO_TEXT = [
  'Here you can add a Membership card to mobile app user after user is added into Membership register',
  'Email address combines the membership card into mobile app user.',
  'Membership card appears into app when the app user logs in.',
  'You can edit the membership registration data by returning into membership registration list.',
];
const MODULE_ID = 39;

class MemberCardAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ci_card:
        this.props.cards.length === 1 ? this.props.cards[0].card_id : null,
      showConfirm: false,
      formValues: {},
      cardName: null,
    };

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onCancelSave = this.onCancelSave.bind(this);
    this.onConfirmSave = this.onConfirmSave.bind(this);
  }
  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    const application_id = this.props.activeWorkspace.cnc_application;

    if (this.props.cardInstanceId) {
      this.props.fetchCardInstance({
        moduleId: MODULE_ID,
        authKey,
        ws_id,
        application_id,
        card_instance_id: this.props.cardInstanceId,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const prevAddSuccess = prevProps.cardinstances.addCardInstanceStatus;
    const addSuccess = this.props.cardinstances.addCardInstanceStatus;

    if (prevAddSuccess !== 'success' && addSuccess === 'success') {
      toast({
        type: 'success',
        title: T('Success'),
        description: T(CARD_INSTANCE_ADD_SUCCESS),
        time: 5000,
      });
      this.props.resetAddCardInstance();
      this.props.closeOnSaveModal(
        this.getCard(this.props.cardinstances.cardinstance.ci_card)
      );
    }
  }

  onSelectCard(e, { value }) {
    //const cards = JSON.parse(localStorage.getItem('membercards'));
    const cardObject = this.props.cards.filter(
      (card) => card.card_id === value
    );
    const cardName = cardObject[0].cd_name;
    this.setState({ ci_card: value, cardName });
  }

  onValidSubmit(formData) {
    const cardinstance = this.props.cardinstances.cardinstance;
    cardinstance.card_instance_id = null;

    if (formData.ci_card) {
      cardinstance.ci_card = formData.ci_card;
      this.setState({
        showConfirm: true,
        confirmText: `${T(SAVE_CARD_CONFIRM_TEXT)} ${cardinstance.ci_email} ?`,
      });
    }
  }

  onCancelSave() {
    this.setState({ showConfirm: false });
  }

  onConfirmSave() {
    this.setState({ showConfirm: false });
    this.saveCardInstance();
  }

  setInfoText() {
    const content = getMultiLineText(INFO_TEXT);
    return <Segment>{content}</Segment>;
  }

  getCard(ci_card) {
    return this.props.cards.filter((item) => item.card_id === ci_card);
  }

  saveCardInstance() {
    const formData = this.props.cardinstances.cardinstance;
    formData.ci_card = this.state.ci_card;
    formData.card_instance_id = null;
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;
    this.props.addCardInstance({
      moduleId: MODULE_ID,
      formData,
      authKey,
      ws_id,
      application_id: this.props.activeWorkspace.cnc_application,
    });
  }

  listCards() {
    //const cards = JSON.parse(localStorage.getItem('membercards'));
    return this.props.cards.map((card) => ({
      key: card.card_id,
      text: `${card.cd_name}: ${localDate(
        card.cd_validfrom,
        'fi'
      )} - ${localDate(card.cd_validto, 'fi')}`,
      value: card.card_id,
    }));
  }

  render() {
    if (
      this.props.cardinstances.cardinstance &&
      this.props.cardinstances.cardInstanceStatus === 'success'
    ) {
      const cardinstance = this.props.cardinstances.cardinstance;
      return (
        <Segment>
          {this.setInfoText()}
          <Segment>
            <div className="ui cards center one ">
              <div className="card">
                <div className="content">
                  <div className="header">{this.state.cardName}</div>
                  <div className="description">
                    <div>
                      <div>
                        {T('Name')}
                        {':'} {cardinstance.ci_firstname}
                        {cardinstance.ci_lastname}
                      </div>
                      <div>
                        {T('Email')}
                        {':'} {cardinstance.ci_email}
                      </div>
                      <div>
                        {T('Phone')}
                        {':'} {cardinstance.ci_phone}
                      </div>
                      <div>
                        {T('Address')}
                        {':'} {cardinstance.ci_address1}
                      </div>
                      <div>
                        {T('Zip')}
                        {':'} {cardinstance.ci_postalcode}
                      </div>
                      <div>
                        {T('City')}
                        {':'} {cardinstance.ci_city}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Segment>
          <Form
            //onSubmit={this.onHandleSubmit.bind(this)}
            onValidSubmit={this.onValidSubmit.bind(this)}
            ref={(ref) => (this.form = ref)}
          >
            <Form.Group widths={'equal'}>
              <Form.Field
                placeholder={T('Select a Membership Card')}
                control={Select}
                required
                label={T('Select Membership Card')}
                name="ci_card"
                options={this.listCards(this.props.cards)}
                onChange={this.onSelectCard.bind(this)}
                value={this.state.ci_card}
              ></Form.Field>
            </Form.Group>
            <Button
              type="button"
              color="black"
              content={T('Cancel')}
              //onClick={(e) => this.onCancel(e)}
              onClick={(e) => this.props.closeModal(e, false)}
            />
            <Button content={T('Save')} type="submit" color="green" />
          </Form>
          <Confirm
            header={T(SAVE_CARD_CONFIRM_HEADER)}
            //content={T(SAVE_CARD_CONFIRM_TEXT)}
            content={this.state.confirmText}
            confirmButton={T('Save')}
            cancelButton={T('Cancel')}
            open={this.state.showConfirm}
            onCancel={this.onCancelSave}
            onConfirm={this.onConfirmSave}
            size="tiny"
          />
        </Segment>
      );
    }
    return <Spinner />;
  }
}

function mapStateToProps({ workspace, cardinstances }) {
  return {
    workspace,
    cardinstances,
    ws_id: workspace.ws_id,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    resetAddCardInstance,
  };
}

export default connect(mapStateToProps, { fetchCardInstance, addCardInstance })(
  MemberCardAdd
);
