import React, { Component } from 'react';
import { Grid, Form, Button, Confirm } from 'semantic-ui-react';
import { T } from '../Common/Helpers';

class ProductStock extends Component {
  state = {
    openConfirmation: false,
    stockId: null,
    stock: 0,
    pr_unit: this.props.stock.unit || '',
    stock_clear: false,
    pr_sales_unit: this.props.stock.salesUnit || 0,
    pr_max_purchase_qty: this.props.stock.pr_max_purchase_qty || '',
    pr_max_person_qty: this.props.stock.pr_max_person_qty || '',
    pr_max_person_qtyError: false,
    pr_max_purchase_qtyError: false,
    stockError: false,
    pr_unitError: false,
    pr_sales_unitError: false,
  };

  onConfirmStopSellingNow = () => {
    const stockId = this.state.stockId;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.stopSellingNow(stockId, authKey);
    this.setState({ openConfirmation: false });
    // console.log('delete confirmed');
  };

  onStopSellingNowClick = (e, stockId) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ openConfirmation: true, stockId });
  };
  onChange = (name, value) =>
    this.setState({ [name]: value, [`${name}Error`]: false });
  onCancel = () => {
    this.props.history.goBack();
  };
  oncancelStopSellingNowConfirmation = () => {
    this.setState({ openConfirmation: false });
  };

  onSubmitStock = () => {
    const { pr_max_person_qty, pr_max_purchase_qty } = this.state;
    const valid = this.checkInputValidity();
    const optionFieldsError = this.validateLimitPerPersonAndLimitPerSale(
      pr_max_person_qty,
      pr_max_purchase_qty
    );
    if (optionFieldsError) {
      this.setState({ [optionFieldsError]: true });
      return;
    }
    if (valid) {
      const authKey = localStorage.getItem('x-auth-key');
      const { ws_id } = this.props;
      const productId = this.props.productId;
      const { stock, pr_unit, pr_sales_unit, stock_clear } = this.state;
      const data = {
        stock,
        pr_unit,
        pr_sales_unit,
        stock_clear,
        pr_max_person_qty: pr_max_person_qty || null,
        pr_max_purchase_qty: pr_max_purchase_qty || null,
      };
      this.props.modifyProductStock(authKey, productId, data, ws_id);
    }
  };

  validateLimitPerPersonAndLimitPerSale = (
    pt_max_person_qty,
    pt_max_purchase_qty
  ) => {
    // If there's a limit per person, then limit per sale can not be empty
    // If both fields are not empty, then limit per sale should not be greater than limit per person
    if (
      (!pt_max_person_qty && !pt_max_purchase_qty) ||
      (!pt_max_person_qty && pt_max_purchase_qty)
    ) {
      return null;
    } else if (
      (pt_max_person_qty && !pt_max_purchase_qty) ||
      parseInt(pt_max_purchase_qty, 10) > parseInt(pt_max_person_qty, 10)
    ) {
      return 'pr_max_purchase_qtyError';
    }
  };

  checkInputValidity = () => {
    const fields = ['stock', 'pr_unit', 'pr_sales_unit'];
    const { stock } = this.state;
    const { saldo } = this.props.stock;
    const errors = {};
    let valid = true;
    // const check = parseInt(saldo || 0, 10) + parseInt(stock, 10);
    // console.log('Stock: ', this.props.stock, '\nSaldo: ', saldo, '\nCheck: ', check);
    if (stock !== '' && parseInt(saldo || 0, 10) + parseInt(stock, 10) < 0) {
      // stock should not be negative
      // errors.stockError = { content: T('Product saldo should not be negative'), pointing: 'left' };
      errors.stockError = true;
      valid = false;
    }
    fields.forEach((field) => {
      if (this.state[field] === '') {
        errors[`${field}Error`] = true;
        valid = false;
      }
    });
    if (valid === false) {
      this.setState({ ...errors });
    }
    return valid;
  };
  render() {
    const { stock } = this.props;
    // console.log('Product Stock State: ', this.state);
    return (
      <Form>
        <Grid stackable centered>
          <Grid.Row columns={7}>
            <Grid.Column> {T('Sales Unit')} </Grid.Column>
            <Grid.Column> {T('Unit')} </Grid.Column>
            <Grid.Column> {T('Stock Balance')} </Grid.Column>
            <Grid.Column> {T('New Stock Amount')} </Grid.Column>
            <Grid.Column> {T('Limit per Person')} </Grid.Column>
            <Grid.Column> {T('Limit per Sale')} </Grid.Column>
            <Grid.Column> {T('Clear Stock')} </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={7}>
            <Grid.Column>
              <Form.Input
                error={this.state.pr_sales_unitError}
                type="text"
                // label={T('Sales Unit')}
                name="pr_sales_unit"
                value={this.state.pr_sales_unit}
                onChange={(e, { name, value }) => this.onChange(name, value)}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Select
                error={this.state.pr_unitError}
                name="pr_unit"
                fluid
                // label={T('Unit')}
                value={this.state.pr_unit}
                options={this.props.units}
                onChange={(e, { name, value }) => this.onChange(name, value)}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                type="text"
                // label={T('Stock Balance')}
                readOnly
                disabled
                name="pr_stock_balance"
                value={stock.saldo === null ? T('unlimited') : stock.saldo}
                // onChange={(e, { name, value }) => this.props.handleChange(e, name, value)}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                error={this.state.stockError}
                type="number"
                step={1}
                name="stock"
                // label={T('Max Purchase Qty')}
                value={this.state.stock}
                onChange={(e, { name, value }) => this.onChange(name, value)}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                error={this.state.pr_max_person_qtyError}
                type="number"
                step={1}
                name="pr_max_person_qty"
                // label={T('Max Purchase Qty')}
                value={this.state.pr_max_person_qty}
                onChange={(e, { name, value }) => this.onChange(name, value)}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                error={this.state.pr_max_purchase_qtyError}
                type="number"
                step={1}
                name="pr_max_purchase_qty"
                // label={T('Max Purchase Qty')}
                value={this.state.pr_max_purchase_qty}
                onChange={(e, { name, value }) => this.onChange(name, value)}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Checkbox
                toggle
                checked={this.state.stock_clear}
                style={{ marginTop: '10px' }}
                // label={T('Active')}
                onChange={(e, { name, checked }) =>
                  this.onChange(name, checked)
                }
                name="stock_clear"
              />
            </Grid.Column>
            {/*  <Grid.Column>
          <Button
            content={T('Stop Selling Now')}
            name='bt_stop_selling'
            icon='exclamation circle'
            color='red'
            onChange={(e) => this.onStopSellingNowClick(e, 'stockId')}
          />
      </Grid.Column>*/}
            <Confirm
              header={T('Are you sure?')}
              content={T('This will set stock balance to zero, Proceed?')}
              confirmButton={T('Ok')}
              open={this.state.openConfirmation}
              onCancel={this.oncancelStopSellingNowConfirmation}
              onConfirm={this.onConfirmStopSellingNow}
              size="tiny"
            />
          </Grid.Row>
          <Grid.Row columns="1">
            <Grid.Column>
              <Form.Group>
                <Button
                  type="submit"
                  content={T('Save')}
                  color="green"
                  onClick={this.onSubmitStock}
                />
                <Button
                  type="button"
                  secondary
                  content={T('Cancel')}
                  onClick={() => this.onCancel()}
                />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default ProductStock;
