import _ from 'lodash';
import {
  FETCH_SUB_VENUES_WAITING,
  FETCH_SUB_VENUES_SUCCESS,
  FETCH_SUB_VENUES_ERROR,
  FETCH_SUB_VENUES_RESET,
  FETCH_SUB_VENUE_WAITING,
  FETCH_SUB_VENUE_SUCCESS,
  FETCH_SUB_VENUE_ERROR,
  FETCH_SUB_VENUE_RESET,
  EDIT_SUB_VENUE_WAITING,
  EDIT_SUB_VENUE_SUCCESS,
  EDIT_SUB_VENUE_ERROR,
  EDIT_SUB_VENUE_RESET,
  ADD_SUB_VENUE_WAITING,
  ADD_SUB_VENUE_SUCCESS,
  ADD_SUB_VENUE_ERROR,
  ADD_SUB_VENUE_RESET,
  DELETE_SUB_VENUE_WAITING,
  DELETE_SUB_VENUE_SUCCESS,
  DELETE_SUB_VENUE_ERROR,
  DELETE_SUB_VENUE_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  operationState: {
    add: 'init',
    delete: 'init',
    update: 'init',
    fetch: 'init',
    fetchAll: 'init',
  },
  venues: [],
};

export default function SubVenueReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_SUB_VENUES_WAITING: {
      const operationState = { ...state.operationState, fetchAll: 'waiting' };
      return { ...state, ...operationState };
    }
    case FETCH_SUB_VENUES_SUCCESS: {
      // const subVenues = _.mapKeys(action.payload.data, 'place_id');
      const operationState = { ...state.operationState, fetchAll: 'success' };
      return {
        ...state,
        venues: [...action.payload.data],
        ...operationState,
        headerVenues: action.payload.headers,
      };
    }
    case FETCH_SUB_VENUES_ERROR: {
      const operationState = { ...state.operationState, fetchAll: 'error' };
      return { ...state, ...operationState, error: action.payload.error };
    }
    case FETCH_SUB_VENUES_RESET: {
      const operationState = { ...state.operationState, fetchAll: 'init' };
      return { ...state, ...operationState };
    }

    case FETCH_SUB_VENUE_WAITING: {
      const operationState = { ...state.operationState, fetch: 'waiting' };
      return { ...state, operationState, venue: null };
    }
    case FETCH_SUB_VENUE_SUCCESS: {
      const operationState = { ...state.operationState, fetch: 'success' };
      return { ...state, venue: action.payload.data, operationState };
    }
    case FETCH_SUB_VENUE_ERROR: {
      const operationState = { ...state.operationState, fetch: 'error' };
      return {
        ...state,
        operationState,
        venue: null,
        error: action.payload.error,
      };
    }
    case FETCH_SUB_VENUE_RESET: {
      const operationState = { ...state.operationState, fetch: 'init' };
      return { ...state, operationState, venue: null };
    }

    case ADD_SUB_VENUE_WAITING: {
      const operationState = { ...state.operationState, add: 'waiting' };
      return { ...state, operationState };
    }
    case ADD_SUB_VENUE_SUCCESS: {
      const operationState = { ...state.operationState, add: 'success' };
      return {
        ...state,
        venues: {
          ...state.venues,
          [action.payload.data.place_id]: action.payload.data,
        },
        venue: action.payload.data,
        operationState,
      };
    }
    case ADD_SUB_VENUE_ERROR: {
      const operationState = { ...state.operationState, add: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case ADD_SUB_VENUE_RESET: {
      const operationState = { ...state.operationState, add: 'init' };
      return { ...state, operationState };
    }

    case EDIT_SUB_VENUE_WAITING: {
      const operationState = { ...state.operationState, update: 'waiting' };
      return { ...state, operationState };
    }
    case EDIT_SUB_VENUE_SUCCESS: {
      const operationState = { ...state.operationState, update: 'success' };
      return {
        ...state,
        venues: {
          ...state.venues,
          [action.payload.data.place_id]: action.payload.data,
        },
        venue: action.payload.data,
        operationState,
      };
    }
    case EDIT_SUB_VENUE_ERROR: {
      const operationState = { ...state.operationState, update: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case EDIT_SUB_VENUE_RESET: {
      const operationState = { ...state.operationState, update: 'init' };
      return { ...state, operationState };
    }

    case DELETE_SUB_VENUE_WAITING: {
      const operationState = { ...state.operationState, delete: 'waiting' };
      return { ...state, operationState };
    }
    case DELETE_SUB_VENUE_SUCCESS: {
      const updatedLocationList = _.omit(
        state.venues,
        action.payload.data.place_id
      );
      const operationState = { ...state.operationState, delete: 'success' };
      return { ...state, venues: updatedLocationList, operationState };
    }
    case DELETE_SUB_VENUE_ERROR: {
      const operationState = { ...state.operationState, delete: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case DELETE_SUB_VENUE_RESET: {
      const operationState = { ...state.operationState, delete: 'init' };
      return { ...state, operationState };
    }
    default:
      return state;
  }
}
