import _ from 'lodash';
import {
  FETCH_PERFORMERS_WAITING,
  FETCH_PERFORMERS_SUCCESS,
  FETCH_PERFORMERS_ERROR,
  FETCH_PERFORMERS_RESET,
  FETCH_TEAMS_FOR_SEASON_SUCCESS,
  FETCH_PERFORMERS_BY_SERIES_SUCCESS,
  FETCH_PERFORMERS_BY_SERIES_ERROR,
  FETCH_PERFORMERS_BY_SERIES_RESET,
  FETCH_PERFORMER_WAITING,
  FETCH_PERFORMER_SUCCESS,
  FETCH_PERFORMER_ERROR,
  FETCH_PERFORMER_RESET,
  DELETE_PERFORMER_SUCCESS,
  DELETE_PERFORMER_ERROR,
  DELETE_PERFORMER_RESET,
  CREATE_PERFORMER_SUCCESS,
  CREATE_PERFORMER_ERROR,
  CREATE_PERFORMER_RESET,
  EDIT_PERFORMER_SUCCESS,
  EDIT_PERFORMER_ERROR,
  EDIT_PERFORMER_RESET,
  ADD_EVENT_TO_PERFORMER_SUCCESS,
  ADD_EVENT_TO_PERFORMER_ERROR,
  DELETE_PERFORMER_FROM_EVENT_SUCCESS,
  DELETE_PERFORMER_FROM_EVENT_ERROR,
  RESET_PERFOMRER_EVENT_ACTIONS,
  FETCH_ALL_TEAMS_SUCCESS,
  FETCH_ALL_TEAMS_ERROR,
  FETCH_PERFORMER_TEAM_TRANSFER_LIST_SUCCESS,
  FETCH_PERFORMER_TEAM_TRANSFER_LIST_ERROR,
  DELETE_PERFORMER_TEAM_IMAGE_SUCCESS,
  DELETE_PERFORMER_TEAM_IMAGE_ERROR,
  DELETE_PERFORMER_TEAM_IMAGE_RESET,
  UPLOAD_PERFORMER_TEAM_IMAGE_WAITING,
  UPLOAD_PERFORMER_TEAM_IMAGE_SUCCESS,
  UPLOAD_PERFORMER_TEAM_IMAGE_ERROR,
  UPLOAD_PERFORMER_TEAM_IMAGE_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  createStatus: 'init',
  updateStatus: 'init',
  selectStatus: 'init',
  deleteStatus: 'init',
  fetchTeamsStatus: 'init',
  newTeam: null,
  seasonStatus: 'init',
  imageDeleteStatus: 'init',
  uploadTeamImageStatus: 'init',
};

export default function PerformerReducer(state = INIT_STATE, action) {
  // console.log('Action Type: ', action.type, action.payload);
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_PERFORMER_SUCCESS: {
      const seasons = _.mapKeys(action.payload.data.season, 'event_id');
      return {
        ...state,
        [action.payload.data.performer_id]: action.payload.data,
        selectStatus: 'success',
        seasons,
      };
    }
    case FETCH_PERFORMER_WAITING:
      return { ...state, selectStatus: 'waiting' };
    case FETCH_PERFORMER_ERROR:
      return {
        ...state,
        seasons: null,
        selectStatus: 'error',
        error: action.payload.error,
      };
    case FETCH_PERFORMER_RESET:
      return { ...state, selectStatus: 'init' };

    case CREATE_PERFORMER_SUCCESS: {
      // console.log('New Team Id: ', action.payload.data);
      const performersList = {
        ...state.performerList,
        [action.payload.data.performer_id]: action.payload.data,
      };
      const seasons = _.mapKeys(action.payload.data.season, 'event_id');
      return {
        ...state,
        newTeam: action.payload.data.performer_id,
        seasons,
        [action.payload.data.performer_id]: action.payload.data,
        createStatus: 'success',
        performersList,
      };
    }
    case CREATE_PERFORMER_ERROR:
      return {
        ...state,
        createStatus: 'error',
        newTeam: null,
        error: action.payload.error,
      };
    case CREATE_PERFORMER_RESET:
      return { ...state, createStatus: 'init', newTeam: null };

    case FETCH_PERFORMERS_SUCCESS: {
      // console.log('SUCCESS: ', FETCH_PERFORMER_SUCCESS, '/nAction: ', action);
      const performersList = _.map(action.payload.data, (performer) => ({
        [performer.performer_id]: performer,
      }));
      return {
        ...state,
        performersList,
        headers: action.payload.headers,
        selectStatus: 'success',
      };
    }

    case FETCH_PERFORMERS_WAITING:
      return { ...state, selectStatus: 'waiting' };
    case FETCH_PERFORMERS_ERROR:
      return { ...state, selectStatus: 'error', error: action.payload.error };
    case FETCH_PERFORMERS_RESET:
      return { ...state, ...INIT_STATE };

    case DELETE_PERFORMER_SUCCESS: {
      const performersList = _.filter(
        state.performersList,
        (performer) =>
          performer.performer_id !== action.payload.data.performer_id
      );
      // delete state[action.payload.data.performer_id];
      // console.log(state.performersList, '\nDelete:  ', performersList);
      return { ...state, deleteStatus: 'success', performersList };
    }
    case DELETE_PERFORMER_ERROR:
      return { ...state, deleteStatus: 'error', error: action.payload.error };
    case DELETE_PERFORMER_RESET:
      return { ...state, deleteStatus: 'init' };

    case EDIT_PERFORMER_SUCCESS: {
      const performersList = {
        ...state.performersList,
        [action.payload.data.performer_id]: action.payload.data,
      };
      return {
        ...state,
        // [action.payload.data.performer_id]: action.payload.data,
        updateStatus: 'success',
        performersList,
      };
    }
    case EDIT_PERFORMER_ERROR:
      return { ...state, updateStatus: 'error', error: action.payload.error };
    case EDIT_PERFORMER_RESET:
      return { ...state, updateStatus: 'init' };

    case FETCH_PERFORMERS_BY_SERIES_SUCCESS: {
      const performers = _.mapKeys(action.payload.data, 'performer_id');
      return { ...state, performers, selectStatus: 'success' };
    }
    case FETCH_PERFORMERS_BY_SERIES_ERROR:
      return { ...state, selectStatus: 'error', error: action.payload.error };
    case FETCH_PERFORMERS_BY_SERIES_RESET:
      return { ...INIT_STATE };
    case FETCH_TEAMS_FOR_SEASON_SUCCESS: {
      const teams = _.mapKeys(action.payload.data, 'performer_id');
      return { ...state, teams, selectStatus: 'success' };
    }

    case ADD_EVENT_TO_PERFORMER_SUCCESS: {
      const seasons = {
        ...state.seasons,
        [action.payload.data.event_id]: action.payload.data,
      };
      return { ...state, seasons, seasonStatus: 'add success' };
    }
    case ADD_EVENT_TO_PERFORMER_ERROR:
      return {
        ...state,
        seasonStatus: 'add error',
        error: action.payload.error,
      };
    case DELETE_PERFORMER_FROM_EVENT_SUCCESS: {
      const seasons = _.omit(state.seasons, action.payload.data.event_id);
      return { ...state, seasons, seasonStatus: 'delete success' };
    }
    case DELETE_PERFORMER_FROM_EVENT_ERROR:
      return {
        ...state,
        seasonStatus: 'delete error',
        error: action.payload.error,
      };

    case FETCH_PERFORMER_TEAM_TRANSFER_LIST_SUCCESS:
      return {
        ...state,
        transfers: action.payload.data,
        teamHeader: action.payload.headers,
      };
    case FETCH_PERFORMER_TEAM_TRANSFER_LIST_ERROR:
      return { ...state, transfers: null };

    case RESET_PERFOMRER_EVENT_ACTIONS:
      return { ...state, seasonStatus: 'init' };

    case FETCH_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.payload.data,
        fetchTeamsStatus: 'success',
      };
    case FETCH_ALL_TEAMS_ERROR:
      return {
        ...state,
        teams: [],
        error: action.payload.error,
        fetchTeamsStatus: 'error',
      };

    case DELETE_PERFORMER_TEAM_IMAGE_SUCCESS:
      return { ...state, imageDeleteStatus: 'success' };
    case DELETE_PERFORMER_TEAM_IMAGE_ERROR:
      return {
        ...state,
        imageDeleteStatus: 'error',
        error: action.payload.error,
      };
    case DELETE_PERFORMER_TEAM_IMAGE_RESET:
      return { ...state, imageDeleteStatus: 'init' };

    case UPLOAD_PERFORMER_TEAM_IMAGE_SUCCESS:
      return { ...state, uploadTeamImageStatus: 'success' };
    case UPLOAD_PERFORMER_TEAM_IMAGE_WAITING:
      return { ...state, uploadTeamImageStatus: 'waiting' };
    case UPLOAD_PERFORMER_TEAM_IMAGE_ERROR:
      return {
        ...state,
        uploadTeamImageStatus: 'error',
        error: action.payload.error,
      };
    case UPLOAD_PERFORMER_TEAM_IMAGE_RESET:
      return { ...state, uploadTeamImageStatus: 'init' };

    default:
      return state;
  }
}
