import axios from 'axios';
import config from '../../Config/AEConfig';
import {
  FETCH_TICKETING_STATUS_WAITING,
  FETCH_TICKETING_STATUS_SUCCESS,
  FETCH_TICKETING_STATUS_ERROR,
} from '../Types';
import ErrorAction from './ErrorAction';

export const fetchTicketingStatus =
  ({
    authKey,
    ws_id,
    sortByColumn,
    direction,
    fromDate = null,
    toDate = null,
    module_id = 33,
  }) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_TICKETING_STATUS_WAITING });
    let url = `${config.BASE_URL}/ticketing/status?module_id=${module_id}&ws_id=${ws_id}`;
    if (sortByColumn && direction) {
      url += `&sort=${sortByColumn}:${direction}`;
    } else if (sortByColumn && !direction) {
      url += `&sort=${sortByColumn}`;
    }
    if (fromDate) {
      url += `&from_date=${fromDate}`;
    }
    if (toDate) {
      url += `&to_date=${toDate}`;
    }
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_TICKETING_STATUS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_TICKETING_STATUS_ERROR)
          : FETCH_TICKETING_STATUS_ERROR;
        dispatch({ type, payload: error });
      });
  };
