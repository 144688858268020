import axios from 'axios';
import _ from 'lodash';
import config from '../../Config/AEConfig';
import ErrorAction from './ErrorAction';

import {
  WIZARD_MODIFIED_APPLICATION_CONFIG_SUCCESS,
  WIZARD_MODIFIED_APPLICATION_CONFIG_WAITING,
  WIZARD_MODIFIED_APPLICATION_CONFIG_ERROR,
  MODIFY_APPLICATION_CONFIG_WAITING,
  MODIFY_APPLICATION_CONFIG_SUCCESS,
  MODIFY_APPLICATION_CONFIG_ERROR,
  MODIFY_CONFIG_LOGO_WAITING,
  MODIFY_CONFIG_LOGO_SUCCESS,
  MODIFY_CONFIG_LOGO_ERROR,
  // MODIFY_CONFIG_TEMPLATE_SUCCESS,
  //
  // MODIFY_CONFIG_BRAND_COLOR_1_SUCCESS,
  // MODIFY_CONFIG_BRAND_COLOR_2_SUCCESS,
  // MODIFY_CONFIG_BRAND_COLOR_3_SUCCESS,
  // MODIFY_CONFIG_ERROR,
  // MODIFY_CONFIG_RESET,
  FETCH_APPLICATION_CONFIG_WAITING,
  FETCH_APPLICATION_CONFIG_SUCCESS,
  FETCH_APPLICATION_CONFIG_ERROR,
  FETCH_APPLICATION_CONFIG_RESET,
} from '../Types';
// import { setApplicationModules } from './AppModulesActions';

export const modifyMultipleConfig =
  (authKey, inputObject, application) => (dispatch) => {
    dispatch({ type: WIZARD_MODIFIED_APPLICATION_CONFIG_WAITING });
    const inputKeys = Object.keys(inputObject);
    // console.log('Input KEYS: ', inputKeys);
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const modifiedInputObj = _.map(inputKeys, (key) => {
      if (key === 'modules') {
        return axios.post(
          `${config.BASE_URL}/application/${application}/module?module_id=13`,
          { module_id: true, modules: inputObject[key] }
        );
        // return setApplicationModules(authKey, application, inputObject[key]);
      } else if (key !== 'topNavLogo') {
        const values = {
          ac_keyword: key,
          ac_value: inputObject[key],
          ac_application: application,
        };
        return axios.post(`${config.BASE_URL}/app_config?module_id=15`, values);
      }

      const inputValues = {
        target: 'application',
        id: application,
      };
      const inputForm = new FormData();
      Object.keys(inputValues).forEach((inputKey) => {
        inputForm.append(key, inputValues[inputKey]);
      });
      inputForm.append('file', inputObject[key]);
      return axios.post(`${config.BASE_URL}/upload?module_id=15`, inputForm, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
    });
    console.log('Mapped Promises: ', modifiedInputObj);
    Promise.all(modifiedInputObj)
      .then((values) =>
        dispatch({
          type: WIZARD_MODIFIED_APPLICATION_CONFIG_SUCCESS,
          payload: values,
        })
      )
      .catch((error) =>
        dispatch({
          type: WIZARD_MODIFIED_APPLICATION_CONFIG_ERROR,
          payload: error,
        })
      );
  };

export const modifyConfig =
  ({ authKey, keyword, value, application_id, ws_id, callback }) =>
  (dispatch) => {
    // console.log('modifyConfig', authKey, keyword, value, application, callback);
    dispatch({ type: MODIFY_APPLICATION_CONFIG_WAITING });
    const values = {
      ac_keyword: keyword,
      ac_value: value,
      ac_application: application_id,
    };
    axios.defaults.headers.common['x-auth-key'] = authKey;
    return axios
      .post(`${config.BASE_URL}/app_config?module_id=15&ws_id=${ws_id}`, values)
      .then((response) => {
        dispatch({
          type: MODIFY_APPLICATION_CONFIG_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
        callback(response);
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, MODIFY_APPLICATION_CONFIG_ERROR)
          : MODIFY_APPLICATION_CONFIG_ERROR;
        dispatch({ type, payload: error });
        return error;
      });
  };

export const fetchAppConfig =
  ({ authKey, application_id, ws_id }) =>
  (dispatch) => {
    dispatch({ type: FETCH_APPLICATION_CONFIG_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .get(
        `${config.BASE_URL}/app_configs/application/${application_id}?module_id=15&ws_id=${ws_id}`
      )
      .then((response) => {
        dispatch({
          type: FETCH_APPLICATION_CONFIG_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_APPLICATION_CONFIG_ERROR)
          : FETCH_APPLICATION_CONFIG_ERROR;
        dispatch({ type, payload: error });
      });
  };

export function resetAppConfig() {
  return { type: FETCH_APPLICATION_CONFIG_RESET };
}

//For Logo UPLOAD

export const modifyConfigLogo =
  (formData, authKey, application_id, updateConfigCallback, ws_id, callback) =>
  (dispatch) => {
    dispatch({ type: MODIFY_CONFIG_LOGO_WAITING });
    // const formData = { target: 'application',
    //                    id: applicationId,
    //                     value: file,
    //                    };
    // console.log(formData, 'Application Id: ', applicationId);
    // console.log('form data', formData, ' authkey ', authKey, ' callback');
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .post(`${config.BASE_URL}/upload?module_id=15&ws_id=${ws_id}`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch({
          type: MODIFY_CONFIG_LOGO_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
        updateConfigCallback({
          authKey,
          keyword: 'topNavLogo',
          value: response.data.urlSmall,
          application_id,
          ws_id,
          callback,
        });
        // dispatch({ applicationId: formData.id, type: MODIFY_CONFIG_LOGO_SUCCESS, payload: { data: response.data, headers: JSON.parse(JSON.stringify(response?.headers || {})) } });
        // callback(response);
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, MODIFY_CONFIG_LOGO_ERROR)
          : MODIFY_CONFIG_LOGO_ERROR;
        dispatch({ type, payload: error });
      });
  };
