import axios from 'axios';
import config from '../../Config/AEConfig';
import {
  createEventByEventType,
  editEventByType,
  deleteEventByType,
} from './EventBaseFunctions';
import {
  FETCH_EVENTS_WAITING,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_ERROR,
  FETCH_EVENTS_RESET,
  FETCH_EVENTS_SUCCESS_NEW,
  FETCH_EVENTSX_SUCCESS,
  FETCH_EVENT_WAITING,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_ERROR,
  FETCH_EVENT_RESET,

  //  UPDATE_EVENT,
  // CREATE_EVENT_WAITING,
  CREATE_EVENT_SUCCESS,
  // CREATE_EVENT_ERROR,
  COPY_EVENT_WAITING,
  COPY_EVENT_SUCCESS,
  COPY_EVENT_ERROR,
} from '../Types';

import ErrorAction from './ErrorAction';

const MODULE_ID = 3;
//adding id in the response, to accomodate concurrent API calls and not overriding results
export const fetchEvents =
  (
    authKey,
    startPage = null,
    parent,
    ws_id,
    showAll,
    searchterm = '',
    sortByColumn,
    sortDirection
  ) =>
  (dispatch) => {
    dispatch({ type: FETCH_EVENTS_WAITING, payload: { id: parent } });
    const paramObjs = {
      start: startPage,
      all: showAll,
      ws_id,
      searchterm,
      // performer: true,
      module_id: MODULE_ID,
      sort: sortByColumn,
    };

    let apiUrl;
    if (!(parent === undefined || parent === null)) {
      apiUrl = `/events/parent/${parent}?`;
    } else {
      apiUrl = '/events?';
    }

    Object.keys(paramObjs).forEach((param) => {
      if (paramObjs[param] && param === 'sort') {
        apiUrl += `${param}=${paramObjs[param]}:${sortDirection || 'asc'}&`;
      } else if (paramObjs[param]) {
        apiUrl += `${param}=${paramObjs[param]}&`;
      }
    });

    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .get(`${config.BASE_URL}${apiUrl}`)
      .then((response) => {
        dispatch({
          type: FETCH_EVENTS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_EVENTS_ERROR)
          : FETCH_EVENTS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchEventsNew =
  ({
    authKey,
    startPage,
    limit,
    startdate,
    enddate,
    place_ids = null,
    triage_ids = null,
    parent,
    ws_id,
    showAll,
    searchterm = '',
    sortByColumn,
    sortDirection,
    module_id,
    moments = false,
    performer = false,
    showRunning,
    showOnBreak,
    showArchived,
  }) =>
  (dispatch) => {
    dispatch({ type: FETCH_EVENTS_WAITING, payload: { id: parent } });
    const paramObjs = {
      start: startPage,
      all: showAll,
      ws_id,
      searchterm,
      // performer: true,
      module_id,
      sort: sortByColumn,
    };

    let apiUrl = parent ? `/events/parent/${parent}?` : '/events?';

    Object.keys(paramObjs).forEach((param) => {
      if (paramObjs[param] && param === 'sort') {
        apiUrl += `${param}=${paramObjs[param]}:${sortDirection || 'asc'}&`;
      } else if (paramObjs[param]) {
        apiUrl += `${param}=${paramObjs[param]}&`;
      }
    });
    apiUrl += moments ? 'moments=true&' : '';
    apiUrl += performer ? 'performer=true&' : '';
    apiUrl += startdate ? `startdate=${startdate}&` : '';
    apiUrl += enddate ? `enddate=${enddate}&` : '';
    apiUrl += place_ids ? `place_ids=${place_ids.join()}&` : '';
    apiUrl += triage_ids ? `triage_ids=${triage_ids.join()}&` : '';
    apiUrl +=
      showArchived !== undefined
        ? `is_archived=${showArchived ? 'true' : 'false'}&`
        : '';
    apiUrl +=
      showRunning !== undefined
        ? `is_running=${showRunning ? 'true' : 'false'}&`
        : '';
    apiUrl +=
      showOnBreak !== undefined
        ? `is_on_break=${showOnBreak ? 'true' : 'false'}&`
        : '';
    apiUrl += limit ? `limit=${limit}&` : '';

    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .get(`${config.BASE_URL}${apiUrl}`)
      .then((response) => {
        dispatch({
          type: FETCH_EVENTS_SUCCESS_NEW,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_EVENTS_ERROR)
          : FETCH_EVENTS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export function fetchEventsX(
  authKey,
  ws_id,
  startPage = null,
  parent,
  showAll,
  searchterm = '',
  callback
) {
  let temp = null;
  if (showAll === true) {
    temp = 'true';
  } else {
    temp = 'false';
  }
  let apiUrl = `/events?start=${startPage}&limit=10&all=${temp}&searchterm=${searchterm}&module_id=3&ws_id=${ws_id}`;
  if (!(parent === undefined || parent === null)) {
    apiUrl = `/events/parent/${parent}?searchterm=${searchterm}&start=${startPage}&limit=10&all=${temp}&module_id=3&ws_id=${ws_id}`;
  }
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .get(`${config.BASE_URL}${apiUrl}`)
    .then((response) => {
      callback({
        type: CREATE_EVENT_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      });
    })
    .catch((error) => {
      callback({ type: Error(error.response.status), payload: error });
    });
  return {
    type: FETCH_EVENTSX_SUCCESS,
  };
}

export function resetEvents() {
  return {
    type: FETCH_EVENTS_RESET,
  };
}

export const fetchEvent = (authKey, eventId, ws_id) => (dispatch) => {
  dispatch({ type: FETCH_EVENT_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .get(`${config.BASE_URL}/event/${eventId}?ws_id=${ws_id}&module_id=3`)
    .then((response) => {
      dispatch({
        type: FETCH_EVENT_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      });
    })
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_EVENT_ERROR)
        : FETCH_EVENT_ERROR;
      dispatch({ type, payload: error });
    });
};

export function resetEvent() {
  return {
    type: FETCH_EVENT_RESET,
  };
}

export function processEventBundle() {
  return;
}

export const createEvent = (values, authKey, wsId) =>
  createEventByEventType(values, authKey, wsId, 'match');

export const editEvent = (id, authKey, wsId, values) =>
  editEventByType(id, authKey, wsId, values, 'match');

export const deleteEvent = (id, authKey, ws_id) =>
  deleteEventByType(id, authKey, 'match', null, ws_id);

export const copyEvent =
  ({ eventId, authKey, wsId, moduleId = MODULE_ID, newStartDate = null }) =>
  (dispatch) => {
    dispatch({ type: COPY_EVENT_WAITING });

    let apiUrl = `/event/${eventId}/copy?module_id=${moduleId}&ws_id=${wsId}`;
    if (newStartDate) {
      apiUrl += `&new_start_date=${newStartDate}`;
    }

    axios.defaults.headers.common['x-auth-key'] = authKey;
    return axios
      .post(`${config.BASE_URL}${apiUrl}`)
      .then((response) => {
        dispatch({
          type: COPY_EVENT_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
        return response.data;
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, COPY_EVENT_ERROR)
          : COPY_EVENT_ERROR;
        dispatch({ type, payload: error });
        throw error;
      });
  };
