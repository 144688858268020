import React, { Component } from 'react';
import { Table, Form, Icon, Segment, Label, Confirm } from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PopupContent } from '../Common/PopupContent';
import { T, convertPrice } from '../Common/Helpers';

//const DATE_TIME_FORMAT = getDateTimeFormat();
const DATE_TIME_FORMAT = 'l HH:mm';

// const POPUP_SIZE = 'big';
const POPUP_COLOR = 'grey';
const POPUP_WIDE = 'very';
const POPUP_CONTENT_1 =
  'You can define the ticket pre sale starting date. If no date is defined, tickets are for sale immediately when event is created.';

const START_SELLING_TICKETS_LABEL = 'Ticket Pre Sale Starts';
// const TICKET_SELLING_DATE_LABEL = 'Ticket Selling Time';

const TYPE_TEMPLATE = 'template';

class EventTicket extends Component {
  state = {
    active: -1,
    openDeleteConfirmation: false,
    ticketId: null,
    update_price: null,
    minFromDate: moment(),
  };

  onDeleteTicket = (e, ticketId) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ openDeleteConfirmation: true, ticketId });
  };

  onDeleteCancel = () =>
    this.setState({ openDeleteConfirmation: false, ticketId: null });

  onDeleteConfirm = () => {
    this.props.onDeleteEventTicket(this.state.ticketId);
    this.setState({ openDeleteConfirmation: false });
  };

  getTaxList = () => {
    if (this.props.taxList) {
      return _.map(this.props.taxList, (tax) => ({
        text: tax.tc_description,
        value: tax.tax_class_id,
      }));
    }
    return [];
  };

  getCurrencyList = () =>
    _.map(this.props.currencies.data, (currency) => ({
      text: currency.cu_name,
      value: currency.currency_iso_id,
    }));

  getPriceList = () => {
    if (this.props.pricelist.pricelists) {
      return _.map(this.props.pricelist.pricelists, (pricelist) => ({
        text: pricelist.pl_name,
        value: pricelist.price_list_id,
      }));
    }
    return;
  };

  updatePrice(index, value) {
    // console.log('updatePrice', { index, value });
    this.props.updateAttribute('pp_price', index, value);
    this.setState({ update_price: index });
  }

  formatDateTime(sourceValue) {
    let value = sourceValue ? moment(sourceValue).format(DATE_TIME_FORMAT) : '';
    if (value.length > 0 && !moment(value, DATE_TIME_FORMAT).isValid()) {
      value = moment(sourceValue, DATE_TIME_FORMAT)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss z');
      value = moment(value).format(DATE_TIME_FORMAT);
    }
    return value;
  }

  updateAttributeAndState(key, index, value, stateKey) {
    this.props.updateAttribute(key, index, value);
    this.setState({ [stateKey]: value });
  }

  toggleAccordion = (type, enabled, index) => {
    if (type === 'template' && enabled === false) {
      return;
    }
    let activeIndex = index;
    if (this.state.active === activeIndex) {
      activeIndex = -1;
    }
    this.setState({ active: activeIndex });
  };

  renderTickets = (obj) =>
    _.map(obj, (ticketObj) => {
      const ticket = ticketObj.ticket;
      const disabled =
        !ticket.enabled || !ticket.pr_active || ticket.pricelists.length === 0;
      const disabled_recommend = disabled || !ticket.pr_recommended;
      const object_type = ticketObj.type;
      //const b = ticket.pr_recommended_to ? moment(ticket.pr_recommended_to).format(DATE_TIME_FORMAT) : '';
      //const recommended_from =  moment(ticket.pr_recommended_from, DATE_TIME_FORMAT).utc().format('YYYY-MM-DD hh:mm:ss');
      const recommended_from = this.formatDateTime(ticket.pr_recommended_from);
      const recommended_to = this.formatDateTime(ticket.pr_recommended_to);

      let price = -1;
      if (ticket.pricelists.length > 0) {
        price =
          this.state.update_price === ticketObj.index
            ? ticket.pricelists[0].pp_price
            : convertPrice(
                ticket.pricelists[0].pp_currency,
                ticket.pricelists[0].pp_price
              );
      }

      // console.log(`ticket data ${ticket.pr_name}`, ticket)
      // console.log(`price of ${ticket.pr_name}`, price)
      return (
        <React.Fragment key={`frag-${ticketObj.index}`}>
          <Table.Row style={{ color: disabled ? 'grey' : 'rgba(0,0,0,.87)' }}>
            <Table.Cell
              rowSpan={
                !disabled && this.state.active === ticketObj.index ? 2 : 1
              }
              verticalAlign="top"
              textAlign="center"
              collapsing
              style={{
                cursor: 'pointer',
                paddingTop: '1.5em',
                paddingHorizontal: 0,
              }}
              onClick={() =>
                this.toggleAccordion(
                  ticketObj.type,
                  ticket.enabled,
                  ticketObj.index
                )
              }
            >
              {this.state.active !== ticketObj.index ? (
                <Icon name="caret right" size="large" />
              ) : (
                <Icon name="caret down" size="large" />
              )}
            </Table.Cell>
            <Table.Cell fluid>
              <Form.Input
                required
                name="pr_name"
                style={{ minWidth: '7em' }}
                fluid
                type="text"
                disabled={disabled}
                value={ticket.pr_name}
                onChange={(e, { value }) =>
                  this.props.updateAttribute('pr_name', ticketObj.index, value)
                }
              />
            </Table.Cell>
            <Table.Cell width={1}>
              <Form.Input
                error={
                  ticket.pricelists.length > 0
                    ? ticket.pricelists[0].pp_priceError
                    : false
                }
                required
                name="price"
                style={{ minWidth: '6em' }}
                type="number"
                disabled={disabled}
                min={0}
                step={0.5}
                value={price}
                //value={ticket.pricelists.length > 0 ? convertPrice(ticket.pricelists[0].pp_currency, ticket.pricelists[0].pp_price) : -1}
                //value={ticket.pricelists.length > 0 ? ticket.pricelists[0].pp_price : -1}
                //onChange={(e, { value }) => this.props.updateAttribute('pp_price', ticketObj.index, value)}
                onChange={(e, { value }) =>
                  this.updatePrice(ticketObj.index, value)
                }
                onBlur={() => this.setState({ update_price: null })}
              />
            </Table.Cell>
            <Table.Cell width={1}>
              <Form.Input
                error={
                  ticket.pricelists.length > 0 ? ticket.pr_saldoError : false
                }
                name="pr_saldo"
                fluid
                style={{ minWidth: '6em' }}
                type="number"
                step={1}
                disabled={disabled}
                value={ticket.pr_saldo === null ? '' : ticket.pr_saldo}
                min={0}
                onChange={(e, { value }) =>
                  this.props.updateAttribute('pr_saldo', ticketObj.index, value)
                }
              />
            </Table.Cell>
            <Table.Cell width={1}>
              <Form.Input
                error={ticket.pr_max_person_qtyError}
                name="pr_max_person_qty"
                fluid
                style={{ minWidth: '6em' }}
                type="number"
                step={1}
                disabled={disabled}
                value={ticket.pr_max_person_qty || ''}
                min={0}
                onChange={(e, { value }) =>
                  this.props.updateAttribute(
                    'pr_max_person_qty',
                    ticketObj.index,
                    value
                  )
                }
              />
            </Table.Cell>
            <Table.Cell width={1}>
              <Form.Input
                error={ticket.pr_max_purchase_qtyError}
                name="pr_max_purchase_qty"
                fluid
                style={{ minWidth: '6em' }}
                type="number"
                step={1}
                disabled={disabled}
                value={ticket.pr_max_purchase_qty || ''}
                min={0}
                onChange={(e, { value }) =>
                  this.props.updateAttribute(
                    'pr_max_purchase_qty',
                    ticketObj.index,
                    value
                  )
                }
              />
            </Table.Cell>
            <Table.Cell width={1}>
              <Form.Checkbox
                toggle
                name="pr_separate_ticket"
                disabled={disabled}
                checked={ticket.pr_separate_ticket}
                //checked={false}
                onChange={(e, { checked }) =>
                  this.props.updateAttribute(
                    'pr_separate_ticket',
                    ticketObj.index,
                    checked
                  )
                }
              />
            </Table.Cell>
            <Table.Cell width={1}>
              {object_type === TYPE_TEMPLATE ? (
                <Form.Checkbox
                  toggle
                  name="enabled"
                  checked={ticket.enabled}
                  onChange={(e, { checked }) =>
                    this.props.updateAttribute(
                      'enabled',
                      ticketObj.index,
                      checked
                    )
                  }
                />
              ) : (
                <Form.Checkbox
                  toggle
                  name="pr_active"
                  checked={ticket.pr_active}
                  disabled={!ticket.enabled || ticket.pricelists.length === 0}
                  onChange={(e, { checked }) =>
                    this.props.updateAttribute(
                      'pr_active',
                      ticketObj.index,
                      checked
                    )
                  }
                />
              )}
            </Table.Cell>
            <Table.Cell width={2}>
              <Form.Dropdown
                required
                search
                style={{ maxWidth: '150px' }}
                name="pr_tax_class_id"
                placeholder={T('Choose...')}
                fluid
                selection
                selectOnNavigation
                noResultsMessage={T('No result')}
                onChange={(e, { value }) =>
                  this.props.updateAttribute(
                    'pr_tax_class_id',
                    ticketObj.index,
                    value
                  )
                }
                options={this.getTaxList()}
                value={ticket.pr_tax_class_id}
              />
            </Table.Cell>
            <Table.Cell collapsing textAlign="right">
              {object_type === TYPE_TEMPLATE ? (
                <Icon
                  name="add"
                  size="large"
                  color={ticket.enabled ? 'grey' : 'green'}
                  disabled={ticket.enabled}
                  onClick={() =>
                    this.props.updateAttribute('enabled', ticketObj.index, true)
                  }
                />
              ) : (
                <Icon
                  name="trash"
                  size="large"
                  color="red"
                  disabled={ticketObj.type === 'template'}
                  onClick={(e) => this.onDeleteTicket(e, ticket.product_id)}
                />
              )}
            </Table.Cell>
          </Table.Row>
          {!disabled && this.state.active === ticketObj.index && (
            <>
              <Table.Row>
                <Table.Cell colSpan="4">
                  <Form>
                    <Form.Input
                      value={ticket.pr_short_description}
                      name="pr_short_description"
                      label={T('Name On Receipt (Short Description)')}
                      onChange={(e) =>
                        this.props.updateAttribute(
                          'pr_short_description',
                          ticketObj.index,
                          e.target.value
                        )
                      }
                    />
                    <Form.TextArea
                      value={ticket.pr_long_description}
                      rows={4}
                      name="pr_long_description"
                      label={T('Ticket Description in Ticket Shop Listing')}
                      onChange={(e) =>
                        this.props.updateAttribute(
                          'pr_long_description',
                          ticketObj.index,
                          e.target.value
                        )
                      }
                    />
                  </Form>
                </Table.Cell>
                <Table.Cell verticalAlign="top" colSpan="8">
                  <Segment>
                    <Label attached="top">{T('Recommended Ticket')}</Label>
                    <Form>
                      <Form.Checkbox
                        toggle
                        label={T('Show ticket in app recommended products')}
                        name="pr_recommended"
                        disabled={disabled}
                        checked={ticket.pr_recommended}
                        onChange={(e, { checked }) =>
                          this.props.updateAttribute(
                            'pr_recommended',
                            ticketObj.index,
                            checked
                          )
                        }
                      />
                      <Form.Group>
                        <Form.Field>
                          <DateTimeInput
                            //style={{ minWidth: '400px', marginRight: '5px' }}
                            minDate={moment()}
                            closable
                            label={T('Recommend Ticket Start Date')}
                            name="pr_recommended_from"
                            //value={DDMMYYYYHHmm(ticket.pr_recommended_from)}
                            value={recommended_from}
                            //onChange={(e, { value }) => this.updateAttributeAndState('pr_recommended_from', ticketObj.index, value, 'minFromDate')}
                            onChange={(e, { value }) =>
                              this.props.updateAttribute(
                                'pr_recommended_from',
                                ticketObj.index,
                                value
                              )
                            }
                            disabled={disabled_recommend}
                            error={ticket.pr_recommended_from_error}
                            dateTimeFormat={DATE_TIME_FORMAT}
                            autoComplete="off"
                            history="off"
                          />
                        </Form.Field>
                        <Form.Field>
                          <DateTimeInput
                            //style={{ minWidth: '400px', marginRight: '5px' }}
                            minDate={recommended_from}
                            //disabled={disabled}
                            closable
                            label={T('Recommend Ticket End Date')}
                            name="pr_recommended_to"
                            //value={DDMMYYYYHHmm(ticket.pr_recommended_to)}
                            value={recommended_to}
                            onChange={(e, { value }) =>
                              this.props.updateAttribute(
                                'pr_recommended_to',
                                ticketObj.index,
                                value
                              )
                            }
                            disabled={disabled_recommend}
                            error={ticket.pr_recommended_to_error}
                            dateTimeFormat={DATE_TIME_FORMAT}
                            autoComplete="off"
                            history="off"
                          />
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Segment>
                </Table.Cell>
              </Table.Row>
              {ticketObj.ptc_card_id ? (
                <Table.Row>
                  <Table.Cell
                    verticalAlign="top"
                    colSpan="4"
                    textAlign="center"
                  >
                    {T('Ticket visible only for card holders')}
                  </Table.Cell>
                  <Table.Cell
                    verticalAlign="top"
                    colSpan="8"
                    textAlign="center"
                  ></Table.Cell>
                </Table.Row>
              ) : null}
            </>
          )}
        </React.Fragment>
      );
    });

  render() {
    const tickets = this.props.products;
    return (
      <>
        {this.props.advanced_options === true && (
          <Form>
            <Form.Group>
              <DateTimeInput
                style={{ minWidth: '200px' }}
                closable
                //placeholder={moment().format(this.props.date_time_format)}
                maxDate={this.props.ev_event_start}
                name="ev_selling_start"
                label={T(START_SELLING_TICKETS_LABEL)}
                //value={moment(this.props.ev_selling_start).format(DATE_TIME_FORMAT)}
                value={
                  this.props.ev_selling_start
                    ? moment(this.props.ev_selling_start).format(
                        DATE_TIME_FORMAT
                      )
                    : ''
                }
                //onChange={(e, { value }) => this.props.handleDateChange('ev_selling_start', value)}
                onChange={this.props.handleChange}
                dateTimeFormat={DATE_TIME_FORMAT}
                autoComplete="off"
                history="off"
                error={this.props.ev_selling_start_error}
                //placeholder={this.props.date_time_placeholder}
                clearable
              />
              <PopupContent
                wide={POPUP_WIDE}
                content={POPUP_CONTENT_1}
                color={POPUP_COLOR}
              />
            </Form.Group>
          </Form>
        )}
        <Segment>
          <Label attached="top"> {T('Ticket(s)')} </Label>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing />
                <Table.HeaderCell> {T('Name')} </Table.HeaderCell>
                <Table.HeaderCell> {T('Price')} </Table.HeaderCell>
                <Table.HeaderCell> {T('Saldo')} </Table.HeaderCell>
                <Table.HeaderCell> {T('Limit per Person')} </Table.HeaderCell>
                <Table.HeaderCell> {T('Limit per Sale')} </Table.HeaderCell>
                <Table.HeaderCell> {T('Separate Tickets')} </Table.HeaderCell>
                <Table.HeaderCell> {T('In Use')} </Table.HeaderCell>
                <Table.HeaderCell> {T('Tax class')} </Table.HeaderCell>
                <Table.HeaderCell collapsing />
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderTickets(tickets)}</Table.Body>
          </Table>
        </Segment>
        <Confirm
          header={T('Delete ticket')}
          content={T('Are you sure you want to delete the selected ticket?')}
          confirmButton={T('Delete')}
          cancelButton={T('Cancel')}
          open={this.state.openDeleteConfirmation}
          onCancel={() => this.onDeleteCancel('pricelist')}
          onConfirm={() => this.onDeleteConfirm('pricelist')}
          size="tiny"
        />
      </>
    );
  }
}

EventTicket.propTypes = {
  products: PropTypes.array.isRequired,
  updateAttribute: PropTypes.func.isRequired,
  currencies: PropTypes.object,
};
export default EventTicket;
