import axios from 'axios';
import FileSaver from 'file-saver';
import AEConfig from '../../Config/AEConfig';
import {
  FETCH_LOTTERIES_WAITING,
  FETCH_LOTTERIES_SUCCESS,
  FETCH_LOTTERIES_ERROR,
  FETCH_LOTTERIES_RESET,
  ADD_LOTTERY_WAITING,
  ADD_LOTTERY_SUCCESS,
  ADD_LOTTERY_ERROR,
  ADD_LOTTERY_RESET,
  FETCH_LOTTERY_WAITING,
  FETCH_LOTTERY_SUCCESS,
  FETCH_LOTTERY_ERROR,
  FETCH_LOTTERY_RESET,
  EDIT_LOTTERY_WAITING,
  EDIT_LOTTERY_SUCCESS,
  EDIT_LOTTERY_ERROR,
  EDIT_LOTTERY_RESET,
  CANCEL_LOTTERY_WAITING,
  CANCEL_LOTTERY_SUCCESS,
  CANCEL_LOTTERY_ERROR,
  CANCEL_LOTTERY_RESET,
  DELETE_LOTTERY_WAITING,
  DELETE_LOTTERY_SUCCESS,
  DELETE_LOTTERY_ERROR,
  DELETE_LOTTERY_RESET,
  FETCH_LOTTERIES_REPORT_WAITING,
  FETCH_LOTTERIES_REPORT_SUCCESS,
  FETCH_LOTTERIES_REPORT_ERROR,
  FETCH_LOTTERIES_REPORT_RESET,
  DELETE_LOTTERY_PRIZE_WAITING,
  DELETE_LOTTERY_PRIZE_SUCCESS,
  DELETE_LOTTERY_PRIZE_ERROR,
  DELETE_LOTTERY_PRIZE_RESET,
  ADD_MORE_PRIZE,
  ADD_MORE_PRIZE_RESET,
  UPDATE_PRIZE_IMAGE,
  RESET_ALL_PRIZES,
  EDIT_PRIZES_INIT,
  FETCH_LOTTERY_CONFIG_WAITING,
  FETCH_LOTTERY_CONFIG_SUCCESS,
  FETCH_LOTTERY_CONFIG_ERROR,
  PREVIEW_LOTTERY_WAITING,
  PREVIEW_LOTTERY_SUCCESS,
  PREVIEW_LOTTERY_ERROR,
  ESTIMATION_LOTTERY_WAITING,
  ESTIMATION_LOTTERY_SUCCESS,
  ESTIMATION_LOTTERY_ERROR,
} from '../Types';
import ErrorAction from './ErrorAction';

/*const MODULE_ID = '26';
const ADD_MODULE_ID = '27';
const UPDATE_MODULE_ID = '28';*/
const MODULE_ID = '901';
const ADD_MODULE_ID = '901';
const UPDATE_MODULE_ID = '901';

export const fetchLotteries =
  ({ authKey, ws_id, start, searchterm, column, sortOrder }) =>
  (dispatch) => {
    dispatch({ type: FETCH_LOTTERIES_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    let url = `${AEConfig.BASE_URL}/lotteries?module_id=${MODULE_ID}&ws_id=${ws_id}&start=${start}&all=true&paging=true`;
    if (searchterm) {
      url += `&searchterm=${searchterm}`;
    }
    if (column && sortOrder) {
      url += `&sort=${column}:${sortOrder}`;
    } else if (column) {
      url += `&sort=${column}`;
    }
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_LOTTERIES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_LOTTERIES_ERROR)
          : FETCH_LOTTERIES_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const addMorePrize = (data, reset) => (dispatch) => {
  if (reset) {
    dispatch({ type: ADD_MORE_PRIZE_RESET, payload: data });
  } else {
    dispatch({ type: ADD_MORE_PRIZE, payload: data });
  }
};

export const editPrizesInit = (data) => (dispatch) => {
  dispatch({ type: EDIT_PRIZES_INIT, payload: data });
};

export const updatePrizeImage =
  ({ url, key }) =>
  (dispatch) => {
    dispatch({ type: UPDATE_PRIZE_IMAGE, payload: { data: { url, key } } });
  };

export const resetPrizes = () => (dispatch) => {
  dispatch({ type: RESET_ALL_PRIZES });
};

export const fetchLottery = (id, authKey, ws_id) => (dispatch) => {
  dispatch({ type: FETCH_LOTTERY_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  const url = `${AEConfig.BASE_URL}/lotteries/${id}?module_id=${MODULE_ID}&ws_id=${ws_id}`;
  axios
    .get(url)
    .then((response) => {
      dispatch({
        type: FETCH_LOTTERY_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      });
    })
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_LOTTERY_ERROR)
        : FETCH_LOTTERY_ERROR;
      dispatch({ type, payload: error });
    });
};

export const fetchLotteriesReport =
  (authKey, ws_id, lottery_id, report_type) => (dispatch) => {
    dispatch({ type: FETCH_LOTTERIES_REPORT_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${AEConfig.BASE_URL}/lotteries/report/${lottery_id}?module_id=${MODULE_ID}&ws_id=${ws_id}&report_type=${report_type}`;
    axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        let fileName;
        try {
          fileName = response.headers['content-disposition']
            .split(';')[1]
            .split('=')[1];
        } catch {
          fileName = `lottery-report${new Date().getTime()}.${report_type}`;
        }
        FileSaver(blob, fileName);
        dispatch({
          type: FETCH_LOTTERIES_REPORT_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_LOTTERIES_REPORT_ERROR)
          : FETCH_LOTTERIES_REPORT_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchLotteryConfig = (authKey) => (dispatch) => {
  dispatch({ type: FETCH_LOTTERY_CONFIG_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  const url = `${AEConfig.BASE_URL}/config/lottery?type=distance`;
  axios
    .get(url)
    .then((response) => {
      dispatch({
        type: FETCH_LOTTERY_CONFIG_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      });
    })
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_LOTTERY_CONFIG_ERROR)
        : FETCH_LOTTERY_CONFIG_ERROR;
      dispatch({ type, payload: error });
    });
};

export const addLottery = (authKey, data, ws_id, merchant_id) => (dispatch) => {
  dispatch({ type: ADD_LOTTERY_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  const url = `${AEConfig.BASE_URL}/lotteries?module_id=${ADD_MODULE_ID}&ws_id=${ws_id}&merchant_id=${merchant_id}`;
  axios
    .post(url, data)
    .then((response) =>
      dispatch({
        type: ADD_LOTTERY_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      })
    )
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, ADD_LOTTERY_ERROR)
        : ADD_LOTTERY_ERROR;
      dispatch({ type, payload: error });
    });
};

export const deleteLottery = (authKey, id, ws_id) => (dispatch) => {
  dispatch({ type: DELETE_LOTTERY_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  const url = `${AEConfig.BASE_URL}/lotteries/${id}?module_id=${UPDATE_MODULE_ID}&ws_id=${ws_id}`;
  axios
    .delete(url)
    .then((response) =>
      dispatch({
        type: DELETE_LOTTERY_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
        id,
      })
    )
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, DELETE_LOTTERY_ERROR)
        : DELETE_LOTTERY_ERROR;
      dispatch({ type, payload: error });
    });
};

export const cancelLottery = (authKey, id, ws_id) => (dispatch) => {
  dispatch({ type: CANCEL_LOTTERY_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  const url = `${AEConfig.BASE_URL}/lotteries/${id}/cancel?module_id=${UPDATE_MODULE_ID}&ws_id=${ws_id}`;
  axios
    .put(url)
    .then((response) =>
      dispatch({
        type: CANCEL_LOTTERY_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      })
    )
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, CANCEL_LOTTERY_ERROR)
        : CANCEL_LOTTERY_ERROR;
      dispatch({ type, payload: error });
    });
};

export const editLottery =
  (authKey, id, data, ws_id, merchant_id) => (dispatch) => {
    dispatch({ type: EDIT_LOTTERY_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${AEConfig.BASE_URL}/lotteries/${id}?module_id=${UPDATE_MODULE_ID}&ws_id=${ws_id}&merchant_id=${merchant_id}`;
    axios
      .put(url, data)
      .then((response) =>
        dispatch({
          type: EDIT_LOTTERY_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_LOTTERY_ERROR)
          : EDIT_LOTTERY_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const deleteLotteryPrize =
  (authKey, lottery_id, id, ws_id, key) => (dispatch) => {
    dispatch({ type: DELETE_LOTTERY_PRIZE_WAITING });
    // only edit lottery has saved prizes otherwise its state
    if (id) {
      axios.defaults.headers.common['x-auth-key'] = authKey;
      const url = `${AEConfig.BASE_URL}/lotteries/${lottery_id}/prizes/${id}?module_id=${UPDATE_MODULE_ID}&ws_id=${ws_id}`;
      axios
        .delete(url)
        .then((response) =>
          dispatch({
            type: DELETE_LOTTERY_PRIZE_SUCCESS,
            payload: {
              data: response.data,
              headers: JSON.parse(JSON.stringify(response?.headers || {})),
            },
            key,
          })
        )
        .catch((error) => {
          const type = error.response
            ? ErrorAction(error.response.status, DELETE_LOTTERY_PRIZE_ERROR)
            : DELETE_LOTTERY_PRIZE_ERROR;
          dispatch({ type, payload: error });
        });
    } else {
      dispatch({ type: DELETE_LOTTERY_PRIZE_SUCCESS, key });
    }
  };

export const previewLottery = (authKey, lottery_id, ws_id) => (dispatch) => {
  dispatch({ type: PREVIEW_LOTTERY_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  const url = `${AEConfig.BASE_URL}/lotteries/${lottery_id}/preview?module_id=${UPDATE_MODULE_ID}&ws_id=${ws_id}`;
  axios
    .get(url)
    .then((response) => {
      dispatch({
        type: PREVIEW_LOTTERY_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      });
    })
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, PREVIEW_LOTTERY_ERROR)
        : PREVIEW_LOTTERY_ERROR;
      dispatch({ type, payload: error });
    });
};

export const fetchLotteryEstimation =
  (authKey, wsId, applicationId, gameId, registered) => (dispatch) => {
    dispatch({ type: ESTIMATION_LOTTERY_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${AEConfig.BASE_URL}/lotteries/estimate?module_id=${UPDATE_MODULE_ID}&ws_id=${wsId}&application_id=${applicationId}&game_id=${gameId}&registered=${registered}`;
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: ESTIMATION_LOTTERY_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, ESTIMATION_LOTTERY_ERROR)
          : ESTIMATION_LOTTERY_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetEditLottery = () => ({ type: EDIT_LOTTERY_RESET });
export const resetCancelLottery = () => ({ type: CANCEL_LOTTERY_RESET });
export const resetFetchLotteries = () => ({ type: FETCH_LOTTERIES_RESET });
export const resetFetchLottery = () => ({ type: FETCH_LOTTERY_RESET });
export const resetFetchLotteriesReport = () => ({
  type: FETCH_LOTTERIES_REPORT_RESET,
});
export const resetAddLottery = () => ({ type: ADD_LOTTERY_RESET });
export const resetDeleteLottery = () => ({ type: DELETE_LOTTERY_RESET });
export const resetDeleteLotteryPrize = () => ({
  type: DELETE_LOTTERY_PRIZE_RESET,
});
