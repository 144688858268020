import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import moment from 'moment';
import 'moment/locale/fi';

class CCDateTimeInput extends Component {
  state = {
    dateValue: '',
    timeValue: '',
    value: null,
    timeValueWhileEditing: '',
  };
  componentDidMount() {
    // console.log('CCDateTimeInput didMount', this.props);
    this.updateStateFromProps();
  }

  // componentDidUpdate(prevProps) {
  //   console.log('CCDateTimeInput didUpdate', this.props);
  //   if(prevProps.value === null && this.props.value !== null) {
  //     this.updateStateFromProps();
  //   }
  // }
  //
  updateStateFromProps() {
    const { value, automaticDate, automaticTime } = this.props;
    if (value || (automaticDate && automaticTime)) {
      const dateTime = value ? moment(value) : moment();
      if (dateTime.isValid()) {
        this.setState({
          dateValue: dateTime.format('l'),
          timeValue: dateTime.format('HH:mm'),
          timeValueWhileEditing: dateTime.format('HH:mm'),
          value: dateTime,
        });
      }
    } else {
      const dateTime = moment();
      if (automaticDate) {
        this.setState({
          dateValue: dateTime.format('l'),
        });
      }
      if (automaticTime) {
        this.setState({
          timeValue: dateTime.format('HH:mm'),
        });
      }
    }
  }

  updateValue({ newDateValue, newTimeValue }) {
    let { dateValue, timeValue } = this.state;
    if (newDateValue) {
      dateValue = newDateValue.format('l');
      this.setState({ dateValue });
    }
    if (newTimeValue) {
      if (newTimeValue.isValid()) {
        timeValue = newTimeValue.format('HH:mm');
        this.setState({ timeValue, timeValueWhileEditing: timeValue });
      } else {
        this.setState({ timeValue: '', timeValueWhileEditing: '' });
      }
    }
    const { onChange } = this.props;
    if (dateValue && timeValue) {
      const value = moment(`${dateValue} ${timeValue}`, 'l HH:mm');
      this.setState({ value });
      if (onChange) {
        onChange({ value });
      }
    } else {
      this.setState({ value: null });
      if (onChange) {
        onChange({ value: null });
      }
    }
  }

  render() {
    const { disabled, label, timeLabel } = this.props;

    const { dateValue, timeValue, timeValueWhileEditing } = this.state;

    return (
      <>
        <DateInput
          width={10}
          localization="fi"
          readOnly={disabled}
          closable
          required
          label={label}
          value={dateValue || ''}
          dateFormat={'l'}
          onChange={(_, { value }) => {
            const newDateValue = moment(value, 'l');
            if (newDateValue.isValid()) {
              this.updateValue({ newDateValue });
            }
          }}
          onClear={() => {
            this.setState({ dateValue: '', value: null });
          }}
        />
        <Form.Input
          width={6}
          required
          readOnly={disabled}
          label={timeLabel}
          value={timeValueWhileEditing}
          placeholder="HH:mm"
          onKeyPress={(e) => {
            if (e.charCode === 13) {
              const newTimeValue = moment(timeValueWhileEditing, 'HH:mm');
              this.updateValue({ newTimeValue });
            }
          }}
          onFocus={() => {
            this.setState({ timeValueWhileEditing: timeValue });
          }}
          onBlur={() => {
            const newTimeValue = moment(timeValueWhileEditing, 'HH:mm');
            this.updateValue({ newTimeValue });
          }}
          onChange={(_, { value }) => {
            this.setState({ timeValueWhileEditing: value });
          }}
        />
      </>
    );
  }
}

export default CCDateTimeInput;
