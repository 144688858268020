import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { toast } from 'react-semantic-toasts';
import { connect } from 'react-redux';
import { Button, Header, Form, Grid, Segment, Icon } from 'semantic-ui-react';
import { authUser, authReset, checkAuthKey } from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import { T, getErrorMessage, validateEmail } from '../Common/Helpers';
import logo from '../../layout/images/keino_500x160.png';
import '../../Style/App.css';
import packageJson from '../../../package.json';

class Login extends Component {
  state = {
    username: '',
    password: '',
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.auth.status !== 'success' &&
      this.props.auth.status === 'success'
    ) {
      const { res } = this.props.auth;
      localStorage.setItem('x-auth-key', res.data['x-auth-key']);
      this.props.history.push('/app/dashboard');
    } else if (
      prevProps.auth.status !== 'error' &&
      this.props.auth.status === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.auth.error,
        'Authentication failed'
      );
      toast({
        type: 'error',
        title: T('Authentication failed'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.authReset();
    } else if (
      prevProps.auth.status !== 'timeout' &&
      this.props.auth.status === 'timeout'
    ) {
      toast({
        type: 'error',
        title: T('Error'),
        description: T('Gateway Timeout, please re-login'),
        time: 5000,
      });
      this.props.authReset();
    }
  }

  onValidSubmit(event) {
    event.preventDefault();
    const username = event.target.username.value.trim();
    const password = event.target.password.value.trim();
    this.props.authUser({ username, password });
  }

  validateForm() {
    const { username, password } = this.state;
    return validateEmail(username) && password.length >= 6;
  }

  render() {
    if (this.props.auth.status === 'waiting') {
      return <Spinner />;
    }
    if (this.props.auth.loggedIn) {
      return <Redirect to={{ pathname: '/app/dashboard' }} />;
    }
    return (
      <Grid
        textAlign="center"
        style={{ height: '100vh' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <img
            src={logo}
            alt="Keino Logo"
            style={{ height: 'auto', width: '150px', margin: 'auto' }}
          />
          <Form
            size="large"
            onSubmit={(e) => this.onValidSubmit(e)}
            ref={(ref) => (this.form = ref)}
          >
            <Segment stacked>
              <div className="field">
                <div className="ui left icon input">
                  <Icon name="user" />
                  <input
                    name="username"
                    required
                    type="email"
                    placeholder={T('Email')}
                    autoComplete="username"
                    onChange={(e) =>
                      this.setState({ username: e.target.value.trim() })
                    }
                  />
                </div>
              </div>
              <div className="field">
                <div className="ui left icon input">
                  <Icon name="lock" />
                  <input
                    minLength="6"
                    required
                    placeholder={T('Password')}
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    onChange={(e) =>
                      this.setState({ password: e.target.value.trim() })
                    }
                  />
                </div>
              </div>
              <button
                className="ui primary fluid large button"
                disabled={!this.validateForm()}
                type="submit"
              >
                <i className="sign in alternate icon" />
                {T('SIGN IN')}
              </button>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 8,
                }}
              >
                <Link to="/auth/password" floated="right">
                  {T('Forgot password?')}
                </Link>
              </div>
            </Segment>
          </Form>
          <Segment>
            <a target="blank" href="https://www.prometheusone.io/register">
              <button className="ui secondary large button">
                <i className="mail icon" />
                {T('Or request credentials')}
              </button>
            </a>
          </Segment>
          <Header as="h5" textAlign="left" style={{ alignItems: 'left' }}>
            {packageJson.version}
          </Header>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = ({ auth, language }) => ({
  auth,
  language,
  status: auth.status,
  login: auth.login,
  apps: auth.apps,
  companyName: auth.companyName,
  companyId: auth.companyId,
});

export default connect(mapStateToProps, { authUser, authReset, checkAuthKey })(
  withRouter(Login)
);
