/* eslint-env browser */
import axios from 'axios';
import config from '../../Config/AEConfig';

import {
  FETCH_CATEGORIES_WAITING,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_SUCCESS_NEW,
  FETCH_CATEGORIES_ERROR,
  FETCH_CATEGORIES_RESET,
  FETCH_SEASONCATEGORY_WAITING,
  FETCH_SEASONCATEGORY_SUCCESS,
  FETCH_SEASONCATEGORY_ERROR,
  FETCH_SEASONCATEGORY_RESET,
  FETCH_MATCHCATEGORY_WAITING,
  FETCH_MATCHCATEGORY_SUCCESS,
  FETCH_MATCHCATEGORY_ERROR,
  FETCH_MATCHCATEGORY_RESET,
  // ERROR_GATEWAY_TIMEOUT,
} from '../Types';

import ErrorAction from './ErrorAction';

export const fetchCategories =
  (authKey, ws_id, type = false) =>
  (dispatch) => {
    dispatch({ type: FETCH_CATEGORIES_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    //dispatch({ type: FETCH_CATEGORY_WAITING });
    let url = `${config.BASE_URL}/event/categories?ws_id=${ws_id}`;
    if (type) {
      url += `&type=${type}&module_id=3`;
    } else {
      url += '&module_id=3';
    }
    axios
      .get(url)
      .then((response) => {
        // console.log('actions fetch success');
        dispatch({
          type: FETCH_CATEGORIES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        // console.log(error);
        const payloadType = error.response
          ? ErrorAction(error.response.status, FETCH_CATEGORIES_ERROR)
          : FETCH_CATEGORIES_ERROR;
        dispatch({ type: payloadType, payload: error });
      });
  };

export const fetchCategoriesNew =
  ({ authKey, ws_id, type = false, module_id }) =>
  (dispatch) => {
    dispatch({ type: FETCH_CATEGORIES_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    //dispatch({ type: FETCH_CATEGORY_WAITING });
    let url = `${config.BASE_URL}/event/categories?ws_id=${ws_id}`;
    if (type) {
      url += `&type=${type}&module_id=${module_id}`;
    } else {
      url += '&module_id=3';
    }
    axios
      .get(url)
      .then((response) => {
        // console.log('actions fetch success');
        dispatch({
          type: FETCH_CATEGORIES_SUCCESS_NEW,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        // console.log(error);
        const payloadType = error.response
          ? ErrorAction(error.response.status, FETCH_CATEGORIES_ERROR)
          : FETCH_CATEGORIES_ERROR;
        dispatch({ type: payloadType, payload: error });
      });
  };

export const fetchSeasonCategories =
  (authKey, ws_id, type = 'season') =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_SEASONCATEGORY_WAITING });
    let url = `${config.BASE_URL}/event/categories?ws_id=${ws_id}`;
    if (type) {
      url += `&type=${type}&module_id=3`;
    } else {
      url += '&module_id=3';
    }
    axios
      .get(url)
      .then((response) => {
        // console.log('actions fetch success');
        dispatch({
          type: FETCH_SEASONCATEGORY_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        // console.log(error);
        const payloadType = error.response
          ? ErrorAction(error.response.status)
          : FETCH_SEASONCATEGORY_ERROR;
        dispatch({ type: payloadType, payload: error });
      });
  };

export const fetchMatchCategories =
  (authKey, ws_id, type = 'match') =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_MATCHCATEGORY_WAITING });
    let url = `${config.BASE_URL}/event/categories?ws_id=${ws_id}`;
    if (type) {
      url += `&type=${type}&module_id=3`;
    } else {
      url += '&module_id=3';
    }
    axios
      .get(url)
      .then((response) => {
        // console.log('actions fetch success');
        dispatch({
          type: FETCH_MATCHCATEGORY_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        // console.log(error);
        const payloadType = error.response
          ? ErrorAction(error.response.status)
          : FETCH_MATCHCATEGORY_ERROR;
        dispatch({ type: payloadType, payload: error });
      });
  };

export const resetFetchCategories = () => ({ type: FETCH_CATEGORIES_RESET });
export const resetFetchSeasonCategories = () => ({
  type: FETCH_SEASONCATEGORY_RESET,
});
export const resetFetchMatchCategories = () => ({
  type: FETCH_MATCHCATEGORY_RESET,
});
