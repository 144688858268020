import React, { Component } from 'react';
import {
  Message,
  Table,
  Icon,
  Pagination,
  Button,
  Menu,
  Dropdown,
} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/fi';
import { connect } from 'react-redux';
import {
  downloadSoldItemsReport,
  fetchSoldItemsReport,
} from '../../../Redux/actions';
import { T, convertPriceWithSymbol } from '../../Common/Helpers';
import { Spinner } from '../../Common/Spinner';

class SoldItemsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByColumn: this.props.headers.sort.split(':')[0],
      direction: this.getSortDirection(this.props.headers.sort.split(':')[1]),
      lang: this.props.primaryLanguage.ln_alpha_2,
    };
  }

  getSortDirection = (dirn) => (dirn === 'asc' ? 'ascending' : 'descending');

  getLanguageList = () =>
    _.map(this.props.languages, (language) => ({
      text: T(language.ln_name),
      value: language.ln_alpha_2,
    }));

  /* A function that is called when the user clicks on a column header to sort the data. */
  handleSortingReport = (columnName, dirn) => {
    const sortByColumn = columnName;
    let direction = 'ascending';
    if (this.state.sortByColumn === columnName) {
      direction = dirn === 'ascending' ? 'descending' : 'ascending';
    }
    const formatedDirn = direction === 'ascending' ? 'asc' : 'desc';
    const sort = `${columnName}:${formatedDirn}`;
    const { headers } = this.props;
    const {
      ws_id,
      companyId,
      all,
      sl_price_list,
      sl_shop,
      reportRange,
      sl_status,
    } = this.props.searchParams;
    const start = 1; // always sort starts from first page
    const authKey = localStorage.getItem('x-auth-key');
    this.props.fetchSoldItemsReport({
      authKey,
      start,
      companyId,
      range: reportRange,
      all,
      sl_price_list,
      sl_shop,
      searchterm: headers.searchterm,
      sort,
      ws_id,
      status: sl_status,
    });
    this.setState({ sortByColumn, direction });
  };

  /* A function that is called when the user clicks on a page number or arrow in the pagination component. */
  handlePaginationChange = (e, { activePage }) => {
    const { ws_id } = this.props;
    const authKey = localStorage.getItem('x-auth-key');
    const { headers } = this.props;
    const { companyId, all, sl_price_list, sl_shop, reportRange, sl_status } =
      this.props.searchParams;
    this.props.fetchSoldItemsReport({
      authKey,
      start: activePage,
      companyId,
      range: reportRange,
      all,
      sl_price_list,
      sl_shop,
      searchterm: headers.searchterm,
      sort: headers.sort,
      ws_id,
      status: sl_status,
    });
  };

  /* A function that is called when the user clicks on the download button. */
  downloadReport = (format) => {
    const {
      ws_id,
      companyId,
      all,
      sl_price_list,
      sl_shop,
      sort,
      reportRange,
      searchterm,
      sl_status,
    } = this.props.searchParams;
    const { lang } = this.state;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.downloadSoldItemsReport({
      authKey,
      companyId,
      lang,
      fileFormat: format,
      range: reportRange,
      all,
      sl_price_list,
      sl_shop,
      searchterm,
      sort,
      ws_id,
      status: sl_status,
    });
  };

  renderGrid = (sales, status) =>
    _.map(sales, (sale, i) => (
      <Table.Row key={`row-${i}`}>
        <Table.Cell key={`Id-${i}`}> {sale.sales_id} </Table.Cell>
        <Table.Cell key={`Status-${i}`}>
          {sale.sl_status ? status[sale.sl_status] : ''}
        </Table.Cell>
        <Table.Cell key={`Validated-${i}`}>
          {sale.validated_tickets || ''}
        </Table.Cell>
        <Table.Cell key={`Ordered-${i}`}>
          {moment(sale.sl_order_date).format('l HH:mm:ss')}
        </Table.Cell>
        <Table.Cell key={`Delivered-${i}`}>
          {sale.sl_delivery_date &&
            moment(sale.sl_delivery_date).format('l HH:mm:ss')}
        </Table.Cell>
        <Table.Cell key={`Name-${i}`}>
          {sale.sl_user_lastname || sale.sl_user_firstname
            ? `${sale.sl_user_lastname} ${sale.sl_user_firstname}`
            : ''}
        </Table.Cell>
        <Table.Cell key={`Email-${i}`}>{sale.sl_user_email}</Table.Cell>
        <Table.Cell key={`Product-${i}`}>{sale.sr_name} </Table.Cell>
        <Table.Cell key={`Product Code-${i}`}>{sale.sr_code}</Table.Cell>
        <Table.Cell key={`Qty-${i}`}>{sale.sr_qty_ordered}</Table.Cell>
        <Table.Cell key={`Ex. VAT-${i}`}>
          {convertPriceWithSymbol(
            sale.sr_currency,
            sale.sr_row_price_without_tax
          )}
        </Table.Cell>
        <Table.Cell key={`Inc VAT-${i}`}>
          {convertPriceWithSymbol(sale.sr_currency, sale.sr_row_price)}
        </Table.Cell>
        <Table.Cell key={`VAT %-${i}`}>{sale.sr_tax_percentage}</Table.Cell>
      </Table.Row>
    ));

  render() {
    const { sales } = this.props.summary;
    const { headers } = this.props;
    if (this.props.status === 'waiting') {
      return <Spinner />;
    } else if (this.props.status === 'error') {
      return (
        <Message error>
          <Message.Title> {T('Error')} </Message.Title>
          <Message.Content>
            {T('Error occured while fetching sold items report')}
          </Message.Content>
        </Message>
      );
    }
    const { sortByColumn, direction } = this.state;
    const statusText = {
      1: T('Open'),
      2: T('Paid'),
      3: T('Picked up'),
      5: T('Delivered'),
      9: T('Canceled'),
    };
    return (
      <>
        <Menu text size="small">
          <Menu.Menu>
            <Menu.Item header>{T('Sold Items Report')}</Menu.Item>
          </Menu.Menu>
          <Menu.Menu position="right">
            <Dropdown
              required
              search
              item
              name="lang"
              label={T('Language')}
              value={this.state.lang}
              options={this.getLanguageList()}
              onChange={(e, { name, value }) =>
                this.setState({ [name]: value })
              }
            />
            <Menu.Item>
              <Button.Group>
                <Button
                  icon="file pdf"
                  label={T('PDF')}
                  onClick={() => this.downloadReport('pdf')}
                />
                <Button
                  icon="file excel"
                  label={T('CSV')}
                  onClick={() => this.downloadReport('csv')}
                />
                <Button
                  icon="file excel"
                  label={T('XLS')}
                  onClick={() => this.downloadReport('xls')}
                />
              </Button.Group>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Table sortable celled textAlign="left" striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={sortByColumn === 'sales_id' ? direction : null}
                onClick={() => this.handleSortingReport('sales_id', direction)}
              >
                {T('Id')}
              </Table.HeaderCell>
              <Table.HeaderCell> {T('Status')} </Table.HeaderCell>
              <Table.HeaderCell> {T('Käyt.')}</Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortByColumn === 'sl_order_date' ? direction : null}
                onClick={() =>
                  this.handleSortingReport('sl_order_date', direction)
                }
              >
                {T('Ordered')}
              </Table.HeaderCell>
              <Table.HeaderCell> {T('Delivered')} </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortByColumn === 'sl_user_lastname' ? direction : null}
                onClick={() =>
                  this.handleSortingReport('sl_user_lastname', direction)
                }
              >
                {T('Name')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortByColumn === 'sl_user_email' ? direction : null}
                onClick={() =>
                  this.handleSortingReport('sl_user_email', direction)
                }
              >
                {T('Email')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortByColumn === 'sr_name' ? direction : null}
                onClick={() => this.handleSortingReport('sr_name', direction)}
              >
                {T('Product')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortByColumn === 'sr_code' ? direction : null}
                onClick={() => this.handleSortingReport('sr_code', direction)}
              >
                {T('Product Code')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortByColumn === 'sr_qty_ordered' ? direction : null}
                onClick={() =>
                  this.handleSortingReport('sr_qty_ordered', direction)
                }
              >
                {T('Qty')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  sortByColumn === 'sr_row_price_without_tax' ? direction : null
                }
                onClick={() =>
                  this.handleSortingReport(
                    'sr_row_price_without_tax',
                    direction
                  )
                }
              >
                {T('Ex. VAT')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortByColumn === 'sr_row_price' ? direction : null}
                onClick={() =>
                  this.handleSortingReport('sr_row_price', direction)
                }
              >
                {T('Inc VAT')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortByColumn === 'sr_tax_percentage' ? direction : null}
                onClick={() =>
                  this.handleSortingReport('sr_tax_percentage', direction)
                }
              >
                {T('VAT %')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderGrid(sales, statusText)}</Table.Body>
          {headers && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="12" textAlign="right">
                  <Pagination
                    onPageChange={this.handlePaginationChange}
                    defaultActivePage={headers ? headers.current_page : 1}
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    totalPages={headers ? headers.total_pages : 1}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      </>
    );
  }
}
function mapStateToProps({ workspace, sales, languages }) {
  return {
    ws_id: workspace.ws_id,
    summary: sales.summary.soldItems,
    status: sales.status.soldItems,
    headers: sales.headers.soldItems,
    primaryLanguage: languages.primaryLanguage,
  };
}
export default connect(mapStateToProps, {
  downloadSoldItemsReport,
  fetchSoldItemsReport,
})(SoldItemsGrid);
