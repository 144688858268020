import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Button } from 'semantic-ui-react';
import {} from 'semantic-ui-calendar-react-yz';
import DateRangeSelection from './DateRangeSelection';
import moment from 'moment';
import { T, validateHhMm } from '../../Common/Helpers';

const styles = {
  angleArrow: {
    height: 'inherit',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: '40px',
  },
};

const DATE_FORMAT = 'l';
const MONTH = 'month';
const IS_WEEK = 'isoweek';
const CUSTOM = 'custom';
const START_TIME = '08:00';
const END_TIME = '15:30';

class SospaReportDateRange extends Component {
  constructor(props) {
    super(props);
    const { activeSubItem, defaults } = props;
    this.state = {
      activeSubItem: activeSubItem ? activeSubItem : CUSTOM,
      startError: {},
      endError: {},
      startTime: this.props.limitByTime ? this.props.startTime : START_TIME,
      endTime: this.props.limitByTime ? this.props.endTime : END_TIME,
      limitByTime: this.props.limitByTime || false,
      pPercent: this.props.pPercent || false,
      hideZeros: this.props.hideZeros || true,
      hideTurku: this.props.hideTurku || false,
      hideOulu: this.props.hideOulu || false,
      removeDutyHours: this.props.removeDutyHours || true,
      onlyDutyHours: this.props.onlyDutyHours || true,
      reportRange: {
        day: {
          start:
            activeSubItem === 'day' && this.props.start
              ? this.props.start
              : moment().format(DATE_FORMAT),
          end:
            activeSubItem === 'day' && this.props.end
              ? this.props.end
              : moment().format(DATE_FORMAT),
        },
        month: {
          start:
            activeSubItem === MONTH && this.props.start
              ? this.props.start
              : moment()
                  .startOf(MONTH)
                  .subtract(1, 'months')
                  .format(DATE_FORMAT),
          end:
            activeSubItem === MONTH && this.props.end
              ? this.props.end
              : moment().startOf(MONTH).subtract(1, 'days').format(DATE_FORMAT),
        },
        isoweek: {
          start:
            activeSubItem === IS_WEEK && this.props.start
              ? this.props.start
              : moment()
                  .startOf(IS_WEEK)
                  .subtract(1, 'weeks')
                  .format(DATE_FORMAT),
          end:
            activeSubItem === IS_WEEK && this.props.end
              ? this.props.end
              : moment()
                  .startOf(IS_WEEK)
                  .subtract(1, 'days')
                  .format(DATE_FORMAT),
        },
        custom: {
          start:
            activeSubItem === CUSTOM && this.props.start
              ? this.props.start
              : moment()
                  .subtract(1, 'years')
                  .startOf('month')
                  .format(DATE_FORMAT),
          end:
            activeSubItem === CUSTOM && this.props.end
              ? this.props.end
              : moment()
                  .subtract(1, 'months')
                  .endOf('month')
                  .format(DATE_FORMAT),
        },
      },
      ...defaults,
    };
  }

  setActiveMenu = (key, activeItem) => this.setState({ [key]: activeItem });

  handleDateChange = (name, value) => {
    const { activeSubItem } = this.state;
    const reportRange = _.cloneDeep(this.state.reportRange);
    reportRange[activeSubItem][name] = value;
    if (
      moment(reportRange[activeSubItem].start, DATE_FORMAT) <=
      moment(reportRange[activeSubItem].end, DATE_FORMAT)
    ) {
      this.setState({ [`${name}Error`]: {}, reportRange });
    } else {
      this.setState({ [`${name}Error`]: styles.errorStyle });
    }
  };

  handleProvinceChange = (name, value) => {
    //this.setState({ [name]: value });
    this.props.handleChange(name, value);
    const arr = this.props.momentOptions.event_places
      .filter((pl) => pl.pl_parent === value[0])
      .map((pl) => pl.place_id);
    this.props.handleChange('place_ids', arr);
  };

  changeRange = (op, rangeType) => {
    let diff = 'days';
    if (rangeType === MONTH) {
      diff = 'months';
    } else if (rangeType === IS_WEEK) {
      diff = 'weeks';
    }

    const range = _.cloneDeep(this.state.reportRange); // deep copy needed for the nested object
    if (op === 'add') {
      range[rangeType].start = moment(range[rangeType].start, DATE_FORMAT)
        .add(1, diff)
        .format(DATE_FORMAT);
      range[rangeType].end = moment(range[rangeType].start, DATE_FORMAT)
        .endOf(rangeType)
        .format(DATE_FORMAT);
    } else {
      range[rangeType].start = moment(range[rangeType].start, DATE_FORMAT)
        .subtract(1, diff)
        .format(DATE_FORMAT);
      range[rangeType].end = moment(range[rangeType].start, DATE_FORMAT)
        .endOf(rangeType)
        .format(DATE_FORMAT);
    }
    if (moment(range[rangeType].end, DATE_FORMAT) <= new Date()) {
      this.setState({ reportRange: range, startError: {}, endError: {} });
    }
  };

  onDisplayStatistic(statisticType) {
    const reportRange = _.cloneDeep(this.state.reportRange);
    const { start, end } = reportRange[this.state.activeSubItem];
    const {
      startTime,
      endTime,
      limitByTime,
      activeSubItem,
      pPercent,
      hideZeros,
      hideTurku,
      hideOulu,
      removeDutyHours,
      onlyDutyHours,
    } = this.state;
    this.props.onFetchStatistics({
      start,
      end,
      startTime,
      endTime,
      limitByTime,
      activeSubItem,
      statisticType,
      pPercent,
      hideZeros,
      hideTurku,
      hideOulu,
      removeDutyHours,
      onlyDutyHours,
    });
  }

  onDownloadExelGraphic(statisticType) {
    const reportRange = _.cloneDeep(this.state.reportRange);
    const { start, end } = reportRange[this.state.activeSubItem];
    const {
      startTime,
      endTime,
      limitByTime,
      activeSubItem,
      pPercent,
      hideZeros,
      hideTurku,
      hideOulu,
      removeDutyHours,
      onlyDutyHours,
    } = this.state;
    this.props.onDownloadStatistics({
      start,
      end,
      startTime,
      endTime,
      limitByTime,
      activeSubItem,
      statisticType,
      pPercent,
      hideZeros,
      hideTurku,
      hideOulu,
      removeDutyHours,
      onlyDutyHours,
      excelGraphic: true,
    });
  }

  onDownloadStatistic(statisticType) {
    const reportRange = _.cloneDeep(this.state.reportRange);
    const { start, end } = reportRange[this.state.activeSubItem];
    const {
      startTime,
      endTime,
      limitByTime,
      activeSubItem,
      pPercent,
      hideZeros,
      hideTurku,
      hideOulu,
      removeDutyHours,
      onlyDutyHours,
    } = this.state;
    this.props.onDownloadStatistics({
      start,
      end,
      startTime,
      endTime,
      limitByTime,
      activeSubItem,
      statisticType,
      pPercent,
      hideZeros,
      hideTurku,
      hideOulu,
      removeDutyHours,
      onlyDutyHours,
      excelGraphic: false,
    });
  }
  handleMunicipalityChange({ name, value, options }, places) {
    const selected_root_places = options
      .filter((o) => o.isroot && value.includes(o.value))
      .map((o) => o.value);
    if (selected_root_places.length > 0) {
      const values = places
        .filter((pl) => selected_root_places.includes(pl.pl_parent))
        .map((pl) => pl.place_id);
      this.props.handleChange(name, values);
    } else {
      this.props.handleChange(name, value);
    }
  }

  render() {
    //const { activeSubItem } = this.props;
    const {
      activeSubItem,
      limitByTime,
      startTime,
      endTime,
      pPercent,
      hideZeros,
      hideTurku,
      hideOulu,
      removeDutyHours,
      onlyDutyHours,
    } = this.state;
    const { statisticType, status, downloadStatus, options } = this.props;
    const {
      splittable,
      showPercentCheckbox,
      showHideZeroCheckbox,
      showHideTurkuCheckbox,
      showHideOuluCheckbox,
      showRemoveDutyHoursCheckbox,
      displayPlaceFilter,
      displayClientPlaces,
    } = options || {};
    const cnc_context = this.props.activeWorkspace.cnc_context;

    const ev_places = this.props.momentOptions.event_places
      ? this.props.momentOptions.event_places
          .filter((pl) => pl.pl_name !== 'sote-kunnat')
          .map((ep) => ({
            ...ep,
            isroot: false,
          }))
      : [];
    const cl_places = this.props.momentOptions.client_places
      ? this.props.momentOptions.client_places
          .filter((pl) => pl.pl_name !== 'sote-kunnat')
          .map((cp) => ({
            ...cp,
            isroot: false,
          }))
      : [];
    let tmp_places = this.props.momentOptions.event_places
      ? [
          ...this.props.momentOptions.event_places.filter(
            (pl) => pl.pl_name !== 'sote-kunnat'
          ),
        ]
      : [];
    this.props.momentOptions.client_places
      ?.filter((pl) => pl.pl_name !== 'sote-kunnat')
      .forEach((pl) => {
        if (!tmp_places.find((tp) => tp.place_id === pl.place_id)) {
          tmp_places.push(pl);
        }
      });
    const dp_places = tmp_places.map((dp) => ({
      ...dp,
      isroot: false,
    }));
    let event_places = [];
    let client_places = [];
    let display_places = [];
    if (cnc_context === 20002) {
      const root_places = this.props.momentOptions.event_root_places.map(
        (rp) => ({
          ...rp,
          isroot: true,
        })
      );
      client_places = root_places.concat(cl_places);
      event_places = root_places.concat(ev_places);
      display_places = root_places.concat(dp_places);
    } else {
      event_places = ev_places;
      client_places = cl_places;
      display_places = dp_places;
    }

    return (
      <Form>
        <DateRangeSelection
          activeSubItem={activeSubItem}
          setActiveMenu={this.setActiveMenu.bind(this)}
          reportRange={this.state.reportRange}
          changeRange={this.changeRange.bind(this)}
          handleDateChange={this.handleDateChange.bind(this)}
        />
        <Form.Group widths="equal">
          <Form.Field
            checked={limitByTime}
            label={T('Limit the task according to the start time')}
            control="input"
            type="checkbox"
            onChange={() => this.setState({ limitByTime: !limitByTime })}
          />
          {limitByTime && (
            <Form.Input
              label={T('Starting from')}
              type="time"
              value={startTime}
              error={!validateHhMm(startTime)}
              onChange={(e, data) => this.setState({ startTime: data.value })}
            />
          )}
          {limitByTime && (
            <Form.Input
              label={T('Ending to')}
              type="time"
              value={endTime}
              error={!validateHhMm(endTime)}
              onChange={(e, data) => this.setState({ endTime: data.value })}
            />
          )}
        </Form.Group>
        <Form.Group>
          {showRemoveDutyHoursCheckbox ? (
            <Form.Field
              checked={removeDutyHours}
              label={T(
                'Jätä huomiotta arkipäivänä välillä 8:00 - 15:30 tulleet tapahtumat'
              )}
              control="input"
              type="checkbox"
              onChange={() => {
                this.setState({ removeDutyHours: !removeDutyHours });
                if (!removeDutyHours) {
                  this.setState({ onlyDutyHours: false });
                }
              }}
            />
          ) : null}
          {showRemoveDutyHoursCheckbox ? (
            <Form.Field
              checked={onlyDutyHours}
              label={T(
                'Näytä vain arkipäivänä välillä 8:00 - 15:30 tulleet tapahtumat'
              )}
              control="input"
              type="checkbox"
              onChange={() => {
                this.setState({ onlyDutyHours: !onlyDutyHours });
                if (!onlyDutyHours) {
                  this.setState({ removeDutyHours: false });
                }
              }}
            />
          ) : null}
        </Form.Group>
        <Form.Group widths="equal">
          {showPercentCheckbox ? (
            <Form.Field
              checked={pPercent}
              label={T('Calculate percentages based on the entire province')}
              control="input"
              type="checkbox"
              onChange={() => this.setState({ pPercent: !pPercent })}
            />
          ) : null}
        </Form.Group>
        <Form.Group widths="equal">
          {showHideZeroCheckbox ? (
            <Form.Field
              checked={hideZeros}
              label={T('Piilota kunnat, joilla ei ole tapahtumia')}
              control="input"
              type="checkbox"
              onChange={() => this.setState({ hideZeros: !hideZeros })}
            />
          ) : null}
          {showHideTurkuCheckbox ? (
            <Form.Field
              checked={hideTurku}
              label={T('Jätä Turku kokonaan pois laskelmista')}
              control="input"
              type="checkbox"
              onChange={() => this.setState({ hideTurku: !hideTurku })}
            />
          ) : null}
          {showHideOuluCheckbox ? (
            <Form.Field
              checked={hideOulu}
              label={T('Jätä Oulu kokonaan pois laskelmista')}
              control="input"
              type="checkbox"
              onChange={() => this.setState({ hideOulu: !hideOulu })}
            />
          ) : null}
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Dropdown
            clearable
            multiple
            search
            selection
            name="place_ids"
            placeholder={T('All Municipalities')}
            label={T('Event Municipality')}
            options={event_places.map(({ pl_name, place_id, isroot }) => ({
              key: place_id,
              text: pl_name,
              value: place_id,
              'data-isroot': isroot,
            }))}
            onChange={(e, data) =>
              this.handleMunicipalityChange(data, event_places)
            }
            value={this.props.place_ids || []}
          />
          {displayClientPlaces && (
            <Form.Dropdown
              clearable
              multiple
              search
              selection
              name="client_place_ids"
              placeholder={T('All Municipalities')}
              label={T('Asiakkaan kotikunta')}
              options={client_places.map(({ pl_name, place_id, isroot }) => ({
                key: place_id,
                text: pl_name,
                value: place_id,
                'data-isroot': isroot,
              }))}
              onChange={(e, data) =>
                this.handleMunicipalityChange(data, client_places)
              }
              value={this.props.client_place_ids || []}
            />
          )}
        </Form.Group>
        {displayPlaceFilter && (
          <Form.Group widths="equal">
            <Form.Dropdown
              clearable
              multiple
              search
              selection
              name="display_place_ids"
              placeholder={T('Näytä oma pylväs kaikille kunnille')}
              label={T('Niputa muut-sarakkeeseen muut paitsi nämä kunnat')}
              options={display_places.map(({ pl_name, place_id, isroot }) => ({
                key: place_id,
                text: pl_name,
                value: place_id,
                'data-isroot': isroot,
              }))}
              onChange={(e, data) =>
                this.handleMunicipalityChange(data, display_places)
              }
              value={this.props.display_place_ids || []}
            />
          </Form.Group>
        )}
        <Form.Group widths="equal">
          <Form.Select
            clearable
            multiple
            search
            selection
            name="source_ids"
            placeholder={T('All Sources')}
            label={T('Source')}
            options={
              this.props.momentOptions?.source?.map((s) => ({
                text: s.pf_name,
                value: s.performer_id,
              })) ?? []
            }
            onChange={(e, { name, value }) =>
              this.props.handleChange(name, value)
            }
            value={this.props.source_ids || []}
          />
          <Form.Select
            clearable
            multiple
            search
            selection
            name="reason_ids"
            placeholder={T('All Reasons')}
            label={T('Reason')}
            options={
              this.props.momentOptions?.reasons?.map((r) => ({
                text: r.ec_name,
                value: r.eventcategory_id,
              })) ?? []
            }
            onChange={(e, { name, value }) =>
              this.props.handleChange(name, value)
            }
            value={this.props.reason_ids || []}
          />
          <Form.Select
            clearable
            multiple
            search
            selection
            name="mt_keys"
            placeholder={T('All Moment Types')}
            label={T('Moment type')}
            options={
              this.props.momentOptions?.moments?.map((mo) => ({
                text: mo.name,
                value: mo.id,
              })) ?? []
            }
            onChange={(e, { name, value }) =>
              this.props.handleChange(name, value)
            }
            value={this.props.mt_keys || []}
          />
          {cnc_context === 20002 ? null : (
            <Form.Select
              clearable
              multiple
              search
              selection
              name="ev_types"
              placeholder={T('All Client Types')}
              label={T('Client Type')}
              options={
                this.props.momentOptions?.types?.map((t) => ({
                  text: t.value,
                  value: t.code,
                })) ?? []
              }
              onChange={(e, { name, value }) =>
                this.props.handleChange(name, value)
              }
              value={this.props.ev_types || []}
            />
          )}
        </Form.Group>

        <Form.Group>
          <Button
            disabled={status === 'waiting' || downloadStatus === 'waiting'}
            loading={status === 'waiting'}
            primary
            content={T('Hae')}
            onClick={() => this.onDisplayStatistic(statisticType)}
          />
          <Button
            disabled={status === 'waiting' || downloadStatus === 'waiting'}
            loading={downloadStatus === 'waiting'}
            primary
            content={T('Luo Excel-graafi')}
            onClick={() => this.onDownloadExelGraphic(statisticType)}
          />{' '}
        </Form.Group>
        {splittable ? (
          <Form.Group>
            <Form.Select
              selection
              name="slice_size"
              label={T(splittable)}
              options={[
                { text: 'Kaikki', value: 1000 },
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: '30', value: 30 },
                { text: '50', value: 50 },
              ]}
              onChange={(e, { name, value }) =>
                this.props.handleChange(name, value)
              }
              value={this.props.slice_size || []}
            />
          </Form.Group>
        ) : null}
      </Form>
    );
  }
}

export default SospaReportDateRange;
