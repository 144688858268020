import {
  FORGET_SUCCESS,
  FORGET_WAITING,
  FORGET_ERROR,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = { status: 'init', res: null };

export default function ForgetPasswordReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FORGET_SUCCESS:
      return { ...state, res: action.payload.data, status: 'success' };
    case FORGET_WAITING:
      return { ...state, status: 'waiting' };
    case FORGET_ERROR:
      return { ...state, status: 'error', error: action.payload.error };
    default:
      return state;
  }
}
