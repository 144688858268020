import React, { Component } from 'react';
// import UIfx from 'uifx';
import { Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { fetchShops, fetchSales } from '../../Redux/actions';
class Sales extends Component {
  componentDidMount() {
    // const authKey = localStorage.getItem('x-auth-key');
    //      ({ authKey, shopId, module_id = SALES_MODULE_ID, ws_id }) =>
    // this.props.fetchSales({ authKey, shopId, ws_id });
  }
  render() {
    return <Label>Foo</Label>;
  }
}

function mapStateToProps({ auth, shop, workspace }) {
  return { auth, shop, workspace };
}

export default connect(mapStateToProps, { fetchShops, fetchSales })(Sales);
