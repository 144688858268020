import {
  FETCH_TEMPLATES_WAITING,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_ERROR,
  FETCH_TEMPLATES_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  operationState: {
    add: 'init',
    delete: 'init',
    update: 'init',
    fetch: 'init',
    fetchAll: 'init',
  },
  templates: [],
};

export default function ApplicationTemplateReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_TEMPLATES_WAITING: {
      const operationState = { ...state.operationState, fetchAll: 'waiting' };
      return { ...state, operationState };
    }
    case FETCH_TEMPLATES_SUCCESS: {
      const templates = action.payload.data.items;
      const operationState = { ...state.operationState, fetchAll: 'success' };
      const headers = action.payload.headers;
      return { ...state, operationState, headers, templates };
    }
    case FETCH_TEMPLATES_ERROR: {
      const operationState = { ...state.operationState, fetchAll: 'error' };
      return {
        ...state,
        operationState,
        templates: [],
        error: action.payload.error,
      };
    }
    case FETCH_TEMPLATES_RESET: {
      const operationState = { ...state.operationState, fetchAll: 'init' };
      return { ...state, operationState, templates: [] };
    }

    default:
      return state;
  }
}
