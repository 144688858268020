import axios from 'axios';
import config from '../../Config/AEConfig';

import {
  FETCH_LANGUAGES_WAITING,
  FETCH_LANGUAGES_SUCCESS,
  FETCH_LANGUAGES_ERROR,
  FETCH_LANGUAGES_RESET,
  SET_PRIMARY_LANGUAGE_SUCCESS,
} from '../Types';
import ErrorAction from './ErrorAction';

export function fetchLanguages(authKey, ws_id) {
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_LANGUAGES_WAITING });
    return axios
      .get(`${config.BASE_URL}/languages?ws_id=${ws_id}`)
      .then((response) =>
        dispatch({
          type: FETCH_LANGUAGES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_LANGUAGES_ERROR)
          : FETCH_LANGUAGES_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetFetchLanguages() {
  return { type: FETCH_LANGUAGES_RESET };
}

export const setPrimaryLanguage = ({ primaryLanguage }) => ({
  type: SET_PRIMARY_LANGUAGE_SUCCESS,
  payload: primaryLanguage,
});
