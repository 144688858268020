import _ from 'lodash';
import {
  // WIZARD_MODIFIED_APPLICATION_CONFIG_WAITING,
  // WIZARD_MODIFIED_APPLICATION_CONFIG_SUCCESS,
  // WIZARD_MODIFIED_APPLICATION_CONFIG_ERROR,

  FETCH_APPLICATION_CONFIG_WAITING,
  FETCH_APPLICATION_CONFIG_SUCCESS,
  FETCH_APPLICATION_CONFIG_ERROR,
  FETCH_APPLICATION_CONFIG_RESET,
  MODIFY_APPLICATION_CONFIG_WAITING,
  MODIFY_APPLICATION_CONFIG_SUCCESS,
  MODIFY_APPLICATION_CONFIG_ERROR,
  MODIFY_CONFIG_LOGO_WAITING,
  MODIFY_CONFIG_LOGO_SUCCESS,
  MODIFY_CONFIG_LOGO_ERROR,
  MODIFY_CONFIG_BRAND_COLOR_1_SUCCESS,
  MODIFY_CONFIG_BRAND_COLOR_2_SUCCESS,
  MODIFY_CONFIG_BRAND_COLOR_3_SUCCESS,
  AUTH_RESET,
} from '../Types';

// import {
// MODIFY_CONFIG_WAITING,
// MODIFY_CONFIG_SUCCESS,
// MODIFY_CONFIG_ERROR,
// MODIFY_CONFIG_RESET,
// } from '../Types';

const INIT_STATE = {
  status: 'init',
  fetchStatus: 'init',
  modifyStatus: 'init',
  modifyLogoStatus: 'init',
  modifyAppConfigStatus: 'init',
  appConfig: {},
};

export default function WizardReducer(state = INIT_STATE, action) {
  switch (action.type) {
    // case WIZARD_MODIFIED_APPLICATION_CONFIG_WAITING:
    //   return { ...state, modifyAppConfigStatus: 'waiting' };
    // case WIZARD_MODIFIED_APPLICATION_CONFIG_SUCCESS:
    //   return { ...state, modifyAppConfigStatus: 'success' };
    // case WIZARD_MODIFIED_APPLICATION_CONFIG_ERROR:
    //       return { ...state, modifyAppConfigStatus: 'error' };
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_APPLICATION_CONFIG_WAITING:
      return { ...state, fetchStatus: 'waiting' };
    case FETCH_APPLICATION_CONFIG_SUCCESS: {
      return {
        ...state,
        fetchStatus: 'success',
        appConfig: _.mapKeys(action.payload.data, 'ac_keyword'),
      };
    }
    case FETCH_APPLICATION_CONFIG_ERROR:
      return {
        ...state,
        fetchStatus: 'error',
        appConfig: null,
        error: action.payload.error,
      };
    case FETCH_APPLICATION_CONFIG_RESET:
      return { ...state, fetchStatus: 'init' };

    case MODIFY_APPLICATION_CONFIG_WAITING:
      return { ...state, modifyStatus: 'waiting' };
    case MODIFY_APPLICATION_CONFIG_SUCCESS: {
      const appConfig = {
        ...state.appConfig,
        [action.payload.data.ac_keyword]: action.payload.data,
      };
      // console.log('updated Config: ', appConfig, '\nPayload: ', action.payload.data);
      return { ...state, appConfig, modifyStatus: 'success' };
    }
    case MODIFY_APPLICATION_CONFIG_ERROR: {
      // console.log('Config Error, ', action.payload);
      return { ...state, modifyStatus: 'error', error: action.payload.error };
    }
    case MODIFY_CONFIG_LOGO_WAITING:
      return { ...state, modifyLogoStatus: 'waiting' };
    case MODIFY_CONFIG_LOGO_SUCCESS: {
      return { ...state, modifyLogoStatus: 'success' };
    }
    case MODIFY_CONFIG_LOGO_ERROR:
      return {
        ...state,
        modifyLogoStatus: 'error',
        error: action.payload.error,
      };

    case MODIFY_CONFIG_BRAND_COLOR_1_SUCCESS: {
      const appConfig = {
        ...state.appConfig,
        brandColor1: action.payload.data,
      };
      return { ...state, appConfig, modifyStatus: 'success' };
    }
    case MODIFY_CONFIG_BRAND_COLOR_2_SUCCESS: {
      const appConfig = {
        ...state.appConfig,
        brandColor2: action.payload.data,
      };
      return { ...state, appConfig, modifyStatus: 'success' };
    }
    case MODIFY_CONFIG_BRAND_COLOR_3_SUCCESS: {
      const appConfig = {
        ...state.appConfig,
        brandColor3: action.payload.data,
      };
      return { ...state, appConfig, modifyStatus: 'success' };
    }
    default:
      return state;
  }
}
