import React, { Component } from 'react';
import { Form, Image, Button, Message } from 'semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { editShopSettings } from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';

class AppShopSettingsManager extends Component {
  constructor(props) {
    super(props);
    const stateObj = this.getSettings();
    this.state = {
      ...stateObj,
    };
  }
  UNSAFE_componentWillUpdate(nextProps) {
    if (
      this.props.editStaus !== 'success' &&
      nextProps.editStatus === 'sucess'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Shop name edited successfully'),
        time: 5000,
      });
    } else if (
      this.props.editStatus !== 'error' &&
      nextProps.editStaus === 'error'
    ) {
      const errorObj = getErrorMessage(
        nextProps.error,
        'Could not edit shop name'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  onSubmit = (values) => {
    const { ws_id } = this.props;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.editShopSettings({ authKey, values, ws_id });
  };

  onChange = (val, { name, value }) => {
    this.setState({ [name]: value });
  };

  getSettings = () => {
    const { shopSettings } = this.props;
    const stateObj = {};
    _.forEach(shopSettings, (settings, key) => {
      stateObj[key] = settings.bc_value;
    });
    return stateObj;
  };

  updateSetting = (setting) => {
    const { appId, ws_id } = this.props;
    const authKey = localStorage.getItem('x-auth-key');
    const values = { ...setting };
    values.bc_value = this.state[values.bc_keyword];
    values.appId = appId;
    values.ws_id = ws_id;
    this.props.editShopSettings({ authKey, values, ws_id });
  };

  renderFormInput = () => {
    const { shopKeywords, shopSettings } = this.props;
    if (Object.keys(shopSettings).length === 0) {
      return (
        <Message
          info
          icon="info"
          header={T('There are no available settings for the selected app')}
        />
      );
    }
    return _.map(shopSettings, (keywordObj, key) =>
      key !== 'backgroundImage' ? (
        <Form.Group widths="16" key={`form-group-${key}`}>
          <span style={{ width: '100px', textAlign: 'right' }}>
            {T(`${shopKeywords[key].dk_description}`)}
          </span>
          <Form.Input
            key={`input-${key}`}
            id={`form-input-control-${key}`}
            type="text"
            style={{ marginLeft: '20px' }}
            width="3"
            name={key}
            value={this.state[key]}
            onChange={this.onChange}
          />
          <Button
            key={`button-${key}`}
            primary
            style={{ marginLeft: '20px' }}
            icon="check circle"
            onClick={() => this.updateSetting(shopSettings[key])}
          />
        </Form.Group>
      ) : (
        <Form.Group widths="16" key={`form-group-${key}`}>
          <span style={{ width: '100px', textAlign: 'right' }}>
            {T(`${shopKeywords[key].dk_description}`)}
          </span>
          <Image
            src={shopSettings[key].bc_value}
            alt={shopKeywords[key].dk_values}
            key={`image-${key}`}
            style={{ marginLeft: '20px' }}
          />
          <Form.Input
            key={`input-${key}`}
            id={`form-input-control-${key}`}
            type="file"
            name={key}
            label={T(`${shopKeywords[key].dk_description}`)}
          />
        </Form.Group>
      )
    );
  };
  render() {
    return <Form>{this.renderFormInput()}</Form>;
  }
}

function mapStateToProps({ shop, workspace, applications }) {
  return {
    ws_id: workspace.activeWorkspace.id,
    editStatus: shop.editShopNameStatus,
    appId: applications.application.application_id,
    error: shop.error,
  };
}
export default connect(mapStateToProps, { editShopSettings })(
  AppShopSettingsManager
);
