import _ from 'lodash';
import {
  // this reducer should be splitted in to three reducers: shop, product & pricelist

  FETCH_SHOP_PRICELIST_WAITING,
  FETCH_SHOP_PRICELIST_SUCCESS,
  FETCH_SHOP_PRICELIST_ERROR,
  FETCH_SHOP_PRICELIST_RESET,
  FETCH_PRICELIST_WAITING,
  FETCH_PRICELIST_SUCCESS,
  FETCH_PRICELIST_ERROR,
  FETCH_PRICELIST_RESET,
  ADD_SHOP_PRICELIST_WAITING,
  ADD_SHOP_PRICELIST_SUCCESS,
  ADD_SHOP_PRICELIST_ERROR,
  ADD_SHOP_PRICELIST_RESET,
  EDIT_SHOP_PRICELIST_WAITING,
  EDIT_SHOP_PRICELIST_SUCCESS,
  EDIT_SHOP_PRICELIST_ERROR,
  EDIT_SHOP_PRICELIST_RESET,
  DELETE_SHOP_PRICELIST_WAITING,
  DELETE_SHOP_PRICELIST_SUCCESS,
  DELETE_SHOP_PRICELIST_ERROR,
  DELETE_SHOP_PRICELIST_RESET,
  FETCH_OUT_OF_STOCK_MESSAGE_WAITING,
  FETCH_OUT_OF_STOCK_MESSAGE_SUCCESS,
  FETCH_OUT_OF_STOCK_MESSAGE_ERROR,
  FETCH_OUT_OF_STOCK_MESSAGE_RESET,
  EDIT_PRICELIST_PRODUCT_ORDER_WAITING,
  EDIT_PRICELIST_PRODUCT_ORDER_SUCCESS,
  EDIT_PRICELIST_PRODUCT_ORDER_ERROR,
  EDIT_PRICELIST_PRODUCT_ORDER_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  plStatus: 'init',
  plAddStatus: 'init',
  plEditStatus: 'init',
  plDeleteStatus: 'init',
  fetchStockMessageStatus: 'init',
  editPriceListProductOrderStatus: 'init',
  updatedPriceListsOrder: {},
};

export default function PricelistReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_SHOP_PRICELIST_WAITING:
      return { ...state, plStatus: 'waiting' };
    case FETCH_SHOP_PRICELIST_SUCCESS:
      return {
        ...state,
        pricelists: _.mapKeys(action.payload.data.items, 'price_list_id'),
        plStatus: 'success',
      };
    case FETCH_SHOP_PRICELIST_ERROR: {
      return { ...state, plStatus: 'error', error: action.payload.error };
    }
    case FETCH_SHOP_PRICELIST_RESET: {
      return { ...state, plStatus: 'init' };
    }

    case ADD_SHOP_PRICELIST_WAITING:
      return { ...state, plAddStatus: 'waiting' };
    case ADD_SHOP_PRICELIST_SUCCESS: {
      const updatedState = { ...state, plAddStatus: 'success' };
      const dataState = {
        ...updatedState.pricelists,
        [action.payload.data.price_list_id]: action.payload.data,
      };
      return { ...updatedState, pricelists: dataState };
    }
    case ADD_SHOP_PRICELIST_ERROR:
      return { ...state, plAddStatus: 'error', error: action.payload.error };
    case ADD_SHOP_PRICELIST_RESET:
      return { ...state, plAddStatus: 'init' };

    case EDIT_SHOP_PRICELIST_WAITING:
      return { ...state, plEditStatus: 'waiting' };
    case EDIT_SHOP_PRICELIST_SUCCESS:
      return { ...state, plEditStatus: 'success' };
    case EDIT_SHOP_PRICELIST_ERROR:
      return { ...state, plEditStatus: 'error', error: action.payload.error };
    case EDIT_SHOP_PRICELIST_RESET:
      return { ...state, plEditStatus: 'init' };

    case DELETE_SHOP_PRICELIST_WAITING:
      return { ...state, plDeleteStatus: 'waiting' };
    case DELETE_SHOP_PRICELIST_SUCCESS: {
      const updatedState = { ...state, plDeleteStatus: 'success' };
      const dataState = _.omit(
        updatedState.pricelists,
        action.payload.data.price_list_id
      );
      return { ...updatedState, pricelists: dataState };
    }
    case DELETE_SHOP_PRICELIST_ERROR:
      return { ...state, plDeleteStatus: 'error', error: action.payload.error };
    case DELETE_SHOP_PRICELIST_RESET:
      return { ...state, plDeleteStatus: 'reset' };

    case FETCH_PRICELIST_WAITING: {
      const status = { ...state.status };
      status.fetch = 'waiting';
      return { ...state, status };
    }
    case FETCH_PRICELIST_SUCCESS: {
      const status = { ...state.status };
      status.fetch = 'success';
      return { ...state, status, pricelist: action.payload.data };
    }
    case FETCH_PRICELIST_ERROR: {
      const status = { ...state.status };
      status.fetch = 'error';
      return { ...state, status, error: action.payload.error };
    }
    case FETCH_PRICELIST_RESET: {
      const status = { ...state.status };
      status.fetch = 'init';
      return { ...state, status, error: undefined };
    }

    case FETCH_OUT_OF_STOCK_MESSAGE_WAITING:
      return { ...state, fetchOutOfStockMessageStatus: 'waiting' };
    case FETCH_OUT_OF_STOCK_MESSAGE_SUCCESS: {
      const stockMessages = action.payload.data;
      return {
        ...state,
        stockMessages,
        fetchOutOfStockMessageStatus: 'success',
      };
    }
    case FETCH_OUT_OF_STOCK_MESSAGE_ERROR:
      return {
        ...state,
        fetchOutOfStockMessageStatus: 'error',
        error: action.payload.error,
      };
    case FETCH_OUT_OF_STOCK_MESSAGE_RESET:
      return { ...state, fetchOutOfStockMessageStatus: 'init' };

    case EDIT_PRICELIST_PRODUCT_ORDER_WAITING:
      return { ...state, editPriceListProductOrderStatus: 'waiting' };
    case EDIT_PRICELIST_PRODUCT_ORDER_SUCCESS: {
      /*const updatedPriceListsOrder = action.payload.data;
      console.log(updatedPriceListsOrder);
      return { ...state, updatedPriceListsOrder, editPriceListProductOrderStatus: 'success' };*/
      //const status = { ...state.status };
      //status.add = 'success';
      //return { ...state, status, updatedPriceListsOrder: action.payload.data };
      return {
        ...state,
        updatedPriceListsOrder: action.payload.data,
        editPriceListProductOrderStatus: 'success',
      };
    }
    case EDIT_PRICELIST_PRODUCT_ORDER_ERROR:
      return {
        ...state,
        editPriceListProductOrderStatus: 'error',
        error: action.payload.error,
      };
    case EDIT_PRICELIST_PRODUCT_ORDER_RESET:
      return { ...state, editPriceListProductOrderStatus: 'init' };
    default:
      return state;
  }
}
