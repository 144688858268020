import _ from 'lodash';
import {
  FETCH_MODULES_WAITING,
  FETCH_MODULES_SUCCESS,
  FETCH_MODULES_ERROR,
  FETCH_MODULES_RESET,
  SET_MODULE_WAITING,
  SET_MODULE_SUCCESS,
  SET_MODULE_ERROR,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  operationState: {
    add: 'init',
    delete: 'init',
    update: 'init',
    fetch: 'init',
    fetchAll: 'init',
    set: 'init',
  },
  modules: [],
};

export default function ApplicationModulesReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_MODULES_WAITING: {
      const operationState = { ...state.operationState, fetchAll: 'waiting' };
      return { ...state, operationState };
    }
    case FETCH_MODULES_SUCCESS: {
      //console.log('>>', action.payload.data);
      const modules = _.mapKeys(action.payload.data, 'appmodule_id');
      // console.log('modules data', modules);
      const operationState = { ...state.operationState, fetchAll: 'success' };
      const headers = action.payload.headers;
      return { ...state, operationState, headers, modules };
    }
    case FETCH_MODULES_ERROR: {
      const operationState = { ...state.operationState, fetchAll: 'error' };
      return {
        ...state,
        operationState,
        modules: [],
        error: action.payload.error,
      };
    }
    case FETCH_MODULES_RESET: {
      const operationState = { ...state.operationState, fetchAll: 'init' };
      return { ...state, operationState, modules: [] };
    }

    case SET_MODULE_WAITING: {
      const operationState = { ...state.operationState, set: 'waiting' };
      return { ...state, operationState };
    }
    case SET_MODULE_SUCCESS: {
      // console.log('Set Module Success: ', action.payload);
      const module = {
        [action.payload.modules[0].appmodule_id]: {
          ...state.modules[action.payload.modules[0].appmodule_id],
          ...action.payload.modules[0],
        },
      };
      const modules = { ...state.modules, ...module };
      // console.log('Updated modules: ', modules, '\nModule: ', module);
      const operationState = { ...state.operationState, set: 'success' };
      return { ...state, modules, operationState };
    }
    case SET_MODULE_ERROR: {
      const operationState = { ...state.operationState, set: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    default:
      return state;
  }
}
