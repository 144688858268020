/* eslint-env browser */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Menu, Input } from 'semantic-ui-react';
import { fetchNotifications } from '../Redux/actions';
import { T } from '../components/Common/Helpers';

class NotificationNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = { search: '', activeIndex: -1 };
    this.handleSearch = _.debounce(this.handleSearch, 500);
    this.handleOnUpdate = this.handleOnUpdate.bind(this);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (
      this.props.location.pathname !== nextProps.location.pathname &&
      nextProps.search !== ''
    ) {
      this.setState({ search: '' });
    }
  }

  onChange(e) {
    this.setState({ search: e.target.value });
    this.handleSearch(e.target.value);
  }

  handleOnUpdate(e, { width }) {
    this.setState({ width });
  }

  handleSearch(searchterm) {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.ws_id;
    if (authKey === null) {
      this.props.history.push('/auth/login');
    }
    const { notificationHeader } = this.props;

    switch (this.props.location.pathname) {
      case '/app/advertising/list/true': {
        const sort =
          notificationHeader && notificationHeader.sort
            ? notificationHeader.sort
            : 'message_id:asc';
        this.props.fetchNotifications({
          authKey,
          ws_id,
          sortByColumn: sort,
          searchterm,
          application_id: this.props.activeWorkspace.cnc_application,
          sent: 'true',
        });
        // this.props.fetchNotifications({
        //   authKey,
        //   ws_id,
        //   sortByColumn: sort,
        //   start: notificationHeader.current_page,
        //   searchterm,
        //   application_id: this.props.activeWorkspace.cnc_application,
        //   sent: "true",
        // });
        // this.props.fetchNotifications({ authKey, sort, null, notificationHeader.current_page, searchterm });
        break;
      }
      case '/app/advertising/list/false': {
        const sort =
          notificationHeader && notificationHeader.sort
            ? notificationHeader.sort
            : 'message_id:asc';
        this.props.fetchNotifications({
          authKey,
          ws_id,
          sortByColumn: sort,
          searchterm,
        });
        // this.props.fetchNotifications({
        //   authKey,
        //   ws_id,
        //   sortByColumn: sort,
        //   start: notificationHeader.current_page,
        //   searchterm,
        //   application_id: this.props.activeWorkspace.cnc_application,
        //   sent: "false",
        // });
        // this.props.fetchNotifications({ authKey, sort, null, notificationHeader.current_page, searchterm });
        break;
      }
      default:
        console.log('other routes');
    }
  }

  render() {
    return (
      <nav style={{ paddingBottom: 45 }}>
        <Menu stackable fixed="top" style={{ paddingRight: '150px' }}>
          <Menu.Item
            name={T('List')}
            content={T('Push Messages')}
            as={NavLink}
            to={`${this.props.match.path}/list/false`}
          />
          <Menu.Item
            name={T('Archive')}
            content={T('Archived Messages')}
            as={NavLink}
            to={`${this.props.match.path}/list/true`}
          />
          <Menu.Item
            name={T('List')}
            content={T('Add Category')}
            as={NavLink}
            to={`${this.props.match.path}/categories/add`}
          />
          <Menu.Item
            name={T('List')}
            content={T('Push Message Categories')}
            as={NavLink}
            to={`${this.props.match.path}/categories/list`}
          />
          <Menu.Item position="right">
            <Input
              className="icon"
              icon="search"
              value={this.state.search}
              placeholder={T('Search ...')}
              onChange={(e, props) => this.onChange(e, props)}
            />
          </Menu.Item>
        </Menu>
      </nav>
    );
  }
}

function mapStateToProps({ notifications, auth, workspace }) {
  return {
    notificationHeader: notifications.header,
    auth,
    workspace,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
  };
}

export default withRouter(
  connect(mapStateToProps, { fetchNotifications })(withRouter(NotificationNav))
);
