import 'semantic-ui-css/semantic.min.css';
import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import Favicon from 'react-favicon';
import { SemanticToastContainer } from 'react-semantic-toasts';
import reducers from './Redux/reducers';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import 'react-image-crop/dist/ReactCrop.css';
import './Style/App.css';
import Logo from './layout/images/keino_favicon.png';
import AppRouter from './components/Home/AppRouter';

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunk as any),
});

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <Provider store={store}>
    <Favicon url={Logo} />
    <AppRouter />
    <SemanticToastContainer position="top-center" />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
