import axios from 'axios';
import config from '../../Config/AEConfig';
import {
  FETCH_TAXES_WAITING,
  FETCH_TAXES_SUCCESS,
  FETCH_TAXES_ERROR,
  FETCH_TAXES_RESET,
} from '../Types';
import ErrorAction from './ErrorAction';

export const fetchTaxes =
  ({ authKey, company_id, ws_id }) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_TAXES_WAITING });
    const url = `${config.BASE_URL}/taxes/${company_id}?ws_id=${ws_id}`;
    axios
      .get(url)
      .then((response) =>
        dispatch({
          type: FETCH_TAXES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_TAXES_ERROR)
          : FETCH_TAXES_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetTaxes = () => ({ type: FETCH_TAXES_RESET });
