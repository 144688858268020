import axios from 'axios';
import config from '../../Config/AEConfig';
import {
  UPLOAD_FILE_WAITING,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_RESET,
  DELETE_FILE_WAITING,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_ERROR,
} from '../Types';
import ErrorAction from './ErrorAction';

export const uploadFile =
  ({ authKey, formData, module_id, ws_id, ref }) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: UPLOAD_FILE_WAITING, ref });
    /** mock file upload request to avoid unnecessary data on server **/
    // const arr = [
    //   'https://s3-eu-west-1.amazonaws.com/engage-production-files/cms_content/zedoy/large/Jokimaa_logo(7).jpg',
    //   'https://s3-eu-west-1.amazonaws.com/engage-production-files/cms_content/zedoy/large/Jokimaa_logo(6).jpg',
    //   'https://s3-eu-west-1.amazonaws.com/engage-production-files/cms_content/zedoy/large/Jokimaa_logo(5).jpg',
    // ];
    //
    // setTimeout(() => {
    //   // Something you want delayed.
    //   const url = arr[Math.floor(Math.random() * arr.length)];
    //   dispatch({ type: UPLOAD_FILE_SUCCESS, payload: { data: { urlSmall: url, urlLarge: url } }, ref });
    //   // dispatch({
    //   //   type: UPLOAD_FILE_ERROR,
    //   //   payload: { error: 'error uploading file', message: 'failed uploading file' },
    //   //   ref
    //   // });
    // }, 1000);
    // return;
    const url = `${config.BASE_URL}/upload?module_id=${module_id}&ws_id=${ws_id}`;
    axios
      .post(url, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      .then((response) => {
        dispatch({
          type: UPLOAD_FILE_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
          ref,
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, UPLOAD_FILE_ERROR)
          : UPLOAD_FILE_ERROR;
        dispatch({ type, payload: error, ref });
      });
  };

export const deleteFile =
  ({ authKey, file_id, module_id, target, id, ws_id }) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: DELETE_FILE_WAITING, file_id });
    axios
      .delete(
        `${config.BASE_URL}/file/${file_id}?module_id=${module_id}&ws_id=${ws_id}&target=${target}&id=${id}`
      )
      .then((response) =>
        dispatch({
          type: DELETE_FILE_SUCCESS,
          payload: { ...response, file_id },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, DELETE_FILE_ERROR)
          : DELETE_FILE_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetUploadFile = () => ({ type: UPLOAD_FILE_RESET });
