import { Component } from 'react';
import { connect } from 'react-redux';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import moment from 'moment';
import 'moment/locale/fi';
import {
  Table,
  Segment,
  Label,
  Form,
  Confirm,
  Message,
  Icon,
  Header,
  Button,
} from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import { Spinner } from '../Common/Spinner';
import { T, getErrorMessage } from '../Common/Helpers';
import {
  fetchCampaigncodes,
  addCampaigncode,
  editCampaigncode,
  deleteCampaigncode,
  resetFetchCampaigncodes,
  resetAddCampaigncode,
  resetEditCampaigncode,
  resetDeleteCampaigncode,
} from '../../Redux/actions';

const INITIAL_EDIT_CODE = {
  edit_id: null,
  edit_code: '',
  edit_name: '',
  edit_valid_from: '',
  edit_valid_to: '',
  edit_separate_banner: false,
  edit_automatic_for_all: false,
  edit_background_color: '',
  edit_active_color: '',
  edit_inactive_color: '',
};

class Campaigncode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEditMode: false,
      sortByColumn: 'campaigncode_id',
      direction: 'desc',
      deleteCampaigncodeId: null,
      deleteDialogOpen: false,
      displayColorPicker: false,
      ...INITIAL_EDIT_CODE,
    };
  }

  reloadCampaigncodes() {
    const authKey = localStorage.getItem('x-auth-key');
    const { sortByColumn, direction } = this.state;
    const { activeWorkspace } = this.props;
    const { node_collection_id } = activeWorkspace;

    this.props.fetchCampaigncodes({
      authKey,
      ws_id: node_collection_id,
      sortByColumn,
      direction,
    });
  }

  componentDidMount() {
    this.reloadCampaigncodes();
  }

  componentDidUpdate(prevProps) {
    // console.log('Campaigncodes props', this.props);
    if (
      (prevProps.campaign.addCampaigncodeStatus !== 'success' &&
        this.props.campaign.addCampaigncodeStatus === 'success') ||
      (prevProps.campaign.editCampaigncodeStatus !== 'success' &&
        this.props.campaign.editCampaigncodeStatus === 'success') ||
      (prevProps.campaign.deleteCampaigncodeStatus !== 'success' &&
        this.props.campaign.deleteCampaigncodeStatus === 'success')
    ) {
      this.reloadCampaigncodes();
      this.setState({ isEditMode: false });
    }
  }

  editRow(cc) {
    const {
      campaigncode_id,
      cc_code,
      cc_name,
      cc_valid_from,
      cc_valid_to,
      cc_separate_banner,
      cc_background_color,
      cc_active_color,
      cc_inactive_color,
      cc_automatic_for_all,
    } = cc;
    let edit = {
      edit_id: campaigncode_id,
      edit_code: cc_code,
      edit_name: cc_name,
      edit_valid_from: cc_valid_from ? moment(cc_valid_from).format('l') : '',
      edit_valid_to: cc_valid_to ? moment(cc_valid_to).format('l') : '',
      edit_separate_banner: true === cc_separate_banner,
      edit_automatic_for_all: true === cc_automatic_for_all,
      edit_background_color: cc_background_color || '',
      edit_active_color: cc_active_color || '',
      edit_inactive_color: cc_inactive_color || '',
      isEditMode: true,
    };

    this.setState(edit);
  }

  renderCampaigncodes(isWaiting) {
    const { campaign } = this.props;
    const { campaigncodes } = campaign;

    return (
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{T('Koodi')}</Table.HeaderCell>
            <Table.HeaderCell>{T('Nimi')}</Table.HeaderCell>
            <Table.HeaderCell>{T('Alkaen')}</Table.HeaderCell>
            <Table.HeaderCell>{T('Päättyen')}</Table.HeaderCell>
            <Table.HeaderCell collapsing />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {campaigncodes.map((cc) => (
            <Table.Row
              key={cc.campaigncode_id}
              onClick={(e) => {
                this.editRow(cc);
              }}
            >
              <Table.Cell>{cc.cc_code}</Table.Cell>
              <Table.Cell>{cc.cc_name}</Table.Cell>
              <Table.Cell>
                {cc.cc_valid_from ? moment(cc.cc_valid_from).format('l') : ''}
              </Table.Cell>
              <Table.Cell>
                {cc.cc_valid_to ? moment(cc.cc_valid_to).format('l') : ''}
              </Table.Cell>
              <Table.Cell collapsing singleLine>
                <Button
                  icon
                  primary
                  disabled={isWaiting}
                  onClick={() => this.editRow(cc)}
                >
                  <Icon name="edit" />
                  {T('Edit')}
                </Button>
                <Button
                  disabled={isWaiting}
                  negative
                  onClick={() => {
                    this.setState({
                      deleteCampaigncodeId: cc.campaigncode_id,
                      deleteDialogOpen: true,
                    });
                  }}
                >
                  <Icon name="trash alternate" />
                  {T('Delete')}
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

  saveCampaigncode() {
    const authKey = localStorage.getItem('x-auth-key');
    const { activeWorkspace } = this.props;
    const { node_collection_id } = activeWorkspace;

    const {
      edit_id,
      edit_code,
      edit_name,
      edit_valid_from,
      edit_valid_to,
      edit_separate_banner,
      edit_automatic_for_all,
      edit_background_color,
      edit_active_color,
      edit_inactive_color,
    } = this.state;
    const campaigncode = {
      cc_code: edit_code,
      cc_name: edit_name,
      cc_valid_from: moment(edit_valid_from, 'l').toISOString(),
      cc_valid_to: moment(edit_valid_to, 'l').toISOString(),
      cc_automatic_for_all: edit_automatic_for_all,
      cc_separate_banner: edit_separate_banner,
      cc_background_color: edit_background_color,
      cc_active_color: edit_active_color,
      cc_inactive_color: edit_inactive_color,
    };
    if (edit_id) {
      this.props.editCampaigncode({
        campaigncode_id: edit_id,
        campaigncode,
        authKey,
        ws_id: node_collection_id,
      });
    } else {
      this.props.addCampaigncode({
        campaigncode,
        authKey,
        ws_id: node_collection_id,
      });
    }
  }

  handleChange = (e, { type, name, value, checked }) => {
    if (type === 'checkbox') {
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleColorChange = (name, color) => {
    this.setState({ [name]: color.hex });
  };

  handleClick = (picker) => {
    this.setState({
      displayColorPicker:
        this.state.displayColorPicker === picker ? false : picker,
    });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  renderEditCampaigncode(isWaiting) {
    const {
      edit_id,
      edit_code,
      edit_name,
      edit_valid_from,
      edit_valid_to,
      edit_separate_banner,
      edit_automatic_for_all,
      edit_background_color,
      edit_active_color,
      // edit_inactive_color,
    } = this.state;
    const styles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '36px',
          borderRadius: '2px',
        },
        swatch: {
          padding: '5px',
          marginTop: '10px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    const headerText = edit_id
      ? T(`Muokkaa kampanjaa #${edit_id}`)
      : T('Lisää uusi kampanja');
    return (
      <Segment>
        <Label attached="top">{headerText}</Label>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              required
              label={T('Koodi')}
              placeholder={T('Syötä koodi')}
              name="edit_code"
              value={edit_code}
              onChange={this.handleChange}
            />
            <Form.Input
              fluid
              required
              label={T('Nimi')}
              name="edit_name"
              value={edit_name}
              placeholder={T('Anna kampanjalle nimi ')}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <DateInput
              closable
              clearable
              label={T('Alkaen')}
              animation="off"
              name="edit_valid_from"
              placeholder={T('Lisää kampanjan voimassaolon alkamisaika')}
              value={edit_valid_from}
              dateFormat="l"
              onChange={this.handleChange}
            />
            <DateInput
              closable
              clearable
              label={T('Päättyen')}
              animation="off"
              name="edit_valid_to"
              placeholder={T('Lisää kampanjan voimassaolon päättymisaika')}
              value={edit_valid_to}
              dateFormat="l"
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Checkbox
              toggle
              loading={isWaiting}
              name={'edit_automatic_for_all'}
              checked={edit_automatic_for_all}
              label={T(
                'Kampanjan edut näkyvät kaikille (ei vaadi koodin syöttämistä appiin)'
              )}
              onChange={this.handleChange}
            />
            <Form.Checkbox
              toggle
              loading={isWaiting}
              name={'edit_separate_banner'}
              checked={edit_separate_banner}
              label={T('Oma banneri etusivulla')}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <div>
                <Form.Input
                  onClick={() => this.handleClick(1)}
                  label={T('Kampanjaväri (etukuponkien taustaväri)')}
                  name="edit_background_color"
                  value={edit_background_color}
                  placeholder={T('Oletusväri')}
                  onChange={this.handleChange}
                />
                {this.state.displayColorPicker === 1 ? (
                  <div style={styles.popover}>
                    <div style={styles.cover} onClick={this.handleClose} />
                    <SketchPicker
                      color={edit_background_color}
                      onChange={(color) =>
                        this.handleColorChange('edit_background_color', color)
                      }
                    />
                  </div>
                ) : null}
                {edit_background_color && (
                  <div
                    style={styles.swatch}
                    onClick={() => this.handleClick(1)}
                  >
                    <div
                      style={{
                        ...styles.color,
                        backgroundColor: edit_background_color,
                      }}
                    />
                  </div>
                )}
              </div>
            </Form.Field>
            <Form.Field>
              {edit_separate_banner && (
                <div>
                  <Form.Input
                    onClick={() => this.handleClick(2)}
                    label={T('Otsikkotekstin väri kampanjan omassa ikkunassa')}
                    name="edit_active_color"
                    value={edit_active_color}
                    placeholder={T('Oletusväri')}
                    onChange={this.handleChange}
                  />
                  {this.state.displayColorPicker === 2 ? (
                    <div style={styles.popover}>
                      <div style={styles.cover} onClick={this.handleClose} />
                      <SketchPicker
                        color={edit_active_color}
                        onChange={(color) =>
                          this.handleColorChange('edit_active_color', color)
                        }
                      />
                    </div>
                  ) : null}
                  {edit_active_color && (
                    <div
                      style={styles.swatch}
                      onClick={() => this.handleClick(2)}
                    >
                      <div
                        style={{
                          ...styles.color,
                          backgroundColor: edit_active_color,
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            </Form.Field>
          </Form.Group>
          <Button.Group>
            <Form.Button
              disabled={isWaiting}
              loading={isWaiting}
              primary
              onClick={() => this.saveCampaigncode()}
            >
              <Icon name="save" />
              {T('Save')}
            </Form.Button>
            <Form.Button
              disabled={isWaiting}
              secondary
              onClick={() => this.setState({ isEditMode: false })}
            >
              <Icon name="cancel" />
              {T('Cancel')}
            </Form.Button>
          </Button.Group>
        </Form>
      </Segment>
    );
  }

  handleDismiss() {
    this.props.resetFetchCampaigncodes();
    this.props.resetAddCampaigncode();
    this.props.resetEditCampaigncode();
    this.props.resetDeleteCampaigncode();
  }

  render() {
    const {
      campaigncodesStatus,
      deleteCampaigncodeStatus,
      addCampaigncodeStatus,
      editCampaigncodeStatus,
      error,
    } = this.props.campaign;

    let errorMsg = null;
    if (
      campaigncodesStatus === 'error' ||
      deleteCampaigncodeStatus === 'error' ||
      addCampaigncodeStatus === 'error' ||
      editCampaigncodeStatus === 'error'
    ) {
      const errorObj = getErrorMessage(error);
      errorMsg = (
        <Message
          negative
          onDismiss={() => {
            this.handleDismiss();
          }}
          icon="exclamation triangle"
          header={`${T('Error')} ${errorObj.status}`}
          content={T(errorObj.message)}
        />
      );
    }

    const isWaiting =
      campaigncodesStatus === 'waiting' ||
      deleteCampaigncodeStatus === 'waiting' ||
      addCampaigncodeStatus === 'waiting' ||
      editCampaigncodeStatus === 'waiting';

    return (
      <>
        {errorMsg}
        <Header>{T('Kampanjat')}</Header>
        {isWaiting && <Spinner />}
        {!isWaiting && this.renderCampaigncodes(isWaiting)}
        {this.state.isEditMode && this.renderEditCampaigncode(isWaiting)}
        {!this.state.isEditMode && (
          <Button
            disabled={isWaiting}
            primary
            onClick={() =>
              this.setState({ ...INITIAL_EDIT_CODE, isEditMode: true })
            }
          >
            <Icon name="add" />
            {T('Add')}
          </Button>
        )}
        <Confirm
          open={this.state.deleteDialogOpen}
          cancelButton={T('Cancel')}
          header={T('Oletko varma?')}
          content={T('Oletko varma, että haluat poistaa tämän kampanjan?')}
          onCancel={() => this.setState({ deleteDialogOpen: false })}
          onConfirm={() => {
            const authKey = localStorage.getItem('x-auth-key');
            const { activeWorkspace } = this.props;
            const { node_collection_id, cnc_application } = activeWorkspace;
            const campaigncode_id = this.state.deleteCampaigncodeId;

            this.props.deleteCampaigncode({
              campaigncode_id,
              authKey,
              ws_id: node_collection_id,
              application_id: cnc_application,
            });

            this.setState({ deleteDialogOpen: false });
          }}
        />
      </>
    );
  }
}

function mapStateToProps({ campaign, auth, workspace }) {
  return {
    campaign: campaign,
    auth,
    workspace,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
  };
}
export default connect(mapStateToProps, {
  fetchCampaigncodes,
  resetFetchCampaigncodes,
  addCampaigncode,
  resetAddCampaigncode,
  editCampaigncode,
  resetEditCampaigncode,
  deleteCampaigncode,
  resetDeleteCampaigncode,
})(Campaigncode);
