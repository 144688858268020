import { Component } from 'react';
import {
  Form,
  Segment,
  Button,
  Label,
  Message,
  Icon,
  Header,
  Grid,
  Confirm,
  Modal,
} from 'semantic-ui-react';
import { withRouter } from 'react-router';
import moment from 'moment';
import 'moment/locale/fi';
import { connect } from 'react-redux';
import axios from 'axios';
import CCDateTimeInput from './CCDateTimeInput';
import { Spinner } from '../Common/Spinner';
import { T } from '../Common/Helpers';
import config from '../../Config/AEConfig';
import {
  getEventState,
  createMoment,
  getArchivalTimestamp,
  getMetadataValue,
  getEventPerformerByPosition,
} from './WorkCaseUtil';
import { fetchMomentsOptions } from '../../Redux/actions';

const MODULE_ID = 41;
const DEFAULT_WORKER_ID = 5010;

class WorkCaseEdit extends Component {
  state = {
    event: null,
    loadedEvent: null,
    moments: [],
    loadedMoments: [],
    eventcategories: [],
    eventFetchStatus: 'init',
    momentFetchStatus: 'init',
    categoriesFetchStatus: 'init',
    sourceContactMethod: null,
    isInEdit: false,
    addMomentMode: false,
    dateTime: '',
    tags: [],
    saveStatus: 'init',
    error: '',
    saveError: '',
    addMomentId: null,
    momentSaveStatus: 'init',
    deleteMomentIds: [],
    noMomentsDialogOpen: false,
    modalOpen: false,
    modalText: '',
    hakeTime: '',
    workingPairWhileEditing: null,
  };

  initNewEvent() {
    const { cnc_event, cnc_application } = this.props.activeWorkspace;
    const timestamp = new moment();
    const ERCMoment = createMoment({
      performerId: this.getWorkerId(),
      key: 'ERC',
      value: timestamp.format('HH:mm'),
      period: 1,
      timestamp,
    });
    const eventStartMoment = createMoment({
      performerId: this.getWorkerId(),
      key: 'EventStart',
      value: `1`,
      period: 1,
      timestamp,
    });

    this.setState({
      event: {
        ev_application: cnc_application,
        ev_description: null,
        ev_event_start: timestamp.toISOString(),
        ev_event_stop: null,
        ev_eventcategory: null,
        ev_name: '',
        ev_parent: cnc_event,
        ev_place: null,
        ev_place_2: null,
        ev_previous_version: null,
        ev_public: false,
        ev_remote_code: null,
        ev_remote_id: null,
        ev_selling_start: null,
        ev_selling_stop: null,
        ev_type: null,
        ev_visibility_start: null,
        ev_visibility_stop: null,
        event_id: null,
        eventmetadata: [
          {
            event_metadata_id: null,
            em_event: null,
            em_key: 'working_couple',
            em_value: '',
          },
        ],
        eventperformers: [],
      },
      moments: [ERCMoment, eventStartMoment],
      tags: [],
      deleteMomentIds: [],
      eventFetchStatus: 'success',
    });
  }
  componentDidMount() {
    const { selectedEvent } = this.props;
    if (selectedEvent) {
      this.fetchEvent({ selectedEvent });
    } else {
      this.initNewEvent();
    }
    this.fetchCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedEvent } = this.props;

    if (!prevState.isInEdit && this.state.isInEdit) {
      let eventStopReached = false;
      const filteredMoments = [...this.state.moments].filter((m) => {
        if (m.mt_key === 'EventStop') {
          eventStopReached = true;
          return false;
        }
        if (eventStopReached) {
          return m.mt_key !== 'BreakStop';
        }
        return true;
      });
      this.setState({ moments: filteredMoments });
    }

    if (prevProps.selectedEvent !== selectedEvent) {
      this.setState({ saveError: '' });
      if (selectedEvent) {
        this.fetchEvent({ selectedEvent });
      } else {
        this.initNewEvent();
      }
    }
    if (
      prevState.saveStatus === 'saving' &&
      this.state.saveStatus === 'success'
    ) {
      if (this.state.moments.length + this.state.deleteMomentIds.length > 0) {
        this.saveMoments();
      } else {
        this.props.onSaveNewSuccess(this.state.event.event_id);
        this.refreshAll();
      }
    }
    if (
      prevState.momentSaveStatus !== 'success' &&
      this.state.momentSaveStatus === 'success'
    ) {
      this.props.onSaveNewSuccess(this.state.event.event_id);
      this.refreshAll();
    }
  }

  refreshAll() {
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id, selectedEvent } = this.props;
    this.props.fetchMomentsOptions(authKey, ws_id);
    this.props.reloadEvents();
    this.fetchEvent({ selectedEvent });
  }

  fetchEvent({ selectedEvent }) {
    const { ws_id } = this.props;
    axios.defaults.headers.common['x-auth-key'] =
      localStorage.getItem('x-auth-key');
    this.setState({ eventFetchStatus: 'waiting' });
    axios
      .get(
        `${config.BASE_URL}/event/${selectedEvent}?ws_id=${ws_id}&module_id=${MODULE_ID}`
      )
      .then((response) => {
        let loadedEvent = response.data;
        loadedEvent.eventperformers.forEach(
          (ep) => (ep.ep_performer = ep.performer_id)
        );
        const event = JSON.parse(JSON.stringify(loadedEvent));
        const tags = event.tags.map((t) => t.tg_value);

        this.setState({
          event,
          loadedEvent,
          tags,
          eventFetchStatus: 'success',
        });
        if (event.event_id) {
          this.fetchMoments();
        }
      })
      .catch((error) => {
        this.setState({ error, eventFetchStatus: 'error' });
      });
  }

  fetchMoments() {
    const { selectedEvent } = this.props;
    this.setState({ momentFetchStatus: 'waiting' });
    const { ws_id } = this.props;
    axios
      .get(
        `${config.BASE_URL}/moments/${selectedEvent}?ws_id=${ws_id}&module_id=${MODULE_ID}`
      )
      .then((response) => {
        const loadedMoments = JSON.parse(JSON.stringify(response.data));
        const moments = JSON.parse(JSON.stringify(loadedMoments));
        const findHake = moments.find((m) => m.mt_key === 'ERC');
        if (findHake) {
          const hakeTime = moment(findHake.mt_timestamp).format('HH:mm');
          this.setState({ hakeTime });
        }
        this.setState({ moments, loadedMoments, momentFetchStatus: 'success' });
      })
      .catch((error) => {
        this.setState({ momentsError: error, momentFetchStatus: 'error' });
      });
  }

  // fetches all eventcategories in the DB...
  // TODO: should just get the ones we need, see also EVENTCATEGORY_PARENT_ID
  fetchCategories() {
    const { ws_id } = this.props;
    this.setState({ categoriesFetchStatus: 'waiting' });
    let url = `${config.BASE_URL}/event/categories?ws_id=${ws_id}&module_id=${MODULE_ID}`;
    axios
      .get(url)
      .then((response) => {
        this.setState({
          eventcategories: response.data,
          categoriesFetchStatus: 'success',
        });
      })
      .catch((error) => {
        this.setState({
          categoriesError: error,
          categoriesFetchStatus: 'error',
        });
      });
  }

  updateMetadata(em_key, em_value) {
    const metadata = this.state.event.eventmetadata.find(
      (em) => em.em_key === em_key
    );
    if (metadata) {
      metadata.em_value = em_value;
      return;
    }
    // new metadata entry
    this.state.event.eventmetadata.push({
      event_metadata_id: null,
      em_event: this.state.event.event_id,
      em_key,
      em_value,
    });
  }

  renderMoments({ moments, disabled }) {
    const sosMoments = this.props.momentOptions.moments;
    const displayMoments = sosMoments.map((m) => m.id);
    return moments
      .filter((m) => displayMoments.includes(m.mt_key))
      .map((moment, i) => {
        const { name } = sosMoments.find((fm) => fm.id === moment.mt_key);
        return (
          <Form.Group key={`mom_${i}`}>
            {disabled ? (
              <>
                <Form.Field width={4}>
                  <span>{name}</span>
                </Form.Field>
                <Form.Field>
                  <span>{moment.mt_value}</span>
                </Form.Field>
              </>
            ) : (
              <Form.Field width={16}>
                <Button.Group>
                  <Button
                    icon="minus"
                    as="a"
                    onClick={() => {
                      let val = parseInt(moment.mt_value, 10);
                      if (val > 1) {
                        moment.mt_value = `${val - 1}`;
                        this.forceUpdate();
                      }
                    }}
                  />
                  <Button
                    icon="plus"
                    as="a"
                    label={moment.mt_value}
                    labelPosition="left"
                    onClick={() => {
                      let val = parseInt(moment.mt_value, 10);
                      moment.mt_value = `${val + 1}`;
                      this.forceUpdate();
                    }}
                  />
                </Button.Group>
                <span style={{ marginLeft: '1rem' }}>{name}</span>
                <Button
                  as="a"
                  floated="right"
                  negative
                  icon="trash alternate"
                  onClick={() => {
                    const fMoments = this.state.moments.filter(
                      (fm) => fm.mt_key !== moment.mt_key
                    );
                    const deleteMomentIds = moment.moment_id
                      ? [...this.state.deleteMomentIds, moment.moment_id]
                      : this.state.deleteMomentIds;
                    this.setState({ moments: fMoments, deleteMomentIds });
                  }}
                />
              </Form.Field>
            )}
          </Form.Group>
        );
      });
  }

  renderDuration(duration) {
    if (!duration) {
      return '';
    }
    let ret = '';
    const days = duration.days();
    const hours = duration.hours();
    const mins = duration.minutes();
    if (days) {
      ret = days + ' d ';
    }
    ret += hours + ' h ' + mins + ' min';
    return ret;
  }

  renderDelay() {
    const { event, moments } = this.state;
    let ercfind = moments.find((m) => m.mt_key === 'ERC');
    if (ercfind) {
      const ercTime = moment(ercfind.mt_timestamp);
      const eventStart = moment(event.ev_event_start);
      return this.renderDuration(moment.duration(eventStart.diff(ercTime)));
    }
    return '';
  }

  calculatePeriodLength({ pp }) {
    if (pp.start && pp.start.mt_timestamp && pp.stop && pp.stop.mt_timestamp) {
      const startTimestamp = moment(pp.start.mt_timestamp);
      const stopTimestamp = moment(pp.stop.mt_timestamp);
      return moment.duration(stopTimestamp.diff(startTimestamp));
    }
    return null;
  }

  renderPeriodLength({ pp }) {
    const periodLength = this.calculatePeriodLength({ pp });
    return periodLength ? this.renderDuration(periodLength) : '';
  }

  renderTotalDuration({ periodPairs }) {
    let totalDuration = moment.duration(0);
    periodPairs
      .filter((pp) => pp.stop.key !== 'EventStop')
      .forEach((pp) => {
        const diff = this.calculatePeriodLength({ pp });
        diff && totalDuration.add(diff);
      });
    if (totalDuration.asMinutes() > 0) {
      return this.renderDuration(totalDuration);
    }
    return '';
  }

  renderPeriods({
    periodPairs,
    disabled,
    isOnBreak,
    currentPeriod,
    workerId,
    eventId,
    isArchived,
  }) {
    //const nPeriodPairs = periodPairs.length;
    const ret = periodPairs
      .filter((pp) => pp.stop?.mt_key !== 'EventStop' && pp !== null)
      .map((pp, i) => {
        // console.log(`periodPair ${i}`, pp)
        const isFirstRow = i === 0;
        const isLastRow = isArchived
          ? i === periodPairs.length - 3
          : i === periodPairs.length - 2;

        const startMinimum =
          !isFirstRow && periodPairs[pp.start.period - 1]
            ? moment(
                periodPairs[pp.start.period - 1].stop?.mt_timestamp
              ).format('l HH:mm')
            : null;
        const startMaximum =
          pp.stop && pp.stop?.mt_timestamp
            ? moment(pp.stop.mt_timestamp).format('l HH:mm')
            : null;
        const stopMinimum = moment(pp.start?.mt_timestamp).format('l HH:mm');
        const stopMaximum =
          !isLastRow && periodPairs[pp.start.period + 1]
            ? moment(
                periodPairs[pp.start.period + 1].start.mt_timestamp
              ).format('l HH:mm')
            : null;
        return (
          <Grid.Row
            style={{
              paddingTop: isFirstRow ? '1rem' : 0,
              paddingBottom: isLastRow ? '1rem' : '.5rem',
            }}
          >
            <Grid.Column>
              <Form.Group widths="equal">
                <CCDateTimeInput
                  key={`start_${i}`}
                  readOnly={disabled}
                  value={pp.start?.mt_timestamp}
                  automaticDate
                  minDate={startMinimum}
                  maxDate={startMaximum}
                  onChange={({ value }) => {
                    // const dateTime = moment(value, 'l HH:mm');
                    this.updatePeriodStart(pp, value);
                    this.forceUpdate();
                  }}
                />
              </Form.Group>
            </Grid.Column>
            <Grid.Column>
              {pp.stop?.mt_timestamp ? (
                <Form.Group widths="equal">
                  <CCDateTimeInput
                    readOnly={disabled}
                    key={`stop_${i}`}
                    value={pp.stop.mt_timestamp}
                    automaticDate
                    minDate={stopMinimum}
                    maxDate={stopMaximum}
                    onChange={({ value }) => {
                      this.updatePeriodEnd(pp, value, isLastRow, isArchived);
                      this.forceUpdate();
                    }}
                  />
                </Form.Group>
              ) : (
                <Form.Field>
                  <Button
                    key="addPeriodButton"
                    as="a"
                    style={{ marginBottom: '1rem' }}
                    onClick={() => {
                      //console.log('going on break', { performerId: workerid, key: 'BreakStart', value: `${currentPeriod}`, period: currentPeriod })
                      this.setState({
                        moments: [
                          ...this.state.moments,
                          createMoment({
                            eventId,
                            performerId: workerId,
                            key: 'BreakStart',
                            value: `${currentPeriod}`,
                            period: currentPeriod,
                          }),
                        ],
                      });
                    }}
                    primary
                  >
                    {T('Tauolle')}
                  </Button>
                </Form.Field>
              )}
            </Grid.Column>
            <Grid.Column>
              <Form.Input readOnly value={this.renderPeriodLength({ pp })} />
            </Grid.Column>
          </Grid.Row>
        );
      });
    if (!disabled && isOnBreak) {
      return [
        ...ret,
        <Form.Field key="addPeriodButton">
          <Button
            as="a"
            style={{ marginBottom: '1rem' }}
            onClick={() => {
              this.setState({
                moments: [
                  ...this.state.moments,
                  createMoment({
                    eventId,
                    performerId: workerId,
                    key: 'BreakStop',
                    value: `${currentPeriod + 1}`,
                    period: currentPeriod + 1,
                  }),
                ],
              });
            }}
            primary
          >
            <Icon name="plus" />
            {T('Add')}
          </Button>
        </Form.Field>,
      ];
    }
    return ret;
  }

  updatePeriodEnd(pp, value, isLastRow, isArchived) {
    const { event, moments } = this.state;
    pp.stop.mt_timestamp = value.toISOString();
    pp.stop.mt_walltime = value.format('HH:mm');
    if (isLastRow && isArchived) {
      event.ev_event_stop = value.toISOString();
      let eventStopMoment = moments.find((m) => m.mt_key === 'EventStop');
      if (eventStopMoment) {
        eventStopMoment.mt_timestamp = value.toISOString();
      }
      let kludgyBreakStopMoment = moments.find(
        (m) =>
          m.mt_key === 'BreakStop' && m.mt_period === eventStopMoment.mt_period
      );
      if (kludgyBreakStopMoment) {
        kludgyBreakStopMoment.mt_timestamp = value.toISOString();
      }
      //console.log('saving pp', pp);
      //console.log('saving eventStopMoment', eventStopMoment);
    }
  }

  updatePeriodStart(pp, value) {
    pp.start.mt_timestamp = value.toISOString();
    pp.start.mt_walltime = value.format('HH:mm');
    if (pp.start.mt_key === 'EventStart' && pp.start.mt_value === '1') {
      const { event } = this.state;
      this.setState({
        event: {
          ...event,
          ev_event_start: value.toISOString(),
        },
      });
      // check ILMOITUSAIKA WHEN EVENT START IS MOVED
      let { moments } = this.state;
      let ercfind = moments.find((m) => m.mt_key === 'ERC');
      if (ercfind) {
        const oldErcTime = moment(ercfind.mt_timestamp);
        if (oldErcTime.isAfter(value)) {
          ercfind.mt_keyvalue = value.format('HH:mm');
          ercfind.mt_timestamp = value.toISOString();
          // alert(T('Tehtävän saapumisaika siirretty vastaamaan tehtävän aloitusaikaa.'));
          this.setState({
            modalHeader: T('Huom!'),
            modalText: `${T(
              'Tehtävän saapumisaika siirretty vastaamaan tehtävän aloitusaikaa.'
            )}`,
            modalOpen: true,
          });
        }
      }
    }
  }

  updateMomentSaveProgress() {
    if (this.momentSaveCount > 0) {
      this.momentSaveCount--;
    }

    if (this.momentSaveCount === 0) {
      this.setState({ momentSaveStatus: 'success' });
    }
  }

  saveMoments() {
    const { ws_id } = this.props;
    const { deleteMomentIds } = this.state;
    this.setState({ momentSaveStatus: 'saving', deleteMomentIds: [] });
    this.momentSaveCount =
      this.state.moments.length + this.state.deleteMomentIds.length;
    // console.log('saveMoments momentSaveStatus', this.state.momentSaveStatus);
    this.state.moments.forEach((moment) => {
      if (!moment.mt_event) {
        moment.mt_event = this.state.event.event_id;
      }
      if (moment.moment_id) {
        // update
        // console.log('updating moment', moment);
        const url = `${config.BASE_URL}/moment/edit/${moment.moment_id}?module_id=${MODULE_ID}&ws_id=${ws_id}`;
        axios
          .put(url, moment)
          .then((response) => {
            // console.log('update moment put response', response);
            this.updateMomentSaveProgress();
          })
          .catch((saveError) => {
            // console.log('update moment put error', saveError);
            this.setState({
              saveStatus: 'error',
              saveError,
              momentSaveStatus: 'error',
            });
          });
      } else {
        // create
        // console.log('creating moment', moment);
        const url = `${config.BASE_URL}/moment?module_id=${MODULE_ID}&ws_id=${ws_id}`;
        axios
          .post(url, moment)
          .then((response) => {
            // console.log('create moment post response', response);
            this.updateMomentSaveProgress();
          })
          .catch((saveError) => {
            // console.log('create moment post error', saveError);
            this.setState({
              saveStatus: 'error',
              saveError,
              momentSaveStatus: 'error',
            });
          });
      }
    });

    deleteMomentIds.forEach((moment_id) => {
      // console.log('deleting moment', moment_id);
      const url = `${config.BASE_URL}/moment/${moment_id}?module_id=${MODULE_ID}&ws_id=${ws_id}`;
      axios
        .delete(url)
        .then((response) => {
          // console.log('delete moment response', response);
          this.updateMomentSaveProgress();
        })
        .catch((saveError) => {
          // console.log('delete moment error', saveError);
          this.setState({
            saveStatus: 'error',
            saveError,
            momentSaveStatus: 'error',
          });
        });
    });
  }

  saveNewEvent({ ws_id, event }) {
    const url = `${config.BASE_URL}/event?module_id=${MODULE_ID}&ws_id=${ws_id}`;
    axios
      .post(url, event)
      .then((response) => {
        // console.log('save response', response);

        const loadedEvent = JSON.parse(JSON.stringify(response.data));
        const event = JSON.parse(JSON.stringify(response.data));
        this.setState({
          event,
          loadedEvent,
          saveStatus: 'success',
          saveError: '',
        });

        // const { selectedEvent } = this.props;
        // this.fetchEvent({ selectedEvent });
      })
      .catch((saveError) => {
        this.setState({ saveStatus: 'error', saveError });
      });
  }

  updateOldEvent({ ws_id, event }) {
    const { event_id } = event;
    const url = `${config.BASE_URL}/event/${event_id}?module_id=${MODULE_ID}&ws_id=${ws_id}`;
    axios
      .put(url, event)
      .then((response) => {
        // console.log('save response', response);

        const loadedEvent = JSON.parse(JSON.stringify(event));
        this.setState({ loadedEvent, saveStatus: 'success', saveError: '' });

        // const { selectedEvent } = this.props;
        // this.fetchEvent({ selectedEvent });
      })
      .catch((saveError) => {
        this.setState({ saveStatus: 'error', saveError });
      });
  }
  saveEvent() {
    this.setState({ saveStatus: 'saving' });
    const { ws_id } = this.props;
    const { event } = this.state;

    // console.log('saving event', event);
    if (!event.event_id) {
      // save new event
      this.saveNewEvent({ ws_id, event });
    } else {
      this.updateOldEvent({ ws_id, event });
    }
  }

  deleteEvent() {
    this.setState({ saveStatus: 'deleting' });
    const { ws_id } = this.props;
    const { event } = this.state;
    const { event_id } = event;

    const url = `${config.BASE_URL}/event/${event_id}?module_id=${MODULE_ID}&ws_id=${ws_id}`;
    axios
      .delete(url, event)
      .then((response) => {
        // console.log('save response', response);

        this.setState({
          loadedEvent: null,
          event: null,
          saveStatus: 'success',
          saveError: '',
        });
        this.props.resetLoggerMode();
      })
      .catch((saveError) => {
        this.setState({ saveStatus: 'error', saveError });
      });
  }

  getWorkerId() {
    const { sosWorkersParentId } = this.props.momentOptions.application_configs;
    return sosWorkersParentId
      ? parseInt(sosWorkersParentId, 10)
      : DEFAULT_WORKER_ID;
  }

  doArchive() {
    let { event, moments } = this.state;
    const eventId = event.event_id;
    const workerId = this.getWorkerId();
    const { currentPeriod } = getEventState({ event, moments });

    const timestamp = new moment();

    moments.push(
      createMoment({
        eventId,
        performerId: workerId,
        key: 'BreakStop',
        value: `${currentPeriod + 1}`,
        period: currentPeriod + 1,
        timestamp,
      })
    );
    moments.push(
      createMoment({
        eventId,
        performerId: workerId,
        key: 'EventStop',
        value: `${currentPeriod + 1}`,
        period: currentPeriod + 1,
        timestamp,
      })
    );
    moments.push(
      createMoment({
        eventId,
        performerId: workerId,
        key: 'Archival',
        value: `${currentPeriod + 1}`,
        period: currentPeriod + 1,
        timestamp,
      })
    );
    event.ev_event_stop = timestamp.toISOString();
    this.setState({ event, moments });
    this.saveEvent();
  }

  render() {
    // console.log('STATE', this.state);
    // console.log('activeWorkspace', this.props.activeWorkspace);

    if (this.state.error) {
      return (
        <Message negative>
          <Message.Header>{T('Unable to fetch event')}</Message.Header>
          <p>
            {T('Error message:')} {this.state.error.message}
          </p>
        </Message>
      );
    }
    if (this.state.eventFetchStatus !== 'success' || !this.state.event) {
      return <Spinner />;
    }

    if (this.state.momentFetchStatus === 'waiting') {
      return <Spinner />;
    }

    const { event, moments, isInEdit, addMomentMode } = this.state;
    const eventId = event.event_id;

    const {
      isOnBreak,
      isArchived,
      periodPairs,
      hakeTimestamp,
      currentPeriod,
      isEditable,
    } = getEventState({ event, moments, isInEdit });
    // console.log('event state', {
    //   isOnBreak,
    //   isArchived,
    //   periodPairs,
    //   hakeTimestamp,
    //   currentPeriod,
    //   isEditable,
    // });
    // console.log('event', event);
    // console.log('moments', moments);
    // console.log('periodPairs', periodPairs);
    const isSaving =
      this.state.saveStatus === 'saving' ||
      this.state.momentSaveStatus === 'saving';
    const isDeleting = this.state.saveStatus === 'deleting';

    const disabled = isArchived && !isInEdit;

    const event_place_options = this.props.momentOptions.event_places.map(
      (ep) => {
        return {
          key: `ep${ep.place_id}`,
          text: ep.pl_name,
          value: ep.place_id,
        };
      }
    );
    const client_place_options = this.props.momentOptions.client_places.map(
      (cp) => {
        return {
          key: `cp${cp.place_id}`,
          text: cp.pl_name,
          value: cp.place_id,
        };
      }
    );
    console.log(
      'this.props.momentOptions.source',
      this.props.momentOptions.source
    );
    const source_options = this.props.momentOptions.source.map((ss) => {
      return {
        key: `ss${ss.performer_id}`,
        text: ss.pf_name,
        value: ss.performer_id,
      };
    });
    // TODO: change to better system
    const eventcategory_options = this.props.eventcategories.map((ec) => {
      return {
        key: `ec${ec.eventcategory_id}`,
        text: ec.ec_code,
        value: ec.eventcategory_id,
      };
    });
    // TODO: remove hard coding
    const triage_options = this.props.momentOptions.triages.map((tr) => {
      return { key: `tr${tr.id}`, text: tr.triage, value: tr.id };
    });
    const source_contact_method_options =
      this.props.momentOptions.source_contact_methods.map((cm) => {
        return {
          key: `cm${cm.id}`,
          text: cm.source_contact_method,
          value: cm.id,
        };
      });
    let tag_options = this.props.momentOptions.tags.map((t) => {
      return { key: `t${t.tag_id}`, text: t.tg_value, value: t.tg_value };
    });
    this.state.tags.forEach((t) => {
      tag_options.push({ key: `t${t}`, text: t, value: t });
    });
    const moment_options = this.props.momentOptions.moments
      .filter((mt) => moments.findIndex((m) => m.mt_key === `${mt.id}`) === -1)
      .map((mt) => {
        return { key: `mt${mt.id}`, text: mt.name, value: mt.id };
      });

    const source = getEventPerformerByPosition({
      eventPerformers: event.eventperformers,
      position: 'source',
    });

    const workerId = this.getWorkerId();

    const workingPair =
      getMetadataValue({
        event,
        emKey: 'working_couple',
      }) || '';

    const { workingPairWhileEditing } = this.state;
    // render editor
    const momentSorter = (a, b) =>
      new Date(a.mt_timestamp) - new Date(b.mt_timestamp);
    const momentsModified =
      JSON.stringify(this.state.moments.sort(momentSorter)) !==
      JSON.stringify(this.state.loadedMoments.sort(momentSorter));
    const workingPairModified =
      workingPairWhileEditing !== null &&
      workingPairWhileEditing !== workingPair;
    const eventModified =
      JSON.stringify(this.state.event) !==
        JSON.stringify(this.state.loadedEvent) ||
      momentsModified ||
      workingPairModified;
    const { tags } = this.state;
    const { ev_place, ev_place_2, ev_eventcategory, ev_description } = event;
    return (
      <Form
        onSubmit={() => {
          /* ignore enter presses */
        }}
      >
        {isArchived && (
          <Message>
            <Message.Header>{`${T('ARKISTOITU')}\n${moment(
              getArchivalTimestamp(moments)
            ).format('l HH:mm')}`}</Message.Header>
          </Message>
        )}
        {event && <Header as="h4">ID {event.event_id}</Header>}
        <Form.Group widths="equal">
          <Form.Select
            fluid
            search
            selection
            required
            selectOnBlur={false}
            label={T('Tapahtumakunta')}
            options={event_place_options}
            placeholder={T('Valitse...')}
            onChange={(e, d) =>
              !disabled &&
              this.setState({ event: { ...event, ev_place: d.value } })
            }
            value={ev_place}
          />
          <Form.Select
            fluid
            search
            selection
            required
            selectOnBlur={false}
            label={T('Asiakkaan kotikunta')}
            options={client_place_options}
            onChange={(e, d) =>
              !disabled &&
              this.setState({ event: { ...event, ev_place_2: d.value } })
            }
            placeholder={T('Valitse...')}
            value={ev_place_2}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Select
            clearable
            fluid
            search
            selection
            required
            selectOnBlur={false}
            label={T('Triage')}
            options={triage_options}
            value={getMetadataValue({ event, emKey: 'triage' })}
            onChange={(e, d) => {
              if (disabled) {
                return;
              }
              this.updateMetadata('triage', d.value);
              this.forceUpdate();
            }}
            placeholder={T('Valitse...')}
          />
          <Form.Select
            label={T('Lisätagit')}
            placeholder={T('Valitse...')}
            fluid
            search
            selection
            multiple
            allowAdditions
            additionLabel={T('Lisää ')}
            noResultsMessage={T('Ei tageja.')}
            // onAddItem={(e, {value=[]}) => {
            //   console.log('value', value)
            //   const eventtags = value.map(t => {
            //     const gettag = this.props.momentOptions.tags.find(t => t.tg_value == value);
            //     console.log('gettag', gettag);
            //     const tag_id = gettag == null ? null : gettag.tag_id;
            //     return ({ tag_id, tg_value: t });
            //   });
            //   const {event} = this.state;
            //   this.setState({ tags: value, event: { ...event, tags: eventtags }});
            // }}
            onChange={(e, { value = [] }) => {
              if (disabled) {
                return;
              }
              // console.log('value', value)
              const eventtags = value.map((t) => {
                const gettag = this.props.momentOptions.tags.find(
                  (to) => to.tg_value === t
                );
                // console.log('gettag', gettag);
                const tag_id = gettag == null ? null : gettag.tag_id;
                return { tag_id, tg_value: t };
              });
              const { event } = this.state;
              this.setState({
                tags: value,
                event: { ...event, tags: eventtags },
              });
            }}
            options={tag_options}
            value={tags}
          />
          <Form.Select
            required
            fluid
            search
            selection
            selectOnBlur={false}
            label={T('Syykoodi')}
            options={eventcategory_options}
            placeholder={T('Valitse...')}
            onChange={(e, d) => {
              if (disabled) {
                return;
              }
              const { ec_code } = this.props.eventcategories.find(
                (ec) => ec.eventcategory_id === d.value
              );
              this.setState({
                event: {
                  ...event,
                  ev_name: ec_code,
                  ev_eventcategory: d.value,
                },
              });
            }}
            value={ev_eventcategory}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Select
            required
            fluid
            search
            selection
            selectOnBlur={false}
            label={T('Yhteydenottajataho')}
            options={source_options}
            placeholder={T('Valitse...')}
            value={source ? source.ep_performer : null}
            onChange={(e, d) => {
              if (disabled) {
                return;
              }
              if (source) {
                source.ep_performer = d.value;
              } else {
                event.eventperformers.push({
                  event_performer_id: null,
                  ep_position: 'source',
                  ep_performer: d.value,
                });
              }

              this.forceUpdate();
            }}
          />
          <Form.Select
            style={{ opacity: 1 }}
            fluid
            search
            selection
            selectOnBlur={false}
            required
            label={T('Yhteydenottotapa')}
            options={source_contact_method_options}
            placeholder={T('Valitse...')}
            value={
              getMetadataValue({ event, emKey: 'source_contact_method' }) || ''
            }
            onChange={(e, d) => {
              if (disabled) {
                return;
              }
              this.updateMetadata('source_contact_method', d.value);
              this.forceUpdate();
            }}
          />
          <Form.Input
            label={T('Työparit')}
            placeholder={T('Työparit')}
            onFocus={() => {
              this.setState({
                workingPairWhileEditing:
                  getMetadataValue({ event, emKey: 'working_couple' }) || '',
              });
            }}
            onBlur={() => {
              this.updateMetadata(
                'working_couple',
                this.state.workingPairWhileEditing
              );
            }}
            value={
              this.state.workingPairWhileEditing ||
              getMetadataValue({ event, emKey: 'working_couple' }) ||
              ''
            }
            onChange={(e, { value }) => {
              this.setState({ workingPairWhileEditing: value });
            }}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.TextArea
            readOnly={disabled}
            label={T('Lisätiedot')}
            placeholder={T('Kirjoita lisätietoja...')}
            onChange={(e, d) =>
              this.setState({ event: { ...event, ev_description: d.value } })
            }
            value={ev_description || ''}
          />
        </Form.Group>
        <Form.Group>
          <CCDateTimeInput
            readOnly={disabled}
            label={T('Saapumispäivä')}
            timeLabel={T('Saapumisaika')}
            value={hakeTimestamp}
            automaticDate
            maxDate={event.ev_event_start}
            onChange={({ value }) => {
              let { moments } = this.state;
              // console.log('periodPairs', periodPairs);
              const firstPeriod = periodPairs.find(
                (pp) => pp && pp.start.mt_key === 'EventStart'
              );
              // console.log('firstPeriod', firstPeriod);
              // console.log('value', value);
              if (value === null) {
                const { deleteMomentIds } = this.state;
                let find = moments.find((m) => m.mt_key === 'ERC');
                if (find) {
                  const cleared = moments.filter((m) => m.mt_key !== 'ERC');
                  this.setState({ moments: cleared });
                  if (find.moment_id) {
                    this.setState({
                      deleteMomentIds: [...deleteMomentIds, find.moment_id],
                    });
                  }
                }
              } else {
                let find = moments.find((m) => m.mt_key === 'ERC');
                const newTime = value.format('HH:mm');
                if (find) {
                  find.mt_value = newTime;
                  find.mt_walltime = newTime;
                  find.mt_timestamp = value.toISOString();
                  this.setState({ hakeTime: newTime });
                } else {
                  let newMoment = createMoment({
                    eventId,
                    performerId: workerId,
                    key: 'ERC',
                    value: newTime,
                    period: currentPeriod,
                    timestamp: value,
                  });
                  newMoment.mt_walltime = newTime;
                  this.setState({ hakeTime: newTime });
                  moments.push(newMoment);
                }

                this.setState({ moments });

                if (firstPeriod) {
                  this.updatePeriodStart(firstPeriod, value);
                  this.forceUpdate();
                }
              }
            }}
          />
          {/*
          <DateInput
            readOnly={disabled}
            closable
            clearable
            label={T('Saapumispäivä')}
            // minDate={this.state.cm_valid_from}
            animation='off'
            name="ercDateTime"
            maxDate={moment(event.ev_event_start).format('l')}
            value={hakeTimestamp !== null ? moment(hakeTimestamp).format('l') : moment().format('l')}
            dateTimeFormat='l'
            onClear={e => {
              console.log(e);
              const { moments, deleteMomentIds } = this.state;
              let find = moments.find(m => m.mt_key === 'ERC');
              if (find) {
                const cleared = moments.filter(m => m.mt_key !== 'ERC');
                this.setState({ moments: cleared });
                if (find.moment_id) {
                  this.setState({ deleteMomentIds: [...deleteMomentIds, find.moment_id] })
                }
              }
            }}
            onChange={(e, {value}) => {
              let { moments } = this.state;
              let find = moments.find(m => m.mt_key === 'ERC');
              if (find) {
                const dateTime = moment(`${value} ${this.state.hakeTime}`, 'l HH:mm');
                const newTime = dateTime.format('HH:mm');

                find.mt_value = newTime;
                find.mt_timestamp = dateTime.toISOString();
                this.setState({ hakeTime: newTime })
              } else {
                const dateTime = moment(`${value} ${this.state.hakeTime}`, 'l');
                const newTime = dateTime.format('HH:mm');
                let newMoment = createMoment({
                  eventId,
                  performerId: workerId,
                  key: 'ERC',
                  value: newTime,
                  period: currentPeriod,
                  timestamp: dateTime
                });
                newMoment.mt_walltime = newTime;
                this.setState({ hakeTime: newTime })
                moments.push(newMoment);
              }
              this.setState({ moments });
            }}
          />
          <Form.Input fluid label={T('Saapumisaika')}
            value={this.state.hakeTime}
            onBlur={(e) => {
              const hakeTimeMoment = moment(this.state.hakeTime, 'HH:mm');
              this.setState({ hakeTime: hakeTimeMoment.isValid() ? hakeTimeMoment.format('HH:mm') : '' });
            }}
            onChange={(e, {value}) => {
              let { moments } = this.state;
              this.setState({ hakeTime: value });
              let find = moments.find(m => m.mt_key === 'ERC');
              if (find) {
                const storedDate = moment(find.mt_timestamp).format('l');
                const dateTime = moment(`${storedDate} ${value}`, 'l HH:mm');
                if (dateTime.isValid()) {
                  find.mt_value=dateTime.format('HH:mm');
                  find.mt_timestamp=dateTime.toISOString();
                }
              } else {
                const currentDate = (new moment()).format('l');
                const dateTime = moment(`${currentDate} ${value}`, 'l HH:mm');
                if (dateTime.isValid()) {
                  let newMoment = createMoment({
                    eventId,
                    performerId: workerId,
                    key: 'ERC',
                    value: dateTime.format('HH:mm'),
                    period: currentPeriod,
                    timestamp: dateTime
                  });
                  newMoment.mt_walltime = moment().format('HH:mm');
                  moments.push(newMoment);
                }
              }
            }}
          />
          <Form.Input
            width={6}
            label={T('Viive')}
            readOnly
            value={this.renderDelay()}
          />
          */}
          <Form.Input
            width={6}
            label={T('Kokonaiskesto')}
            readOnly
            value={this.renderTotalDuration({ periodPairs })}
          />
        </Form.Group>
        <Segment>
          <Label attached="top">Jaksot</Label>
          <Grid columns={3}>
            <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
              <Grid.Column>
                <Header sub>{T('Alku')}</Header>
              </Grid.Column>
              <Grid.Column>
                <Header sub>{T('Loppu')}</Header>
              </Grid.Column>
              <Grid.Column>
                <Header sub>{T('Kesto')}</Header>
              </Grid.Column>
            </Grid.Row>
            {this.renderPeriods({
              periodPairs,
              disabled,
              isOnBreak,
              currentPeriod,
              workerId,
              eventId,
              isArchived,
            })}
          </Grid>
        </Segment>
        <Segment>
          <Label attached="top">Toimenpiteet</Label>
          {this.renderMoments({ moments, disabled })}
          {addMomentMode && (
            <Form.Select
              searchInput={{ autoFocus: true }}
              fluid
              closable
              search
              selection
              selectOnBlur={false}
              options={moment_options}
              placeholder={T('Valitse...')}
              onChange={(e, d) => {
                // console.log('onChange d', d);
                if (d.value) {
                  const { moments } = this.state;
                  const description = d.options.find(
                    (o) => o.value === d.value
                  ).text;
                  this.setState({
                    moments: [
                      ...moments,
                      createMoment({
                        eventId,
                        description,
                        performerId: workerId,
                        key: `${d.value}`,
                        value: `1`,
                        period: currentPeriod,
                      }),
                    ],
                  });
                  // console.log('onChange');
                }
              }}
              onClose={() => {
                this.setState({ addMomentMode: false });
                // console.log('onClose');
              }}
            />
          )}
          {!addMomentMode && !disabled && (
            <Form.Field>
              <Button
                as="a"
                disabled={disabled}
                onClick={() => this.setState({ addMomentMode: true })}
                primary
              >
                <Icon name="plus" />
                {T('Add')}
              </Button>
            </Form.Field>
          )}
        </Segment>
        {this.state.saveError && (
          <Message
            negative
            header={T(this.state.saveError.response.data.message)}
            content={T(this.state.saveError.message)}
          />
        )}
        {isArchived && !isInEdit ? (
          <Button.Group>
            <Button
              as="a"
              disabled={!isEditable}
              onClick={() => this.setState({ isInEdit: true })}
              primary
            >
              <Icon name="edit" />
              {T('Edit')}
            </Button>
          </Button.Group>
        ) : (
          <>
            <Button.Group>
              <Button
                as="a"
                loading={isSaving}
                disabled={
                  !eventModified ||
                  disabled ||
                  isSaving ||
                  isDeleting ||
                  !event.ev_place
                }
                onClick={() => this.saveEvent()}
                primary
              >
                <Icon name="save" />
                {T('Save')}
              </Button>
              <Button
                as="a"
                disabled={
                  (!eventModified && !isInEdit) || isSaving || isDeleting
                }
                secondary
                onClick={() => {
                  this.props.history.push({
                    pathname: `/app/workcaselogging/list`,
                  });
                  if (!this.state.event.event_id) {
                    this.props.resetLoggerMode();
                    return;
                  }
                  const event = JSON.parse(
                    JSON.stringify(this.state.loadedEvent)
                  );
                  const moments = JSON.parse(
                    JSON.stringify(this.state.loadedMoments)
                  );
                  this.setState({ event, moments, isInEdit: false });
                }}
              >
                <Icon name="cancel" />
                {T('Cancel')}
              </Button>
            </Button.Group>
            {event.event_id && (
              <Button.Group floated="right">
                <Button
                  as="a"
                  disabled={isSaving || isDeleting}
                  loading={isDeleting}
                  onClick={() => {
                    this.setState({ deleteDialogOpen: true });
                  }}
                  negative
                >
                  <Icon name="trash" />
                  {T('Delete')}
                </Button>
              </Button.Group>
            )}
          </>
        )}
        {!isArchived && event.event_id && (
          <Button.Group style={{ marginTop: '1rem' }}>
            <Button
              as="a"
              disabled={!isOnBreak || eventModified || isSaving || isDeleting}
              secondary
              onClick={() => {
                let { event, moments } = this.state;
                if (
                  event.ev_name === '0' ||
                  event.ev_name === '200' ||
                  event.ev_name === '400'
                ) {
                  if (
                    !event.ev_description ||
                    event.ev_description.length === 0
                  ) {
                    //alert(T('Lisätiedot vaaditaan'),`${T('Tehtävätyyppi')} ${event.ev_name} ${T('edellyttää, että täytät lisätietokentän ennen arkistointia.')}`);
                    this.setState({
                      modalHeader: T('Lisätiedot vaaditaan'),
                      modalText: `${T('Tehtävätyyppi')} ${event.ev_name} ${T(
                        'edellyttää, että täytät lisätietokentän ennen arkistointia.'
                      )}`,
                      modalOpen: true,
                    });
                    return;
                  }
                }

                const sosMoments = this.props.momentOptions.moments;
                const displayMoments = sosMoments.map((m) => m.id);

                let cdMoments = 0;
                moments.forEach((m) => {
                  if (displayMoments.includes(m.mt_key)) {
                    cdMoments++;
                  }
                });
                const find = moments.find((m) => m.mt_key === '9');
                //console.log('displayMoments', displayMoments);
                //console.log('cdMoments', cdMoments);
                if (!find || cdMoments > 1) {
                  if (!event.ev_place) {
                    // alert(T('Kunta vaaditaan'),`${T('Ainoastaan ohjausta ja neuvontaa sisältävän tehtävän voi arkistoida ilman kuntaa.')}`);
                    this.setState({
                      modalHeader: T('Tapahtumakunta vaaditaan'),
                      modalText: `${T(
                        'Ainoastaan ohjausta ja neuvontaa sisältävän tehtävän voi arkistoida ilman tapahtumakuntaa.'
                      )}`,
                      modalOpen: true,
                    });
                    return;
                  }
                  if (!event.ev_place_2) {
                    // alert(T('Kunta vaaditaan'),`${T('Ainoastaan ohjausta ja neuvontaa sisältävän tehtävän voi arkistoida ilman kuntaa.')}`);
                    this.setState({
                      modalHeader: T('Asiakkaan kotikunta vaaditaan'),
                      modalText: `${T(
                        'Ainoastaan ohjausta ja neuvontaa sisältävän tehtävän voi arkistoida ilman asiakkaan asiakkaan kotikuntaa.'
                      )}`,
                      modalOpen: true,
                    });
                    return;
                  }
                  if (!getMetadataValue({ event, emKey: 'triage' })) {
                    this.setState({
                      modalHeader: T('Triage vaaditaan'),
                      modalText: `${T(
                        'Ainoastaan ohjausta ja neuvontaa sisältävän tehtävän voi arkistoida ilman kiireellisyysluokitusta.'
                      )}`,
                      modalOpen: true,
                    });
                    return;
                  }
                  if (!event.ev_eventcategory) {
                    // alert(T('Syykoodi vaaditaan'),`${T('Ainoastaan ohjausta ja neuvontaa sisältävän tehtävän voi arkistoida ilman syykoodia.')}`);
                    this.setState({
                      modalHeader: T('Syykoodi vaaditaan'),
                      modalText: `${T(
                        'Ainoastaan ohjausta ja neuvontaa sisältävän tehtävän voi arkistoida ilman syykoodia.'
                      )}`,
                      modalOpen: true,
                    });
                    return;
                  }
                  if (
                    !getMetadataValue({ event, emKey: 'source_contact_method' })
                  ) {
                    this.setState({
                      modalHeader: T('Yhteydenottotapa vaaditaan'),
                      modalText: `${T(
                        'Ainoastaan ohjausta ja neuvontaa sisältävän tehtävän voi arkistoida ilman yhteydenottotapaa.'
                      )}`,
                      modalOpen: true,
                    });
                    return;
                  }
                  // if (!event.ev_type) {
                  //   alert(T('Asiakastyyppi vaaditaan'),`${T('Ainoastaan ohjausta ja neuvontaa sisältävän tehtävän voi arkistoida ilman asiakastyyppiä.')}`);
                  //   return;
                  // }
                  if (!source || !source.performer_id) {
                    // alert(T('Yhteydenottajataho vaaditaan'),`${T('Ainoastaan ohjausta ja neuvontaa sisältävän tehtävän voi arkistoida ilman ilmoittajaa.')}`);
                    this.setState({
                      modalHeader: T('Yhteydenottajataho vaaditaan'),
                      modalText: `${T(
                        'Ainoastaan ohjausta ja neuvontaa sisältävän tehtävän voi arkistoida ilman ilmoittajaa.'
                      )}`,
                      modalOpen: true,
                    });
                    return;
                  }
                  // if (sourceid === 5001) {
                  //   const findERC = e.moments.find(m => m.key === 'ERC');
                  //   if (!findERC) {
                  //     alert(T('HäKen ilmoitusaika vaaditaan'),`${T('Hätäkeskuksen ilmoitusaika vaaditaan, mikäli ilmoittaja on Hätäkeskus.')}`);
                  //     return;
                  //   }
                  // }
                  // if (!e.worker) {
                  //   alert(T('Työntekijä vaaditaan'),`${T('Ainoastaan ohjausta ja neuvontaa sisältävän tehtävän voi arkistoida ilman työntekijää.')}`);
                  //   return;
                  // }
                }
                if (cdMoments === 0) {
                  this.setState({ noMomentsDialogOpen: true });
                } else {
                  this.doArchive();
                }
              }}
            >
              <Icon name="archive" />
              {T('Lopeta ja arkistoi')}
            </Button>
          </Button.Group>
        )}

        <Confirm
          open={this.state.deleteDialogOpen}
          header={T('Oletko varma?')}
          cancelButton={T('Cancel')}
          content={T(
            'Oletko varma, että haluat poistaa tämän tehtävän? Poistettua tehtävää ei voi enää palauttaa.'
          )}
          onCancel={() => this.setState({ deleteDialogOpen: false })}
          onConfirm={() => {
            this.deleteEvent();
            this.setState({ deleteDialogOpen: false });
          }}
        />
        <Confirm
          open={this.state.noMomentsDialogOpen}
          header={T('Oletko varma?')}
          cancelButton={T('Cancel')}
          content={T(
            'Tehtävällä ei ole yhtään toimenpidettä. Oletko varma, että haluat arkistoida tehtävän?'
          )}
          onCancel={() => this.setState({ noMomentsDialogOpen: false })}
          onConfirm={() => {
            this.doArchive();
            this.setState({ noMomentsDialogOpen: false });
          }}
        />
        <Modal open={this.state.modalOpen}>
          <Header icon="warning" content={this.state.modalHeader} />
          <Modal.Content>
            <Modal.Description>
              <p>{this.state.modalText}</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="black"
              onClick={() => this.setState({ modalOpen: false })}
            >
              {T('Sulje')}
            </Button>
          </Modal.Actions>
        </Modal>
      </Form>
    );
  }
}

function mapStateToProps({
  workspace,
  languages,
  eventReport,
  eventcategories,
}) {
  return {
    ws_id: workspace.ws_id,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    langStatus: languages.status,
    languages: languages.languages,
    momentOptions: eventReport.momentOptions,
    eventcategories: eventcategories.eventcategories,
  };
}
export default connect(mapStateToProps, { fetchMomentsOptions })(
  withRouter(WorkCaseEdit)
);
