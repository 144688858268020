/* eslint-env browser */
import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { Segment, Message, Header, Menu } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { Spinner } from '../Common/Spinner';
import ProductGeneralFields from './ProductGeneralFields';
import ProductStockContainer from './ProductStockContainer';
import SubProductAttributesController from './SubProductAttributesController';
import SubProductAlternativesController from './SubProductAlternativesController';
import ProductPricelistContainer from './ProductPricelistContainer';
import {
  fetchProduct,
  fetchCurrencies,
  fetchTaxes,
  fetchProductUnits,
  fetchPriceLists,
  addProduct,
  editProduct,
  addProductReset,
  editProductReset,
  uploadFile,
  resetUploadFile,
  deleteProductPrice,
  deleteProductPriceReset,
  fetchProductTypes,
  fetchOutOfStockMessages,
} from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';

const CURRENCY = 'EUR';

const errorStyle = {
  backgroundColor: '#FFF6F6',
  borderColor: '#E0B4B4',
  color: '#9F3A38',
  WebkitBoxShadow: 'none',
  boxShadow: 'none',
  outline: '1px solid #FFF6F6',
};

class ManageProduct extends Component {
  constructor(props) {
    super(props);
    const p_priceListId =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.priceListId
        ? this.props.location.state.priceListId
        : null;
    let stateObj = {};

    // const locationState = this.props.location && this.props.location.state;
    // console.log('Product constructor: ', this.props.product);
    if (this.props.productProp !== undefined) {
      const product = { ...this.props.productProp };
      stateObj = { ...product, pricelists: product.pricelists || [] };
      // console.log('Not undefined: ', product);
      if (product.pr_main_image) {
        const name = product.pr_main_image.split('/').pop();
        stateObj = {
          ...stateObj,
          files: [
            {
              name,
              preview: product.pr_main_image,
              file_id: product.pr_main_image_file_id,
            },
          ],
        };
      }
    } else {
      //add empty price list for first row of prices
      // stateObj = { pricelists: [{ pp_currency: null, pp_price: null, pp_price_list: locationState ? locationState.priceListId : null, product_price_id: null, pp_active: true }] };
      let p_currency = null;
      let p_price_list_id = null;

      if (p_priceListId !== null) {
        p_currency = CURRENCY;
        p_price_list_id = p_priceListId;
        if (this.props.product && this.props.product.priceListId) {
          p_price_list_id = this.props.product.priceListId;
        }
      }
      stateObj = {
        pricelists: [
          {
            pp_currency: p_currency,
            pp_price: null,
            //pp_price_list: this.props.product ? this.props.product.priceListId : p_price_list_id,
            pp_price_list: p_price_list_id,
            product_price_id: null,
            pp_active: true,
          },
        ],
      };
    }
    //console.log('Location State: ', locationState);
    this.state = {
      confirmDelete: false,
      // cm_valid_from: '',
      pr_active: true,
      pr_separate_ticket: false,
      recommend: false,
      pr_recommended_from: null,
      pr_recommended_to: null,
      pr_recommended_from_error: false,
      pr_recommended_to_error: false,
      pr_image_updated: false,
      pr_image_deleted: false,
      deleted_file: null,
      // pricelists: [],
      files: [],
      // pr_parent: locationState && locationState.parentId ? locationState.parentId : '',
      pr_name: '',
      pr_code: '',
      pr_short_description: '',
      pr_long_description: '',
      ...stateObj,
      // pr_langErrorStyle: {},
      // pr_typeErrorStyle: {},
      activeItem: 'stock',
      //priceListId: (this.props.location && this.props.location.state && this.props.location.state.priceListId) ? this.props.location.state.priceListId : null,
      priceListId: p_priceListId,
      value: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.getPriceList = this.getPriceList.bind(this);
    //this.deleteImage = this.deleteImage.bind(this);
    // this.renderPrice = this.renderPrice.bind(this);
    // this.getMerchants = this.getMerchants.bind(this);
    this.postData = this.postData.bind(this);
  }

  componentDidMount() {
    const { ws_id } = this.props;
    // localStorage.setItem('route', this.props.history.location.pathname);
    const company_id = this.props.workspace.workspaces[ws_id].cnc_company;
    const authKey = localStorage.getItem('x-auth-key');
    if (this.props.tax.fetchStatus !== 'success') {
      this.props.fetchTaxes({ authKey, company_id, ws_id });
    }
    this.props.fetchPriceLists({ authKey, ws_id });
    if (this.props.product.unitStatus !== 'success') {
      this.props.fetchProductUnits({ authKey, ws_id });
    }
    // this.props.fetchLanguages(authKey, ws_id);
    if (this.props.currencies.status !== 'success') {
      this.props.fetchCurrencies({ authKey, ws_id });
    }
    if (this.props.product.fetchProductTypesStatus !== 'success') {
      this.props.fetchProductTypes({ authKey, ws_id });
    }

    if (this.props.fetchOutOfStockMessageStatus !== 'success') {
      this.props.fetchOutOfStockMessages({ authKey, ws_id });
    }
  }

  componentDidUpdate(prevProps) {
    // const authKey = localStorage.getItem('x-auth-key');
    /*  if (prevProps.file.uploadStatus !== 'success' && this.props.file.uploadStatus === 'success') {
      //post product data
      // console.log('redirect post data .... edit');
      this.postData();
    }*/
    if (
      prevProps.product.addStatus !== 'success' &&
      this.props.product.addStatus === 'success'
    ) {
      //redirect to list
      this.props.addProductReset();

      if (this.state.priceListId !== null) {
        this.props.history.push(
          `/app/pricelist/manage/${this.state.priceListId}`
        );
        //this.props.history.push('/app/pricelist/');
      } else {
        this.props.history.push({
          pathname: '/app/product/list',
          state: { productAdded: true },
        });
      }
      // this.props.history.push(`/app/shop/products/manage/${this.props.product.newlyCreatedProduct}`);
    }
    if (
      prevProps.product.editStatus !== 'success' &&
      this.props.product.editStatus === 'success'
    ) {
      //redirect to list
      this.props.editProductReset();
      // this.props.history.push('/app/shop/products/list');
      this.props.history.goBack();
    }
    /*  if (prevProps.file.uploadStatus !== 'error' && this.props.file.uploadStatus === 'error') {
      //console.error();
      const errorObj = getErrorMessage(this.props.file.error, 'Could not upload image');
      toast({ type: 'error', animation: 'scale', title: T('Error'), description: T(errorObj.message), time: 5000 });
      this.props.resetUploadFile();
    }*/
    if (
      prevProps.product.addStatus !== 'error' &&
      this.props.product.addStatus === 'error'
    ) {
      //error
      this.props.addProductReset();
      const errorObj = getErrorMessage(
        this.props.product.error,
        'Failed to add product'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
    if (
      prevProps.product.editStatus !== 'error' &&
      this.props.product.editStatus === 'error'
    ) {
      //error
      this.props.editProductReset();
      const errorObj = getErrorMessage(
        this.props.product.error,
        'Product update failed'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
    if (
      prevProps.product.plDeleteStatus !== 'error' &&
      this.props.product.plDeleteStatus === 'error'
    ) {
      //error
      this.props.deleteProductPriceReset();
      const errorObj = getErrorMessage(
        this.props.product.error,
        'Failed to delete product price'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  // onDrop(files) {
  //   this.setState({
  //     files: files.map(file => Object.assign(file, { preview: URL.createObjectURL(file) })),
  //     pr_image_updated: true });
  //   //, () => this.uploadFile()
  // }

  onValidSubmit = (product) => {
    // let pr_merchant_id = this.props.auth.companyId;// it should always set from node collection companyId
    let pr_merchant_id =
      this.props.workspace.workspaces[this.props.ws_id].cnc_company; // it should always set from node collection companyId
    // console.log('Merchang Id: ', this.state.pr_merchant_id, ' Prop: ', this.props.auth.companyId);
    if (
      (product && !product.pr_merchant_id) ||
      this.state.pr_merchant_id !== null
    ) {
      // pr_merchant_id = this.props.auth.companyId;
      pr_merchant_id =
        this.props.workspace.workspaces[this.props.ws_id].cnc_company;
    }

    let updatedData = {};
    if (product) {
      updatedData = { ...product, pr_merchant_id };
    }
    this.setState({ postData: updatedData }, () => this.uploadFile(product));
    //console.log(' submit data: ', updatedData, this.state);
  };

  onCancel() {
    // const state = this.props.location.state;
    // if (state && state.ticketList) {
    //   this.props.history.push('/app/ticketing/tickets/list');
    // } else {
    //   this.props.history.push('/app/shop/products/list');
    // }
    this.props.history.goBack();
  }

  getLanguageList() {
    return _.map(this.props.languages, (language) => ({
      text: T(language.ln_name),
      value: language.ln_alpha_2,
    }));
  }

  getCurrencyList() {
    return _.map(this.props.currencies.data, (currency) => ({
      text: T(currency.cu_name),
      value: currency.currency_iso_id,
    }));
  }

  getPriceList() {
    if (this.props.pricelist.pricelists) {
      return _.map(this.props.pricelist.pricelists, (pricelist) => ({
        text: pricelist.pl_name,
        value: parseInt(pricelist.price_list_id, 10),
      }));
    }
    return null;
  }

  getProductUnits() {
    if (this.props.product.units) {
      return _.map(this.props.product.units, (unit) => ({
        text: T(unit.unit),
        value: unit.unit,
      }));
    }
    return [];
  }

  getTaxList() {
    if (this.props.tax.data) {
      return _.map(this.props.tax.data, (tax) => ({
        text: tax.tc_description,
        value: tax.tax_class_id,
      }));
    }
    return [];
  }

  getProductTypes = () =>
    _.map(this.props.product.productTypes, (productType) => ({
      text: T(productType.pr_code_value),
      value: productType.pr_code,
    }));
  //
  //  validateInputControl = fields => {
  //    const valid = _.every(fields, field => Boolean(this.state.postData[field]));
  //    if (!valid) {
  //      const errors = {};
  //       _.forEach(fields, field => { errors[`${field}ErrorStyle`] = (this.state.postData[field] ? {} : errorStyle); });
  //       //console.log('Errors: ', errors);
  //      this.setState({ ...errors });
  //    }
  //    return valid;
  //  }

  getStockMessageList = () =>
    _.map(this.props.product.stockMessages, (stockMessage) => ({
      text: T(stockMessage.dk_values),
      value: stockMessage.dk_values,
    }));

  validatePriceListInputControls = (pricelists, fields) => {
    let valid = true;
    for (let i = 0; i < pricelists.length && valid; i++) {
      valid = _.every(fields, (field) => Boolean(pricelists[i][field]));
    }
    if (!valid) {
      const tempPricelists = _.map(pricelists, (pl) => {
        const errors = {};
        _.forEach(fields, (field) => {
          errors[`${field}ErrorStyle`] = pl[field] ? {} : errorStyle;
        });
        return { ...pl, ...errors };
      });
      this.setState({ pricelists: tempPricelists });
    }
    return valid;
  };
  postData() {
    //pricelist needs a separate API
    // let valid = this.validateInputControl(['pr_lang', 'pr_type', 'pr_tax_class_id', 'pr_unit']);
    // console.log('Valid: ', valid, '\nState: ', this.state);
    // if (!valid) {
    //   return;
    // }
    // valid = this.validatePriceListInputControls(this.state.postData.pricelists, ['pp_currency', 'pp_price_list']);
    // if (!valid) {
    //   return;
    // }
    const authKey = localStorage.getItem('x-auth-key');
    let updatedData;
    //    if (this.props.file.data && data.pr_image_updated) {
    /*  if (this.props.file.data) {
      updatedData = { ...data, pr_main_image: this.props.file.data.urlLarge || this.props.file.data.urlSmall };
    } else {*/
    updatedData = this.state.postData;
    //  }
    //let pricelists = _.values(updatedData.pricelists);
    //pricelists = _.map(pricelists, pl => ({ ...pl, pp_price: pl.pp_price.toString() })); //backend expects string price :D
    //updatedData = { ...updatedData, pricelists, pr_active: !!updatedData.pr_active };
    updatedData = { ...updatedData, pricelists: this.state.pricelists };
    // const { productId } = this.props.match.params;
    const { product_id } = updatedData;
    if (product_id) {
      //edit
      this.props.editProduct(
        authKey,
        updatedData,
        product_id,
        this.props.ws_id
      );
    } else {
      //add
      this.props.addProduct(authKey, updatedData, this.props.ws_id);
    }
  }
  // TODO upload file should be removes cause FileUpload component is used
  uploadFile(product) {
    const { files, pr_image_updated } = product;
    if (files.length > 0 && pr_image_updated) {
      //upload files and then post form data in componentDidUpdate
      const { ws_id, workspace } = this.props;
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          // const fileAsBinaryString = reader.result;
          const formData = new FormData();
          const inputValues = {
            id: workspace.workspaces[ws_id].cnc_company,
            target: 'product',
          };
          Object.keys(inputValues).forEach((key) => {
            formData.append(key, inputValues[key]);
          });
          formData.append('file', file);
          const authKey = localStorage.getItem('x-auth-key');
          this.props.uploadFile({
            authKey,
            formData,
            module_id: 17,
            ws_id: this.props.ws_id,
          });
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.readAsBinaryString(file);
      });
      /*} else if (pr_image_deleted && deleted_file !== null) {
      this.deleteImage(deleted_file);*/
    } else {
      this.postData();
    }
  }

  handleChange = (e, { name, value, checked }) => {
    this.setState({
      [`${name}ErrorStyle`]: {},
      [name]: typeof checked === 'boolean' ? checked : value,
    });
  };

  handleItemClick = (activeItem) => this.setState({ activeItem });

  priceListUpdate(p_pricelists) {
    this.setState({ pricelists: p_pricelists });
  }

  renderActive = (activeItem) => {
    const productId = this.props.product.product_id;
    const attributesList = [];
    const productList = [];
    const priceLists = [];
    const stocks = [];
    // const stock = { pr_name: 'Fake Stock',
    //                 pr_code: 'RX008',
    //                 pr_sales_unit: 4,
    //                 pr_unit: 'pc',
    //                 pr_stock_balance: 500,
    //                 pr_max_purchase_qty: 2,
    //                 pr_active: true
    //              };
    const newProduct = {
      pr_name: '',
      pr_short_description: '',
      pr_long_description: '',
      pr_type: '',
      pr_active: true,
      pr_separate_ticket: false,
      recommend: false,
      pr_recommended_from: null,
      pr_recommended_to: null,
      pr_recommended_from_error: false,
      pr_recommended_to_error: false,
      pr_code: '',
      files: [],
      pr_tax_class_id: '',
      product_id: null,
      pr_out_of_stock_message: null,
    };
    _.forEach(this.state.subproducts, (product) => {
      let files = [];
      if (product.pr_main_image) {
        const name = product.pr_main_image.split('/').pop();
        files = [
          {
            name,
            preview: product.pr_main_image,
            file_id: product.pr_main_image_file_id,
          },
        ];
      }
      attributesList.push({
        productId: product.product_id,
        pr_name: product.pr_name,
        data: product.attributes,
      });
      productList.push({
        productName: product.pr_name,
        product: { ...product, files },
        praentId: product.pr_parent,
      });
      priceLists.push({
        productName: product.pr_name,
        productId: product.product_id,
        pricelists: product.pricelists,
      });
      stocks.push({
        productName: product.pr_name,
        productId: product.product_id,
        stock: {
          saldo: product.pr_saldo,
          unit: product.pr_unit,
          salesUnit: product.pr_sales_unit,
          pr_max_purchase_qty: product.pr_max_purchase_qty,
          pr_max_person_qty: product.pr_max_person_qty,
        },
      });
    });
    attributesList.unshift({
      productId: this.state.product_id,
      pr_name: this.state.pr_name,
      data: this.state.attributes,
    });
    productList.unshift({
      productName: T('Add Alternative Product'),
      product: newProduct,
      parentId: productId,
    });
    priceLists.unshift({
      productName: this.state.pr_name,
      productId: this.state.product_id,
      pricelists: this.state.pricelists,
    });
    stocks.unshift({
      productName: this.state.pr_name,
      productId: this.state.product_id,
      stock: {
        saldo: this.state.pr_saldo,
        unit: this.state.pr_unit,
        salesUnit: this.state.pr_sales_unit,
        pr_max_purchase_qty: this.state.pr_max_purchase_qty,
        pr_max_person_qty: this.state.pr_max_person_qty,
      },
    });
    // console.log('Product Id: ', productId);
    switch (activeItem) {
      case 'stock':
        return (
          <ProductStockContainer
            stocks={stocks}
            units={this.getProductUnits()}
            history={this.props.history}
            parentId={this.state.product_id}
          />
        );
      case 'attributes':
        return (
          <SubProductAttributesController
            productId={this.state.product_id}
            attList={attributesList}
          />
        );
      case 'productAlternatives':
        return (
          <SubProductAlternativesController
            productList={productList}
            productTypes={this.getProductTypes()}
            onValidSubmit={this.onValidSubmit}
            history={this.props.history}
            taxList={this.props.tax.data}
            parentId={this.state.product_id}
          />
        );
      /*case 'pricelists':
           return (<ProductPricelistContainer
                        productId={this.state.product_id}
                        priceLists={priceLists}
                        plOptions={this.getPriceList()}
                        currencyList={this.getCurrencyList()}
           />);*/
      default:
    }
  };

  renderPriceList() {
    const priceLists = [];
    //priceLists.unshift({ productName: this.state.pr_name,
    //                   productId: this.state.product_id,
    //                 pricelists: this.state.pricelists
    //             });

    priceLists.push({
      productName: this.state.pr_name,
      productId: this.state.product_id,
      pricelists: this.state.pricelists,
    });
    return (
      <ProductPricelistContainer
        productId={this.state.product_id}
        priceLists={priceLists}
        plOptions={this.getPriceList()}
        currencyList={this.getCurrencyList()}
        priceListId={this.priceListId}
        priceListCallback={this.priceListUpdate.bind(this)}
      />
    );
  }

  render() {
    if (
      this.props.currencies.status === 'waiting' ||
      //  this.props.file.uploadStatus === 'waiting' ||
      //  this.props.file.addStatus === 'waiting' ||
      //  this.props.file.editStatus === 'waiting' ||
      this.props.product.fetchProductTypesStatus === 'waiting' ||
      this.props.tax.fetchStatus === 'waiting' ||
      this.props.pricelist.plStatus === 'waiting'
    ) {
      return <Spinner />;
    } else if (
      this.props.currencies.status === 'error' ||
      this.props.product.fetchProductTypesStatus === 'error' ||
      this.props.tax.fetchStatus === 'error' ||
      this.props.pricelist.plStatus === 'error'
    ) {
      return (
        <Message
          error
          header={T('Error')}
          list={[T('Error while fetching data')]}
        />
      );
    }
    //console.log('State: ', this.state);

    // console.log('PRODUCT state :-> ', this.state);
    // console.log('PRODUCT Props :-> ', this.props.product);
    // if product code for event ticket is changed, it won't be listed as Ticket
    // const disableProductCode = this.props.product === undefined ? false : this.props.product.pr_type === 'entry' || this.props.product.pr_type === 'engage_ticket';

    const {
      activeItem,
      pr_name,
      pr_short_description,
      pr_long_description,
      pr_type,
      pr_active,
      pr_separate_ticket,
      recommend,
      pr_recommended_from,
      pr_recommended_to,
      pr_recommended_from_error,
      pr_recommended_to_error,
      pr_code,
      files,
      pr_tax_class_id,
      product_id,
      pr_template,
      pr_out_of_stock_message,
      pr_main_image,
      pr_main_image_file_id,
      pr_merchant_id,
    } = this.state;
    const productId = this.props.productProp
      ? this.props.productProp.product_id
      : undefined;
    const product = {
      pr_name,
      pr_short_description,
      pr_long_description,
      pr_type,
      pr_active,
      pr_separate_ticket,
      recommend,
      pr_recommended_from,
      pr_recommended_to,
      pr_recommended_from_error,
      pr_recommended_to_error,
      pr_code,
      files: files || [],
      pr_tax_class_id,
      product_id,
      pr_template,
      pr_out_of_stock_message,
      pr_main_image,
      pr_main_image_file_id,
      pr_merchant_id,
    };
    // if (this.ddlListNotEmpty !== true) {
    //   this.productTypes = this.getProductTypes();
    // }
    // const locationState = this.props.location.state;
    // const subProduct = locationState && locationState.parentId ? ' sub ' : ' ';
    return (
      <Segment>
        <Header as="h3">{T('Manage product')}</Header>
        <ProductGeneralFields
          ws_id={this.props.ws_id}
          product={product}
          productTypes={this.getProductTypes()}
          onValidSubmit={this.onValidSubmit}
          handleChange={this.handleChange}
          onDrop={this.onDrop}
          history={this.props.history}
          taxList={this.props.tax.data}
          stockMessages={this.getStockMessageList()}
          priceListHandler={this.renderPriceList()}
          //deleteImage={this.deleteImage}
        />

        {productId && (
          <div style={{ marginBottom: '200px' }}>
            <Menu stackable pointing>
              <Menu.Item
                name="stock"
                active={activeItem === 'stock'}
                onClick={(e, { name }) => this.handleItemClick(name)}
              >
                {T('Stock')}
              </Menu.Item>
              <Menu.Item
                name="attributes"
                active={activeItem === 'attributes'}
                onClick={(e, { name }) => this.handleItemClick(name)}
              >
                {T('Attributes')}
              </Menu.Item>
              <Menu.Item
                name="productAlternatives"
                active={activeItem === 'productAlternatives'}
                onClick={(e, { name }) => this.handleItemClick(name)}
              >
                {T('Product Alternatives')}
              </Menu.Item>
            </Menu>
            {this.renderActive(activeItem)}
          </div>
        )}
      </Segment>
    );
  }
}

function mapStateToProps({
  pricelist,
  product,
  languages,
  auth,
  tax,
  currencies,
  workspace,
}) {
  return {
    product,
    pricelist,
    languages: languages.languages,
    // langStatus: languages.status,
    auth,
    tax,
    currencies,
    workspace,
    ws_id: workspace.ws_id,
  };
}

export default connect(mapStateToProps, {
  fetchProduct,
  // fetchLanguages,
  fetchCurrencies,
  fetchTaxes,
  fetchProductUnits,
  fetchPriceLists,
  addProduct,
  editProduct,
  addProductReset,
  editProductReset,
  uploadFile,
  resetUploadFile,
  deleteProductPrice,
  deleteProductPriceReset,
  fetchProductTypes,
  fetchOutOfStockMessages,
})(withRouter(ManageProduct));
