import axios from 'axios';
import config from '../../Config/AEConfig';

import {
  FETCH_LOCATIONS_WAITING,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_ERROR,
  FETCH_LOCATIONS_RESET,
  FETCH_LOCATION_WAITING,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_ERROR,
  FETCH_LOCATION_RESET,
  EDIT_LOCATION_WAITING,
  EDIT_LOCATION_SUCCESS,
  EDIT_LOCATION_ERROR,
  EDIT_LOCATION_RESET,
  ADD_LOCATION_WAITING,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_ERROR,
  ADD_LOCATION_RESET,
  DELETE_LOCATION_WAITING,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_ERROR,
  DELETE_LOCATION_RESET,
  SELECTED_EDIT_VENUE_LOCATION,
  SELECTED_ADD_VENUE_LOCATION,
  SELECTED_EDIT_SUB_VENUE_LOCATION,
  SELECTED_ADD_SUB_VENUE_LOCATION,
  RESET_SELECTED_EDIT_VENUE_LOCATION,
  RESET_SELECTED_ADD_VENUE_LOCATION,
  RESET_SELECTED_EDIT_SUB_VENUE_LOCATION,
  RESET_SELECTED_ADD_SUB_VENUE_LOCATION,
  FETCH_PICKUP_POINTS_WAITING,
  FETCH_PICKUP_POINTS_SUCCESS,
  FETCH_PICKUP_POINTS_ERROR,
  FETCH_PICKUP_POINTS_RESET,
  ADD_PICKUP_POINT_WAITING,
  ADD_PICKUP_POINT_SUCCESS,
  ADD_PICKUP_POINT_ERROR,
  ADD_PICKUP_POINT_RESET,
} from '../Types';
import ErrorAction from './ErrorAction';

const MODULE_ID = 9;
const VENUE_ADD_MODULE_ID = 29;
const PICKUP_POINT_MODULE_ID = 30;

//const VENUE_ADD_MODULE_ID = 9;
//const PICKUP_POINT_MODULE_ID = 9;

export const setSelectedLocationById = (locationId, authKey, ws_id) => {
  if (!locationId) {
    return { type: SELECTED_EDIT_SUB_VENUE_LOCATION, payload: {} };
  }
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .get(
        `${config.BASE_URL}/location/${locationId}?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) =>
        dispatch({
          type: SELECTED_EDIT_SUB_VENUE_LOCATION,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, SELECTED_EDIT_SUB_VENUE_LOCATION)
          : SELECTED_EDIT_SUB_VENUE_LOCATION;
        dispatch({ type, payload: error });
      });
  };
};

export const fetchPickupPoints =
  (authKey, ws_id, pl_type = 'pickup_point') =>
  (dispatch) => {
    dispatch({ type: FETCH_PICKUP_POINTS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/places?module_id=${PICKUP_POINT_MODULE_ID}&ws_id=${ws_id}&pl_type=${pl_type}`;
    axios
      .get(url)
      .then((response) =>
        dispatch({
          type: FETCH_PICKUP_POINTS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_PICKUP_POINTS_ERROR)
          : FETCH_PICKUP_POINTS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetFetchPickupPoints = () => ({
  type: FETCH_PICKUP_POINTS_RESET,
});

export const addPickupPoint =
  (authKey, data, price_list_id, ws_id) => (dispatch) => {
    dispatch({ type: ADD_PICKUP_POINT_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/place?module_id=${PICKUP_POINT_MODULE_ID}&ws_id=${ws_id}&price_list_id=${price_list_id}`;
    axios
      .post(url, data)
      .then((response) =>
        dispatch({
          type: ADD_PICKUP_POINT_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_PICKUP_POINT_ERROR)
          : ADD_PICKUP_POINT_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetAddPickupPoint = () => ({ type: ADD_PICKUP_POINT_RESET });

export const selectedEditVenueLocation = (location) => ({
  type: SELECTED_EDIT_VENUE_LOCATION,
  payload: location,
});

export const resetselectedEditVenueLocation = () => ({
  type: RESET_SELECTED_EDIT_VENUE_LOCATION,
});

export const selectedAddVenueLocation = (location) => ({
  type: SELECTED_ADD_VENUE_LOCATION,
  payload: location,
});

export const resetselectedAddVenueLocation = () => ({
  type: RESET_SELECTED_ADD_VENUE_LOCATION,
});

export const selectedEditSubVenueLocation = (location) => ({
  type: SELECTED_EDIT_SUB_VENUE_LOCATION,
  payload: location,
});

export const resetselectedEditSubVenueLocation = () => ({
  type: RESET_SELECTED_EDIT_SUB_VENUE_LOCATION,
});

export const selectedAddSubVenueLocation = (location) => ({
  type: SELECTED_ADD_SUB_VENUE_LOCATION,
  payload: location,
});

export const resetselectedAddSubVenueLocation = () => ({
  type: RESET_SELECTED_ADD_SUB_VENUE_LOCATION,
});

export const fetchLocations =
  (authKey, ws_id, paging, start, searchterm, sort, asc) => (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    let url = `${config.BASE_URL}/locations?module_id=${MODULE_ID}&ws_id=${ws_id}`;
    if (paging) {
      const page = start || 1;
      url += `&paging=true&start=${page}`;
    } else {
      url += '&paging=false';
    }
    if (searchterm) {
      url += `&searchterm=${searchterm}`;
    }
    if (sort && asc) {
      url += `&sort=${sort}:${asc}`;
    } else if (sort) {
      url += `&sort=${sort}`;
    }
    dispatch({ type: FETCH_LOCATIONS_WAITING });
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_LOCATIONS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_LOCATIONS_ERROR)
          : FETCH_LOCATIONS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetFetchLocations = () => ({ type: FETCH_LOCATIONS_RESET });

export const fetchLocation = (id, authKey, ws_id) => (dispatch) => {
  dispatch({ type: FETCH_LOCATION_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .get(
      `${config.BASE_URL}/location/${id}?module_id=${MODULE_ID}&ws_id=${ws_id}`
    )
    .then((response) =>
      dispatch({
        type: FETCH_LOCATION_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      })
    )
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_LOCATION_ERROR)
        : FETCH_LOCATION_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetFetchLocation = () => ({ type: FETCH_LOCATION_RESET });

export const editLocation = (id, data, authKey, ws_id) => (dispatch) => {
  dispatch({ type: EDIT_LOCATION_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .put(
      `${config.BASE_URL}/location/${id}?module_id=${VENUE_ADD_MODULE_ID}&ws_id=${ws_id}`,
      data
    )
    .then((response) =>
      dispatch({
        type: EDIT_LOCATION_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      })
    )
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, EDIT_LOCATION_ERROR)
        : EDIT_LOCATION_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetEditLocation = () => ({ type: EDIT_LOCATION_RESET });

export const addLocation = (data, authKey, ws_id) => (dispatch) => {
  dispatch({ type: ADD_LOCATION_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .post(
      `${config.BASE_URL}/location?module_id=${VENUE_ADD_MODULE_ID}&ws_id=${ws_id}`,
      data
    )
    .then((response) =>
      dispatch({
        type: ADD_LOCATION_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      })
    )
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, ADD_LOCATION_ERROR)
        : ADD_LOCATION_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetAddLocation = () => ({ type: ADD_LOCATION_RESET });

export const deleteLocation = (id, authKey, ws_id) => (dispatch) => {
  dispatch({ type: DELETE_LOCATION_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .delete(
      `${config.BASE_URL}/location/${id}?module_id=${MODULE_ID}&ws_id=${ws_id}`
    )
    .then((response) =>
      dispatch({
        type: DELETE_LOCATION_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      })
    )
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, DELETE_LOCATION_ERROR)
        : DELETE_LOCATION_ERROR;
      dispatch({ type, payload: error });
    });
};

export const resetDeleteLocation = () => ({ type: DELETE_LOCATION_RESET });
