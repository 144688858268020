import React, { Component } from 'react';
import { Form, Button, Header, Grid, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
import { connect } from 'react-redux';
// import momentTimezone from 'moment-timezone';
import { T } from '../Common/Helpers';
import ModalWindow, { ModalContext } from '../Common/ModalWindow';
import ShopOpeningHour from './ShopOpeningHour';
import ShopSpecialOpeningHour from './ShopSpecialOpeningHour';
import PriceListMinimized from '../Pricelist/PriceListMinimized';
import SpecialOpeningHoursList from './SpecialOpeningHoursList';
import FileUpload from '../Common/FileUpload';
import { PopupContent } from '../Common/PopupContent';
import { deleteFile } from '../../Redux/actions';

const upload_target = 'shop';
const SHOP_OPEN_TIME = '00:00';
const SHOP_CLOSE_TIME = '00:00';
const POPUP_ADD_SHOP =
  'Close or open the shop immediately. The shop will stay open or closed until next day. Shop is now open = green unlock. Shop is now closed = red lock.';
const POPUP_PRICELIST =
  'Price lists connected to the shop. Removing the connetion will not remove the price list only the connection to the shop will be removed.';

class ShopManage extends Component {
  constructor(props) {
    super(props);
    let shop_opening_hours = {};
    let shop_alt_opening_hours = {};
    let shopObj = { shop_opening_hours };

    const saoh_valid_from = moment();
    const new_alt_opening = {
      saoh_valid_from: saoh_valid_from.toISOString(),
      saoh_valid_to: saoh_valid_from.add(1, 'days').toISOString(),
      shop_alt_opening_hours_id: null,
      saoh_day_of_week: '',
      saoh_open_time: '',
      saoh_close_time: '',
      saoh_day_of_weekError: false,
      saoh_open_timeError: false,
      saoh_closed: false,
    };
    if (this.props.shopId) {
      const application_id = this.props.application_id;
      shopObj = { ...this.props.shop };
      // const altGroupedObj = _.map(shopObj.shop_alt_opening_hours, (saoh, index) => ({ [index]: saoh }));
      const keys = _.mapKeys(shopObj.shop_opening_hours, 'soh_day_of_week');
      //   const altGroups = this.groupSpecialOpeningHoursTogether(shopObj.shop_alt_opening_hours);
      // _.forEach(altGroups, (group, key) => {
      //   const altKeys = _.mapKeys(group, 'saoh_day_of_week');
      //   shop_alt_opening_hours = { ...shop_alt_opening_hours, [key]: { ...this.initializeSpecialOpeningHours(true, altKeys) } };
      // });
      shop_alt_opening_hours = {
        ...shopObj.shop_alt_opening_hours,
        new_alt_opening,
      };
      shop_opening_hours = { ...this.initializeOpeningHours(true, keys) };
      shopObj = {
        ...shopObj,
        shop_opening_hours,
        shop_alt_opening_hours,
        shop_config: [
          {
            sc_application: application_id,
            sc_keyword: 'infoButtonText',
            sc_value: 'My shop button',
          },
        ],
      };
    } else {
      shopObj = {
        sh_merchant: this.props.merchantId,
        sh_name: '',
        sh_place: null,
        shop_opening_hours: { ...this.initializeOpeningHours(true, null) },
        shop_alt_opening_hours: { new_alt_opening },
        shop_price_list: [],
        main_image_url: null,
        main_image_id: null,
        open_status: false,
        imageDeleteData: null,
      };
    }
    this.state = {
      ...shopObj,
      addNewAltOpening: false,
      selectedSpecialOpening: null,
      sh_nameError: false,
      sh_placeError: false,
      imageDeleteData: null,
    };
  }
  setImageDeleted(imageDeleteData) {
    this.setState({ imageDeleteData });
  }

  formatTime(value) {
    if (value.length === 8) {
      const tmp = value.split(':');
      return tmp[0] + ':' + tmp[1];
    }
    return value;
  }

  getOpenStatus = (shop) => {
    if (shop.open_status === true) {
      return (
        <Icon
          name="unlock"
          size="big"
          color="green"
          onClick={(e) => this.setCurrentShopOpenStatus(e, true)}
        />
      );
    }
    return (
      <Icon
        name="lock"
        size="big"
        color="red"
        onClick={(e) => this.setCurrentShopOpenStatus(e, false)}
      />
    );
  };

  // setSpecialShopOpeningTimezoneFormat = saohArray =>
  //   _.map(saohArray, saoh => {
  //     const validFrom = moment(saoh.saoh_valid_from);
  //     const validTo = moment(saoh.saoh_valid_to);
  //     const saoh_valid_from = validFrom.toISOString();
  //
  //     const saoh_valid_to = validTo.toISOString();
  //
  //     let saoh_open_time = saoh.saoh_open_time;
  //     if (saoh_open_time) {
  //       saoh_open_time = moment(`${saoh_open_time} Z`, 'HH:mm');
  //       saoh_open_time = saoh_open_time.tz(timezone).format('HH:mm');
  //     }
  //
  //     let saoh_close_time = saoh.saoh_close_time;
  //     if (saoh_close_time) {
  //       saoh_close_time = moment(`${saoh_close_time} Z`, 'HH:mm');
  //       saoh_close_time = saoh_close_time.tz(timezone).format('HH:mm');
  //     }
  //
  //     return { ...saoh, saoh_valid_from, saoh_valid_to, saoh_open_time, saoh_close_time };
  //   });

  setShopOpening = (index, type, value) => {
    const shop_opening_hours = { ...this.state.shop_opening_hours };
    if (typeof value === 'boolean') {
      let tempOpens = SHOP_OPEN_TIME;
      let tempCloses = SHOP_CLOSE_TIME;
      if (value === true) {
        tempOpens = '';
        tempCloses = '';
      }
      shop_opening_hours[index][type] = !shop_opening_hours[index][type];
      shop_opening_hours[index].soh_open_time = tempOpens;
      shop_opening_hours[index].soh_close_time = tempCloses;
    } else {
      //shop_opening_hours[index][type] = moment(value, 'l HH:mm').toISOString();
      shop_opening_hours[index][type] = value;
    }
    // console.log(index, type, value, '\nState: ', shopOpening[index]);
    this.setState({ shop_opening_hours });
  };
  setSpecialShopOpening = (objKey, type, value) => {
    // console.log(`Key: ${objKey}\nType: ${type} \nValue: ${value}`);
    const shop_alt_opening_hours = { ...this.state.shop_alt_opening_hours };
    if (typeof value === 'boolean') {
      // let tempOpens = '08:00';
      // let tempCloses = '16:00';
      // if (value === true) {
      //   tempOpens = '';
      //   tempCloses = '';
      // }
      shop_alt_opening_hours[objKey][type] =
        !shop_alt_opening_hours[objKey][type];
      // shop_alt_opening_hours[objKey].saoh_open_time = tempOpens;
      // shop_alt_opening_hours[objKey].saoh_close_time = tempCloses;
      shop_alt_opening_hours[objKey].saoh_shop = this.props.shopId;
    } else if (type === 'saoh_valid_from' || type === 'saoh_valid_to') {
      const now = moment();
      if (type === 'saoh_valid_from') {
        const validFrom = moment(value, 'l HH:mm');
        let validTo = moment(shop_alt_opening_hours[objKey].saoh_valid_to);
        if (validFrom.isBefore(now)) {
          toast({
            type: 'error',
            title: T('Error'),
            description: T('Valid-from cannot be in the past!'),
            time: 5000,
          });
          return;
        } else if (validTo.isBefore(validFrom)) {
          validTo = moment(validFrom, 'l HH:mm').add(1, 'days');
          shop_alt_opening_hours[objKey].saoh_valid_from =
            validFrom.toISOString();
          shop_alt_opening_hours[objKey].saoh_valid_to = validTo.toISOString();
        } else {
          shop_alt_opening_hours[objKey].saoh_valid_from =
            validFrom.toISOString();
        }
      } else if (type === 'saoh_valid_to') {
        const validTo = moment(value, 'l HH:mm');
        const validFrom = moment(
          shop_alt_opening_hours[objKey].saoh_valid_from
        );
        if (validTo.isBefore(validFrom)) {
          toast({
            type: 'error',
            title: T('Error'),
            description: T('Valid-to cannot precede valid-from field!'),
            time: 5000,
          });
          return;
        }
        shop_alt_opening_hours[objKey].saoh_valid_to = validTo.toISOString();
      }
    } else {
      // if ((objKey === 'new_alt_opening') && (type === 'saoh_day_of_week' || type === 'saoh_open_time')) {
      shop_alt_opening_hours[objKey][`${type}Error`] = false;
      // }
      shop_alt_opening_hours[objKey][type] = value;
      shop_alt_opening_hours[objKey].saoh_shop = this.props.shopId;
    }
    // console.log('Shop alt opening hours: ', shop_alt_opening_hours);
    this.setState({ shop_alt_opening_hours });
  };

  setCurrentShopOpenStatus = (e, saoh_closed) => {
    let midNight = moment().endOf('day');
    const saoh_close_time = null;
    let now = moment();
    const opens_from = saoh_closed ? null : moment().format('HH:mm');
    const saoh_day_of_week = now.day() + 1;
    const obj = {
      saoh_day_of_week,
      saoh_open_time: opens_from,
      saoh_valid_from: now.toISOString(),
      saoh_valid_to: midNight.toISOString(),
      saoh_close_time,
      saoh_closed,
      saoh_shop: this.props.shopId || null,
    };
    // debugger;
    this.submitAddShop(obj);
  };

  groupSpecialOpeningHoursTogether = (specialOpenings) =>
    _.reduce(
      specialOpenings,
      (acc, opening) => {
        const temp = { ...acc };
        temp[opening.saoh_valid_from] = [
          ...(temp[opening.saoh_valid_from] || []),
          opening,
        ];
        return temp;
      },
      {}
    );
  chooseSpecialOpeningToDisplay = (objKey) =>
    this.state.selectedSpecialOpening !== objKey &&
    this.setState({ selectedSpecialOpening: objKey });

  displaySpecialOpening = () => {
    const objKey = this.state.selectedSpecialOpening;
    if (!objKey) return <div />;
    const opening = { ...this.state.shop_alt_opening_hours[objKey] };
    return (
      <ShopSpecialOpeningHour
        key={objKey}
        title={T('Special shop opening hours')}
        active
        altOpening
        so={opening}
        // valid={opening.valid}
        setShopOpening={this.setSpecialShopOpening}
        formatTime={this.formatTime}
        objKey={objKey}
      />
    );
  };

  deleteExistingSpecialOpeningHour = (index) => {
    const shop_alt_opening_hours = { ...this.state.shop_alt_opening_hours };
    delete shop_alt_opening_hours[index];
    // const selectedSpecialOpening = selectedSpecialOpening === index ? null : selectedSpecialOpening;
    this.setState({ shop_alt_opening_hours, selectedSpecialOpening: null });
  };

  // TODO this is done in a stupid way, correct this
  initializeOpeningHours = (defaultOpenning, ids) => {
    const shop_opening_hours = {
      1: {
        shop_opening_hours_id: null,
        soh_day_of_week: 1,
        soh_open_time: SHOP_OPEN_TIME,
        soh_close_time: SHOP_CLOSE_TIME,
        closed: defaultOpenning,
      },
      2: {
        shop_opening_hours_id: null,
        soh_day_of_week: 2,
        soh_open_time: SHOP_OPEN_TIME,
        soh_close_time: SHOP_CLOSE_TIME,
        closed: defaultOpenning,
      },
      3: {
        shop_opening_hours_id: null,
        soh_day_of_week: 3,
        soh_open_time: SHOP_OPEN_TIME,
        soh_close_time: SHOP_CLOSE_TIME,
        closed: defaultOpenning,
      },
      4: {
        shop_opening_hours_id: null,
        soh_day_of_week: 4,
        soh_open_time: SHOP_OPEN_TIME,
        soh_close_time: SHOP_CLOSE_TIME,
        closed: defaultOpenning,
      },
      5: {
        shop_opening_hours_id: null,
        soh_day_of_week: 5,
        soh_open_time: SHOP_OPEN_TIME,
        soh_close_time: SHOP_CLOSE_TIME,
        closed: defaultOpenning,
      },
      6: {
        shop_opening_hours_id: null,
        soh_day_of_week: 6,
        soh_open_time: SHOP_OPEN_TIME,
        soh_close_time: SHOP_CLOSE_TIME,
        closed: defaultOpenning,
      },
      7: {
        shop_opening_hours_id: null,
        soh_day_of_week: 7,
        soh_open_time: SHOP_OPEN_TIME,
        soh_close_time: SHOP_CLOSE_TIME,
        closed: defaultOpenning,
      },
    };
    if (ids) {
      const keys = Object.keys(ids).map((e) => parseInt(e, 10));
      _.forEach(shop_opening_hours, (op, key) => {
        if (keys.indexOf(op.soh_day_of_week) !== -1) {
          shop_opening_hours[key] = ids[key];
          // shop_opening_hours[key].soh_open_time = momentTimezone.tz(ids[key].soh_open_time, timezone).format('HH:mm ZZ');
          // shop_opening_hours[key].soh_close_time = momentTimezone.tz(ids[key].soh_close_time, timezone).format('HH:mm ZZ');
          shop_opening_hours[key].closed = false;
          // shop_opening_hours[key].edited = true;
        } else {
          shop_opening_hours[key].closed = true;
          shop_opening_hours[key].soh_open_time = '';
          shop_opening_hours[key].soh_close_time = '';
          // console.log('Im here', key, op);
        }
      });
    }
    return shop_opening_hours;
  };

  toggleAddNewSpecialOpeningHours = () => {
    this.setState((prevState) => ({
      addNewAltOpening: !prevState.addNewAltOpening,
    }));
  };

  filterShopOpeningHours = () => {
    const shop_opening_hours = { ...this.state.shop_opening_hours };
    const filteredOpeningHours = Object.values(
      _.filter(shop_opening_hours, (oh) => oh.closed === false)
    );
    return filteredOpeningHours.map((oh) => ({
      ...oh,
      // soh_open_time: moment(oh.soh_open_time, 'HH:mm Z'),
      // soh_close_time: moment(oh.soh_close_time, 'HH:mm Z')
    }));
  };

  filterValidSoh = () => {
    let data = { ...this.state.shop_alt_opening_hours.new_alt_opening };
    const fields = ['saoh_day_of_week', 'saoh_open_time', 'saoh_close_time'];
    const valid = _.every(fields, (field) => data[field] !== '');
    const error = _.some(fields, (field) => data[field] !== '');
    if (valid) {
      return {
        valid: true,
        data: Object.values(this.state.shop_alt_opening_hours),
      };
    } else if (error) {
      // console.log('Validating dates: ', data);
      const errorFields = fields.filter((field) => data[field] === '');
      return { valid: false, errorFields };
    }
    data = { ...this.state.shop_alt_opening_hours };
    delete data.new_alt_opening;
    return { valid: true, data: Object.values(data) };
  };
  filterSpecialShopOpeningHours = () => {
    const shop_alt_opening_hours = { ...this.state.shop_alt_opening_hours };
    const filteredTime = [];
    const filteredOpeningHours = this.state.addNewAltOpening
      ? shop_alt_opening_hours
      : _.filter(
          shop_alt_opening_hours,
          (oh, key) => key !== 'new_alt_opening'
        );
    _.forEach(filteredOpeningHours, (ohObj) =>
      _.filter(ohObj.time, (oh) => oh.saoh_closed === false).map((aoh) =>
        filteredTime.push({
          ...aoh,
          saoh_valid_from: ohObj.valid.saoh_valid_from,
          saoh_valid_to: ohObj.valid.saoh_valid_to,
          saoh_shop: this.props.shopId || null,
        })
      )
    );
    return filteredTime;
    // return _.map(filteredOpeningHours, oh => (
    //   { ...oh,
    //     saoh_valid_from: moment(this.state.shop_alt_opening_hours.valid.saoh_valid_from, 'l HH:mm Z'),
    //     saoh_valid_to: moment(this.state.shop_alt_opening_hours.valid.saoh_valid_to, 'l HH:mm Z'),
    //     // saoh_open_time: moment(oh.saoh_open_time, 'HH:mm Z'),
    //     // saoh_close_time: moment(oh.saoh_close_time, 'HH:mm Z')
    //   }));
  };

  validateFields() {
    const style = {};
    const requiredStringFields = ['sh_name'];
    const requiredNumericFields = [];
    let valid = true;

    requiredStringFields.forEach((field) => {
      const val = this.state[field];
      if (val === undefined || val === null || val.toString().trim() === '') {
        style[`${field}Error`] = true;
        this.setState({ ...style });
        valid = false;
      }
    });

    requiredNumericFields.forEach((field) => {
      const val = this.state[field];
      if (val === undefined || val === null) {
        style[`${field}Error`] = true;
        this.setState({ ...style });
        valid = false;
      }
    });

    return valid;
  }

  submitAddShop = (openToggler) => {
    let sender = 'edit';

    if (this.validateFields() === false) {
      return;
    }

    const authKey = localStorage.getItem('x-auth-key');
    const data = { ...this.state };

    if (this.state.imageDeleteData !== null) {
      this.props.deleteFile(this.state.imageDeleteData, this.props.ws_id);
      data.main_image_url = null;
      data.main_image_id = null;
    }

    delete data.status; //removing internal state variables
    delete data.addNewAltOpening;
    delete data.selectedSpecialOpening;
    data.shop_opening_hours = this.filterShopOpeningHours();
    // data.shop_alt_opening_hours = this.filterSpecialShopOpeningHours();
    const tempObj = this.filterValidSoh();
    if (!tempObj.valid) {
      const errorFields = tempObj.errorFields;
      const errorObject = {};
      errorFields.forEach((field) => {
        errorObject[`${field}Error`] = true;
      });
      let shop_alt_opening_hours = { ...this.state.shop_alt_opening_hours };
      const new_alt_opening = {
        ...shop_alt_opening_hours.new_alt_opening,
        ...errorObject,
      };
      shop_alt_opening_hours = { ...shop_alt_opening_hours, new_alt_opening };
      this.setState({ shop_alt_opening_hours });
      return;
    }
    data.shop_alt_opening_hours = tempObj.data;
    if (openToggler) {
      data.shop_alt_opening_hours.push(openToggler);
      sender = 'shop-operation';
    }
    const { ws_id } = this.props;
    // for (const [value] of Object.entries(data.shop_alt_opening_hours)) {
    //   value.saoh_valid_from = value.saoh_valid_from, 'l HH:mm').toISOString();
    //   value.saoh_valid_to = value.saoh_valid_to, 'l HH:mm').toISOString();
    // }
    if (this.props.shopId) {
      const shopId = this.props.shopId;
      this.props.editShop({ authKey, shopId, formData: data, ws_id, sender });
    } else {
      this.props.addShop({ authKey, formData: data, ws_id });
    }
  };
  addPriceListsToList = (shop_price_list) => {
    this.setState({ shop_price_list });
  };
  removePricelist = (id) => {
    const shop_price_list = this.state.shop_price_list.filter(
      (ele) => ele.price_list_id !== id
    );
    this.setState({ shop_price_list });
  };

  imageUpdate(urlSmall, urlLarge, key, file_id) {
    this.setState({ main_image_url: urlLarge, main_image_id: file_id });
  }

  // NOT IN USE
  imageDelete(status, deleted) {
    if (
      this.state.main_image_url !== null &&
      this.state.main_image_id > 0 &&
      status === 'success' &&
      deleted === true
    ) {
      this.setState({ main_image_url: null, main_image_id: null });
    }
  }

  handleInputChange = (e, { name, value }) => {
    this.setState({ [name]: value, [`${name}Error`]: false });
  };

  renderPlaces = () =>
    _.map(this.props.places, (place) => ({
      text: place.pl_name,
      value: place.place_id,
    }));

  renderPriceLists = (pricelists) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
        minHeight: '150px',
        maxHeight: '400px',
        overflowY: 'scroll',
        border: '1px solid rgb(232, 232, 232)',
        marginRight: '15px',
        marginBottom: '5px',
        padding: '10px',
      }}
    >
      {_.map(pricelists, (pricelist) => (
        <Form.Group inline key={`group-${pricelist.price_list_id}`}>
          <div
            style={{
              display: 'inline-block',
              width: '250px',
              textAlign: 'left',
            }}
          >
            <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
              {pricelist.pl_name}
            </span>
          </div>
          {/*  disabled
                key={`name-${pickup.place_id}`}
                name='pickup_name'
                value={pickup.pl_name}
            />*/}
          <Button
            key={`button-${pricelist.price_list_id}`}
            onClick={() => this.removePricelist(pricelist.price_list_id)}
            icon="delete"
            size="mini"
            color="red"
          />
        </Form.Group>
      ))}
    </div>
  );

  render() {
    const buttonLabel = this.props.shopId ? T('Save') : T('Add Shop');
    const header = this.props.shopId ? T('Edit Shop') : T('Create Shop');
    return (
      <React.Fragment>
        <Header> {header} </Header>
        <Form style={{ marginTop: '50px' }}>
          <Grid stackable>
            <Grid.Row columns="2">
              <Grid.Column width={4}>
                <Form.Input
                  error={this.state.sh_nameError}
                  required
                  name="sh_name"
                  label={T('Shop name')}
                  value={this.state.sh_name}
                  onChange={this.handleInputChange}
                  type="text"
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <Form.Field>
                  <Form.Dropdown
                    error={this.state.sh_placeError}
                    //required
                    search
                    selection
                    selectOnNavigation
                    noResultsMessage={T('No result')}
                    name="sh_place"
                    label={T('Location')}
                    value={this.state.sh_place}
                    onChange={this.handleInputChange}
                    options={this.renderPlaces()}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <h3>{T('Price lists')}</h3>
            <Grid.Row columns="1">
              <Grid.Column>
                <h4>
                  {T('Price lists connected to the shop')}
                  <PopupContent content={POPUP_PRICELIST} />
                </h4>
                <Form.Group>
                  {this.renderPriceLists(this.state.shop_price_list)}
                  <ModalWindow
                    buttonColor="blue"
                    buttonSize="tiny"
                    buttonLabel={T('Select Price lists')}
                    header={T('Select price lists')}
                  >
                    <ModalContext.Consumer>
                      {({ closeModal }) => (
                        <PriceListMinimized
                          closeModal={closeModal}
                          selectedPriceLists={this.state.shop_price_list}
                          setPriceList={this.addPriceListsToList}
                          price_list_id={this.props.price_list_id}
                          history={this.props.history}
                        />
                      )}
                    </ModalContext.Consumer>
                  </ModalWindow>
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <h3>{T('Opening times')}</h3>
            {this.props.shopId && (
              <Grid.Row columns="1">
                <Grid.Column style={{ cursor: 'pointer' }}>
                  <h4>
                    {T('Open or close the shop immediately')}
                    <PopupContent content={POPUP_ADD_SHOP} />
                  </h4>
                  {this.getOpenStatus(this.state)}
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row columns="2">
              <Grid.Column>
                <h4>{T('Define normal shop openig hours')}</h4>
                <ShopOpeningHour
                  title={T(
                    'Normal shop opening hours. Set times as 0:00-0:00 if shop is alway open.'
                  )}
                  shopOpening={this.state.shop_opening_hours}
                  setShopOpening={this.setShopOpening}
                  formatTime={this.formatTime}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column>
                <h4>{T('Define exceptional shop openig hours')}</h4>
                {/*<Form.Checkbox
                    toggle
                    name="add_new_alt_opening"
                    checked={this.state.addNewAltOpening}
                    onChange={this.toggleAddNewSpecialOpeningHours}
                    label={T('Add new special opening hours')}
                  />*/}
                <ShopSpecialOpeningHour
                  title={T('Add new special shop opening hours')}
                  altOpening
                  active={false}
                  so={this.state.shop_alt_opening_hours.new_alt_opening}
                  // valid={this.state.shop_alt_opening_hours.new_alt_opening.valid}
                  setShopOpening={this.setSpecialShopOpening}
                  formatTime={this.formatTime}
                  objKey="new_alt_opening"
                />
              </Grid.Column>
              <Grid.Column>
                {Object.keys(this.state.shop_alt_opening_hours).length > 1 && (
                  <SpecialOpeningHoursList
                    deleteExistingSpecialOpeningHour={
                      this.deleteExistingSpecialOpeningHour
                    }
                    specialOpenings={this.state.shop_alt_opening_hours}
                    filteredKey="new_alt_opening"
                    chooseSpecialOpeningToDisplay={
                      this.chooseSpecialOpeningToDisplay
                    }
                  />
                )}
                {this.displaySpecialOpening()}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <Form.Field>
                  <h3>{T('Shop image')}</h3>
                  <FileUpload
                    id={this.props.merchantId}
                    file_key={this.state.key}
                    file_url={this.state.main_image_url || undefined}
                    file_id={this.state.main_image_id}
                    ws_id={this.props.ws_id}
                    target={upload_target}
                    module_id={this.props.module_id}
                    callback={this.imageUpdate.bind(this)}
                    deletecallback={this.setImageDeleted.bind(this)}
                    style={{
                      height: 193.5,
                      width: 200,
                      minHeight: 193.5,
                      minWidth: 200,
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        <Button
          content={T(buttonLabel)}
          icon="save"
          primary
          type="submit"
          onClick={() => this.submitAddShop(null)}
        />
        <Button
          style={{ marginTop: '20px' }}
          content={T('Cancel')}
          icon="ban"
          secondary
          onClick={() => this.props.history.push('/app/shop/list')}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps({ workspace, languages }) {
  return {
    ws_id: workspace.ws_id,
    workspace,
    languages: languages.languages,
  };
}

//export default ShopManage;
export default connect(mapStateToProps, { deleteFile })(ShopManage);
