import {
  FETCH_CARDS_WAITING,
  FETCH_CARDS_SUCCESS,
  FETCH_CARDS_ERROR,
  FETCH_CARDS_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  status: 'init',
};

export default function CardReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_CARDS_WAITING:
      return { ...state, status: 'waiting' };
    case FETCH_CARDS_SUCCESS:
      return { ...state, data: action.payload.data, status: 'success' };
    case FETCH_CARDS_ERROR:
      return { ...state, status: 'error', error: action.payload.error };
    case FETCH_CARDS_RESET:
      return { ...state, status: 'init', error: undefined };
    default:
      return state;
  }
}
