/* eslint-env browser */
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { toast } from 'react-semantic-toasts';
import {
  Table,
  Button,
  Icon,
  Menu,
  Pagination,
  Confirm,
  Header,
} from 'semantic-ui-react';
import moment from 'moment';
import {
  fetchShops,
  deleteShop,
  resetDeleteShop,
  editShop,
  resetEditShop,
} from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import { T, getErrorMessage } from '../Common/Helpers';
import { getPopupContent } from '../Common/PopupContent';
//const DATE_TIME_FORMAT = 'l HH:mm';
const POPUP_ADD_SHOP =
  'Close or open the shop immediately. The shop will stay open or closed until next day. Shop is now open = green unlock. Shop is now closed = red lock.';
const POPUP_ICON_SIZE = 'big';

class ShopList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDelete: false,
      selectedShop: null,
      current_dt: moment(),
    };

    /*  this.current_dt = moment();
    this.currentDay = {
      day_of_week: this.current_dt.format('e'),
      time: this.current_dt.format('HH:mm:ss'),
      date_time: this.current_dt.format(DATE_TIME_FORMAT)
    };
*/
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.isAdmin = this.props.auth.isSuperAdmin;
    this.setCurrentShopOpenStatus = this.setCurrentShopOpenStatus.bind(this);
  }

  componentDidMount() {
    // localStorage.setItem('route', this.props.history.location.pathname);
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    this.props.fetchShops({ authKey, ws_id });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.shop.shopDeleteStatus === 'success' &&
      prevProps.shop.shopDeleteStatus !== 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Shop deleted successfully'),
        time: 5000,
      });
      this.props.resetDeleteShop();
    }
    if (
      this.props.shop.shopDeleteStatus === 'error' &&
      prevProps.shop.shopDeleteStatus !== 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.shop.error,
        'Error occurred while deleting pricelist'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetDeleteShop();
    }
  }

  onDelete(e, value) {
    this.setState({ confirmDelete: true, selectedShop: value });
  }

  onDeleteCancel() {
    this.setState({ confirmDelete: false });
  }

  onDeleteConfirm() {
    const { selectedShop } = this.state;
    if (selectedShop) {
      const { ws_id } = this.props;
      const authKey = localStorage.getItem('x-auth-key');
      this.props.deleteShop({ authKey, shopId: selectedShop, ws_id });
    }
    this.setState({ confirmDelete: false });
  }

  getConfigButtons(shop_id) {
    return (
      <Table.Cell
        textAlign="right"
        singleLine
        onClick={(e) => e.stopPropagation()}
        style={{ cursor: 'default' }}
      >
        <Button
          primary
          onClick={() =>
            this.props.history.push(`/app/shop/manageshop/config/${shop_id}`)
          }
        >
          <Icon name="cogs" size="small" />
        </Button>

        <Button
          value={shop_id}
          disabled={this.props.auth.isSuperAdmin !== true}
          onClick={(e) => this.onDelete(e, shop_id)}
          content={T('Delete')}
        >
          <Icon name="trash" size="small" />
        </Button>
      </Table.Cell>
    );
  }

  /*getPopupContent(content) {
    return (
      <Popup content={T(content)} trigger={<Icon name='question circle' size='big' />} />
    );
  }*/

  getOpenStatus = (shop) => {
    if (shop.open_status === true) {
      return (
        <Icon
          name="unlock"
          size="big"
          color="green"
          onClick={(e) => this.setCurrentShopOpenStatus(e, shop, true)}
        />
      );
    }
    return (
      <Icon
        name="lock"
        size="big"
        color="red"
        onClick={(e) => this.setCurrentShopOpenStatus(e, shop, false)}
      />
    );
  };

  setCurrentShopOpenStatus = (e, shop, saoh_closed) => {
    let midNight = moment().endOf('day');
    const saoh_close_time = null;
    let now = moment();
    const opens_from = saoh_closed ? null : moment().format('HH:mm Z');
    const saoh_day_of_week = now.day() + 1;
    const obj = {
      saoh_day_of_week,
      saoh_open_time: opens_from,
      saoh_valid_from: now.toISOString(),
      saoh_valid_to: midNight.toISOString(),
      saoh_close_time,
      saoh_closed,
      saoh_shop: shop.shop_id,
    };
    this.submitEditShopAltOpen(shop, obj);
  };

  submitEditShopAltOpen = (shop, obj) => {
    const sender = 'edit';
    const shopId = shop.shop_id;
    const authKey = localStorage.getItem('x-auth-key');
    shop.shop_alt_opening_hours.push(obj);
    const data = {
      shop_id: shopId,
      shop_alt_opening_hours: shop.shop_alt_opening_hours,
    };
    const { ws_id } = this.props;
    this.props.editShop({ authKey, shopId, formData: data, ws_id, sender });
  };

  navigateToLink = (shop) => {
    this.props.history.push({
      pathname: `/app/shop/manageshop/${shop.shop_id}`,
      state: shop,
    });
  };

  handlePaginationChange() {
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    this.props.fetchShops({ authKey, ws_id });
  }

  renderShops() {
    return _.map(this.props.shop.shops, (shop) => (
      <Table.Row
        key={shop.shop_id}
        onClick={() => this.navigateToLink(shop)}
        style={{ cursor: 'pointer' }}
        className="tablerow"
      >
        <Table.Cell>{shop.shop_id}</Table.Cell>
        <Table.Cell>{shop.sh_name}</Table.Cell>
        <Table.Cell>{this.getOpenStatus(shop)}</Table.Cell>
        {this.isAdmin === true ? this.getConfigButtons(shop.shop_id) : false}
      </Table.Row>
    ));
  }

  render() {
    if (this.props.shop.shopsFetchStatus === 'waiting') {
      return <Spinner />;
    }

    return (
      <React.Fragment>
        <Header> {T('Shops List')} </Header>
        <Table sortable compact celled padded stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{T('Id')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Name')}</Table.HeaderCell>
              <Table.HeaderCell>
                {T('Open or Close the shop')}
                {getPopupContent(POPUP_ADD_SHOP, POPUP_ICON_SIZE)}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderShops()}
            <Table.Row>
              <Confirm
                header={T('Delete content')}
                content={T(
                  'Are you sure you want to delete the selected shop?'
                )}
                confirmButton={T('Delete')}
                cancelButton={T('Cancel')}
                open={this.state.confirmDelete}
                onCancel={this.onDeleteCancel}
                onConfirm={this.onDeleteConfirm}
                size="tiny"
              />
            </Table.Row>
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="4">
                <Link to="/app/shop/manageshop">
                  <Button icon="plus" primary content={T('Add')} />
                </Link>
                {false && this.props.shop.headers && (
                  <Menu floated="right" pagination>
                    <Pagination
                      onPageChange={this.handlePaginationChange}
                      defaultActivePage={this.props.shop.headers.current_page}
                      ellipsisItem={{
                        content: <Icon name="ellipsis horizontal" />,
                        icon: true,
                      }}
                      firstItem={{
                        content: <Icon name="angle double left" />,
                        icon: true,
                      }}
                      lastItem={{
                        content: <Icon name="angle double right" />,
                        icon: true,
                      }}
                      prevItem={{
                        content: <Icon name="angle left" />,
                        icon: true,
                      }}
                      nextItem={{
                        content: <Icon name="angle right" />,
                        icon: true,
                      }}
                      totalPages={this.props.shop.headers.total_pages}
                    />
                  </Menu>
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ auth, shop, workspace }) {
  return { auth, shop, ws_id: workspace.ws_id };
}

export default connect(mapStateToProps, {
  fetchShops,
  deleteShop,
  resetDeleteShop,
  editShop,
  resetEditShop,
})(withRouter(ShopList));
