import React, { Component } from 'react';
import _ from 'lodash';
// import UIfx from 'uifx';
import {
  Dropdown,
  Dimmer,
  Loader,
  Message,
  Segment,
  Card,
  Button,
  Icon,
  Transition,
  Confirm,
  Label,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Spinner } from '../Common/Spinner';
import config from '../../Config/AEConfig';
import { fetchShops } from '../../Redux/actions';
import { T } from '../Common/Helpers';

import bellAudioSrc from '../../audio/bell_ring.mp3';
const bellAudio = new Audio(bellAudioSrc);

// const bell = new UIfx(bellAudio);

// onClick of first interaction on page before I need the sounds
// (This is a tiny MP3 file that is silent and extremely short - retrieved from https://bigsoundbank.com and then modified)
bellAudio.src =
  'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';

class WaiterCalls extends Component {
  state = {
    connected: false,
    lastMessage: '',
    currentShop: null,
    loggedInToShop: false,
    userShopMessages: [],
    visible: {},
    audioEnabled: false,
    declineUsm: {},
  };
  componentDidMount() {
    const { ws_id, activeWorkspace } = this.props.workspace;
    const { cnc_application } = activeWorkspace;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.fetchShops({ authKey, ws_id, application_id: cnc_application });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.shop.shopsFetchStatus !== 'success' &&
      this.props.shop.shopsFetchStatus === 'success'
    ) {
      // console.log('shops loaded ok', this.props.shop);
      if (!this.state.currentShop) {
        const waiterCallShops = this.getWaiterCallShops();
        if (waiterCallShops.length > 0) {
          this.setState({ currentShop: waiterCallShops[0].shop_id });
        }
      }
      this.initWebSocket();
    }
    if (
      this.state.connected &&
      this.state.currentShop &&
      !this.state.loggedInToShop
    ) {
      this.loginToShop();
    }
  }

  componentWillUnmount() {
    this.cancelRefreshTimer();
  }

  cancelRefreshTimer() {
    if (this.rtHandle) {
      clearTimeout(this.rtHandle);
      this.rtHandle = null;
    }
  }

  resetRefreshTimer() {
    this.cancelRefreshTimer();
    this.rtHandle = setTimeout(() => {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        this.ws.send(
          JSON.stringify({
            action: 'refresh',
          })
        );
      }
      this.rtHandle = null;
    }, 60 * 1000); // fire after one minut
  }

  initWebSocket() {
    this.ws = new WebSocket(`${config.WS2_BASE_URL}mobileapi/shop`);

    this.ws.onopen = () => {
      this.setState({ connected: true });
      //const authKey = localStorage.getItem('x-auth-key');
      //this.ws.send(JSON.stringify({ action: 'join', updateChannelName }));
    };

    this.ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data.action === 'loginResponse' && data.result === 'success') {
        this.setState({ loggedInToShop: true, shopId: data.shopId });
      }
      if (data.action === 'userShopMessages') {
        this.setState({ userShopMessages: data.userShopMessages });
        this.resetRefreshTimer();
      }
    };

    this.ws.onerror = (e) => {
      console.log('WaiterCalls Error from WebSocket: ', e.message);
    };

    this.ws.onclose = (e) => {
      if (!this.unmounting) {
        this.setState({ connected: false, loggedInToShop: false });
        this.rtHandle = setTimeout(() => {
          this.initWebSocket();
        }, 2000);
      }
    };
  }

  loginToShop = () => {
    const authKey = localStorage.getItem('x-auth-key');
    this.ws.send(
      JSON.stringify({
        action: 'registerShop',
        sessionKey: authKey,
        shopId: this.state.currentShop,
      })
    );
  };

  onChange = (e, data) => {
    const { value } = data;
    this.setState({ currentShop: value, loggedInToShop: false });
  };

  getWaiterCallShops = () => {
    const shops = _.map(this.props.shop.shops, (obj) => obj);
    return shops.filter((s) => {
      const { shop_config } = s;
      const callConfig = shop_config.find(
        (sc) => sc.sc_keyword === 'callWaiter'
      );
      // console.log('callConfig', callConfig)
      return callConfig && callConfig.sc_value === 'true';
    });
  };

  renderShopList() {
    if (this.props.shop === null || this.props.shop.shops === null) {
      return <Loader active inline />;
    }
    const options = this.getWaiterCallShops().map((s) => ({
      key: s.shop_id,
      text: s.sh_name,
      value: s.shop_id,
    }));
    // console.log('options',options);
    if (options.length === 0) {
      return <p>{T('No shops found for current user.')}</p>;
    }
    return (
      <Dropdown
        placeholder={T('Choose Shop')}
        options={options}
        selection
        value={this.state.currentShop}
        onChange={this.onChange}
      />
    );
  }

  handleApproveClick = (usm) => {
    this.setState({
      visible: { ...this.state.visible, [usm.userMessageId]: false },
    });
    const splitMsg = usm.message.split(':');
    this.ws.send(
      JSON.stringify({
        action: 'messageToUser',
        message: `waiterComing:${splitMsg[1]}`,
        userId: usm.userId,
      })
    );
  };

  handleDeclineClick = () => {
    const usm = this.state.declineUsm;
    this.setState({
      visible: { ...this.state.visible, [usm.userMessageId]: false },
    });
    const splitMsg = usm.message.split(':');
    this.ws.send(
      JSON.stringify({
        action: 'messageToUser',
        message: `waiterDeclined:${splitMsg[1]}`,
        userId: usm.userId,
      })
    );
  };

  handleVisitedClick = (usm) => {
    this.setState({
      visible: { ...this.state.visible, [usm.userMessageId]: false },
    });
    const splitMsg = usm.message.split(':');
    this.ws.send(
      JSON.stringify({
        action: 'messageToUser',
        message: `waiterVisited:${splitMsg[1]}`,
        userId: usm.userId,
      })
    );
  };

  handleCancelClick = (usm) => {
    this.setState({
      visible: { ...this.state.visible, [usm.userMessageId]: false },
    });
    const splitMsg = usm.message.split(':');
    this.ws.send(
      JSON.stringify({
        action: 'messageToUser',
        message: `waiterCancelled:${splitMsg[1]}`,
        userId: usm.userId,
      })
    );
  };

  getFilteredMessages(userShopMessages) {
    if (!userShopMessages) {
      return [];
    }
    const filteredMessages = {};
    userShopMessages.forEach((usm) => {
      filteredMessages[usm.userId] = usm; // only look at the last message of each user
    });
    return _.values(filteredMessages);
  }
  renderMessages(msgTypes) {
    const filteredMessages = this.getFilteredMessages(
      this.state.userShopMessages
    );
    if (filteredMessages.length === 0) {
      return <Message info>{T('No calls')}</Message>;
    }
    let playSound = false;
    const cards = filteredMessages
      .filter((m) => msgTypes.includes(m.message.split(':')[0]))
      .sort((a, b) => {
        return a.userMessageId - b.userMessageId;
      })
      .map((usm) => {
        //console.log('rendering message card', usm);
        const splitMsg = usm.message.split(':');
        if (splitMsg.length !== 2) {
          return null;
        }

        const summonerName =
          usm.userName == null ? T('Unregistered User') : usm.userName;

        let content;
        let buttons;
        let color;
        let icon;
        switch (splitMsg[0]) {
          case 'summonWaiter':
            if (this.state.visible[usm.userMessageId] === undefined) {
              playSound = true;
              this.setState({
                visible: { ...this.state.visible, [usm.userMessageId]: true },
              });
            }
            icon = <Icon name="alarm" color="red" size="large" />;
            content = (
              <Card.Description>
                {T('Please respond to call from table')} {splitMsg[1]} (
                <strong>{summonerName}</strong>)
              </Card.Description>
            );
            buttons = (
              <div className="ui two buttons">
                <Button
                  inverted
                  color="green"
                  onClick={() => {
                    this.handleApproveClick(usm);
                  }}
                >
                  {T('Approve')}
                </Button>
                <Button
                  inverted
                  color="red"
                  onClick={() => {
                    this.setState({ declineUsm: usm, declineDialogOpen: true });
                  }}
                >
                  {T('Block User')}
                </Button>
              </div>
            );
            color = 'green';
            break;
          case 'waiterComing':
            if (this.state.visible[usm.userMessageId] === undefined) {
              this.setState({
                visible: { ...this.state.visible, [usm.userMessageId]: true },
              });
            }
            icon = <Icon name="wait" color="green" size="large" />;
            content = (
              <Card.Description>
                {T('Table')} {splitMsg[1]} (<strong>{summonerName}</strong>)
                {T('has been informed that a waiter is coming.')}
              </Card.Description>
            );
            buttons = (
              <div className="ui two buttons">
                <Button
                  color="green"
                  inverted
                  onClick={() => this.handleVisitedClick(usm)}
                >
                  {T('Visited')}
                </Button>
                <Button
                  color="red"
                  inverted
                  onClick={() => {
                    this.setState({ declineUsm: usm, declineDialogOpen: true });
                  }}
                >
                  {T('Block User')}
                </Button>
              </div>
            );
            color = 'yellow';
            break;
          case 'waiterDeclined':
            if (this.state.visible[usm.userMessageId] === undefined) {
              this.setState({
                visible: { ...this.state.visible, [usm.userMessageId]: true },
              });
            }
            content = (
              <Card.Description>
                {T('Table')} {splitMsg[1]} (<strong>{summonerName}</strong>){' '}
                {T('has been informed that the call was declined.')}
              </Card.Description>
            );
            color = 'grey';
            break;
          case 'waiterCancelled':
            content = (
              <Card.Description>
                {T('Table')} {splitMsg[1]} (<strong>{summonerName}</strong>){' '}
                {T('has been informed that the call was cancelled.')}
              </Card.Description>
            );
            color = 'grey';
            break;
          case 'waiterUserCancelled':
            content = (
              <Card.Description>
                {T('Table')} {splitMsg[1]} (<strong>{summonerName}</strong>){' '}
                {T('has cancelled the summon.')}
              </Card.Description>
            );
            color = 'grey';
            break;
          case 'waiterVisited':
            content = (
              <Card.Description>
                {T('Table')} {splitMsg[1]} (<strong>{summonerName}</strong>){' '}
                {T('was marked as visited.')}
              </Card.Description>
            );
            color = 'grey';
            break;
          default:
            return null;
        }
        return (
          <Transition
            animation={'scale'}
            key={`usm${usm.userMessageId}`}
            visible={this.state.visible[usm.userMessageId]}
          >
            <Card color={color}>
              <Card.Content>
                <Card.Header>
                  {icon} {`${T('Table')} ${splitMsg[1]}`}
                </Card.Header>
                <Card.Meta>{usm.timeStamp}</Card.Meta>
                {content}
              </Card.Content>
              <Card.Content extra>{buttons}</Card.Content>
            </Card>
          </Transition>
        );
      });

    if (this.state.audioEnabled && playSound) {
      bellAudio.play();
    }

    return cards;
  }

  renderWaiterCall() {
    if (!this.state.loggedInToShop) {
      return (
        <Segment>
          {this.renderShopList()}
          <Message negative>{T('No shop Selected')}</Message>
        </Segment>
      );
    }
    return (
      <>
        {this.renderShopList()}
        <Segment basic>
          <Label color="red" attached="top">
            {T('Incoming Calls')}
          </Label>
          <Card.Group style={{ minHeight: 200 }}>
            {this.renderMessages(['summonWaiter'])}
          </Card.Group>
        </Segment>
        <Segment basic>
          <Label color="yellow" attached="top">
            {T('Calls In Progess')}
          </Label>
          <Card.Group style={{ minHeight: 200 }}>
            {this.renderMessages(['waiterComing'])}
          </Card.Group>
        </Segment>
        <Segment basic>
          <Label color="green" attached="top">
            {T('Completed Calls')}
          </Label>
          <Card.Group style={{ minHeight: 200 }}>
            {this.renderMessages([
              'waiterVisited',
              'waiterCancelled',
              'waiterDeclined',
              'waiterUserCancelled',
            ])}
          </Card.Group>
        </Segment>
        <Confirm
          open={this.state.declineDialogOpen}
          cancelButton={T('Cancel')}
          header={T('Oletko varma?')}
          content={T(
            'Oletko varma, että haluat estää tämän käyttäjän? Asiakas ei pysty tekemään uutta kutsua 30 minuuttiin.'
          )}
          onCancel={() => this.setState({ declineDialogOpen: false })}
          onConfirm={() => {
            this.handleDeclineClick();
            this.setState({ declineDialogOpen: false });
          }}
        />
      </>
    );
  }

  render() {
    if (this.props.shop.shopsFetchStatus !== 'success') {
      return <Spinner />;
    }
    if (!this.state.connected) {
      return (
        <Dimmer inverted active>
          <Loader content="No server connection, trying to reconnect..." />
        </Dimmer>
      );
    }

    return (
      <Segment>
        {!this.state.audioEnabled ? (
          <Button
            inverted
            color="green"
            onClick={() => {
              bellAudio.play();
              bellAudio.src = bellAudioSrc;
              this.setState({ audioEnabled: true });
            }}
          >
            {T('Käynnistä')}
          </Button>
        ) : (
          this.renderWaiterCall()
        )}
      </Segment>
    );
  }
}

function mapStateToProps({ auth, shop, workspace }) {
  return { auth, shop, workspace };
}

export default connect(mapStateToProps, { fetchShops })(WaiterCalls);
