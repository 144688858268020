import React, { Component } from 'react';
import _ from 'lodash';
import { reduxForm } from 'redux-form';
import { Button, Segment, Header, Icon } from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
import { Form } from 'formsy-semantic-ui-react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import jstz from 'jstz';
import { toast } from 'react-semantic-toasts';
import { Spinner } from '../Common/Spinner';
import ModalWindow, { ModalContext } from '../Common/ModalWindow';
import PickupPointsList from '../PickupPoint/PickupPointsList';
import {
  addPriceList,
  editPriceList,
  addPriceListReset,
  editPriceListReset,
  fetchOutOfStockMessages,
} from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';

const errorStyle = {
  backgroundColor: '#FFF6F6',
  border: '1px solid #E0B4B4',
  color: '#9F3A38',
  WebkitBoxShadow: 'none',
  boxShadow: 'none',
};

const timezone = jstz.determine().name();

class ManagePriceList extends Component {
  constructor(props) {
    super(props);
    if (this.props.pricelist.pricelist && this.props.match.params.priceListId) {
      const {
        pl_active,
        pl_description,
        pl_event,
        pl_name,
        pl_valid_from,
        pl_valid_to,
        pr_merchant_id,
        price_list_id,
        pickup_points,
        pl_email_receipt_bcc,
        delivery_methods,
        pl_message_text,
        pl_message_icon,
        pl_ticket_validation_required,
        pl_out_of_stock_message,
        pl_permit_product_giving,
      } = this.props.pricelist.pricelist;
      this.state = {
        pl_active,
        pl_description,
        pl_event,
        pl_name,
        pl_valid_from: pl_valid_from
          ? moment(pl_valid_from).format('l HH:mm')
          : '',
        pl_valid_to: pl_valid_to ? moment(pl_valid_to).format('l HH:mm') : '',
        pr_merchant_id,
        price_list_id,
        pl_email_receipt_bcc,
        pickup_points: pickup_points || [],
        // markedPickups: [], //pickups that are about to be removed from the list,
        pl_message_text,
        pl_message_icon,
        delivery_methods,
        pl_ticket_validation_required,
        pl_out_of_stock_message,
        pl_permit_product_giving,
      };
    } else {
      let validFromDate = momentTimezone.tz(new Date(), timezone).format();
      validFromDate = moment(validFromDate).format('l HH:mm');
      // localStorage.setItem('route', this.props.history.location.pathname);
      this.state = {
        pl_active: true,
        pl_description: '',
        pl_event: '',
        pl_name: '',
        pl_email_receipt_bcc: '',
        pl_valid_from: validFromDate,
        pl_valid_to: null,
        validFromStyle: {},
        validToStyle: {},
        pickup_points: [],
        pl_message_text: '',
        pl_message_icon: '',
        delivery_methods: [],
        pl_ticket_validation_required: true,
        pl_out_of_stock_message: null,
        pl_permit_product_giving: true,
      };
    }
    this.state.advanced_options = false;
    this.state.advanced_btn_text = 'Show';

    this.handleAdvancedSettings = this.handleAdvancedSettings.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    const { ws_id } = this.props;
    const authKey = localStorage.getItem('x-auth-key');
    if (this.props.fetchOutOfStockMessageStatus !== 'success') {
      this.props.fetchOutOfStockMessages({ authKey, ws_id });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pricelist.plAddStatus !== 'success' &&
      this.props.pricelist.plAddStatus === 'success'
    ) {
      //redirect to list
      //this.props.history.push('/app/shop/pricelists/list');
      this.props.history.goBack();
    }
    if (
      prevProps.pricelist.plEditStatus !== 'success' &&
      this.props.pricelist.plEditStatus === 'success'
    ) {
      //redirect to list
      this.props.history.push('/app/pricelist/list');
    }
    if (
      prevProps.pricelist.plAddStatus !== 'error' &&
      this.props.pricelist.plAddStatus === 'error'
    ) {
      //error
      this.props.addPriceListReset();
      const errorObj = getErrorMessage(
        this.props.pricelist.error,
        'Failed to add pricelist'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
    if (
      prevProps.pricelist.plEditStatus !== 'error' &&
      this.props.pricelist.plEditStatus === 'error'
    ) {
      //error
      this.props.editPriceListReset();
      const errorObj = getErrorMessage(
        this.props.pricelist.error,
        'Failed to update pricelist'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  onValidSubmit = () => {
    const { pl_valid_from, pl_valid_to } = this.state;
    const validFrom = pl_valid_from ? moment(pl_valid_from, 'l HH:mm') : null;
    const validTo = pl_valid_to ? moment(pl_valid_to, 'l HH:mm') : null;
    const pr_merchant_id = this.props.merchantId;
    const application_id = this.props.applicationId;

    // if (validFrom < new Date(moment().startOf('day'))) {
    //   this.setState({ validFromStyle: errorStyle, validToStyle: {} });
    //   return;
    // } else
    //if (this.state.pl_valid_to === '' || validFrom >= validTo) {
    if (validFrom && validTo && validFrom.isSameOrAfter(validTo)) {
      this.setState({ validFromStyle: {}, validToStyle: errorStyle });
      return;
    }
    const data = { ...this.state };
    delete data.validFromStyle;
    delete data.validToStyle;
    const updatedData = {
      ...data,
      pl_valid_from: validFrom ? validFrom.toISOString() : null,
      pl_valid_to: validTo ? validTo.toISOString() : null,
      pr_merchant_id,
      application_id,
    };
    // console.log(' on valid submit ...', data, updatedData);
    if (updatedData.pl_valid_to === '') {
      updatedData.pl_valid_to = null;
    }
    const { priceListId } = this.props.match.params;
    const authKey = localStorage.getItem('x-auth-key');
    if (!updatedData.pl_event) {
      delete updatedData.pl_event;
    }
    const { ws_id } = this.props;
    if (priceListId) {
      //edit
      this.props.editPriceList(authKey, updatedData, priceListId, ws_id);
    } else {
      //add
      this.props.addPriceList(authKey, updatedData, ws_id);
    }
  };

  onCancel() {
    // this.props.history.push('/app/shop/pricelists/list');
    this.props.history.goBack();
  }
  getDeliveryMethods = (id) => {
    if (this.state.delivery_methods.length) {
      const deliveryMethod = this.state.delivery_methods.filter(
        (delivery) => delivery.delivery_method_id === id
      );
      // console.log('Delivery Method: ', deliveryMethod, '\nId: ', id);
      if (deliveryMethod.length) {
        return true;
      }
      return false;
    }
    return false;
  };

  getDeliveryMethodsChecked = (id) => {
    let checked = false;
    let delivery_methods = [...this.state.delivery_methods];
    if (delivery_methods.length !== 0) {
      delivery_methods = delivery_methods.filter(
        (method) => method.delivery_method_id === id
      );
      if (delivery_methods.length !== 0) {
        checked = true;
      }
    }
    return checked;
  };
  // getMerchants() {
  //   if (this.props.auth.companies) {
  //     return _.map(this.props.auth.companies, (company) =>
  //       ({ text: company.co_name, value: company.company_id })
  //     );
  //   }
  //   return;
  // }

  getStockMessageList = () =>
    _.map(this.props.pricelist.stockMessages, (stockMessage) => ({
      text: T(stockMessage.dk_values),
      value: stockMessage.dk_values,
    }));

  addPickupPointsToList = (pickup_points) => {
    this.setState({ pickup_points });
  };
  removePickupPoint = (id) => {
    const pickup_points = this.state.pickup_points.filter(
      (ele) => ele.place_id !== id
    );
    this.setState({ pickup_points });
  };

  handleAdvancedSettings() {
    let advanced_options = false;
    let advanced_btn_text = 'Show';
    if (this.state.advanced_options === false) {
      advanced_options = true;
      advanced_btn_text = 'Hide';
    }
    this.setState({ advanced_options, advanced_btn_text });
    //this.setState({ advanced: this.state.advanced_options !== true });
  }

  // removePickupPointAlternative = () => {
  //   const idList = this.state.markedPickups;
  //   if (idList.length === 0) return;
  //   const pickupPoints = this.state.pickupPoints.filter(ele => idList.indexOf(ele.place_id) === -1);
  //   this.setState({ pickupPoints, markedPickups: [] });
  // }

  handleChange = (e, { name, checked, value }) =>
    this.setState({ [name]: typeof checked === 'boolean' ? checked : value });

  handleDeliveryChange = (e, { name, checked }) => {
    let delivery_methods;
    if (name === 'pickup' && checked) {
      delivery_methods = [
        ...this.state.delivery_methods,
        { delivery_method_id: 1 },
      ];
    } else if (name === 'pickup' && !checked) {
      delivery_methods = [...this.state.delivery_methods];
      delivery_methods = delivery_methods.filter(
        (delivery) => delivery.delivery_method_id !== 1
      );
    } else if (name === 'mail' && checked) {
      delivery_methods = [
        ...this.state.delivery_methods,
        { delivery_method_id: 2 },
      ];
    } else if (name === 'mail' && !checked) {
      delivery_methods = [...this.state.delivery_methods];
      delivery_methods = delivery_methods.filter(
        (delivery) => delivery.delivery_method_id !== 2
      );
    }
    // console.log('Name: ', name, ' Value: ', checked, '\nDelivery: ', delivery_methods);
    this.setState({ delivery_methods });
  };

  renderPickupPoints = (pickups) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
        minHeight: '100px',
        maxHeight: '200px',
        overflowY: 'scroll',
        border: '1px solid grey',
        marginRight: '15px',
        marginBottom: '5px',
        padding: '10px',
      }}
    >
      {_.map(pickups, (pickup) => (
        <Form.Group inline key={`group-${pickup.place_id}`}>
          <div
            style={{
              display: 'inline-block',
              width: '100%',
              textAlign: 'left',
            }}
          >
            <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
              {pickup.pl_name}
            </span>
            {/*  disabled
                  key={`name-${pickup.place_id}`}
                  name='pickup_name'
                  value={pickup.pl_name}
              />*/}
          </div>
          <Icon
            name="remove"
            key={`button-${pickup.place_id}`}
            onClick={() => this.removePickupPoint(pickup.place_id)}
            size="large"
            corner="top-left"
            color="red"
          />
        </Form.Group>
      ))}
    </div>
  );
  render() {
    if (
      this.props.pricelist.plAddStatus === 'waiting' ||
      this.props.pricelist.plEditStatus === 'waiting'
    ) {
      return <Spinner />;
    }
    return (
      <Segment>
        <Header as="h3">{T('Manage Pricelist')}</Header>
        <Form
          onValidSubmit={this.onValidSubmit.bind(this)}
          ref={(ref) => (this.form = ref)}
        >
          <Form.Group widths="equal">
            <Form.Input
              required
              name="pl_name"
              label={T('Name')}
              value={this.state.pl_name}
              onChange={this.handleChange}
            />
            <Form.Input
              required
              name="pl_description"
              label={T('Description')}
              value={this.state.pl_description}
              onChange={this.handleChange}
            />
            <Form.Field>
              <Form.Input
                type="text"
                name="pl_email_receipt_bcc"
                label={T('Email Receipt BCC')}
                value={this.state.pl_email_receipt_bcc}
                onChange={this.handleChange}
              />
            </Form.Field>
            {/*<Form.Dropdown
            required
            search
            name="pr_merchant_id"
            label={T('Merchant')}
            placeholder={T('Merchant')}
            fluid
            selection
            clearable
            selectOnNavigation
            noResultsMessage={T('No result')}
            onChange={this.handleChange}
            options={this.getMerchants()}
            value={this.state.pr_merchant_id}
          />*/}
          </Form.Group>
          <Form.Group
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ flex: 1 }}>
              <Form.Field>
                <DateTimeInput
                  closable
                  animation="off"
                  name="pl_valid_from"
                  label={T('Valid from')}
                  onChange={this.handleChange}
                  value={this.state.pl_valid_from}
                  style={this.state.validFromStyle}
                  dateTimeFormat="l HH:mm"
                  minDate={this.state.pl_valid_from}
                />
              </Form.Field>
            </div>
            <div style={{ flex: 1 }}>
              <Form.Field>
                <DateTimeInput
                  //style={{ flex: 1 }}
                  closable
                  animation="off"
                  name="pl_valid_to"
                  label={T('Valid to')}
                  onChange={this.handleChange}
                  value={this.state.pl_valid_to ? this.state.pl_valid_to : ''}
                  dateTimeFormat="l HH:mm"
                  style={this.state.validToStyle}
                  clearable
                  //minDate={this.state.pl_valid_from}
                  minDate={moment(this.state.pl_valid_from)}
                />
              </Form.Field>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginBottom: '10px',
                marginTop: '5px',
              }}
            >
              <div
                style={{
                  width: '100%',
                  textAlign: 'left',
                  paddingLeft: '20px',
                  marginBottom: '10px',
                }}
              >
                {T('Delivery Methods')}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  paddingLeft: '20px',
                }}
              >
                <Form.Checkbox
                  name="pickup"
                  style={{ marginRight: '20px' }}
                  label={T('Multiple pickup points')}
                  defaultChecked={this.getDeliveryMethods(1)}
                  checked={this.getDeliveryMethods(1)}
                  onChange={this.handleDeliveryChange}
                />
                <Form.Checkbox
                  name="mail"
                  label={T('Mail')}
                  defaultChecked={this.getDeliveryMethods(2)}
                  checked={this.getDeliveryMethods(2)}
                  onChange={this.handleDeliveryChange}
                />
              </div>
            </div>
          </Form.Group>
          {this.getDeliveryMethodsChecked(1) === true && (
            <div>
              <h3>{T('Selected Pickup Points')}</h3>
              <Form.Group>
                {this.renderPickupPoints(this.state.pickup_points)}
                <ModalWindow
                  buttonColor="blue"
                  buttonSize="tiny"
                  buttonLabel={T('Select pickup points')}
                  header={T('Select pickup points')}
                >
                  <ModalContext.Consumer>
                    {({ closeModal }) => (
                      <PickupPointsList
                        closeModal={closeModal}
                        setLocation={this.addPickupPointsToList}
                        selectedPickups={this.state.pickup_points}
                        price_list_id={this.props.price_list_id}
                      />
                    )}
                  </ModalContext.Consumer>
                </ModalWindow>
              </Form.Group>
            </div>
          )}
          <Form.Group>
            <Form.Field>
              <Form.Checkbox
                toggle
                style={{ marginTop: '25px' }}
                label={T('Active')}
                width={4}
                defaultChecked={this.state.pl_active || false}
                checked={this.state.pl_active}
                name="pl_active"
                onChange={this.handleChange}
              />
            </Form.Field>
          </Form.Group>
          {this.state.advanced_options === true && (
            <Form.Group widths="equal">
              <Form.Input
                type="text"
                name="pl_message_text"
                label={T('Warning Message Text')}
                value={this.state.pl_message_text}
                onChange={this.handleChange}
              />
              <Form.Field>
                <Form.Input
                  type="text"
                  name="pl_message_icon"
                  label={T('Warning Message Icon')}
                  value={this.state.pl_message_icon}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field required>
                <Form.Dropdown
                  search
                  name="pl_out_of_stock_message"
                  label={T('Out Of Stock Message')}
                  placeholder={T('Out of stock message')}
                  fluid
                  selection
                  clearable
                  selectOnNavigation
                  noResultsMessage={T('No result')}
                  options={this.getStockMessageList()}
                  onChange={this.handleChange}
                  value={this.state.pl_out_of_stock_message}
                />
              </Form.Field>
              <Form.Field>
                <Form.Checkbox
                  toggle
                  style={{ marginTop: '25px' }}
                  label={T('Ticket Validation Required')}
                  width={4}
                  defaultChecked={this.state.pl_ticket_validation_required}
                  checked={this.state.pl_ticket_validation_required}
                  name="pl_ticket_validation_required"
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <Form.Checkbox
                  toggle
                  style={{ marginTop: '25px' }}
                  label={T('Allow Ticket Send')}
                  width={4}
                  defaultChecked={this.state.pl_permit_product_giving}
                  checked={this.state.pl_permit_product_giving}
                  name="pl_permit_product_giving"
                  onChange={this.handleChange}
                />
              </Form.Field>
            </Form.Group>
          )}
          <Form.Group>
            <Button content={T('Save')} icon="save" color="green" />
            <Button
              type="button"
              icon="ban"
              secondary
              content={T('Cancel')}
              onClick={() => this.onCancel()}
            />
            <Button
              type="button"
              icon="external alternate"
              primary
              content={T(`${this.state.advanced_btn_text} advanced settings`)}
              onClick={() => this.handleAdvancedSettings()}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

function mapStateToProps({ pricelist, languages, auth, tax, file, workspace }) {
  return {
    pricelist,
    languages: languages.languages,
    langStatus: languages.status,
    auth,
    tax,
    file,
    merchantId: workspace.workspaces[workspace.ws_id].cnc_company,
    applicationId: workspace.workspaces[workspace.ws_id].cnc_application,
    ws_id: workspace.ws_id,
  };
}

export default reduxForm({
  form: 'pricelistaddform',
})(
  connect(mapStateToProps, {
    addPriceList,
    editPriceList,
    addPriceListReset,
    editPriceListReset,
    fetchOutOfStockMessages,
  })(withRouter(ManagePriceList))
);
