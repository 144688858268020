import axios from 'axios';
import config from '../../Config/AEConfig';

import {
  FETCH_APP_STATISTICS_WAITING,
  FETCH_APP_STATISTICS_SUCCESS,
  FETCH_APP_STATISTICS_ERROR,
  FETCH_APP_STATISTICS_RESET,
} from '../Types';

import ErrorAction from './ErrorAction';

export const fetchAppStatistics =
  (
    moduleId,
    authKey,
    ws_id,
    application_id,
    statisticType,
    slgEvent,
    startDate,
    endDate,
    lang
  ) =>
  (dispatch) => {
    dispatch({ type: FETCH_APP_STATISTICS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    let url = `${config.BASE_URL}/statistics/${statisticType}?slg_info=stateChange&module_id=${moduleId}&ws_id=${ws_id}&application_id=${application_id}&slg_event=${slgEvent}&lang=${lang}`;
    if (startDate) {
      url += `&start_date=${startDate}`;
    }
    if (endDate) {
      url += `&end_date=${endDate}`;
    }
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_APP_STATISTICS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const payloadType = error.response
          ? ErrorAction(error.response.status, FETCH_APP_STATISTICS_ERROR)
          : FETCH_APP_STATISTICS_ERROR;
        dispatch({ type: payloadType, payload: error });
      });
  };

export const resetFetchAppStatistics = () => ({
  type: FETCH_APP_STATISTICS_RESET,
});
