import _ from 'lodash';
import {
  ADD_PERFORMERS_PLAYER_ERROR,
  ADD_PERFORMERS_PLAYER_RESET,
  ADD_PERFORMERS_PLAYER_SUCCESS,
  FETCH_PERFORMERS_PLAYERS_SUCCESS,
  FETCH_PERFORMERS_PLAYERS_ERROR,
  FETCH_PERFORMERS_PLAYERS_RESET,
  FETCH_PERFORMERS_PLAYER_SUCCESS,
  FETCH_PERFORMERS_PLAYER_ERROR,
  FETCH_PERFORMERS_PLAYER_RESET,
  FETCH_PERFORMERS_PLAYERS_WAITING,
  EDIT_PERFORMERS_PLAYER_SUCCESS,
  EDIT_PERFORMERS_PLAYER_ERROR,
  EDIT_PERFORMERS_PLAYER_RESET,
  DELETE_PERFORMERS_PLAYER_SUCCESS,
  DELETE_PERFORMERS_PLAYER_ERROR,
  DELETE_PERFORMERS_PLAYER_RESET,
  UPLOAD_PERFORMERS_PLAYER_SUCCESS,
  UPLOAD_PERFORMERS_PLAYER_ERROR,
  UPLOAD_PERFORMERS_PLAYER_RESET,
  FETCH_PERFORMER_METADATA_KEYS_SUCCESS,
  FETCH_PERFORMER_METADATA_KEYS_ERROR,
  ADD_PERFORMER_METADATA_SUCCESS,
  ADD_PERFORMER_METADATA_ERROR,
  ADD_PERFORMER_METADATA_RESET,
  DELETE_PERFORMER_METADATA_SUCCESS,
  DELETE_PERFORMER_METADATA_ERROR,
  DELETE_PERFORMER_METADATA_RESET,
  EDIT_PERFORMER_METADATA_SUCCESS,
  EDIT_PERFORMER_METADATA_ERROR,
  EDIT_PERFORMER_METADATA_RESET,
  TRANSFER_PLAYER_SUCCESS,
  TRANSFER_PLAYER_ERROR,
  TRANSER_PLAYER_RESET,
  EDIT_TRANSFER_PLAYER_SUCCESS,
  EDIT_TRANSFER_PLAYER_ERROR,
  EDIT_TRANSFER_PLAYER_RESET,
  DELETE_TRANSFER_CONNECTION_SUCCESS,
  DELETE_TRANSFER_CONNECTION_ERROR,
  RESET_DELETE_TRANSFER_CONNECTION,
  ADD_PERFORMER_CMS_CONTENT_WAITING,
  ADD_PERFORMER_CMS_CONTENT_SUCCESS,
  ADD_PERFORMER_CMS_CONTENT_ERROR,
  EDIT_PERFORMER_CMS_CONTENT_SUCCESS,
  EDIT_PERFORMER_CMS_CONTENT_ERROR,
  DELETE_PERFORMER_CMS_CONTENT_SUCCESS,
  DELETE_PERFORMER_CMS_CONTENT_ERROR,
  FETCH_PERFORMER_CMS_CONTENT_SUCCESS,
  FETCH_PERFORMER_CMS_CONTENT_ERROR,
  FETCH_PERFORMER_CMS_CONTENT_RESET,
  FETCH_CMS_CONTENT_TYPES_SUCCESS,
  FETCH_CMS_CONTENT_TYPES_ERROR,
  FETCH_PERFORMER_CMS_CONTENTS_SUCCESS,
  FETCH_PERFORMER_CMS_CONTENTS_ERROR,
  FETCH_CMS_CHILD_CONTENT_SUCCESS,
  FETCH_CMS_CHILD_CONTENT_ERROR,
  FETCH_CMS_CHILD_CONTENT_LIST_WAITING,
  FETCH_CMS_CHILD_CONTENT_LIST_SUCCESS,
  FETCH_CMS_CHILD_CONTENT_LIST_ERROR,
  FETCH_CMS_CHILD_CONTENT_LIST_RESET,
  FETCH_PERFORMER_PLAYER_TRANSFER_LIST_SUCCESS,
  FETCH_PERFORMER_PLAYER_TRANSFER_LIST_ERROR,
  FETCH_PERFORMER_PLAYER_METADATA_SUCCESS,
  FETCH_PERFORMER_PLAYER_METADATA_ERROR,
  DELETE_PERFORMER_PLAYER_IMAGE_WAITING,
  DELETE_PERFORMER_PLAYER_IMAGE_SUCCESS,
  DELETE_PERFORMER_PLAYER_IMAGE_ERROR,
  DELETE_PERFORMER_PLAYER_IMAGE_RESET,
  UPLOAD_PLAYER_TRANSFER_IMAGE_SUCCESS,
  UPLOAD_PLAYER_TRANSFER_IMAGE_ERROR,
  UPLOAD_PLAYER_TRANSFER_IMAGE_RESET,
  UPLOAD_PLAYER_TRANSFER_IMAGE_WAITING,
  UPLOAD_PERFORMERS_PLAYER_WAITING,
  FETCH_PLAYERS_LIST_FOR_LINEUP_SUCCESS,
  FETCH_PLAYERS_LIST_FOR_LINEUP_WAITING,
  FETCH_PLAYERS_LIST_FOR_LINEUP_ERROR,
  FETCH_PLAYERS_LIST_FOR_LINEUP_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  createStatus: 'init',
  updateStatus: 'init',
  selectStatus: 'init',
  deleteStatus: 'init',
  uploadSatus: 'init',
  metadataStatus: 'init',
  metadataAdd: 'init',
  metadataDelete: 'init',
  metadataEdit: 'init',
  transferredStatus: 'init',
  editTransferStatus: 'init',
  deleteTransferConnectionStatus: 'init',
  cmsStatus: 'init',
  cmsFetchStatus: 'init',
  childCmsListStatus: 'init',
  deleteImageStatus: 'init',
  transferImage: 'init',
  lineupsStatus: 'init',
  cmsList: {},
};

export default function PerformerPlayerReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case ADD_PERFORMERS_PLAYER_SUCCESS:
      return { ...state, player: action.payload.data, createStatus: 'success' };
    case ADD_PERFORMERS_PLAYER_ERROR:
      return { ...state, createStatus: 'error', error: action.payload.error };
    case ADD_PERFORMERS_PLAYER_RESET:
      return { ...state, player: null, createStatus: 'init' };

    case FETCH_PERFORMERS_PLAYERS_SUCCESS:
      return {
        ...state,
        selectStatus: 'success',
        players: action.payload.data,
        headers: action.payload.headers,
      };
    case FETCH_PERFORMERS_PLAYERS_ERROR:
      return {
        ...state,
        players: null,
        selectStatus: 'error',
        error: action.payload.error,
      };
    case FETCH_PERFORMERS_PLAYERS_RESET:
      return { ...state, players: null, headers: null, selectStatus: 'init' };

    case FETCH_PERFORMERS_PLAYERS_WAITING:
      return { ...state, selectStatus: 'waiting' };

    case FETCH_PERFORMERS_PLAYER_SUCCESS:
      return {
        ...state,
        selectStatus: 'success',
        // metadata: _.mapKeys(action.payload.data.performer_metadata, 'performer_metadata_id'),
        player: action.payload.data,
      };
    case FETCH_PERFORMERS_PLAYER_ERROR:
      return {
        ...state,
        player: null,
        selectStatus: 'error',
        error: action.payload.error,
      };
    case FETCH_PERFORMERS_PLAYER_RESET:
      return { ...state, player: null, selectStatus: 'init' };

    case EDIT_PERFORMERS_PLAYER_SUCCESS:
      return { ...state, updateStatus: 'success', player: action.payload.data };
    case EDIT_PERFORMERS_PLAYER_ERROR:
      return {
        ...state,
        player: null,
        updateStatus: 'error',
        error: action.payload.error,
      };
    case EDIT_PERFORMERS_PLAYER_RESET:
      return { ...state, player: null, updateStatus: 'init' };

    case DELETE_PERFORMERS_PLAYER_SUCCESS:
      return { ...state, deleteStatus: 'success', player: action.payload.data };
    case DELETE_PERFORMERS_PLAYER_ERROR:
      return { ...state, deleteStatus: 'error', error: action.payload.error };
    case DELETE_PERFORMERS_PLAYER_RESET:
      return { ...state, deleteStatus: 'init' };

    case UPLOAD_PERFORMERS_PLAYER_SUCCESS:
      return { ...state, uploadStatus: 'success' };
    case UPLOAD_PERFORMERS_PLAYER_WAITING:
      return { ...state, uploadStatus: 'waiting' };
    case UPLOAD_PERFORMERS_PLAYER_ERROR:
      return {
        ...state,
        player: null,
        uploadStatus: 'error',
        error: action.payload.error,
      };
    case UPLOAD_PERFORMERS_PLAYER_RESET:
      return { ...state, uploadStatus: 'init' };

    case FETCH_PERFORMER_METADATA_KEYS_SUCCESS:
      return {
        ...state,
        metadatakeys: action.payload.data,
        metadataStatus: 'success',
      };
    case FETCH_PERFORMER_METADATA_KEYS_ERROR:
      return { ...state, metadataStatus: 'error', error: action.payload.error };

    case ADD_PERFORMER_METADATA_SUCCESS:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          [action.payload.data.performer_metadata_id]: action.payload.data,
        },
        metadataAdd: 'success',
      };
    case ADD_PERFORMER_METADATA_ERROR:
      return { ...state, metadataAdd: 'error', error: action.payload.error };
    case ADD_PERFORMER_METADATA_RESET:
      return { ...state, metadataAdd: 'init', metadata: {} };

    case DELETE_PERFORMER_METADATA_SUCCESS:
      return {
        ...state,
        metadataDelete: 'success',
        metadata: _.omit(state.metadata, action.payload.data.metadata_id),
      };
    case DELETE_PERFORMER_METADATA_ERROR:
      return { ...state, metadataDelete: 'error', error: action.payload.error };
    case DELETE_PERFORMER_METADATA_RESET:
      return { ...state, metadataDelete: 'init' };

    case EDIT_PERFORMER_METADATA_SUCCESS:
      return {
        ...state,
        metadataEdit: 'success',
        metadata: {
          ...state.metadata,
          [action.payload.data.performer_metadata_id]: action.payload.data,
        },
      };
    case EDIT_PERFORMER_METADATA_ERROR:
      return { ...state, metadataEdit: 'error', error: action.payload.error };
    case EDIT_PERFORMER_METADATA_RESET:
      return { ...state, metadataEdit: 'init' };

    case TRANSFER_PLAYER_SUCCESS:
      return {
        ...state,
        transferredStatus: 'success',
        tranferId: action.payload.data.performer_connection_id,
      };
    case TRANSFER_PLAYER_ERROR:
      return {
        ...state,
        transferredStatus: 'error',
        transferId: '',
        error: action.payload.error,
      };
    case TRANSER_PLAYER_RESET:
      return { ...state, transferredStatus: 'inti', transferId: '' };

    case EDIT_TRANSFER_PLAYER_SUCCESS:
      return { ...state, editTransferStatus: 'success' };
    case EDIT_TRANSFER_PLAYER_ERROR:
      return {
        ...state,
        editTransferStatus: 'error',
        error: action.payload.error,
      };
    case EDIT_TRANSFER_PLAYER_RESET:
      return { ...state, editTransferStatus: 'init' };

    case DELETE_TRANSFER_CONNECTION_SUCCESS:
      return { ...state, deleteTransferConnectionStatus: 'success' };
    case DELETE_TRANSFER_CONNECTION_ERROR:
      return {
        ...state,
        deleteTransferConnectionStatus: 'error',
        error: action.payload.error,
      };
    case RESET_DELETE_TRANSFER_CONNECTION:
      return { ...state, deleteTransferConnectionStatus: 'init' };

    case ADD_PERFORMER_CMS_CONTENT_WAITING:
      return { ...state, cmsStatus: 'waiting', cms: undefined };
    case ADD_PERFORMER_CMS_CONTENT_SUCCESS: {
      // debugger;
      // console.log('Add CMS success,: ', action.payload.data, '\nRedux State: ', state);
      const id = action.payload.data.cms_content_id;
      const cmsList = { ...(state.cmsList || {}), [id]: action.payload.data };
      // console.log('Cms List: ', cmsList, '\nRedux State: ', state);
      return {
        ...state,
        cmsList,
        cms: action.payload.data,
        newlyCreatedId: action.payload.data.cms_content_id,
        cmsStatus: 'success',
      };
    }
    case ADD_PERFORMER_CMS_CONTENT_ERROR:
      return {
        ...state,
        cms: null,
        cmsStatus: 'error',
        error: action.payload.error,
      };

    case EDIT_PERFORMER_CMS_CONTENT_SUCCESS:
      return { ...state, cms: action.payload.data, cmsStatus: 'success' };
    case EDIT_PERFORMER_CMS_CONTENT_ERROR:
      return {
        ...state,
        cms: null,
        cmsStatus: 'error',
        error: action.payload.error,
      };

    case DELETE_PERFORMER_CMS_CONTENT_SUCCESS: {
      const cmsList = _.omit(state.cmsList, action.payload.data.cms_content_id);
      return { ...state, cmsList, cms: null, cmsStatus: 'success' };
    }
    case DELETE_PERFORMER_CMS_CONTENT_ERROR:
      return {
        ...state,
        cms: null,
        cmsStatus: 'error',
        error: action.payload.error,
      };

    case FETCH_PERFORMER_CMS_CONTENT_SUCCESS:
      return { ...state, cms: action.payload.data, cmsFetchStatus: 'success' };
    case FETCH_PERFORMER_CMS_CONTENT_ERROR:
      return { ...state, cms: null, cmsFetchStatus: 'error' };

    case FETCH_PERFORMER_CMS_CONTENT_RESET:
      return { ...state, cmsStatus: 'init', cmsFetchStatus: 'init' };
    case FETCH_CMS_CONTENT_TYPES_SUCCESS:
      return {
        ...state,
        cmsTypes: action.payload.data,
        cmsTypesStatus: 'success',
      };
    case FETCH_CMS_CONTENT_TYPES_ERROR:
      return { ...state, cmsTypesStatus: 'error', error: action.payload.error };

    case FETCH_PERFORMER_CMS_CONTENTS_SUCCESS: {
      const cmsList = _.mapKeys(action.payload.data, 'cms_content_id');
      return { ...state, cmsList, cmsFetchStatus: 'success' };
    }
    case FETCH_PERFORMER_CMS_CONTENTS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        cmsList: null,
        cmsFetchStatus: 'error',
      };

    case FETCH_CMS_CHILD_CONTENT_SUCCESS:
      return {
        ...state,
        childCms: action.payload.data,
        childCmsStatus: 'susscess',
      };
    case FETCH_CMS_CHILD_CONTENT_ERROR:
      return {
        ...state,
        childCmsStatus: 'susscess',
        error: action.payload.error,
      };

    case FETCH_CMS_CHILD_CONTENT_LIST_WAITING:
      return { ...state, childCmsList: [], childCmsListStatus: 'waiting' };
    case FETCH_CMS_CHILD_CONTENT_LIST_SUCCESS:
      return {
        ...state,
        childCmsList: action.payload.data,
        childCmsListStatus: 'success',
      };
    case FETCH_CMS_CHILD_CONTENT_LIST_ERROR:
      return { ...state, childCmsList: null, childCmsListStatus: 'error' };
    case FETCH_CMS_CHILD_CONTENT_LIST_RESET:
      return { ...state, childCmsList: null, childCmsListStatus: 'init' };

    case FETCH_PERFORMER_PLAYER_TRANSFER_LIST_SUCCESS:
      return {
        ...state,
        transfers: action.payload.data,
        transferHeader: action.payload.headers,
      };
    case FETCH_PERFORMER_PLAYER_TRANSFER_LIST_ERROR:
      return {
        ...state,
        transfers: null,
        transferHeader: null,
        error: action.payload.error,
      };
    case FETCH_PERFORMER_PLAYER_METADATA_SUCCESS: {
      const metadata = _.mapKeys(action.payload.data, 'performer_metadata_id');
      // console.log('IN REDUCER metadata: ', metadata, action.payload.data);
      return { ...state, metadata };
    }
    case FETCH_PERFORMER_PLAYER_METADATA_ERROR:
      return { ...state, metadata: null, error: action.payload.error };

    case DELETE_PERFORMER_PLAYER_IMAGE_WAITING:
      return { ...state, deleteImageStatus: 'waiting' };
    case DELETE_PERFORMER_PLAYER_IMAGE_SUCCESS:
      return { ...state, deleteImageStatus: 'success' };
    case DELETE_PERFORMER_PLAYER_IMAGE_ERROR:
      return {
        ...state,
        deleteImageStatus: 'error',
        error: action.payload.error,
      };
    case DELETE_PERFORMER_PLAYER_IMAGE_RESET:
      return { ...state, deleteImageStatus: 'init' };

    case UPLOAD_PLAYER_TRANSFER_IMAGE_SUCCESS:
      return { ...state, transferImage: 'success' };
    case UPLOAD_PLAYER_TRANSFER_IMAGE_WAITING:
      return { ...state, transferImage: 'waiting' };
    case UPLOAD_PLAYER_TRANSFER_IMAGE_ERROR:
      return { ...state, transferImage: 'error', error: action.payload.error };
    case UPLOAD_PLAYER_TRANSFER_IMAGE_RESET:
      return { ...state, transferImage: 'init' };

    case FETCH_PLAYERS_LIST_FOR_LINEUP_SUCCESS: {
      const lineupsTemp = _.map(action.payload.data, (player) => {
        const id = player.performer_id;
        const name = player.pf_name;
        let imgUrl = '';
        let fieldRole = 'Other';
        player.performer_metadata.forEach((metadata) => {
          if (metadata.pm_key === 'imageurl') {
            imgUrl = metadata.pm_value;
          } else if (metadata.pm_key === 'fieldRole') {
            fieldRole = metadata.pm_value;
          }
        });
        return { fieldRole, name, imgUrl, id };
      });
      const lineups = _.groupBy(lineupsTemp, 'fieldRole');
      const lineupPlayersList = _.mapKeys(lineupsTemp, 'id');
      return { ...state, lineupsStatus: 'success', lineups, lineupPlayersList };
    }
    case FETCH_PLAYERS_LIST_FOR_LINEUP_WAITING:
      return { ...state, lineupsStatus: 'waiting' };
    case FETCH_PLAYERS_LIST_FOR_LINEUP_ERROR:
      return {
        ...state,
        lineupsStatus: 'error',
        lineups: {},
        error: action.payload.error,
      };
    case FETCH_PLAYERS_LIST_FOR_LINEUP_RESET:
      return { ...state, lineupsStatus: 'init', lineups: {} };
    default:
      return state;
  }
}
