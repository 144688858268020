import React, { Component } from 'react';
import {
  Button,
  Segment,
  // Confirm,
  Checkbox,
  // Dimmer,
  Loader,
} from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { T, getErrorMessage } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';
import ManageLogo from './ManageLogo';
// import ImageCrop from '../PerformerPlayers/ImageCrop';
// import LocationListContainer from './LocationListContainer';
// import ModalWindow, { ModalContext } from '../Venues/ModalWindow';
import {
  addVenue,
  editVenue,
  editCompany,
  resetEditCompany,
  fetchCompany,
  deleteCompany,
  resetDeleteCompany,
  deleteCompanyLogo,
  uploadCompanyLogo,
} from '../../Redux/actions';

// const errorStyle = {
//               backgroundColor: '#FFF6F6',
//               borderColor: '#E0B4B4',
//               color: '#9F3A38',
//               WebkitBoxShadow: 'none',
//                boxShadow: 'none'
//           };

class EditCompany extends Component {
  constructor(props) {
    super(props);
    const company = this.props.company;
    const companyObj = this.updateStateVariable(company, false);
    this.state = {
      ...companyObj,
    };
    this.getCountryList = this.getCountryList.bind(this);
    this.onValidSubmit = this.onValidSubmit.bind(this);
    // this.setSelectedCountry = this.setSelectedCountry.bind(this);
    // this.oncancelDeleteConfirmation = this.oncancelDeleteConfirmation.bind(this);
    // this.onConfirmDelete = this.onConfirmDelete.bind(this);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { add, update } = nextProps.venues.operationState;
    const addVenueStatus = this.props.venues.operationState.add;
    const editVenueStatus = this.props.venues.operationState.update;
    if (addVenueStatus !== 'success' && add === 'success') {
      const co_merchant_address = nextProps.venues.venue.place_id;
      const tempState = { ...nextState, co_merchant_address };
      this.onValidSubmit(tempState);
    } else if (addVenueStatus !== 'error' && add === 'error') {
      const errorObj = getErrorMessage(
        nextProps.venues.error,
        'Error has occurred while adding location'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (editVenueStatus !== 'success' && update === 'success') {
      this.onValidSubmit(nextState);
    } else if (editVenueStatus !== 'error' && update === 'error') {
      const errorObj = getErrorMessage(
        nextProps.venues.error,
        'Error has occurred while editing location'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      this.props.uploadStatus !== 'success' &&
      nextProps.uploadStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Image uploaded successfully'),
        time: 5000,
      });
      this.updateStateVariable(nextProps.company);
    } else if (
      this.props.uploadStatus !== 'error' &&
      nextProps.uploadStatus === 'error'
    ) {
      const errorObj = getErrorMessage(nextProps.venues.error);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      this.props.deleteLogoStatus !== 'success' &&
      nextProps.deleteLogoStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Image deleted successfully'),
        time: 5000,
      });
      this.updateStateVariable(nextProps.company);
    } else if (
      this.props.deleteLogoStatus !== 'error' &&
      nextProps.deleteLogoStatus === 'error'
    ) {
      const errorObj = getErrorMessage(nextProps.venues.error);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      this.props.editStatus !== 'success' &&
      nextProps.editStatus === 'success'
    ) {
      this.props.resetEditCompany();
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Company edited successfully'),
        time: 5000,
      });
      // nextProps.history.push('/app/companies/list');
    } else if (nextProps.editStatus === 'error') {
      const errorObj = getErrorMessage(nextProps.venues.error);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetEditCompany();
    } else if (
      this.props.deleteStatus !== 'success' &&
      nextProps.deleteStatus === 'success'
    ) {
      this.props.resetDeleteCompany();
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Company deleted successfully'),
        time: 5000,
      });
      // nextProps.history.push('/app/companies/list');
    } else if (
      this.props.deleteStatus !== 'error' &&
      nextProps.deleteStatus === 'error'
    ) {
      const errorObj = getErrorMessage(nextProps.venues.error);
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetDeleteCompany();
    }
  }

  onValidSubmit = (state) => {
    const inputValues = { ...state };
    const companyId = this.props.company.company_id;
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    this.props.editCompany(companyId, inputValues, authKey, ws_id);
  };

  // onConfirmDelete() {
  //   // const companyId = this.props.match.params.companyId;
  //   const companyId = this.props.companyId;
  //   const authKey = localStorage.getItem('x-auth-key');
  //   this.props.deleteCompany(companyId, authKey);
  //   this.setState({ openDeleteConfirmation: false });
  //   this.props.closeModal();
  //   // console.log('delete confirmed');
  // }
  //
  // onDeleteClick() {
  //   this.setState({ openDeleteConfirmation: true });
  // }
  //
  // oncancelDeleteConfirmation() {
  //   this.setState({ openDeleteConfirmation: false });
  // }

  onCancel = () => {
    this.props.history.push('/app/dashboard');
  };

  onChange = (e, { name, value }) => {
    if (name.startsWith('pl')) {
      const company_address = { ...this.state.company_address, [name]: value };
      this.setState({ company_address });
    } else {
      this.setState({ [name]: value });
    }
  };

  setSelectedCountry(e, { value }) {
    this.setState({ selectedCountry: value, selectStyle: {} });
  }

  getCountryList() {
    const selectLabel = {
      country_iso2: '',
      value: '',
      country_name: T('Select a Country'),
    };
    const countriesProp = this.props.countries.countries;
    if (typeof countriesProp === 'object' && countriesProp.length > 0) {
      const countries = this.props.countries.countries.slice();
      countries.unshift(selectLabel);
      return _.map(countries, (option) =>
        // ({ flag: option.country_iso2.toLowerCase(), text: option.country_name, value: option.country_iso2 })
        ({ text: option.country_name, value: option.country_iso2 })
      );
    }
    return selectLabel;
  }
  // setSelectedLocation = (selectedLocation) => {
  //   let company_address = this.state.company_address;
  //   company_address = { ...company_address, ...selectedLocation };
  //   this.setState({ co_merchant_address: selectedLocation, company_address });
  // }
  getImageLogo = (companyLogo) =>
    companyLogo && companyLogo.length > 0 ? companyLogo[0].fl_path_small : '';

  // getCompanyLocationId = (locationState) => {
  //   if (locationState && typeof locationState === 'object') {
  //     return locationState.place_id;
  //   } else if (locationState && typeof locationState === 'number') {
  //     return locationState;
  //   }
  //   return null;
  // }
  postData = () => {
    const id = this.state.co_merchant_address;
    const authKey = localStorage.getItem('x-auth-key');
    const company_address = { ...this.state.company_address };
    const isLocationModified = this.isCompanyLocationModified();
    const { ws_id } = this.props;
    const pl_parent = this.props.workspace.workspaces[ws_id].cnc_place;
    if (id && isLocationModified) {
      //Edit places
      this.props.editVenue(id, company_address, authKey, ws_id);
    } else if (id && !isLocationModified) {
      this.onValidSubmit(this.state);
    } else if (!id) {
      //Create new place
      this.props.addVenue(
        { ...company_address, pl_parent, pl_type: 'merchantaddress' },
        authKey,
        ws_id
      );
    }
  };
  isCompanyLocationModified = () => {
    const company_address = { ...this.state.company_address };
    const addressProp = { ...this.props.company };
    const modified = Object.keys(company_address).some(
      (key) => company_address[key] !== addressProp[key]
    );
    return modified;
  };
  updateStateVariable = (company, update = true) => {
    const address = company.company_address;
    const companyObj = {
      co_name: company.co_name,
      co_vat: company.co_vat,
      co_country: company.co_country,
      co_payiq_shop_id: company.co_payiq_shop_id,
      co_payiq_shop_password: company.co_payiq_shop_password,
      company_logo: company.company_logo,
      co_merchant_address: company.co_merchant_address,
      // fl_path_original: company.company_logo && company.company_logo.length > 0 ? company.company_logo[0].fl_path_original : '',
      co_is_active: company.co_is_active,
      company_address: {
        pl_name: address ? address.pl_name : '',
        pl_address: address ? address.pl_address : '',
        pl_zip: address ? address.pl_zip : '',
        pl_city: address ? address.pl_city : '',
        pl_email: address ? address.pl_email : '',
        pl_phone: address ? address.pl_phone : '',
      },
    };
    if (update) {
      this.setState({ ...companyObj });
    } else {
      return companyObj;
    }
  };

  renderOptionsCountryModified(opt, label) {
    const countries = opt.slice();
    countries.unshift({ id: '', country_name: T('Select a Country') });
    return _.map(countries, (option) => (
      <option key={option.id} value={option.id}>
        {option[label]}
      </option>
    ));
  }

  render() {
    const { add, update } = this.props.venues.operationState;
    if (
      this.props.countries.status !== 'success' ||
      add === 'waiting' ||
      update === 'waiting'
    ) {
      return <Spinner />;
    }
    // console.log('Company State: ', this.state.co_merchant_address);
    return (
      <Segment>
        <h3>{T('Edit Company')} </h3>
        <Form onValidSubmit={this.postData} ref={(ref) => (this.form = ref)}>
          <Form.Group widths="equal">
            <Form.Input
              required
              name="co_name"
              value={this.state.co_name}
              label={T('Company Name')}
              placeholder={T('Company Name')}
              autoFocus
              onChange={this.onChange}
            />
            <Form.Input
              required
              name="co_vat"
              value={this.state.co_vat}
              label={T('VAT')}
              placeholder={T('VAT')}
              onChange={this.onChange}
            />
            <Form.Dropdown
              required
              name="co_country"
              label={T('Country')}
              placeholder={T('Country')}
              search
              selection
              clearable
              selectOnNavigation
              noResultsMessage={T('No result')}
              // style={this.state.selectStyle}
              fluid
              onChange={this.onChange}
              options={this.getCountryList()}
              value={this.state.co_country}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label={T('PayIQ shop id')}
              value={this.state.co_payiq_shop_id}
              name="co_payiq_shop_id"
              type="text"
              placeholder={T('PayIQ shop id')}
              onChange={this.onChange}
            />
            <Form.Input
              label={T('PayIQ shop password')}
              value={this.state.co_payiq_shop_password}
              name="co_payiq_shop_password"
              type="password"
              onChange={this.onChange}
              autoComplete="new-password"
            />
          </Form.Group>
          <label>
            <h3> {T('Receipt Information')} </h3>
          </label>
          {/*<Form.Group>
        <Form.Input
            name="pl_location"
            label={T('Map Location')}
            width={6}
            style={{ minWidth: '350px' }}
            readOnly
            type="string"
            // onChange={this.onLocationChange}
            value={this.getCompanyLocationId(this.state.co_merchant_address)}
            onKeyPress={this.preventFormSubmitOnEnter}
            action={
              <ModalWindow
                buttonColor="blue"
                buttonSize="tiny"
                buttonLabel={T('Select Location')}
                header={T('Select Location')}
              >
                  <ModalContext.Consumer>
                    {({ closeModal }) =>
                    (<LocationListContainer
                      closeModal={closeModal}
                      setSelectedLocation={this.setSelectedLocation}
                    />)}
                  </ModalContext.Consumer>
              </ModalWindow>
        }
        />
      </Form.Group>*/}
          <div
            style={{
              justifyContent: 'flex-start',
              display: 'flex',
              flexFlow: 'row wrap',
              width: '100%',
              margin: '5px 0 50px 0',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                flexFlow: 'column wrap',
                margin: '0 30px 5px 20px',
              }}
            >
              {this.props.uploadStatus === 'waiting' ||
              this.props.deleteLogoStatus === 'waiting' ? (
                <Loader active inline="centered" />
              ) : (
                <ManageLogo
                  hideCancelButton
                  showDeleteButton={!!this.state.company_logo === true}
                  onDeleteImage={this.props.deleteCompanyLogo}
                  imageId={this.props.company.company_id}
                  image={this.getImageLogo(this.state.company_logo)}
                  uploadImage={this.props.uploadCompanyLogo}
                  originalUrl={this.state.fl_path_original}
                />
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexFlow: 'column wrap',
                margin: '0 30px 5px 20px',
              }}
            >
              <Form.Input
                required
                label={T('Name')}
                value={this.state.company_address.pl_name}
                name="pl_name"
                type="text"
                placeholder={T('Name')}
                autoComplete="off"
                onChange={this.onChange}
              />
              <Form.Input
                required
                label={T('Address')}
                value={this.state.company_address.pl_address}
                name="pl_address"
                type="text"
                placeholder={T('Address')}
                autoComplete="off"
                onChange={this.onChange}
              />
              <Form.Input
                required
                label={T('Zip')}
                value={this.state.company_address.pl_zip}
                name="pl_zip"
                type="number"
                placeholder={T('Zip')}
                autoComplete="off"
                onChange={this.onChange}
              />
              <Form.Input
                required
                label={T('City')}
                value={this.state.company_address.pl_city}
                name="pl_city"
                type="text"
                placeholder={T('City')}
                autoComplete="off"
                onChange={this.onChange}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexFlow: 'column wrap',
                margin: '0 30px 5px 20px',
              }}
            >
              <Form.Input
                label={T('Email')}
                value={this.state.company_address.pl_email}
                name="pl_email"
                type="email"
                placeholder={T('Email')}
                autoComplete="off"
                onChange={this.onChange}
              />
              <Form.Input
                label={T('Phone')}
                value={this.state.company_address.pl_phone}
                name="pl_phone"
                type="tel"
                placeholder={T('Phone')}
                autoComplete="off"
                onChange={this.onChange}
              />
            </div>
          </div>
          <Form.Group>
            <Form.Field>
              <Checkbox
                toggle
                label={T('Active')}
                defaultChecked={this.state.co_is_active || false}
                checked={this.state.pl_active}
                name="co_is_active"
                onChange={this.handleChange}
              />
            </Form.Field>
          </Form.Group>
          <Button content={T('Save')} color="green" />
          <Button
            type="button"
            color="black"
            content={T('Cancel')}
            onClick={() => this.onCancel()}
          />
          {/*  <Button
           type="button"
           color="red"
           content={T('Delete Company')}
           onClick={this.onDeleteClick.bind(this)}
        />
        <Confirm
            header={T('Delete Company')}
            content={T('Are you sure you want to delete the company?')}
            confirmButton={T('Delete')}
            open={this.state.openDeleteConfirmation}
            onCancel={this.oncancelDeleteConfirmation}
            onConfirm={this.onConfirmDelete}
            size="tiny"
        />*/}
        </Form>
      </Segment>
    );
  }
}

function mapStateToProps({ companies, countries, venues, workspace }) {
  return {
    countries,
    venues,
    company: companies.company,
    editStatus: companies.operationState.update,
    uploadStatus: companies.operationState.uploadStatus,
    deleteLogoStatus: companies.operationState.deleteLogoStatus,
    ws_id: workspace.ws_id,
    workspace,
  };
}

export default connect(mapStateToProps, {
  addVenue,
  editVenue,
  editCompany,
  resetEditCompany,
  fetchCompany,
  deleteCompany,
  resetDeleteCompany,
  deleteCompanyLogo,
  uploadCompanyLogo,
})(EditCompany);
