import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Header,
  Menu,
  Form,
  Button,
  Icon,
  Table,
  Pagination,
  Message,
  Dropdown,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { fetchEventReport, downloadEventReport } from '../../../Redux/actions';
import { T, getErrorMessage, validateHhMm } from '../../Common/Helpers';
import { Spinner } from '../../Common/Spinner';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import fi from 'date-fns/locale/fi';
//import EventSummary from './EventSummary';
//import EventGrid from './EventGrid';
const NO_RESULTS = 'No results found';
const NO_RESULTS_DETAILED = 'noTaskWithCriteria';

registerLocale('fi', fi);

const styles = {
  angleArrow: {
    height: 'inherit',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: '40px',
  },
  errorStyle: {
    backgroundColor: '#FFF6F6',
    border: ' solid 1px #E0B4B4',
    color: '#9F3A38',
    WebkitBoxShadow: 'none',
    boxShadow: 'none',
  },
  input: {
    padding: '0.67857143em 1em',
    border: '1px solid rgba(34, 36, 38, 0.15)',
    borderRadius: '0.28571429rem',
    width: '100%',
  },
  'input.error': {
    backgroundColor: '#FFF6F6',
    border: '1px solid #E0B4B4',
    color: '#9F3A38',
  },
};

class EventReportManager extends Component {
  constructor(props) {
    super(props);
    const userLang =
      navigator.language?.substring(0, 2) ||
      navigator.userLanguage?.substring(0, 2) ||
      'en';
    moment.locale(userLang);
    this.state = {
      activeSubItem: 'month',
      searchterm: '',
      lang: 'en',
      startError: {},
      endError: {},
      limitByTime: false,
      limitByPublicHoliday: false,
      startTime: '08:00',
      endTime: '16:00',
      rowsPerPage: 20,
      currentPage: 1,
      removeDutyHours: true,
      onlyDutyHours: false,
      reportRange: {
        day: {
          start: moment().startOf('day').toISOString(),
          end: moment().startOf('day').toISOString(),
        },
        month: {
          start: moment().startOf('month').subtract(1, 'months').toISOString(),
          end: moment().startOf('month').subtract(1, 'days').toISOString(),
        },
        isoweek: {
          start: moment().startOf('isoweek').subtract(1, 'weeks').toISOString(),
          end: moment().startOf('isoweek').subtract(1, 'days').toISOString(),
        },
        custom: {
          start: moment().subtract(1, 'years').toISOString(),
          end: moment().toISOString(),
        },
      },
    };

    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }
  UNSAFE_componentWillUpdate(nextProps) {
    if (
      this.props.eventReport.report.status === 'waiting' &&
      nextProps.eventReport.report.status === 'success'
    ) {
      const { total_number } = nextProps.eventReport.report.data;
      const { rowsPerPage } = this.state;
      if ((this.state.currentPage - 1) * rowsPerPage > total_number) {
        //this.state.currentPage = 1;
        this.setState({ currentPage: 1 });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log("prevState", prevState);
    //console.log("this.state", this.state);

    if (prevState.sort !== this.state.sort) {
      this.fetchReport();
    }

    if (
      prevProps.eventReport.report.status !== 'error' &&
      this.props.eventReport.report.status === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.eventReport.report.error,
        'Error while fetching report'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(`${errorObj.status}: ${errorObj.message}`),
        time: 5000,
      });
    }
    if (
      prevProps.eventReport.download.status !== 'error' &&
      this.props.eventReport.download.status === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.eventReport.download.error,
        'Error while downloading report'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(`${errorObj.status}: ${errorObj.message}`),
        time: 5000,
      });
    }
  }

  getLanguageList() {
    return _.map(this.props.languages, (language) => ({
      text: T(language.ln_name),
      value: language.ln_alpha_2,
    }));
  }

  getReportStatus(status) {
    return _.map(status, (st) => ({ text: st.name, value: st.status }));
  }

  setActiveMenu(key, activeItem) {
    this.setState({ [key]: activeItem });
  }

  getEventReportParams({ activePage, reportType }) {
    const authKey = localStorage.getItem('x-auth-key');
    const { searchterm, activeSubItem, sort } = this.state;
    const reportRange = _.cloneDeep(this.state.reportRange);
    const { start, end } = reportRange[activeSubItem];
    const { ws_id } = this.props;
    const {
      place_ids,
      source_ids,
      reason_ids,
      mt_keys,
      ev_types,
      client_place_ids,
      source_contact_methods,
      triage_ids,
      tag_ids,
      limitByPublicHoliday,
      removeDutyHours,
      onlyDutyHours,
    } = this.state;
    const params = {
      authKey,
      ws_id,
      place_ids,
      performer_ids: source_ids,
      eventcategory_ids: reason_ids,
      ev_types,
      mt_keys,
      client_place_ids,
      source_contact_methods,
      triage_ids,
      tag_ids,
      start: activePage,
      reportType,
      limitByPublicHoliday,
      searchterm: searchterm.length > 0 ? searchterm : null,
      startdate: moment(start).format('YYYY-MM-DD'),
      enddate: moment(end).format('YYYY-MM-DD'),
      sort,
      removeDutyHours,
      onlyDutyHours,
    };
    if (this.state.limitByTime) {
      params.start_time = this.state.startTime;
      params.end_time = this.state.endTime;
    }
    return params;
  }

  changeRange(op, rangeType) {
    let diff = 'days';
    if (rangeType === 'month') {
      diff = 'months';
    } else if (rangeType === 'isoweek') {
      diff = 'weeks';
    }
    const range = _.cloneDeep(this.state.reportRange);

    if (op === 'add') {
      range[rangeType].start = moment(range[rangeType].start)
        .add(1, diff)
        .startOf(rangeType)
        .toISOString();
      range[rangeType].end = moment(range[rangeType].start)
        .endOf(rangeType)
        .toISOString();
    } else {
      range[rangeType].start = moment(range[rangeType].start)
        .subtract(1, diff)
        .startOf(rangeType)
        .toISOString();
      range[rangeType].end = moment(range[rangeType].start)
        .endOf(rangeType)
        .toISOString();
    }

    if (moment(range[rangeType].end).isSameOrBefore(moment())) {
      this.setState({ reportRange: range, startError: {}, endError: {} });
    }
  }

  handleChange(name, value) {
    this.setState({ [name]: value });
  }

  handleDateChange(name, value) {
    const { activeSubItem } = this.state;
    const reportRange = _.cloneDeep(this.state.reportRange);
    reportRange[activeSubItem][name] = moment(value, 'l').toISOString();
    if (
      moment(reportRange[activeSubItem].start) <=
      moment(reportRange[activeSubItem].end)
    ) {
      this.setState({ [`${name}Error`]: {}, reportRange });
    } else {
      this.setState({ [`${name}Error`]: styles.errorStyle });
    }
  }

  validateForm() {
    return !(
      this.state.limitByTime &&
      (!validateHhMm(this.state.startTime) ||
        !validateHhMm(this.state.startTime))
    );
  }

  fetchReport(activePage) {
    if (this.validateForm()) {
      this.props.fetchEventReport(this.getEventReportParams({ activePage }));
    } else {
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T('Korjaa virheelliset tiedot'),
        time: 5000,
      });
    }
  }

  downloadReport(reportType) {
    if (this.props.eventReport.download.status === 'waiting') {
      return;
    }
    this.props.downloadEventReport(this.getEventReportParams({ reportType }));
  }

  handlePaginationChange(e, { activePage }) {
    const { total_number } = this.props.eventReport.report.data;
    const { rowsPerPage } = this.state;
    if ((activePage - 1) * rowsPerPage > total_number) {
      return;
    }
    this.setState({ currentPage: activePage });
    //this.fetchReport(activePage);
  }

  handleSortingReport(columnName, dirn) {
    const sortByColumn = columnName;
    let direction = 'ascending';
    if (this.state.sortByColumn === columnName) {
      direction = dirn === 'ascending' ? 'descending' : 'ascending';
    }
    const formatedDirn = direction === 'ascending' ? 'asc' : 'desc';
    const sort = `${columnName}:${formatedDirn}`;
    this.setState({ sortByColumn, direction, sort });
  }

  renderTags(tags) {
    return tags.map((t) => ({ text: t.tg_name }));
  }

  renderEventReportRows(cnc_context) {
    const { items } = this.props.eventReport.report.data;
    const { rowsPerPage, currentPage } = this.state;
    let tags = [];
    const itemSlice =
      items?.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
      ) || [];
    return itemSlice.map((item, i) => {
      if (cnc_context === 20002 && item.tags !== undefined) {
        tags = item.tags.map((t, i) => {
          return (
            <span key={`tag_${i}`}>
              {t.tg_value}
              <br />
            </span>
          );
        });
      }

      const moments = item.reasons.map((r, i2) => {
        const number = r.number > 1 ? `\u00a0(${r.number})` : '';
        return (
          <span key={`mom_${i}_${i2}`}>
            {r.name}
            {number}
            <br />
          </span>
        );
      });
      const dateTime = moment(item.event_start).format('l HH:mm');
      const firstCol =
        cnc_context === 20002 ? (
          <Link to={`/app/workcaselogging/edit/${item.event_id}`}>
            {item.event_id}
          </Link>
        ) : (
          item.event_id
        );

      return (
        <Table.Row key={`eventReportRow_${i}`}>
          <Table.Cell>{firstCol}</Table.Cell>
          <Table.Cell>{item.pl_name}</Table.Cell>
          <Table.Cell>{dateTime}</Table.Cell>
          <Table.Cell>{item.event_time}</Table.Cell>
          <Table.Cell>{item.source}</Table.Cell>
          {cnc_context === 20002 ? (
            <>
              <Table.Cell>{item.ec_code}</Table.Cell>
              <Table.Cell>{moments}</Table.Cell>
              <Table.Cell>{item.place_2}</Table.Cell>
              <Table.Cell>{item.source_contact_method}</Table.Cell>
              <Table.Cell>{item.triage}</Table.Cell>
              <Table.Cell>{tags}</Table.Cell>
              <Table.Cell>{item.delay_time}</Table.Cell>
            </>
          ) : (
            <>
              <Table.Cell>{item.ec_name}</Table.Cell>
              <Table.Cell>{moments}</Table.Cell>
              <Table.Cell>{item.customer_type}</Table.Cell>
            </>
          )}
        </Table.Row>
      );
    });
  }

  renderPagination(cnc_context) {
    const { total_number } = this.props.eventReport.report.data;

    const { rowsPerPage, currentPage } = this.state;
    const totalPages = parseInt(total_number / rowsPerPage, 10) + 1;
    const firstItem = (currentPage - 1) * rowsPerPage + 1;
    const colSpan = cnc_context === 20002 ? '13' : 8;
    let lastItem = currentPage * rowsPerPage;
    lastItem = Math.min(lastItem, total_number);

    //const { headers } = this.props.eventReport.report;
    return (
      <Table.Row>
        <Table.HeaderCell colSpan={colSpan}>
          {`${firstItem} - ${lastItem} / ${total_number}`}
          <Pagination
            floated="right"
            onPageChange={this.handlePaginationChange}
            activePage={currentPage}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={totalPages}
          />
        </Table.HeaderCell>
      </Table.Row>
    );
  }

  renderReport(cnc_context) {
    if (this.props.eventReport.report.status === 'init') {
      return;
    }
    if (this.props.eventReport.report.status === 'waiting') {
      return <Spinner />;
    }
    if (this.props.eventReport.report.status === 'success') {
      const isDownloading =
        this.props.eventReport.download.status === 'waiting';
      const { reportType } = this.props.eventReport.download;
      const { sortByColumn, direction } = this.state;
      const { data, startdate, enddate } = this.props.eventReport.report;

      const dateRange = `${moment(startdate).format('DD.MM.YYYY')} - ${moment(
        enddate
      ).format('DD.MM.YYYY')}`;

      const { total_number, total_time } = data;
      if (total_number === 0) {
        return (
          <Message
            icon="search"
            header={T(NO_RESULTS)}
            content={T(NO_RESULTS_DETAILED)}
            info
          />
        );
      }
      return (
        <>
          <Header dividing>
            {T('Event Report')} ({dateRange})
          </Header>
          <Header.Subheader>
            Yhteiskesto: {total_time}
            <Button.Group floated="right">
              <Button
                loading={isDownloading && reportType === 'csv'}
                icon="file text"
                onClick={() => this.downloadReport('csv')}
                label={T('CSV')}
              />
              <Button
                loading={isDownloading && reportType === 'xls'}
                icon="file excel"
                onClick={() => this.downloadReport('xls')}
                label={T('XLS')}
              />
            </Button.Group>
          </Header.Subheader>
          <Table sortable celled striped>
            <Table.Header>
              {this.renderPagination(cnc_context)}
              <Table.Row>
                <Table.HeaderCell
                  sorted={sortByColumn === 'event_id' ? direction : null}
                  onClick={() =>
                    this.handleSortingReport('event_id', direction)
                  }
                >
                  {T('Tehtävä')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortByColumn === 'pl_name' ? direction : null}
                  onClick={() => this.handleSortingReport('pl_name', direction)}
                >
                  {T('Kunta')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortByColumn === 'event_start' ? direction : null}
                  onClick={() =>
                    this.handleSortingReport('event_start', direction)
                  }
                >
                  {T('Pvm ja aika')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortByColumn === 'event_time' ? direction : null}
                  onClick={() =>
                    this.handleSortingReport('event_time', direction)
                  }
                >
                  {T('Kesto')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortByColumn === 'source' ? direction : null}
                  onClick={() => this.handleSortingReport('source', direction)}
                >
                  {T('Ilmoittaja')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortByColumn === 'ec_name' ? direction : null}
                  onClick={() => this.handleSortingReport('ec_name', direction)}
                >
                  {T('Syy')}
                </Table.HeaderCell>
                <Table.HeaderCell>{T('Toimenpiteet')}</Table.HeaderCell>
                {cnc_context === 20002 ? (
                  <>
                    <Table.HeaderCell
                      sorted={sortByColumn === 'place_2' ? direction : null}
                      onClick={() =>
                        this.handleSortingReport('place_2', direction)
                      }
                    >
                      {T('Client Location')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={
                        sortByColumn === 'source_contact_method'
                          ? direction
                          : null
                      }
                      onClick={() =>
                        this.handleSortingReport(
                          'source_contact_method',
                          direction
                        )
                      }
                    >
                      {T('Contact Method')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortByColumn === 'triage' ? direction : null}
                      onClick={() =>
                        this.handleSortingReport('triage', direction)
                      }
                    >
                      {T('Triage')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortByColumn === 'tag' ? direction : null}
                      onClick={() => this.handleSortingReport('tag', direction)}
                    >
                      {T('Tag')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortByColumn === 'delay_time' ? direction : null}
                      onClick={() =>
                        this.handleSortingReport('delay_time', direction)
                      }
                    >
                      {T('Delay Time')}
                    </Table.HeaderCell>
                  </>
                ) : (
                  <Table.HeaderCell
                    sorted={sortByColumn === 'customer_type' ? direction : null}
                    onClick={() =>
                      this.handleSortingReport('customer_type', direction)
                    }
                  >
                    {T('Asiakastyyppi')}
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>

            <Table.Body>{this.renderEventReportRows(cnc_context)}</Table.Body>
            <Table.Footer>{this.renderPagination(cnc_context)}</Table.Footer>
          </Table>
        </>
      );
    }
  }

  render() {
    const { activeSubItem } = this.state;
    const { cnc_context } = this.props.activeWorkspace;
    const { removeDutyHours, onlyDutyHours } = this.state;
    const isFetching = this.props.eventReport.report.status === 'waiting';
    const userLang = moment.locale();

    return (
      <>
        <Header>{T('Event Report')}</Header>
        <Form>
          <Form.Group widths="equal">
            <Menu compact>
              <Menu.Item
                name="Month"
                content={T('Month')}
                active={activeSubItem === 'month'}
                onClick={() => this.setActiveMenu('activeSubItem', 'month')}
              />
              <Menu.Item
                name="Week"
                content={T('Week')}
                active={activeSubItem === 'isoweek'}
                onClick={() => this.setActiveMenu('activeSubItem', 'isoweek')}
              />
              <Menu.Item
                name="Day"
                content={T('Day')}
                active={activeSubItem === 'day'}
                onClick={() => this.setActiveMenu('activeSubItem', 'day')}
              />
              <Menu.Item
                name="Custom"
                content={T('Custom')}
                active={activeSubItem === 'custom'}
                onClick={() => this.setActiveMenu('activeSubItem', 'custom')}
              />
            </Menu>
            <Icon
              style={styles.angleArrow}
              size="large"
              name="angle left"
              onClick={() => this.changeRange('subtract', activeSubItem)}
            />
            <Form.Field>
              <label>{T('Event from')}</label>
              <div className="date-input-wrapper">
                <DatePicker
                  selected={moment(
                    this.state.reportRange[activeSubItem].start
                  ).toDate()}
                  onChange={(date) => {
                    if (date) {
                      const value = moment(date).format('l');
                      this.handleDateChange('start', value);
                    }
                  }}
                  maxDate={new Date()}
                  dateFormat="dd.MM.yyyy"
                  className="semantic-ui-datepicker"
                  calendarClassName="semantic-ui-calendar"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText={T('Select date')}
                  locale={userLang}
                />
              </div>
            </Form.Field>
            <Form.Field>
              <label>{T('Event to')}</label>
              <div className="date-input-wrapper">
                <DatePicker
                  selected={moment(
                    this.state.reportRange[activeSubItem].end
                  ).toDate()}
                  onChange={(date) => {
                    if (date) {
                      const value = moment(date).format('l');
                      this.handleDateChange('end', value);
                    }
                  }}
                  maxDate={new Date()}
                  dateFormat="dd.MM.yyyy"
                  className="semantic-ui-datepicker"
                  calendarClassName="semantic-ui-calendar"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText={T('Select date')}
                  locale={userLang}
                />
              </div>
            </Form.Field>
            <Icon
              style={styles.angleArrow}
              size="large"
              name="angle right"
              onClick={() => this.changeRange('add', activeSubItem)}
            />
          </Form.Group>
          <Form.Field
            checked={this.state.limitByTime}
            label={T('Rajaa tehtävän aloituskellonajan mukaan')}
            control="input"
            type="checkbox"
            onChange={() =>
              this.setState({ limitByTime: !this.state.limitByTime })
            }
          />
          {this.state.limitByTime && (
            <Form.Group inline>
              <Form.Field>
                <label>{T('Alkaen')}</label>
                <input
                  type="time"
                  value={this.state.startTime}
                  className={validateHhMm(this.state.startTime) ? '' : 'error'}
                  onChange={(e) => this.setState({ startTime: e.target.value })}
                />
              </Form.Field>
              <Form.Field>
                <label>{T('Päättyen')}</label>
                <input
                  type="time"
                  value={this.state.endTime}
                  className={validateHhMm(this.state.endTime) ? '' : 'error'}
                  onChange={(e) => this.setState({ endTime: e.target.value })}
                />
              </Form.Field>
            </Form.Group>
          )}
          {cnc_context === 20002 ? (
            <Form.Field
              checked={this.state.limitByPublicHoliday}
              label={T('Normaalin työajan ulkopuolella tehty työ')}
              control="input"
              type="checkbox"
              onChange={() =>
                this.setState({
                  limitByPublicHoliday: !this.state.limitByPublicHoliday,
                })
              }
            />
          ) : null}
          {cnc_context === 20002 ? (
            <Form.Group>
              <Form.Field
                checked={removeDutyHours}
                label={T(
                  'Jätä huomiotta arkipäivänä välillä 8:00 - 15:30 tulleet tapahtumat'
                )}
                control="input"
                type="checkbox"
                onChange={() => {
                  this.setState({ removeDutyHours: !removeDutyHours });
                  if (!removeDutyHours) {
                    this.setState({ onlyDutyHours: false });
                  }
                }}
              />
              <Form.Field
                checked={onlyDutyHours}
                label={T(
                  'Näytä vain arkipäivänä välillä 8:00 - 15:30 tulleet tapahtumat'
                )}
                control="input"
                type="checkbox"
                onChange={() => {
                  this.setState({ onlyDutyHours: !onlyDutyHours });
                  if (!onlyDutyHours) {
                    this.setState({ removeDutyHours: false });
                  }
                }}
              />
            </Form.Group>
          ) : null}

          <Form.Group>
            <Form.Field>
              <label>{T('Event Municipality')}</label>
              <Dropdown
                className="selection multiple search"
                clearable
                multiple
                search
                selection
                name="place_ids"
                placeholder={T('All Municipalities')}
                options={
                  this.props.eventReport.momentOptions.event_places
                    ? this.props.eventReport.momentOptions.event_places
                        .filter((pl) => pl.pl_name !== 'sote-kunnat')
                        .map((pl) => ({ text: pl.pl_name, value: pl.place_id }))
                    : []
                }
                onChange={(e, { name, value }) =>
                  this.handleChange(name, value)
                }
                value={this.state.place_ids || []}
              />
            </Form.Field>
            <Form.Field>
              <label>{T('Source')}</label>
              <Dropdown
                className="selection multiple search"
                clearable
                multiple
                search
                selection
                name="source_ids"
                placeholder={T('All Sources')}
                options={
                  this.props.eventReport.momentOptions.source
                    ? this.props.eventReport.momentOptions.source.map((s) => ({
                        text: s.pf_name,
                        value: s.performer_id,
                      }))
                    : []
                }
                onChange={(e, { name, value }) =>
                  this.handleChange(name, value)
                }
                value={this.state.source_ids || []}
              />
            </Form.Field>
            <Form.Field>
              <label>{T('Reason')}</label>
              <Dropdown
                className="selection multiple search"
                clearable
                multiple
                search
                selection
                name="reason_ids"
                placeholder={T('All Reasons')}
                options={
                  this.props.eventReport.momentOptions.reasons
                    ? this.props.eventReport.momentOptions.reasons.map((r) => ({
                        text: r.ec_code,
                        value: r.eventcategory_id,
                      }))
                    : []
                }
                onChange={(e, { name, value }) =>
                  this.handleChange(name, value)
                }
                value={this.state.reason_ids || []}
              />
            </Form.Field>
            <Form.Field>
              <label>{T('Moment type')}</label>
              <Dropdown
                className="selection multiple search"
                clearable
                multiple
                search
                selection
                name="mt_keys"
                placeholder={T('All Moment Types')}
                options={
                  this.props.eventReport.momentOptions.moments
                    ? this.props.eventReport.momentOptions.moments.map(
                        (mo) => ({
                          text: mo.name,
                          value: mo.id,
                        })
                      )
                    : []
                }
                onChange={(e, { name, value }) =>
                  this.handleChange(name, value)
                }
                value={this.state.mt_keys || []}
              />
            </Form.Field>
            {cnc_context === 20002 ? null : (
              <Form.Field>
                <label>{T('Client Type')}</label>
                <Dropdown
                  className="selection multiple search"
                  clearable
                  multiple
                  search
                  selection
                  name="ev_types"
                  placeholder={T('All Client Types')}
                  options={
                    this.props.eventReport.momentOptions.types
                      ? this.props.eventReport.momentOptions.types.map((t) => ({
                          text: t.value,
                          value: t.code,
                        }))
                      : []
                  }
                  onChange={(e, { name, value }) =>
                    this.handleChange(name, value)
                  }
                  value={this.state.ev_types || []}
                />
              </Form.Field>
            )}
          </Form.Group>
          {cnc_context === 20002 ? (
            <Form.Group>
              <Form.Field>
                <label>{T('Customer Hometown')}</label>
                <Dropdown
                  className="selection multiple search"
                  clearable
                  multiple
                  search
                  selection
                  name="client_place_ids"
                  placeholder={T('All Municipalities')}
                  options={
                    this.props.eventReport.momentOptions.client_places
                      ? this.props.eventReport.momentOptions.client_places
                          .filter((pl) => pl.pl_name !== 'sote-kunnat')
                          .map((pl) => ({
                            text: pl.pl_name,
                            value: pl.place_id,
                          }))
                      : []
                  }
                  onChange={(e, { name, value }) =>
                    this.handleChange(name, value)
                  }
                  value={this.state.client_place_ids || []}
                />
              </Form.Field>
              <Form.Field>
                <label>{T('Contact Method')}</label>
                <Dropdown
                  className="selection multiple search"
                  clearable
                  multiple
                  search
                  selection
                  name="source_contact_methods"
                  placeholder={T('All Methods')}
                  options={this.props.eventReport.momentOptions.source_contact_methods.map(
                    (scm) => ({
                      text: scm.source_contact_method,
                      value: scm.id,
                    })
                  )}
                  onChange={(e, { name, value }) =>
                    this.handleChange(name, value)
                  }
                  value={this.state.source_contact_methods || []}
                />
              </Form.Field>
              <Form.Field>
                <label>{T('Triage')}</label>
                <Dropdown
                  className="selection multiple search"
                  clearable
                  multiple
                  search
                  selection
                  name="triage_ids"
                  placeholder={T('All Triages')}
                  options={this.props.eventReport.momentOptions.triages.map(
                    (triage) => ({ text: triage.triage, value: triage.id })
                  )}
                  onChange={(e, { name, value }) =>
                    this.handleChange(name, value)
                  }
                  value={this.state.triage_ids || []}
                />
              </Form.Field>
              <Form.Field>
                <label>{T('Tag')}</label>
                <Dropdown
                  className="selection multiple search"
                  clearable
                  multiple
                  search
                  selection
                  name="tag_ids"
                  placeholder={T('All Tags')}
                  options={this.props.eventReport.momentOptions.tags.map(
                    (tag) => ({
                      text: tag.tg_value,
                      value: tag.tag_id,
                    })
                  )}
                  onChange={(e, { name, value }) =>
                    this.handleChange(name, value)
                  }
                  value={this.state.tag_ids || []}
                />
              </Form.Field>
            </Form.Group>
          ) : null}
          <Form.Group>
            <Button
              primary
              content={
                isFetching ? (
                  <Icon name="spinner" loading />
                ) : (
                  T('Display Report')
                )
              }
              onClick={() => this.fetchReport()}
              disabled={isFetching}
            />
          </Form.Group>
        </Form>
        {isFetching ? (
          <div style={{ textAlign: 'center', padding: '2em' }}>
            <Icon name="spinner" loading size="large" />
            <div style={{ marginTop: '1em' }}>{T('Assembling report...')}</div>
          </div>
        ) : (
          this.renderReport(cnc_context)
        )}
      </>
    );
  }
}

function mapStateToProps({ eventReport, workspace }) {
  return {
    ws_id: workspace.ws_id,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
    workspace,
    eventReport,
  };
}

export default connect(mapStateToProps, {
  fetchEventReport,
  downloadEventReport,
  /*
                        resetFetchEventReport,*/
})(EventReportManager);
