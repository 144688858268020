import axios from 'axios';
import config from '../../Config/AEConfig';

import {
  FETCH_PERFORMERS_SUCCESS,
  FETCH_PERFORMERS_WAITING,
  FETCH_PERFORMERS_ERROR,
  FETCH_PERFORMERS_RESET,
  FETCH_TEAMS_FOR_SEASON_WAITING,
  FETCH_TEAMS_FOR_SEASON_SUCCESS,
  FETCH_TEAMS_FOR_SEASON_ERROR,
  FETCH_PERFORMER_WAITING,
  FETCH_PERFORMER_SUCCESS,
  FETCH_PERFORMER_ERROR,
  FETCH_PERFORMER_RESET,
  CREATE_PERFORMER_SUCCESS,
  CREATE_PERFORMER_ERROR,
  CREATE_PERFORMER_RESET,
  DELETE_PERFORMER_SUCCESS,
  DELETE_PERFORMER_ERROR,
  DELETE_PERFORMER_RESET,
  EDIT_PERFORMER_SUCCESS,
  EDIT_PERFORMER_ERROR,
  EDIT_PERFORMER_RESET,
  FETCH_PERFORMERS_BY_SERIES_SUCCESS,
  FETCH_PERFORMERS_BY_SERIES_ERROR,
  ADD_EVENT_TO_PERFORMER_SUCCESS,
  ADD_EVENT_TO_PERFORMER_ERROR,
  DELETE_PERFORMER_FROM_EVENT_SUCCESS,
  DELETE_PERFORMER_FROM_EVENT_ERROR,
  FETCH_ALL_TEAMS_SUCCESS,
  FETCH_ALL_TEAMS_ERROR,
  RESET_PERFOMRER_EVENT_ACTIONS,
  FETCH_PERFORMER_TEAM_TRANSFER_LIST_SUCCESS,
  FETCH_PERFORMER_TEAM_TRANSFER_LIST_ERROR, // FETCH_PERFORMERS_BY_SERIES_ERROR,
  DELETE_PERFORMER_TEAM_IMAGE_WAITING,
  DELETE_PERFORMER_TEAM_IMAGE_SUCCESS,
  DELETE_PERFORMER_TEAM_IMAGE_ERROR,
  DELETE_PERFORMER_TEAM_IMAGE_RESET,
  UPLOAD_PERFORMER_TEAM_IMAGE_WAITING,
  UPLOAD_PERFORMER_TEAM_IMAGE_SUCCESS,
  UPLOAD_PERFORMER_TEAM_IMAGE_ERROR,
  UPLOAD_PERFORMER_TEAM_IMAGE_RESET,
} from '../Types';

import ErrorAction from './ErrorAction';

const MODULE_ID = '4';
const TEAM_SEASON_MODULE_ID = '8';
// const getConnector = (paramAdded) => (paramAdded ? '&' : '?');

export const fetchPerformers =
  (
    authKey,
    ws_id,
    startPage,
    searchterm,
    sortByColumn,
    sortDirection,
    seasonFilterId
  ) =>
  (dispatch) => {
    dispatch({ type: FETCH_PERFORMERS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    let url = `${config.BASE_URL}/performers/type/team?start=${startPage}&paging=true&ws_id=${ws_id}`;
    if (searchterm) url += `&searchterm=${searchterm}`;
    url += `&module_id=${MODULE_ID}`;
    if (sortByColumn && sortDirection) {
      url += `&sort=${sortByColumn}:${sortDirection}`;
    } else if (sortByColumn) {
      url += `&sort=${sortByColumn}`;
    }
    if (seasonFilterId) {
      url += `&event_id=${seasonFilterId}`;
    }
    // console.log('FETCH PERFORMS URL: ', url);
    axios
      .get(url)
      .then((response) => {
        //console.log('success, fetch performers');
        dispatch({
          type: FETCH_PERFORMERS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        //console.log('Error, fetch performers', error);
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_PERFORMERS_ERROR)
          : FETCH_PERFORMERS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetFetchPerformers = () => ({ type: FETCH_PERFORMERS_RESET });

export const fetchPerformersBySeries =
  (authKey, type, series, ws_id) => (dispatch) => {
    //!--Series are parent Events
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/event/${series}/performers?type=${type}&module_id=3&ws_id=${ws_id}`;
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_PERFORMERS_BY_SERIES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const payloadType = error.response
          ? ErrorAction(error.response.status, FETCH_PERFORMERS_BY_SERIES_ERROR)
          : FETCH_PERFORMERS_BY_SERIES_ERROR;
        dispatch({ type: payloadType, payload: error });
      });
  };

export const fetchTeamsForSeason =
  (authKey, type, series, ws_id) => (dispatch) => {
    //!--Series are parent Events
    dispatch({ type: FETCH_TEAMS_FOR_SEASON_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/event/${series}/performers?type=${type}&module_id=3&ws_id=${ws_id}`;
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_TEAMS_FOR_SEASON_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const payloadType = error.response
          ? ErrorAction(error.response.status, FETCH_TEAMS_FOR_SEASON_ERROR)
          : FETCH_TEAMS_FOR_SEASON_ERROR;
        dispatch({ type: payloadType, payload: error });
      });
  };

export const searchPerformerTeams =
  (authKey, ws_id, startPage, searchterm) => (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    let url = `${config.BASE_URL}/performers/type/team?start=${startPage}&ws_id=${ws_id}`;
    if (searchterm) url += `&searchterm=${searchterm}`;
    url += `&module_id=${MODULE_ID}`;
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_PERFORMERS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_PERFORMERS_ERROR)
          : FETCH_PERFORMERS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchPerformer = (authKey, id, ws_id) => (dispatch) => {
  dispatch({ type: FETCH_PERFORMER_WAITING });
  axios.defaults.headers.common['x-auth-key'] = authKey;
  axios
    .get(
      `${config.BASE_URL}/performer/${id}?season=true&module_id=${MODULE_ID}&ws_id=${ws_id}`
    )
    .then((response) => {
      dispatch({
        type: FETCH_PERFORMER_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      });
    })
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_PERFORMER_ERROR)
        : FETCH_PERFORMER_ERROR;
      dispatch({ type, payload: error });
    });
};
export const resetFetchPerformer = () => ({ type: FETCH_PERFORMER_RESET });

export function resetPerformerUpdate() {
  return {
    type: EDIT_PERFORMER_RESET,
  };
}

export function createPerformer(values, authKey, wsId) {
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .post(
        `${config.BASE_URL}/performer?module_id=${MODULE_ID}&ws_id=${wsId}`,
        values
      )
      .then((response) => {
        dispatch({
          type: CREATE_PERFORMER_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, CREATE_PERFORMER_ERROR)
          : CREATE_PERFORMER_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function editPerformer(id, authKey, values, ws_id) {
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .put(
        `${config.BASE_URL}/performer/${id}?module_id=${MODULE_ID}&ws_id=${ws_id}`,
        values
      )
      .then((response) => {
        dispatch({
          type: EDIT_PERFORMER_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_PERFORMER_ERROR)
          : EDIT_PERFORMER_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetCreatePerformer() {
  return { type: CREATE_PERFORMER_RESET };
}

export function deletePerformer(id, authKey, ws_id) {
  // console.log('DELETE performer called');
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) =>
    axios
      .delete(
        `${config.BASE_URL}/performer/${id}?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) =>
        dispatch({
          type: DELETE_PERFORMER_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, DELETE_PERFORMER_ERROR)
          : DELETE_PERFORMER_ERROR;
        dispatch({ type, payload: error });
      });
}

export function resetPerformerDelete() {
  return { type: DELETE_PERFORMER_RESET };
}

export function addPerformerToEvent(eventId, performerId, authKey, ws_id) {
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .post(
        `${config.BASE_URL}/performer/${performerId}/event?module_id=${TEAM_SEASON_MODULE_ID}&ws_id=${ws_id}`,
        { ep_event: eventId }
      )
      .then((response) => {
        dispatch({
          type: ADD_EVENT_TO_PERFORMER_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_EVENT_TO_PERFORMER_ERROR)
          : ADD_EVENT_TO_PERFORMER_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function removePerformerFromEvent(performerId, eventId, authKey, ws_id) {
  const url = `${config.BASE_URL}/performer/${performerId}/event/${eventId}?module_id=${TEAM_SEASON_MODULE_ID}&ws_id=${ws_id}`;
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) =>
    axios
      .delete(url)
      .then((response) => {
        dispatch({
          type: DELETE_PERFORMER_FROM_EVENT_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              DELETE_PERFORMER_FROM_EVENT_ERROR
            )
          : DELETE_PERFORMER_FROM_EVENT_ERROR;
        dispatch({ type, payload: error });
      });
}

export function resetPerformerEventActions() {
  return { type: RESET_PERFOMRER_EVENT_ACTIONS };
}
export const fetchAllTeams = (authKey, ws_id) => (dispatch) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  //axios.get(`${config.BASE_URL}/performers/type/player`)
  const url = `${config.BASE_URL}/performers/search?type=team&module_id=${MODULE_ID}&ws_id=${ws_id}`;
  axios
    .get(url)
    .then((response) => {
      dispatch({
        type: FETCH_ALL_TEAMS_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      });
    })
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, FETCH_ALL_TEAMS_ERROR)
        : FETCH_ALL_TEAMS_ERROR;
      dispatch({ type, payload: error });
    });
};

export const fetchPerformerTeamTransferList =
  (performerId, ws_id, authKey, isTeam = true, start) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    let url =
      `${config.BASE_URL}/performer/transfers/${performerId}?team=${isTeam}&ws_id=${ws_id}` +
      `&module_id=${MODULE_ID}`;
    if (start) {
      url += `&start=${start}`;
    }
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_PERFORMER_TEAM_TRANSFER_LIST_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              FETCH_PERFORMER_TEAM_TRANSFER_LIST_ERROR
            )
          : FETCH_PERFORMER_TEAM_TRANSFER_LIST_ERROR;
        dispatch({ type, payload: error });
      });
  };

export function deletePerformerTeamImage(performerId, url, authKey, ws_id) {
  return (dispatch) => {
    dispatch({ type: DELETE_PERFORMER_TEAM_IMAGE_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const data = { url, target: 'performer' };
    axios
      .delete(
        `${config.BASE_URL}/file/${performerId}?module_id=${MODULE_ID}&ws_id=${ws_id}`,
        {
          data,
        }
      )
      .then((response) => {
        dispatch({
          type: DELETE_PERFORMER_TEAM_IMAGE_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              DELETE_PERFORMER_TEAM_IMAGE_ERROR
            )
          : DELETE_PERFORMER_TEAM_IMAGE_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetDeletePerformerTeamImage() {
  return { type: DELETE_PERFORMER_TEAM_IMAGE_RESET };
}

export function uploadPerformerTeamImage(performerData, authKey, ws_id) {
  // const inputValues = { ...performerData, 'target': 'performer'}
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: UPLOAD_PERFORMER_TEAM_IMAGE_WAITING });
    axios
      .post(
        `${config.BASE_URL}/upload?module_id=${MODULE_ID}&ws_id=${ws_id}`,
        performerData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      )
      .then((response) => {
        dispatch({
          type: UPLOAD_PERFORMER_TEAM_IMAGE_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              UPLOAD_PERFORMER_TEAM_IMAGE_ERROR
            )
          : UPLOAD_PERFORMER_TEAM_IMAGE_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetUploadPerformerTeamImage() {
  return { type: UPLOAD_PERFORMER_TEAM_IMAGE_RESET };
}
