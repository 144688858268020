/* eslint-env browser */
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Table,
  Button,
  Icon,
  Menu,
  Pagination,
  Confirm,
  Message,
  Form,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
import 'moment/locale/fi';
import {
  fetchCmsContents,
  deleteCmsContent,
  deleteCmsContentReset,
  fetchCCDataKeyValues,
} from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import { T, getErrorMessage } from '../Common/Helpers';
import ModalWindow, { ModalContext } from '../Common/ModalWindow';
import ManageCmsContent from './ManageCmsContent';

const DEFAULT_TYPE = 'default';

class CmsContentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDelete: false,
      sortByColumn: 'cms_content_id',
      direction: 'desc',
    };

    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    //this.onDelete = this.onDelete.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
  }

  componentDidMount() {
    // localStorage.setItem('route', this.props.history.location.pathname);
    const content_type = this.props.match.params.type || null;
    const cm_content_type =
      content_type && content_type === 'event' ? null : content_type;
    const authKey = localStorage.getItem('x-auth-key');
    const { sortByColumn, direction } = this.state;
    const { content_category, category_id } = this.props.config;
    const { location } = this.props;
    const ws_id = this.props.workspace.activeWorkspace.id;
    const lastPage =
      (this.props.cmsContent.headers &&
        this.props.cmsContent.headers.total_pages) ||
      1;
    let start;
    try {
      start = this.props.cmsContent.headers.current_page;
    } catch {
      start = 1;
    }
    let data = {
      authKey,
      ws_id,
      sortByColumn,
      dirn: direction,
      contentCategory: content_category,
      contentId: category_id,
      start:
        location && location.state && location.state.contentAdded
          ? lastPage
          : start,
    };
    if (cm_content_type) {
      data = { ...data, cm_content_type };
    }
    this.props.fetchCmsContents(data);

    if (this.props.keyValueSettings.status !== 'success') {
      this.props.fetchCCDataKeyValues(authKey);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.type !== this.props.match.params.type) {
      const content_type = this.props.match.params.type;
      const cm_content_type =
        content_type && content_type === 'event' ? null : content_type;
      // console.log(cm_content_type);
      const authKey = localStorage.getItem('x-auth-key');
      const { sortByColumn, direction } = this.state;
      const { content_category, category_id } = this.props.config;
      const ws_id = this.props.workspace.activeWorkspace.id;

      const data = {
        authKey,
        ws_id,
        sortByColumn,
        dirn: direction,
        contentCategory: content_category,
        contentId: category_id,
        cm_content_type,
      };
      this.props.fetchCmsContents(data);
    }

    if (
      this.props.cmsContent.deleteStatus === 'success' &&
      prevProps.cmsContent.deleteStatus !== 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Content deleted successfully'),
        time: 5000,
      });
      this.props.deleteCmsContentReset();
    }

    if (
      this.props.cmsContent.deleteStatus === 'error' &&
      prevProps.cmsContent.deleteStatus !== 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.cmsContent.error,
        'Error, deleting content'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      // this.props.deleteCmsContentReset();
    }
  }

  /*onDelete(e, { value }) {
    e.stopPropagation();
    this.setState({ confirmDelete: true, selectedContent: value });
  }*/

  onDeleteCancel() {
    this.setState({ confirmDelete: false });
  }

  onDeleteConfirm() {
    const selectedContent = this.state.deleteContent;
    //const { selectedContent } = this.state;
    if (selectedContent) {
      const authKey = localStorage.getItem('x-auth-key');
      const ws_id = this.props.workspace.activeWorkspace.id;
      this.props.deleteCmsContent(selectedContent, authKey, ws_id);
    }
    this.setState({ confirmDelete: false });
  }

  onDeleteClick(e, cms_content_id) {
    e.stopPropagation();
    this.setState({ confirmDelete: true, deleteContent: cms_content_id });
  }

  getDefaultContentTypeValue(contenttypes) {
    //const contenttypes = this.listContentTypes();
    const defaultOption = contenttypes.find(
      (typeobject) => typeobject.dk_description === DEFAULT_TYPE
    );
    //this.setState({ cm_content_type: defaultOption.dk_key, update: true });
    return defaultOption && defaultOption.dk_key;
  }

  listContentTypes(content_type) {
    if (this.props.keyValueSettings.status !== 'success') {
      return [];
    }
    return this.props.keyValueSettings.contenttypes.filter(
      (contenttype) => contenttype.dk_ref_row_type === content_type
    );
  }

  listContentTypeOptions(contenttypes) {
    return contenttypes.map((contenttype) => ({
      key: contenttype.dk_key,
      text: T(contenttype.dk_values),
      value: contenttype.dk_key,
    }));
  }

  handlePaginationChange(e, { activePage }) {
    const { headers } = this.props.cmsContent;
    const { content_category, category_id } = this.props.config;
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.fetchCmsContents({
      authKey,
      ws_id,
      sortByColumn: headers.sort,
      dirn: null,
      start: activePage,
      searchterm: headers.searchterm,
      contentCategory: content_category,
      contentId: category_id,
    });
  }
  handleContentTypeChange(e, { name, value }) {
    const fieldStatus = value === '' || false;
    this.setState({ [name]: value, [`${name}Error`]: fieldStatus });
  }

  renderCmsContents(contenttypes, content_type, default_content_type) {
    const { content_category, category_id } = this.props.config;
    return _.map(this.props.cmsContent.dataSet, (content) => {
      const columnItems = [];
      if (content.cm_content_type !== 'hand_program') {
        const contentTypeOption = contenttypes.filter(
          (contentType) => contentType.dk_key === content.cm_content_type
        );
        columnItems.push(content.cms_content_id);
        columnItems.push(
          contentTypeOption && contentTypeOption.length > 0
            ? T(contentTypeOption[0].dk_values)
            : null
        );
        columnItems.push(content.cm_short_header);
        columnItems.push(
          content.cm_valid_from &&
            moment(content.cm_valid_from).format('l HH:mm')
        );
        columnItems.push(
          content.cm_valid_to && moment(content.cm_valid_to).format('l HH:mm')
        );
        columnItems.push(
          <Button
            key={`button_${content.cms_content_id}`}
            style={{ marginLeft: 2 }}
            className="ui red button"
            type="button"
            content={T('Delete')}
            onClick={(e) => this.onDeleteClick(e, content.cms_content_id)}
          />
        );
        return (
          <ModalWindow
            key={`modal_${content.cms_content_id}`}
            modalSize="large"
            header={T('Edit Content')}
            tableRowTrigger={columnItems}
          >
            <ModalContext.Consumer>
              {({ closeModal }) => (
                <ManageCmsContent
                  key={`manage_${content.cms_content_id}`}
                  closeModal={closeModal}
                  categoryId={content.cms_content_id}
                  content_type={content_type}
                  history={{ location: { pathname: null } }}
                  match={{
                    params: {
                      type: content_type,
                      parentId: content.cms_content_id,
                    },
                  }}
                  config={{ content_category, category_id }}
                  content={content}
                  contenttypes={contenttypes}
                  default_content_type={default_content_type}
                  multiple_options={this.props.multiple_options || false}
                  target={this.props.match.params.type}
                />
              )}
            </ModalContext.Consumer>
          </ModalWindow>
        );
      }
    });
  }

  render() {
    if (this.props.cmsContent.fetchStatus === 'error') {
      const errorObj = getErrorMessage(this.props.cmsContent.error);
      return (
        <Message
          negative
          icon="exclamation triangle"
          header={T('Error')}
          content={`${T('Status')}: ${errorObj.status}, ${T('Message')}: ${T(
            errorObj.message
          )}`}
        />
      );
    }
    if (this.props.cmsContent.fetchStatus !== 'success') {
      return <Spinner />;
    }
    const content_type =
      this.props.content_type || this.props.match.params.type;
    const contenttypes = this.listContentTypes(content_type);
    const default_content_type = this.getDefaultContentTypeValue(contenttypes);
    const { content_category, category_id } = this.props.config;
    return (
      <>
        {_.size(this.props.cmsContent.dataSet) > 0 && (
          <Table sortable compact celled padded stackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{T('Content Id')}</Table.HeaderCell>
                <Table.HeaderCell>{T('Content Type')}</Table.HeaderCell>
                <Table.HeaderCell>{T('Short header')}</Table.HeaderCell>
                <Table.HeaderCell>{T('Valid from')}</Table.HeaderCell>
                <Table.HeaderCell>{T('Valid to')}</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.renderCmsContents(
                contenttypes,
                content_type,
                default_content_type
              )}
              <Table.Row>
                <Confirm
                  header={T('Delete content')}
                  content={T(
                    'Are you sure you want to delete the selected content?'
                  )}
                  confirmButton={T('Delete')}
                  cancelButton={T('Cancel')}
                  open={this.state.confirmDelete}
                  onCancel={this.onDeleteCancel}
                  onConfirm={this.onDeleteConfirm}
                  size="tiny"
                />
              </Table.Row>
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="6">
                  {this.props.cmsContent.headers &&
                    this.props.cmsContent.headers.total_pages > 1 && (
                      <Menu floated="right" pagination>
                        <Pagination
                          onPageChange={this.handlePaginationChange}
                          defaultActivePage={
                            this.props.cmsContent.headers.current_page
                          }
                          ellipsisItem={{
                            content: <Icon name="ellipsis horizontal" />,
                            icon: true,
                          }}
                          firstItem={{
                            content: <Icon name="angle double left" />,
                            icon: true,
                          }}
                          lastItem={{
                            content: <Icon name="angle double right" />,
                            icon: true,
                          }}
                          prevItem={{
                            content: <Icon name="angle left" />,
                            icon: true,
                          }}
                          nextItem={{
                            content: <Icon name="angle right" />,
                            icon: true,
                          }}
                          totalPages={this.props.cmsContent.headers.total_pages}
                        />
                      </Menu>
                    )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
        <>
          <Form.Group widths="equal">
            <Form.Select
              name="cm_content_type"
              options={this.listContentTypeOptions(contenttypes)}
              placeholder={T('Select Content Type')}
              defaultValue={default_content_type}
              onChange={this.handleContentTypeChange.bind(this)}
              search
              error={this.state.ev_eventcategoryError}
              required
            />
            <ModalWindow
              header={T('Add')}
              modalSize="large"
              buttonColor="blue"
              buttonLabel={T('Add')}
            >
              <ModalContext.Consumer>
                {({ closeModal }) => (
                  <ManageCmsContent
                    closeModal={closeModal}
                    content_type={content_type}
                    history={{ location: { pathname: null } }}
                    match={{ params: { type: content_type, parent_id: null } }}
                    config={{ content_category, category_id }}
                    contenttypes={contenttypes}
                    default_content_type={default_content_type}
                    multiple_options={this.props.multiple_options || false}
                    target={this.props.match.params.type}
                    cm_content_type={
                      this.state.cm_content_type || default_content_type
                    }
                  />
                )}
              </ModalContext.Consumer>
            </ModalWindow>
          </Form.Group>
        </>
      </>
    );
  }
}

function mapStateToProps({ auth, cmsContent, workspace, keyValueSettings }) {
  return { auth, cmsContent, workspace, keyValueSettings };
}

export default connect(mapStateToProps, {
  fetchCmsContents,
  deleteCmsContent,
  deleteCmsContentReset,
  fetchCCDataKeyValues,
})(withRouter(CmsContentList));
