import React, { Component } from 'react';
import {
  Segment,
  Label,
  Form,
  Icon,
  Button,
  Grid,
  Message,
  Image,
  Loader,
  Modal,
  Table,
  Popup,
} from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { withRouter } from 'react-router';
import moment from 'moment';
import 'moment/locale/fi';
import FileUpload from '../Common/FileUpload';
import { T, getErrorMessage } from '../Common/Helpers';
import {
  fetchCampaign,
  addCampaign,
  editCampaign,
  resetFetchCampaign,
  resetAddCampaign,
  resetEditCampaign,
  resetDeleteCampaign,
  fetchCampaigncategories,
  fetchCampaigncodes,
  fetchShops,
  fetchCampaignCouponCodes,
  generateCampaignCouponCodes,
  resetGenerateCampaignCouponCodes,
  downloadCampaignCouponCodes,
  deleteCampaignCouponCodes,
  resetDeleteCampaignCouponCodes,
} from '../../Redux/actions';

const MODULE_ID = 802;
const imageStyle = {
  height: 200,
  width: 200,
  minHeight: 193.5,
  minWidth: 193.5,
};

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generateCodes: false,
      addModalOpen: false,
      number_of_codes: '',
      description: '',
      campaign_id: null,
      cp_campaigncategory: null,
      cp_campaigncode: null,
      cp_company_image: null,
      cp_currency: 'EUR',
      cp_description: '',
      cp_editor: null,
      cp_max_per_campaign: 0,
      cp_max_per_device: 1,
      cp_owner_company: null,
      cp_place: null,
      cp_price: '',
      cp_pricetext: '',
      cp_regular_price_text: '',
      cp_remote_id: null,
      cp_requires_validation: false,
      cp_shop: null,
      cp_target_type: 1,
      cp_title: '',
      cp_valid_from: '',
      cp_valid_to: '',
      cp_visible_from: '',
      cp_visible_to: '',
      edit_valid_from: '',
      edit_valid_to: '',
      edit_visible_from: '',
      edit_visible_to: '',
      file_id: null,
      file_url: '',
    };
    // bind local functions
    this.handleChange = this.handleChange.bind(this);
    this.saveCampaign = this.saveCampaign.bind(this);
    this.imageUpdate = this.imageUpdate.bind(this);
    this.imageDelete = this.imageDelete.bind(this);
    this.downloadCouponCodes = this.downloadCouponCodes.bind(this);
    this.deleteCampaignCouponCodes = this.deleteCampaignCouponCodes.bind(this);
    this.reloadCampaignCouponCodes = this.reloadCampaignCouponCodes.bind(this);
    this.reloadCampaigncategories = this.reloadCampaigncategories.bind(this);
    this.reloadCampaigncodes = this.reloadCampaigncodes.bind(this);
    this.reloadShops = this.reloadShops.bind(this);
    this.reloadCampaign = this.reloadCampaign.bind(this);
  }

  componentDidMount() {
    this.props.resetAddCampaign();
    this.props.resetEditCampaign();
    this.props.resetDeleteCampaign();

    this.reloadCampaigncategories();
    this.reloadCampaigncodes();
    this.reloadShops();

    const { match } = this.props;
    const { params } = match;
    const campaignId = parseInt(params.campaignId, 10);
    if (campaignId > 0) {
      this.reloadCampaign({ campaignId });
    } else {
      const { activeWorkspace } = this.props;
      const { cnc_company } = activeWorkspace;
      this.setState({ cp_owner_company: cnc_company });
    }
  }

  componentDidUpdate(prevProps) {
    const { uploadStatus } = this.props.file;
    console.log('uploadStatus', uploadStatus);
    // console.log('componentDidUpdate', this.props.campaign);

    if (
      prevProps.campaign.editCampaignStatus !== 'success' &&
      this.props.campaign.editCampaignStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Etukuponki tallennettu'),
        time: 5000,
      });
      this.props.resetEditCampaign();
    }
    if (
      prevProps.campaign.addCampaignStatus !== 'success' &&
      this.props.campaign.addCampaignStatus === 'success'
    ) {
      //console.log('this.props.campaign.addCampaign', this.props.campaign.addCampaignStatus )
      const { campaign_id } = this.props.campaign.addCampaign;
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Etukuponki tallennettu'),
        time: 5000,
      });
      this.reloadCampaignCouponCodes({ campaignId: campaign_id });
      this.props.resetAddCampaign();
      this.props.history.replace({
        pathname: `/app/campaign/manage/${campaign_id}`,
      });
      this.setState({ campaign_id });
    }
    if (
      prevProps.campaign.campaignStatus !== 'success' &&
      this.props.campaign.campaignStatus === 'success'
    ) {
      const { campaign, files } = this.props.campaign.campaign;
      const { cp_valid_from, cp_valid_to, cp_visible_from, cp_visible_to } =
        campaign;

      let file_id, file_url;
      if (files.length > 0) {
        file_id = files[0].file_id;
        file_url = files[0].fl_path_large;
      }
      this.setState({
        ...campaign,
        file_id,
        file_url,
        edit_valid_from: cp_valid_from ? moment(cp_valid_from).format('l') : '',
        edit_valid_to: cp_valid_to ? moment(cp_valid_to).format('l') : '',
        edit_visible_from: cp_visible_from
          ? moment(cp_visible_from).format('l')
          : '',
        edit_visible_to: cp_visible_to ? moment(cp_visible_to).format('l') : '',
      });

      this.reloadCampaignCouponCodes({ campaignId: campaign.campaign_id });
    }
    if (
      prevProps.campaign.generateCampaignCouponCodesStatus !== 'success' &&
      this.props.campaign.generateCampaignCouponCodesStatus === 'success'
    ) {
      const { campaign } = this.props.campaign.campaign;
      this.props.resetGenerateCampaignCouponCodes();
      this.setState({ addModalOpen: false });
      this.reloadCampaignCouponCodes({ campaignId: campaign.campaign_id });
    }
    if (
      prevProps.campaign.deleteCampaignCouponCodesStatus !== 'success' &&
      this.props.campaign.deleteCampaignCouponCodesStatus === 'success'
    ) {
      const { campaign } = this.props.campaign.campaign;
      this.props.resetDeleteCampaignCouponCodes();
      this.reloadCampaignCouponCodes({ campaignId: campaign.campaign_id });
    }
  }

  reloadCampaignCouponCodes({ campaignId }) {
    const authKey = localStorage.getItem('x-auth-key');
    const { activeWorkspace } = this.props;
    const { node_collection_id } = activeWorkspace;
    this.props.fetchCampaignCouponCodes({
      authKey,
      ws_id: node_collection_id,
      campaign_id: campaignId,
      module_id: MODULE_ID,
    });
  }

  reloadCampaigncategories() {
    const authKey = localStorage.getItem('x-auth-key');
    const { activeWorkspace } = this.props;
    const { node_collection_id } = activeWorkspace;

    this.props.fetchCampaigncategories({ authKey, ws_id: node_collection_id });
  }

  reloadCampaigncodes() {
    const authKey = localStorage.getItem('x-auth-key');
    const { sortByColumn, direction } = this.state;
    const { activeWorkspace } = this.props;
    const { node_collection_id, cnc_application } = activeWorkspace;

    this.props.fetchCampaigncodes({
      authKey,
      ws_id: node_collection_id,
      sortByColumn,
      direction,
      application_id: cnc_application,
    });
  }

  reloadShops() {
    const authKey = localStorage.getItem('x-auth-key');
    const { activeWorkspace } = this.props;
    const { node_collection_id, cnc_application } = activeWorkspace;

    this.props.fetchShops({
      authKey,
      ws_id: node_collection_id,
      application_id: cnc_application,
      noLodash: true,
    });
  }

  reloadCampaign({ campaignId }) {
    const authKey = localStorage.getItem('x-auth-key');
    const { activeWorkspace } = this.props;
    const { node_collection_id } = activeWorkspace;

    this.props.fetchCampaign({
      authKey,
      ws_id: node_collection_id,
      campaign_id: campaignId,
    });
  }

  saveCampaign() {
    const authKey = localStorage.getItem('x-auth-key');
    const { activeWorkspace } = this.props;
    const { node_collection_id } = activeWorkspace;

    const {
      campaign_id,
      cp_campaigncategory,
      cp_campaigncode,
      cp_company_image,
      // cp_currency,
      cp_description,
      // cp_editor,
      cp_max_per_campaign,
      cp_max_per_device,
      // cp_owner_company,
      // cp_place,
      cp_price,
      cp_pricetext,
      cp_regular_price_text,
      cp_remote_id,
      cp_requires_validation,
      cp_shop,
      // cp_target_type,
      cp_title,
      edit_valid_from,
      edit_valid_to,
      edit_visible_from,
      edit_visible_to,
      file_id,
    } = this.state;

    const campaign = {
      cp_campaigncategory,
      cp_campaigncode: cp_campaigncode ? cp_campaigncode : null,
      cp_company_image,
      cp_currency: 'EUR',
      cp_description,
      cp_max_per_campaign:
        !cp_requires_validation || cp_max_per_campaign === ''
          ? null
          : parseInt(cp_max_per_campaign, 10),
      cp_max_per_device:
        !cp_requires_validation || cp_max_per_device === ''
          ? null
          : parseInt(cp_max_per_device, 10),
      cp_price: cp_price === '' ? null : parseInt(cp_price, 10),
      cp_pricetext,
      cp_regular_price_text: cp_regular_price_text ? cp_regular_price_text : '',
      cp_remote_id,
      cp_requires_validation,
      cp_shop: cp_shop === '' ? null : cp_shop,
      cp_title,
      cp_valid_from:
        edit_valid_from === '' || edit_valid_from === null
          ? null
          : moment(edit_valid_from, 'l').startOf('day').toISOString(),
      cp_valid_to:
        edit_valid_to === '' || edit_valid_to === null
          ? null
          : moment(edit_valid_to, 'l').endOf('day').toISOString(),
      cp_visible_from:
        edit_visible_from === '' || edit_visible_from === null
          ? null
          : moment(edit_visible_from, 'l').startOf('day').toISOString(),
      cp_visible_to:
        edit_visible_to === '' || edit_visible_to === null
          ? null
          : moment(edit_visible_to, 'l').endOf('day').toISOString(),

      file_id: file_id ? file_id : null,
    };
    if (campaign_id) {
      this.props.editCampaign({
        campaign_id,
        campaign,
        authKey,
        ws_id: node_collection_id,
      });
    } else {
      const coupon = this.state.generateCodes && {
        description: this.state.description,
        number_of_codes: this.state.number_of_codes,
      };
      this.props.addCampaign({
        campaign,
        authKey,
        coupon,
        ws_id: node_collection_id,
      });
    }
  }

  handleChange(e, { name, type, value, checked, options }) {
    // console.log('{ name, type, value, checked, options }', {
    //   name,
    //   type,
    //   value,
    //   checked,
    //   options,
    // });
    if (type === 'checkbox') {
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: value });
    }
    if (name === 'cp_shop') {
      // update also company image when shop is changed
      if (value) {
        const shopConfig = options.find((o) => o.value === value).shop_config;
        // console.log('shopConfig', shopConfig);
        const shopLogoConfig = shopConfig.find(
          (sc) => sc.sc_keyword === 'shopLogo'
        );
        // console.log('shopLogoConfig', shopLogoConfig);
        if (shopLogoConfig) {
          this.setState({ cp_company_image: shopLogoConfig.sc_value });
        }
      } else {
        this.setState({ cp_company_image: null });
      }
    }
  }

  imageUpdate(urlSmall, urlLarge, ref, file_id, target) {
    // console.log('imageUpdate', { urlSmall, urlLarge, ref, file_id, target });
    this.setState({ file_id, file_url: urlLarge });
  }
  imageDelete({ authKey, file_id, module_id, target, id }) {
    // console.log('imageDelete', { authKey, file_id, module_id, target, id });
    this.setState({ file_id: null, file_url: '' });
  }
  downloadCouponCodes(type) {
    const authKey = localStorage.getItem('x-auth-key');
    const { activeWorkspace } = this.props;
    const { node_collection_id } = activeWorkspace;
    const { campaign } = this.props.campaign.campaign;

    this.props.downloadCampaignCouponCodes({
      authKey,
      ws_id: node_collection_id,
      campaign_id: campaign.campaign_id,
      module_id: MODULE_ID,
      type,
    });
  }

  deleteCampaignCouponCodes({ coupon_series_id }) {
    const authKey = localStorage.getItem('x-auth-key');
    const { activeWorkspace } = this.props;
    const { node_collection_id } = activeWorkspace;
    const { campaign } = this.props.campaign.campaign;

    this.props.deleteCampaignCouponCodes({
      authKey,
      ws_id: node_collection_id,
      campaign_id: campaign.campaign_id,
      coupon_series_id,
      module_id: MODULE_ID,
    });
  }

  renderEditCampaign(isWaiting) {
    const {
      campaign_id,
      cp_campaigncategory,
      cp_campaigncode,
      cp_company_image,
      // cp_currency,
      cp_description,
      // cp_editor,
      cp_max_per_campaign,
      cp_max_per_device,
      // cp_owner_company,
      // cp_place,
      // cp_price,
      cp_pricetext,
      cp_regular_price_text,
      // cp_remote_id,
      cp_requires_validation,
      cp_shop,
      // cp_target_type,
      cp_title,
      edit_valid_from,
      edit_valid_to,
      edit_visible_from,
      edit_visible_to,
      file_id,
      file_url,
    } = this.state;

    const { shopsFetchStatus } = this.props.shop;

    const campaigncategory_options =
      this.props.campaign.campaigncategories.length > 0 &&
      this.props.campaign.campaigncategories.map((ccg) => {
        return {
          key: `ccg${ccg.campaigncategory_id}`,
          text: ccg.ccg_name,
          value: ccg.campaigncategory_id,
        };
      });

    const campaigncode_options = this.props.campaign.campaigncodes.map((cc) => {
      return {
        key: `cc${cc.campaigncode_id}`,
        text: `${cc.cc_code} (${cc.cc_name})`,
        value: cc.campaigncode_id,
      };
    });

    const shop_options =
      this.props.shop.shops && this.props.shop.shops.length
        ? this.props.shop.shops.map((s) => {
            return {
              key: `cc${s.shop_id}`,
              text: s.sh_name,
              value: s.shop_id,
              shop_config: s.shop_config,
            };
          })
        : null;

    const { activeWorkspace } = this.props;
    const { node_collection_id, cnc_application } = activeWorkspace;
    const { campaignCouponCodes } = this.props.campaign;
    const { coupon_series, coupon_codes } = campaignCouponCodes;
    const hasCouponCodes = !!campaignCouponCodes.coupon_series;
    const totalCouponCodeUses =
      coupon_codes &&
      coupon_series &&
      coupon_codes.length * coupon_series.cp_max_uses;
    const totalUsesLeft =
      coupon_codes &&
      coupon_series &&
      coupon_codes.reduce(
        (a, c) => (a += c.cc_uses_left * coupon_series.cp_max_uses),
        0
      );
    const showCouponDeleteButton = totalUsesLeft === totalCouponCodeUses;
    const headerText = campaign_id
      ? T(`Muokkaa etukuponkia #${campaign_id}`)
      : T('Lisää uusi etukuponki');
    const formNotValid =
      !cp_title ||
      !cp_description ||
      (this.state.generateCodes &&
        (!this.state.description ||
          isNaN(parseInt(this.state.number_of_codes, 10)) ||
          parseInt(this.state.number_of_codes, 10) === 0 ||
          parseInt(this.state.number_of_codes, 10) > 1000)) ||
      (cp_requires_validation &&
        (isNaN(parseInt(this.state.cp_max_per_device, 10)) ||
          parseInt(this.state.cp_max_per_device, 10) === 0));

    return (
      <Segment>
        <Label attached="top">{headerText}</Label>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              loading={isWaiting}
              fluid="true"
              required
              label={T('Kupongin otsikkoteksti')}
              placeholder={T('Syötä kupongin "iskulause" tähän')}
              name="cp_title"
              value={cp_title}
              onChange={this.handleChange}
            />
            <Form.Select
              disabled={this.state.generateCodes}
              loading={isWaiting}
              selection
              clearable
              name="cp_campaigncode"
              label={T('Kampanja(koodi)')}
              options={campaigncode_options}
              placeholder={T('Valitse...')}
              onChange={this.handleChange}
              value={cp_campaigncode}
            />
            {campaigncategory_options && (
              <Form.Select
                loading={isWaiting}
                fluid
                selection
                clearable
                label={T('Ryhmittely (ei käytössä sovelluksessa vielä)')}
                options={campaigncategory_options}
                placeholder={T('Valitse...')}
                onChange={this.handleChange}
                name="cp_campaigncategory"
                value={
                  !this.state.generateCodes ? cp_campaigncategory : undefined
                }
              />
            )}
          </Form.Group>
          {!cp_campaigncode &&
            !campaign_id &&
            (isWaiting ? (
              <Form.Field>
                <Loader active inline size="tiny" />
              </Form.Field>
            ) : (
              <>
                <Form.Checkbox
                  toggle
                  name="generateCodes"
                  checked={this.state.generateCodes}
                  onChange={this.handleChange}
                  label={T(
                    'Edun näkyminen sovelluksessa vaatii kertakäyttökoodin'
                  )}
                />
                {this.state.generateCodes && (
                  <Grid columns={1} celled>
                    <Grid.Row>
                      <Grid.Column>
                        <Label attached="top">{T('Generoi koodisarja')}</Label>
                        <Form.Group widths="equal">
                          <Form.Input
                            loading={isWaiting}
                            fluid="true"
                            required
                            label={T(
                              'Kuvaus (näkyy käyttäjälle sovelluksessa)'
                            )}
                            placeholder={T('Kirjoita kuvaus tähän')}
                            name="description"
                            value={this.state.description}
                            type="text"
                            onChange={this.handleChange}
                          />
                          <Form.Input
                            loading={isWaiting}
                            fluid="true"
                            required
                            label={T('Montako koodia haluat luoda?')}
                            placeholder={T('Kirjoita lukumäärä tähän')}
                            name="number_of_codes"
                            value={this.state.number_of_codes}
                            type="number"
                            step={1}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )}
              </>
            ))}
          {!cp_campaigncode && campaign_id && (
            <Form.Group>
              {hasCouponCodes ? (
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>{T('Description')}</Table.HeaderCell>
                      <Table.HeaderCell>
                        {T('Kuponkikoodeja yht')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>{T('Syöttämättä')}</Table.HeaderCell>
                      <Table.HeaderCell />
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        {coupon_series && coupon_series.cp_description}
                      </Table.Cell>
                      <Table.Cell>{totalCouponCodeUses}</Table.Cell>
                      <Table.Cell>{totalUsesLeft}</Table.Cell>
                      <Table.Cell textAlign="right">
                        {showCouponDeleteButton && (
                          <Button
                            disabled={isWaiting}
                            icon="delete"
                            negative
                            onClick={() =>
                              this.deleteCampaignCouponCodes({
                                coupon_series_id:
                                  coupon_series.coupon_series_id,
                              })
                            }
                          >
                            {T('Delete')}
                          </Button>
                        )}
                        <Button
                          disabled={isWaiting}
                          icon="file excel"
                          label={T('Vie XLS')}
                          onClick={() => this.downloadCouponCodes('xls')}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Header>
                </Table>
              ) : (
                <Button.Group>
                  <Form.Button
                    disabled={isWaiting || shopsFetchStatus !== 'success'}
                    loading={isWaiting || shopsFetchStatus !== 'success'}
                    primary
                    onClick={() => this.setState({ addModalOpen: true })}
                  >
                    <Icon name="random" />
                    {T('Generoi kuponkikoodisarja')}
                  </Form.Button>
                </Button.Group>
              )}
            </Form.Group>
          )}
          <Grid columns={shop_options ? 2 : 1} celled>
            <Grid.Row>
              <Grid.Column>
                <Label attached="top">{T('Edun kuva')}</Label>
                <Form.Field>
                  {isWaiting ? (
                    <Loader active inline />
                  ) : (
                    <FileUpload
                      id={cnc_application}
                      key={`file_upload_${campaign_id}`}
                      file_key={`cp_img_${campaign_id}`}
                      file_url={file_url}
                      file_id={file_id}
                      ws_id={node_collection_id}
                      target={'campaign'}
                      module_id={MODULE_ID}
                      callback={this.imageUpdate}
                      deletecallback={this.imageDelete}
                      style={imageStyle}
                    />
                  )}
                </Form.Field>
              </Grid.Column>
              {shop_options && (
                <Grid.Column>
                  <Label attached="top">
                    {T('Kiinnitetty kauppaan')}{' '}
                    <Popup
                      content={T(
                        'Jätä tyhjäksi tai valitse lunastuspaikka. Se näkyy kupongilla etu-otsikon yläpuolella.'
                      )}
                      trigger={<Icon name="question circle" />}
                    />
                  </Label>
                  <Form.Select
                    loading={shopsFetchStatus !== 'success'}
                    disabled={isWaiting}
                    label={T('Kauppa')}
                    selection
                    clearable
                    name="cp_shop"
                    options={shop_options}
                    placeholder={
                      shopsFetchStatus !== 'success'
                        ? T('Ladataan...')
                        : T('Valitse...')
                    }
                    onChange={this.handleChange}
                    value={cp_shop}
                  />

                  <p>
                    {cp_company_image &&
                      T(
                        'Kuva kaupan tiedoista. Näkyy sovelluksena kampanjakuvana, jos sitä ei ole syötetty erikseen.'
                      )}
                  </p>
                  <Image
                    bordered
                    floated="left"
                    size="small"
                    src={cp_company_image}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
          <Form.Group widths="equal">
            <Form.TextArea
              fluid="true"
              required
              rows={4}
              name="cp_description"
              label={T('Pitkä kuvaus')}
              placeholder={T('Syötä kupongin leipäteksti tähän')}
              value={cp_description}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group widths="4">
            <Form.Input
              loading={isWaiting}
              fluid="true"
              label={T('Etuhinnan kuvaus tekstinä')}
              placeholder={T('-20 %')}
              name="cp_pricetext"
              value={cp_pricetext}
              onChange={this.handleChange}
            />
            <Form.Input
              type="text"
              loading={isWaiting}
              fluid="true"
              label={T(
                'Lisäteksti etuhinnan alle (esim. -20 % tai Norm. 23,40 €)'
              )}
              placeholder={T('Norm. 123,40 €')}
              name="cp_regular_price_text"
              value={cp_regular_price_text}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group>
            {isWaiting ? (
              <Form.Field>
                <Loader active inline size="tiny" />
              </Form.Field>
            ) : (
              <Form.Checkbox
                toggle
                loading={isWaiting}
                name={'cp_requires_validation'}
                checked={cp_requires_validation}
                label={T('Pyytää mitätöintiä sovelluksessa ("slaideri")')}
                onChange={this.handleChange}
              />
            )}
          </Form.Group>
          {cp_requires_validation && (
            <Grid columns={1} celled>
              <Grid.Row>
                <Grid.Column>
                  <Label attached="top">
                    {T('Rajoitukset')}{' '}
                    <Popup
                      content={T(
                        'Syötä tähän kenttään kuinka monta kertaa etutiketin voi mitätöidä per laite. Mitätönti = "slaiderin" pyyhkäisy, jonka jälkeen tiketin tausta alkaa vaihdella värejä. Värien vaihtuminen jatkuu 5 minuuttia, jonka jälkeen slaideri ilmestyy takaisin.'
                      )}
                      trigger={<Icon name="question circle" />}
                    />
                  </Label>
                  <Form.Group widths="equal">
                    <Form.Input
                      loading={isWaiting}
                      step={1}
                      fluid="true"
                      required
                      label={T('Montako mitätöintiä per laite')}
                      placeholder={T('Syötä tähän numero')}
                      name="cp_max_per_device"
                      value={cp_max_per_device}
                      type="number"
                      onChange={this.handleChange}
                    />
                    <Form.Input
                      loading={isWaiting}
                      fluid="true"
                      label={T(
                        'Montako etua yht. kaikille sovelluksen käyttäjille, 0 = ei rajaa'
                      )}
                      placeholder={T('Syötä tähän numero tai 0')}
                      name="cp_max_per_campaign"
                      value={cp_max_per_campaign}
                      type="number"
                      step={1}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
          <Grid columns={1} celled>
            <Grid.Row>
              <Grid.Column>
                <Label attached="top">
                  {T('Kupongin näkyminen ja voimassaoloaika')}
                </Label>
                <Form.Group widths={4}>
                  <DateInput
                    loading={isWaiting}
                    closable
                    clearable
                    label={T('Kuponki alkaa näkyä sovelluksessa (keskiyöllä)')}
                    animation="off"
                    name="edit_visible_from"
                    placeholder={T('Näkyy heti')}
                    value={edit_visible_from}
                    dateFormat="l"
                    onChange={this.handleChange}
                  />
                  <DateInput
                    loading={isWaiting}
                    closable
                    clearable
                    label={T(
                      'Kuponki lakkaa näkymästä sovelluksessa (päivän lopuksi)'
                    )}
                    animation="off"
                    name="edit_visible_to"
                    placeholder={T('Kun kampanja päättyy')}
                    value={edit_visible_to}
                    dateFormat="l"
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group widths={4}>
                  <DateInput
                    loading={isWaiting}
                    closable
                    clearable
                    label={T('Kupongin voimassaolo alkaa')}
                    animation="off"
                    name="edit_valid_from"
                    placeholder={T('Voimassa heti')}
                    value={edit_valid_from}
                    dateFormat="l"
                    onChange={this.handleChange}
                  />
                  <DateInput
                    loading={isWaiting}
                    closable
                    clearable
                    label={T('Kuponki lakkaa olemasta voimassa')}
                    animation="off"
                    name="edit_valid_to"
                    placeholder={T('Voimassa toistaiseksi')}
                    value={edit_valid_to}
                    dateFormat="l"
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Button.Group>
            <Form.Button
              disabled={
                isWaiting || shopsFetchStatus !== 'success' || formNotValid
              }
              loading={isWaiting || shopsFetchStatus !== 'success'}
              primary
              onClick={() => this.saveCampaign()}
            >
              <Icon name="save" />
              {T('Save')}
            </Form.Button>
            <Form.Button
              disabled={isWaiting}
              secondary
              onClick={() => this.props.history.goBack()}
            >
              <Icon name="cancel" />
              {T('Cancel')}
            </Form.Button>
          </Button.Group>
        </Form>
      </Segment>
    );
  }

  render() {
    // console.log('edit campaign', this.state);

    const {
      campaignStatus,
      deleteCampaignStatus,
      addCampaignStatus,
      editCampaignStatus,
      campaignscategorieStatus,
      campaigncodesStatus,
      campaignCouponCodesStatus,
      generateCampaignCouponCodesStatus,
      deleteCampaignCouponCodesStatus,
      error,
    } = this.props.campaign;

    let errorMsg = null;
    if (
      campaignStatus === 'error' ||
      deleteCampaignStatus === 'error' ||
      addCampaignStatus === 'error' ||
      editCampaignStatus === 'error'
    ) {
      const errorObj = getErrorMessage(error);
      errorMsg = (
        <Message
          negative
          onDismiss={() => {
            this.handleDismiss();
          }}
          icon="exclamation triangle"
          header={`${T('Error')} ${errorObj.status}`}
          content={T(errorObj.message)}
        />
      );
    }

    const isWaiting =
      campaignStatus === 'waiting' ||
      deleteCampaignStatus === 'waiting' ||
      addCampaignStatus === 'waiting' ||
      editCampaignStatus === 'waiting' ||
      campaignscategorieStatus === 'waiting' ||
      campaigncodesStatus === 'waiting' ||
      campaignCouponCodesStatus === 'waiting' ||
      generateCampaignCouponCodesStatus === 'waiting' ||
      deleteCampaignCouponCodesStatus === 'waiting';

    return (
      <>
        {errorMsg}
        {this.renderEditCampaign(isWaiting)}
        <Modal
          size="mini"
          open={this.state.addModalOpen}
          onClose={() => this.setState({ addModalOpen: false })}
        >
          <Modal.Header>{T('Luo kuponkikoodisarja')}</Modal.Header>
          <Modal.Content>
            <Form.Input
              loading={isWaiting}
              fluid
              label={T('Kuvaus (näkyy käyttäjälle sovelluksessa)')}
              placeholder={T('Kirjoita kuvaus tähän')}
              name="description"
              value={this.state.description}
              type="text"
              onChange={this.handleChange}
            />
            <Form.Input
              loading={isWaiting}
              fluid
              label={T('Montako koodia haluat luoda?')}
              placeholder={T('Kirjoita lukumäärä tähän')}
              name="number_of_codes"
              value={this.state.number_of_codes}
              type="number"
              step={1}
              onChange={this.handleChange}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              disabled={
                isWaiting ||
                !this.state.description ||
                parseInt(this.state.number_of_codes, 10) === 0 ||
                parseInt(this.state.number_of_codes, 10) > 1000
              }
              onClick={() => {
                const authKey = localStorage.getItem('x-auth-key');
                const { activeWorkspace } = this.props;
                const { node_collection_id } = activeWorkspace;
                const { campaign } = this.props.campaign.campaign;
                this.props.generateCampaignCouponCodes({
                  authKey,
                  ws_id: node_collection_id,
                  campaign_id: campaign.campaign_id,
                  n_codes: parseInt(this.state.number_of_codes, 10),
                  description: this.state.description,
                  max_uses: 1,
                  module_id: MODULE_ID,
                });
              }}
            >
              {T('Luo koodit')}
            </Button>
            <Button
              secondary
              disabled={isWaiting}
              onClick={() => this.setState({ addModalOpen: false })}
            >
              {T('Peruuta')}
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

function mapStateToProps({ campaign, auth, shop, file, workspace }) {
  return {
    campaign: campaign,
    auth,
    workspace,
    shop,
    file,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
  };
}
export default connect(mapStateToProps, {
  fetchCampaign,
  resetFetchCampaign,
  addCampaign,
  resetAddCampaign,
  editCampaign,
  resetEditCampaign,
  resetDeleteCampaign,
  fetchCampaigncategories,
  fetchCampaigncodes,
  fetchShops,
  fetchCampaignCouponCodes,
  generateCampaignCouponCodes,
  resetGenerateCampaignCouponCodes,
  downloadCampaignCouponCodes,
  deleteCampaignCouponCodes,
  resetDeleteCampaignCouponCodes,
})(withRouter(Campaign));
