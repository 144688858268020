import axios from 'axios';
import config from '../../Config/AEConfig';
import {
  FETCH_CMS_CONTENT_SUCCESS,
  FETCH_CMS_CONTENT_WAITING,
  FETCH_CMS_CONTENT_ERROR,
  FETCH_CMS_CONTENT_RESET,
  ADD_CMS_CONTENT_WAITING,
  ADD_CMS_CONTENT_SUCCESS,
  ADD_CMS_CONTENT_ERROR,
  ADD_CMS_CONTENT_RESET,
  EDIT_CMS_CONTENT_WAITING,
  EDIT_CMS_CONTENT_SUCCESS,
  EDIT_CMS_CONTENT_ERROR,
  EDIT_CMS_CONTENT_RESET,
  DELETE_CMS_CONTENT_WAITING,
  DELETE_CMS_CONTENT_SUCCESS,
  DELETE_CMS_CONTENT_ERROR,
  DELETE_CMS_CONTENT_RESET,
  CMS_CONTENT_TYPES_WAITING,
  CMS_CONTENT_TYPES_SUCCESS,
  CMS_CONTENT_TYPES_ERROR,
  CMS_CONTENT_TYPES_RESET,
  FETCH_CMS_CONTENT_CHILDREN_WAITING,
  FETCH_CMS_CONTENT_CHILDREN_SUCCESS,
  FETCH_CMS_CONTENT_CHILDREN_ERROR,
  FETCH_CMS_CONTENT_CHILDREN_RESET,
  SAVE_CMS_CONTENTS_WAITING,
  SAVE_CMS_CONTENTS_SUCCESS,
  SAVE_CMS_CONTENTS_ERROR,
  SAVE_CMS_CONTENTS_RESET,
  DELETE_HAND_PROGRAM_WAITING,
  DELETE_HAND_PROGRAM_SUCCESS,
  DELETE_HAND_PROGRAM_ERROR,
  DELETE_HAND_PROGRAM_RESET,
  // ERROR_GATEWAY_TIMEOUT,
} from '../Types';
import ErrorAction from './ErrorAction';

let MODULE_ID = 15;

export const fetchCmsContents =
  ({
    authKey,
    ws_id,
    sortByColumn,
    dirn,
    start,
    searchterm,
    contentCategory,
    contentId,
    cm_content_type,
  }) =>
  (dispatch) => {
    dispatch({ type: FETCH_CMS_CONTENT_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/cmscontents/${contentCategory}/${contentId}`;
    let sort;
    if (sortByColumn && dirn) {
      sort = `${sortByColumn}:${dirn}`;
    } else if (sortByColumn) {
      sort = sortByColumn;
    }

    // handle module ids for different content categories
    switch (contentCategory) {
      case 'performer':
        MODULE_ID = 4;
        break;
      case 'event':
        MODULE_ID = 3;
        break;
      case 'place':
        MODULE_ID = 9;
        break;
      default:
        MODULE_ID = 15;
    }

    axios
      .get(url, {
        params: {
          start,
          searchterm,
          sort,
          ws_id,
          module_id: MODULE_ID,
          cm_content_type,
        },
      })
      .then((response) => {
        dispatch({
          type: FETCH_CMS_CONTENT_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_CMS_CONTENT_ERROR)
          : FETCH_CMS_CONTENT_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const saveCmsContents =
  (authKey, contents, contentType, ws_id) => (dispatch) => {
    dispatch({ type: SAVE_CMS_CONTENTS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/cmscontents/${contentType}?ws_id=${ws_id}&module_id=${MODULE_ID}`;
    axios
      .post(url, contents)
      .then((response) => {
        dispatch({
          type: SAVE_CMS_CONTENTS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, SAVE_CMS_CONTENTS_ERROR)
          : SAVE_CMS_CONTENTS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchCmsChildContents =
  ({ authKey, parentId, ws_id }) =>
  (dispatch) => {
    dispatch({ type: FETCH_CMS_CONTENT_CHILDREN_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/cmscontents/parent/${parentId}?ws_id=${ws_id}&module_id=${MODULE_ID}`;
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_CMS_CONTENT_CHILDREN_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_CMS_CONTENT_CHILDREN_ERROR)
          : FETCH_CMS_CONTENT_CHILDREN_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const addCmsContent = (content, authKey, ws_id) => (dispatch) => {
  // const url = `${config.BASE_URL}/cmscontent?module_id=${MODULE_ID}`;
  /**
   * The url was edited and now permissions are checked on the backend
   * without ws_id or module_id, Only parent id is required during edit
   **/
  const url = `${config.BASE_URL}/cmscontents?ws_id=${ws_id}&module_id=${MODULE_ID}`;
  axios.defaults.headers.common['x-auth-key'] = authKey;
  dispatch({ type: ADD_CMS_CONTENT_WAITING });
  axios
    .post(url, content)
    .then((response) => {
      dispatch({
        type: ADD_CMS_CONTENT_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      });
    })
    .catch((error) => {
      const type = error.response
        ? ErrorAction(error.response.status, ADD_CMS_CONTENT_ERROR)
        : ADD_CMS_CONTENT_ERROR;
      dispatch({ type, payload: error });
    });
};

export const editCmsContent =
  (parentId, content, authKey, ws_id) => (dispatch) => {
    // console.log('Edit Content', contentId, content);
    dispatch({ type: EDIT_CMS_CONTENT_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    // const url = `${config.BASE_URL}/cmscontent/${contentId}?module_id=${MODULE_ID}`;
    /**
     * The url was edited and now permissions are checked on the backend
     * without ws_id or module_id, Only parent id is required during edit
     **/
    const url = `${config.BASE_URL}/cmscontents/${parentId}?ws_id=${ws_id}&module_id=${MODULE_ID}`;
    axios
      .put(url, content)
      .then((response) => {
        dispatch({
          type: EDIT_CMS_CONTENT_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_CMS_CONTENT_ERROR)
          : EDIT_CMS_CONTENT_ERROR;
        dispatch({ type, payload: error });
      });
  };

//export const deleteCmsContent = (contentId, authKey) => (dispatch) => {
export const deleteCmsContent = (contentId, authKey, ws_id) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    dispatch({ type: DELETE_CMS_CONTENT_WAITING });
    axios
      .delete(
        `${config.BASE_URL}/cmscontents/${contentId}?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) =>
        dispatch({
          type: DELETE_CMS_CONTENT_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, DELETE_CMS_CONTENT_ERROR)
          : DELETE_CMS_CONTENT_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const fetchCmsContentTypes = (authKey, type, ws_id) => (dispatch) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  dispatch({ type: CMS_CONTENT_TYPES_WAITING });
  const url = `${config.BASE_URL}/cmscontenttypes/${type}?ws_id=${ws_id}&module_id=${MODULE_ID}`;
  axios
    .get(url)
    .then((response) =>
      dispatch({
        type: CMS_CONTENT_TYPES_SUCCESS,
        payload: {
          data: response.data,
          headers: JSON.parse(JSON.stringify(response?.headers || {})),
        },
      })
    )
    .catch((error) => {
      const payloadType = error.response
        ? ErrorAction(error.response.status, CMS_CONTENT_TYPES_ERROR)
        : CMS_CONTENT_TYPES_ERROR;
      dispatch({ type: payloadType, payload: error });
    });
};

export const deleteHandprogram = (contentId, authKey, ws_id) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  // The reducer is different from the cms content hence the duplicate
  return (dispatch) => {
    dispatch({ type: DELETE_HAND_PROGRAM_WAITING });
    axios
      .delete(
        `${config.BASE_URL}/cmscontents/${contentId}?ws_id=${ws_id}&module_id=${MODULE_ID}`
      )
      .then((response) =>
        dispatch({
          type: DELETE_HAND_PROGRAM_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, DELETE_HAND_PROGRAM_ERROR)
          : DELETE_HAND_PROGRAM_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const resetCmsContents = () => ({ type: FETCH_CMS_CONTENT_RESET });
export const resetSaveCmsContents = () => ({ type: SAVE_CMS_CONTENTS_RESET });
export const addCmsContentReset = () => ({ type: ADD_CMS_CONTENT_RESET });
export const editCmsContentReset = () => ({ type: EDIT_CMS_CONTENT_RESET });
export const deleteCmsContentReset = () => ({ type: DELETE_CMS_CONTENT_RESET });
export const deleteHandprogramReset = () => ({
  type: DELETE_HAND_PROGRAM_RESET,
});
export const resetCmsContentTypes = () => ({ type: CMS_CONTENT_TYPES_RESET });
export const resetCmsContentChildren = () => ({
  type: FETCH_CMS_CONTENT_CHILDREN_RESET,
});
