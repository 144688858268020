import _ from 'lodash';
import {
  FETCH_WORKSPACES_SUCCESS,
  FETCH_WORKSPACES_WAITING,
  FETCH_WORKSPACES_ERROR,
  FETCH_WORKSPACES_RESET,
  ADD_WORKSPACES_SUCCESS,
  ADD_WORKSPACES_WAITING,
  ADD_WORKSPACES_ERROR,
  ADD_WORKSPACES_RESET,
  FETCH_WORKSPACE_MODULES_WAITING,
  FETCH_WORKSPACE_MODULES_SUCCESS,
  FETCH_WORKSPACE_MODULES_ERROR,
  FETCH_WORKSPACE_MODULES_RESET,
  SET_ACTIVE_WORKSPACE,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  operationState: {
    add: 'init',
    delete: 'init',
    update: 'init',
    fetch: 'init',
    fetchAll: 'init',
    fetchModules: 'init',
  },
  workspaces: [],
  workspace: {},
  workspaceModules: {},
  activeWorkspace: {},
};

export default function WorkspaceReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_WORKSPACES_WAITING: {
      // const operationState = { ...state.operationState, fetchAll: 'waiting' };
      return { ...INIT_STATE, fetchAll: 'waiting' };
    }
    case FETCH_WORKSPACES_SUCCESS: {
      let workspaces = action.payload.data;
      if (workspaces.length === 0) {
        const operationState = { ...state.operationState, fetchAll: 'error' };
        return {
          ...state,
          operationState,
          workspaces: [],
          error: 'No workspaces defined',
        };
      }

      // workspaces = _.map(workspaces, workspace => workspace);
      workspaces = _.mapKeys(workspaces, 'node_collection_id');
      // const workspaceIds = Object.keys(workspaces);
      // const key = workspaceIds.length ? workspaceIds[0] : null;
      // const activeWs = key ? workspaces[key] : null;
      // let name = localStorage.getItem('active-workspace-name');
      let id = localStorage.getItem('active-workspace-id');
      // let contextId = localStorage.getItem('active-workspace-context_id');

      id = id && parseInt(id, 10); // || (activeWs ? activeWs.node_collection_id : null);
      // name = name || (activeWs ? activeWs.cnc_name : null);
      // contextId = (contextId && parseInt(contextId, 10)) || (activeWs ? activeWs.cnc_context : null);
      console.log('localStorage workspace id ', id);
      let find = action.payload.data.find((ws) => ws.node_collection_id === id);
      if (!find) {
        find = action.payload.data[0];
      }

      const { cnc_name, node_collection_id, cnc_context } = find;

      localStorage.setItem('active-workspace-name', cnc_name);
      localStorage.setItem('active-workspace-id', node_collection_id);
      localStorage.setItem('active-workspace-context_id', cnc_context);

      const activeWorkspace = {
        ...find,
        id: node_collection_id,
        name: cnc_name,
        contextId: cnc_context,
      };

      const operationState = { ...state.operationState, fetchAll: 'success' };
      const headers = action.payload.headers;
      const res = {
        ...state,
        operationState,
        headers,
        workspaces,
        ws_id: node_collection_id,
        activeWorkspace,
      };
      return res;
    }
    case FETCH_WORKSPACES_ERROR: {
      const operationState = { ...state.operationState, fetchAll: 'error' };
      return {
        ...state,
        operationState,
        workspaces: [],
        error: action.payload.error,
      };
    }
    case FETCH_WORKSPACES_RESET: {
      const operationState = { ...state.operationState, fetchAll: 'reset' };
      return { ...state, operationState, workspaces: [] };
    }

    case ADD_WORKSPACES_WAITING: {
      const operationState = { ...state.operationState, add: 'waiting' };
      return { ...state, operationState };
    }
    case ADD_WORKSPACES_SUCCESS: {
      const workspace = action.payload.data;
      const operationState = { ...state.operationState, add: 'success' };
      const headers = action.payload.headers;
      const res = { ...state, operationState, headers, workspace };
      return res;
    }
    case ADD_WORKSPACES_ERROR: {
      const operationState = { ...state.operationState, add: 'error' };
      return {
        ...state,
        operationState,
        workspace: {},
        error: action.payload.error,
      };
    }
    case ADD_WORKSPACES_RESET: {
      const operationState = { ...state.operationState, add: 'reset' };
      return { ...state, operationState, workspace: {} };
    }

    case FETCH_WORKSPACE_MODULES_WAITING:
      return { ...state, fetchModules: 'waiting' };
    case FETCH_WORKSPACE_MODULES_SUCCESS:
      return {
        ...state,
        workspaceModules: action.payload.data,
        fetchModules: 'success',
      };
    case FETCH_WORKSPACE_MODULES_ERROR:
      return { ...state, fetchModules: 'error', error: action.payload.error };
    case FETCH_WORKSPACE_MODULES_RESET:
      return { ...state, workspaceModules: {}, fetchModules: 'init' };

    case SET_ACTIVE_WORKSPACE: {
      const data = action.payload;

      let activeWorkspace = state.workspaces[data.id];
      const { cnc_name, node_collection_id, cnc_context } = activeWorkspace;
      activeWorkspace = {
        ...activeWorkspace,
        id: node_collection_id,
        name: cnc_name,
        contextId: cnc_context,
      };

      localStorage.setItem('active-workspace-name', cnc_name);
      localStorage.setItem('active-workspace-id', node_collection_id);
      localStorage.setItem('active-workspace-context_id', cnc_context);

      return {
        ...state,
        activeWorkspace,
        ws_id: node_collection_id,
        actionstatus: 'success',
      };
    }
    default:
      return state;
  }
}
