/* eslint-env browser */
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-semantic-toasts';
import {
  Table,
  Button,
  Icon,
  Menu,
  Pagination,
  Confirm,
  Header,
} from 'semantic-ui-react';
import {
  fetchPriceLists,
  deletePriceList,
  deletePricelistReset,
} from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import {
  T,
  StatusIcon,
  getErrorMessage,
  formatDateTime,
} from '../Common/Helpers';

class PriceLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDelete: false,
      sortByColumn: 'product_id',
      direction: 'asc',
    };

    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
  }

  componentDidMount() {
    // console.log('opening with props ', this.props);
    // localStorage.setItem('route', this.props.history.location.pathname);
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    const searchterm = null;
    const all = true;
    this.props.fetchPriceLists({ authKey, ws_id, searchterm, all });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.pricelist.plDeleteStatus === 'success' &&
      prevProps.pricelist.plDeleteStatus !== 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Price list deleted successfully'),
        time: 5000,
      });
      this.props.deletePricelistReset();
    }
    if (
      this.props.pricelist.plDeleteStatus === 'error' &&
      prevProps.pricelist.plDeleteStatus !== 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.pricelist.error,
        'Error occurred while deleting pricelist'
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.deletePricelistReset();
    }
  }

  onDelete(e, { value }) {
    this.setState({ confirmDelete: true, selectedPl: value });
  }

  onDeleteCancel() {
    this.setState({ confirmDelete: false });
  }

  onDeleteConfirm() {
    const { selectedPl } = this.state;
    if (selectedPl) {
      const authKey = localStorage.getItem('x-auth-key');
      this.props.deletePriceList(selectedPl, authKey, this.props.ws_id);
    }
    this.setState({ confirmDelete: false });
  }

  navigateToLink = (price_list_id) => {
    //this.props.history.push({ pathname: `/app/pricelist/manage/${plist.price_list_id}`, state: plist });
    this.props.history.push({
      pathname: `/app/pricelist/manage/${price_list_id}`,
    });
  };

  handlePaginationChange() {
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    const searchterm = null;
    const all = true;
    this.props.fetchPriceLists({ authKey, ws_id, searchterm, all });
  }

  renderPriceLists() {
    return _.map(this.props.pricelist.pricelists, (plist) => (
      <Table.Row
        key={plist.price_list_id}
        onClick={() => this.navigateToLink(plist.price_list_id)}
        style={{ cursor: 'pointer' }}
        className="tablerow"
      >
        <Table.Cell>{plist.price_list_id}</Table.Cell>
        <Table.Cell>{plist.pl_name}</Table.Cell>
        <Table.Cell>{plist.pl_description}</Table.Cell>
        <Table.Cell>{formatDateTime(plist.pl_valid_from)}</Table.Cell>
        <Table.Cell>{formatDateTime(plist.pl_valid_to)}</Table.Cell>
        <Table.Cell>
          <StatusIcon status={plist.pl_active} />
        </Table.Cell>
        <Table.Cell
          textAlign="right"
          singleLine
          onClick={(e) => e.stopPropagation()}
          style={{ cursor: 'default' }}
        >
          <Button
            color="red"
            type="button"
            icon="trash"
            value={plist.price_list_id}
            onClick={this.onDelete}
            content={T('Delete')}
          />
        </Table.Cell>
      </Table.Row>
    ));
  }

  render() {
    if (this.props.pricelist.plStatus === 'waiting') {
      return <Spinner />;
    }

    return (
      <React.Fragment>
        <Header> {T('Pricelist')} </Header>
        <Table sortable compact celled padded stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{T('Id')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Name')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Description')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Valid from')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Valid to')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Active')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderPriceLists()}
            <Table.Row>
              <Confirm
                header={T('Delete content')}
                content={T(
                  'Are you sure you want to delete the selected pricelist?'
                )}
                confirmButton={T('Delete')}
                cancelButton={T('Cancel')}
                open={this.state.confirmDelete}
                onCancel={this.onDeleteCancel}
                onConfirm={this.onDeleteConfirm}
                size="tiny"
              />
            </Table.Row>
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="7">
                <Link to="/app/pricelist/manage">
                  <Button icon="plus" primary content={T('Add')} />
                </Link>
                {this.props.pricelist.headers && (
                  <Menu floated="right" pagination>
                    <Pagination
                      onPageChange={this.handlePaginationChange}
                      defaultActivePage={
                        this.props.pricelist.headers.current_page
                      }
                      ellipsisItem={{
                        content: <Icon name="ellipsis horizontal" />,
                        icon: true,
                      }}
                      firstItem={{
                        content: <Icon name="angle double left" />,
                        icon: true,
                      }}
                      lastItem={{
                        content: <Icon name="angle double right" />,
                        icon: true,
                      }}
                      prevItem={{
                        content: <Icon name="angle left" />,
                        icon: true,
                      }}
                      nextItem={{
                        content: <Icon name="angle right" />,
                        icon: true,
                      }}
                      totalPages={this.props.pricelist.headers.total_pages}
                    />
                  </Menu>
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ auth, pricelist, workspace }) {
  return { auth, pricelist, ws_id: workspace.ws_id };
}

export default connect(mapStateToProps, {
  fetchPriceLists,
  deletePriceList,
  deletePricelistReset,
})(withRouter(PriceLists));
