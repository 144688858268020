import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Table, Button, Confirm } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { T } from '../Common/Helpers';
import { deleteHandprogram } from '../../Redux/actions';

class EventHandProgramDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      openDeleteConfirmation: false,
      content_id: -1,
    };
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
  }

  onConfirmDelete() {
    const { content_id } = this.state;
    const authKey = localStorage.getItem('x-auth-key');
    this.props.deleteHandprogram(content_id, authKey, this.props.ws_id);
    this.setState({ openDeleteConfirmation: false });
  }

  onDeleteClick = (e, content_id) => {
    e.stopPropagation();
    this.setState({ openDeleteConfirmation: true, content_id });
  };

  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false, content_id: -1 });
  }

  displayHandProgram = (index, props) => (
    <Table.Row key={index}>
      <Table.Cell>{props.id}</Table.Cell>
      <Table.Cell>{props.lang}</Table.Cell>
      <Table.Cell>{props.header}</Table.Cell>
      <Table.Cell>
        <a
          href={props.url}
          alt="hand program"
          rel="noopener noreferrer"
          target="_blank"
        >
          {props.url}
        </a>
      </Table.Cell>
      <Table.Cell collapsing singleLine>
        <Button
          style={{ marginLeft: 2 }}
          className="ui red button"
          key={`button-${props.id}`}
          type="button"
          content={T('Delete')}
          onClick={(e) => this.onDeleteClick(e, props.id)}
        />
      </Table.Cell>
    </Table.Row>
  );

  renderHandProgram = (handProgram) => {
    if (handProgram.cms_content_id !== undefined) {
      return (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{T('Id')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Language')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Short Header')}</Table.HeaderCell>
              <Table.HeaderCell>{T('URL')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(handProgram.children, (child, index) =>
              this.displayHandProgram(index, {
                id: child.cms_content_id,
                lang: child.cm_language,
                header: child.cm_short_header,
                url: child.cm_url,
              })
            )}
          </Table.Body>
        </Table>
      );
    }
  };
  render() {
    return (
      <>
        {this.renderHandProgram(this.props.handProgram[0])}
        <Confirm
          header={T('Delete hand program')}
          content={T('Are you sure you want to delete the hand program?')}
          confirmButton={T('Delete')}
          cancelButton={T('Cancel')}
          open={this.state.openDeleteConfirmation}
          onCancel={this.oncancelDeleteConfirmation}
          onConfirm={this.onConfirmDelete}
          size="tiny"
        />
      </>
    );
  }
}

EventHandProgramDisplay.propTypes = {
  handProgram: PropTypes.array.isRequired,
};

function mapStateToProps({ workspace }) {
  return {
    ws_id: workspace.ws_id,
  };
}

export default connect(mapStateToProps, { deleteHandprogram })(
  EventHandProgramDisplay
);
