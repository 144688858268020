import {
  FETCH_COUNTRY_WAITING,
  FETCH_COUNTRY_SUCCESS,
  FETCH_COUNTRY_ERROR,
  FETCH_COUNTRY_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  status: 'init',
};

export default function CountryReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_COUNTRY_WAITING:
      return { ...state, status: 'waiting' };
    case FETCH_COUNTRY_SUCCESS:
      return { ...state, countries: action.payload.data, status: 'success' };
    case FETCH_COUNTRY_ERROR:
      return { ...state, status: 'error', error: action.payload.error };
    case FETCH_COUNTRY_RESET:
      return { ...state, status: 'init' };
    default:
      return state;
  }
}
