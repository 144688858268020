import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { Spinner } from '../Common/Spinner';
import { T } from '../Common/Helpers';
import EventManageGame from './EventManageGame';
import {
  fetchDefaultTicketSettings,
  fetchCurrencies,
  fetchTaxes,
  fetchEvent,
  fetchPriceLists,
} from '../../Redux/actions';

const ALL_ITEMS = false;
// const TICKETING_MODULE_ID = 32;

class EventManageGameContainer extends Component {
  componentDidMount() {
    const authKey = localStorage.getItem('x-auth-key');
    const ws_id = this.props.workspace.activeWorkspace.id;

    const { match } = this.props;
    const { params } = match;
    if (match.path.indexOf('/match/edit') !== -1) {
      this.props.fetchEvent(authKey, params.eventId, ws_id);
    }

    const appId = this.props.workspace.workspaces[ws_id].cnc_application;
    const merchantId = this.props.workspace.workspaces[ws_id].cnc_company;
    const all_items = ALL_ITEMS;

    //const has_ticketing_module = this.has_ticketing_module(); // check if the user has permission for ticketing
    //console.log(has_ticketing_module);
    if (this.has_ticketing_module()) {
      this.props.fetchDefaultTicketSettings(
        authKey,
        appId,
        merchantId,
        ws_id,
        all_items
      );
    }

    if (this.props.auth.companyId && this.props.tax.fetchStatus !== 'success') {
      this.props.fetchTaxes({
        authKey,
        company_id: this.props.auth.companyId,
        ws_id,
      });
    }
    if (this.props.pricelist.plStatus !== 'success') {
      this.props.fetchPriceLists({ authKey, ws_id });
    }
    if (this.props.currencies.status !== 'success') {
      this.props.fetchCurrencies({ authKey, ws_id });
    }
    // console.log(this.props);
  }

  componentDidUpdate() {}

  has_ticketing_module() {
    const ticketing_modules = [];
    this.props.workspace.workspaceModules.forEach((module) => {
      if (
        module.module_id === 32 ||
        module.module_id === 33 ||
        module.module_id === 34
      ) {
        ticketing_modules.push(module.module_id);
      }
    });
    return ticketing_modules.length === 3;
  }

  navigateToSeason = (seasonId) => {
    // console.log('seasonId', seasonId);
    this.props.history.push({
      pathname: `/app/events/season/edit/${seasonId}`,
    });
  };

  render() {
    const { match } = this.props;
    const { params } = match;
    const matchToEdit =
      match.path.indexOf('/match/edit') !== -1 ? params.eventId : null;

    if (
      this.props.fetchAll === 'waiting' ||
      this.props.currencies.status === 'waiting' ||
      this.props.tax.fetchStatus === 'waiting' ||
      this.props.pricelist.plStatus === 'waiting' ||
      (matchToEdit && this.props.events.fetchStatus === 'waiting')
    ) {
      return <Spinner />;
    } else if (
      this.props.fetchAll === 'error' ||
      this.props.currencies.status === 'error' ||
      this.props.tax.fetchStatus === 'error' ||
      this.props.pricelist.plStatus === 'error' ||
      (matchToEdit && this.props.events.fetchStatus === 'error')
    ) {
      return (
        <Message error>
          <Message.Header>{T('Error')}</Message.Header>
          <p>{T('Error has occured, while fetching data')}</p>
        </Message>
      );
    }

    return (
      <>
        <Button
          type="button"
          basic
          icon="caret left"
          content={T('Back')}
          onClick={() => this.props.history.goBack()}
        />
        <EventManageGame
          {...this.props}
          haveTicketing={this.has_ticketing_module()}
          auth={this.props.auth}
          shop={this.props.shop}
          currencies={this.props.currencies}
          languages={this.props.languages}
          event={matchToEdit ? this.props.events.event : null}
          defaultTickets={this.props.defaultTickets}
          taxList={this.props.tax.data}
          reloadEvent={(event_id) => {
            const ws_id = this.props.workspace.activeWorkspace.id;
            const authKey = localStorage.getItem('x-auth-key');
            this.props.fetchEvent(authKey, event_id || matchToEdit, ws_id);
          }}
        />
      </>
    );
  }
}

function mapStateToProps({
  pricelist,
  languages,
  auth,
  tax,
  currencies,
  workspace,
  events,
  defaultTickets,
}) {
  return {
    pricelist,
    languages: languages.languages,
    fetchAll: defaultTickets.opStatus.fetchAll,
    defaultTickets: defaultTickets.settings,
    auth,
    tax,
    currencies,
    workspace,
    events,
  };
}

export default connect(mapStateToProps, {
  fetchDefaultTicketSettings,
  fetchCurrencies,
  fetchTaxes,
  fetchEvent,
  fetchPriceLists,
})(withRouter(EventManageGameContainer));
