/* eslint-env browser */
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Form,
  Checkbox,
  Table,
  Button,
  Icon,
  Menu,
  Pagination,
} from 'semantic-ui-react';
import { fetchPriceLists } from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import { T, StatusIcon } from '../Common/Helpers';

class PriceListMinimized extends Component {
  constructor(props) {
    super(props);
    const ids = this.props.selectedPriceLists.map((ele) => ele.price_list_id);
    this.state = {
      sortByColumn: 'product_id',
      direction: 'asc',
      selectedPriceListIds: ids,
      selectedPriceLists: this.props.selectedPriceLists,
      disableSelectedButton: null,
    };
    this.priceList = [];
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }
  componentDidMount() {
    // console.log('opening with props ', this.props);
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    this.props.fetchPriceLists({ authKey, ws_id });
  }

  handlePaginationChange() {
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    this.props.fetchPriceLists({ authKey, ws_id });
  }
  handlePriceListChange(e, pricelist) {
    const id = pricelist.price_list_id;
    if (this.state.selectedPriceListIds.indexOf(id) === -1) {
      this.setState({
        selectedPriceListIds: [
          ...this.state.selectedPriceListIds,
          pricelist.price_list_id,
        ],
        selectedPriceLists: [...this.state.selectedPriceLists, pricelist],
        disableSelectedButton: false,
      });
    } else {
      const selectedPriceListIds = this.state.selectedPriceListIds.filter(
        (price_list_id) => price_list_id !== id
      );
      const selectedPriceLists = this.state.selectedPriceLists.filter(
        (ele) => ele.price_list_id !== id
      );
      this.setState({
        selectedPriceListIds,
        selectedPriceLists,
        disableSelectedButton: false,
      });
    }
  }

  handleSelectedPriceList(pricelist) {
    this.props.setPriceList(pricelist);
    // this.handleLocationChange(null, pickup);
    this.props.closeModal();
  }
  renderPriceLists = () =>
    _.map(this.props.pricelists, (pl) => {
      const plist = {
        pl_active: pl.pl_active,
        pl_description: pl.pl_description,
        pl_name: pl.pl_name,
        pl_valid_from: pl.pl_valid_from,
        pl_valid_to: pl.pl_valid_to,
        pr_merchant_id: pl.pr_merchant_id,
        price_list_id: pl.price_list_id,
      };
      return (
        <Table.Row
          key={plist.price_list_id}
          style={{ cursor: 'pointer' }}
          onClick={(e) => this.handlePriceListChange(e, plist)}
        >
          <Table.Cell key={`${plist.price_list_id}-id`}>
            <Form.Field>
              <Checkbox
                label={plist.price_list_id}
                name="radioGroup"
                value={plist.price_list_id}
                checked={
                  this.state.selectedPriceListIds.indexOf(
                    plist.price_list_id
                  ) !== -1
                }
                // onChange={(e) => this.handlePriceListChange(e, plist)}
              />
            </Form.Field>
          </Table.Cell>
          <Table.Cell>{plist.pl_name}</Table.Cell>
          <Table.Cell>
            <StatusIcon status={plist.pl_active} />
          </Table.Cell>
        </Table.Row>
      );
    });

  render() {
    if (this.props.plStatus === 'waiting') {
      return <Spinner />;
    }

    return (
      <Table sortable compact celled padded striped stackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{T('Id')}</Table.HeaderCell>
            <Table.HeaderCell>{T('Name')}</Table.HeaderCell>
            <Table.HeaderCell>{T('Active')}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderPriceLists()}</Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="4">
              <Link to="/app/pricelist/manage">
                <Button primary content={T('Add New')} />
              </Link>
              {this.props.pricelists.headers && (
                <Menu floated="right" pagination>
                  <Pagination
                    onPageChange={this.handlePaginationChange}
                    defaultActivePage={this.props.headers.current_page}
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    totalPages={this.props.headers.total_pages}
                  />
                </Menu>
              )}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan="3">
              <Button
                color="green"
                content={T('Add selected pricelists')}
                onClick={() =>
                  this.handleSelectedPriceList(this.state.selectedPriceLists)
                }
                style={{ marginLeft: '10px' }}
              />
              <Button
                color="black"
                content={T('Cancel')}
                onClick={this.props.closeModal}
                style={{ marginLeft: '10px' }}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}

function mapStateToProps({ auth, pricelist, workspace }) {
  return {
    auth,
    headers: pricelist.headers,
    pricelists: pricelist.pricelists,
    plStatus: pricelist.plStatus,
    ws_id: workspace.ws_id,
  };
}

export default connect(mapStateToProps, { fetchPriceLists })(
  PriceListMinimized
);
