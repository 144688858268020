import axios from 'axios';
import config from '../../Config/AEConfig';

import {
  ADD_PERFORMERS_PLAYER_SUCCESS,
  ADD_PERFORMERS_PLAYER_ERROR,
  ADD_PERFORMERS_PLAYER_RESET,
  FETCH_PERFORMERS_PLAYERS_SUCCESS,
  FETCH_PERFORMERS_PLAYERS_ERROR,
  FETCH_PERFORMERS_PLAYERS_RESET,
  FETCH_PERFORMERS_PLAYERS_WAITING,
  FETCH_PERFORMERS_PLAYER_SUCCESS,
  FETCH_PERFORMERS_PLAYER_ERROR,
  FETCH_PERFORMERS_PLAYER_RESET,
  EDIT_PERFORMERS_PLAYER_SUCCESS,
  EDIT_PERFORMERS_PLAYER_ERROR,
  EDIT_PERFORMERS_PLAYER_RESET,
  DELETE_PERFORMERS_PLAYER_SUCCESS,
  DELETE_PERFORMERS_PLAYER_ERROR,
  DELETE_PERFORMERS_PLAYER_RESET,
  UPLOAD_PERFORMERS_PLAYER_SUCCESS,
  UPLOAD_PERFORMERS_PLAYER_ERROR,
  UPLOAD_PERFORMERS_PLAYER_RESET,
  UPLOAD_PERFORMERS_PLAYER_WAITING,
  FETCH_PERFORMER_METADATA_KEYS_SUCCESS,
  FETCH_PERFORMER_METADATA_KEYS_ERROR,
  ADD_PERFORMER_METADATA_SUCCESS,
  ADD_PERFORMER_METADATA_ERROR,
  ADD_PERFORMER_METADATA_RESET,
  DELETE_PERFORMER_METADATA_SUCCESS,
  DELETE_PERFORMER_METADATA_ERROR,
  DELETE_PERFORMER_METADATA_RESET,
  EDIT_PERFORMER_METADATA_SUCCESS,
  EDIT_PERFORMER_METADATA_ERROR,
  EDIT_PERFORMER_METADATA_RESET,
  TRANSFER_PLAYER_SUCCESS,
  TRANSFER_PLAYER_ERROR,
  TRANSER_PLAYER_RESET,
  EDIT_TRANSFER_PLAYER_SUCCESS,
  EDIT_TRANSFER_PLAYER_ERROR,
  EDIT_TRANSFER_PLAYER_RESET,
  DELETE_TRANSFER_CONNECTION_SUCCESS,
  DELETE_TRANSFER_CONNECTION_ERROR,
  RESET_DELETE_TRANSFER_CONNECTION,
  ADD_PERFORMER_CMS_CONTENT_WAITING,
  ADD_PERFORMER_CMS_CONTENT_SUCCESS,
  ADD_PERFORMER_CMS_CONTENT_ERROR,
  EDIT_PERFORMER_CMS_CONTENT_SUCCESS,
  EDIT_PERFORMER_CMS_CONTENT_ERROR,
  DELETE_PERFORMER_CMS_CONTENT_SUCCESS,
  DELETE_PERFORMER_CMS_CONTENT_ERROR,
  FETCH_PERFORMER_CMS_CONTENT_SUCCESS,
  FETCH_PERFORMER_CMS_CONTENT_ERROR,
  FETCH_PERFORMER_CMS_CONTENT_RESET,
  FETCH_PERFORMER_CMS_CONTENTS_SUCCESS,
  FETCH_PERFORMER_CMS_CONTENTS_ERROR,
  FETCH_CMS_CONTENT_TYPES_SUCCESS,
  FETCH_CMS_CONTENT_TYPES_ERROR,
  FETCH_CMS_CHILD_CONTENT_SUCCESS,
  FETCH_CMS_CHILD_CONTENT_ERROR,
  FETCH_CMS_CHILD_CONTENT_LIST_WAITING,
  FETCH_CMS_CHILD_CONTENT_LIST_SUCCESS,
  FETCH_CMS_CHILD_CONTENT_LIST_ERROR,
  FETCH_CMS_CHILD_CONTENT_LIST_RESET,
  FETCH_PERFORMER_PLAYER_TRANSFER_LIST_SUCCESS,
  FETCH_PERFORMER_PLAYER_TRANSFER_LIST_ERROR,
  FETCH_PERFORMER_PLAYER_METADATA_SUCCESS,
  FETCH_PERFORMER_PLAYER_METADATA_ERROR,
  DELETE_PERFORMER_PLAYER_IMAGE_WAITING,
  DELETE_PERFORMER_PLAYER_IMAGE_SUCCESS,
  DELETE_PERFORMER_PLAYER_IMAGE_ERROR,
  DELETE_PERFORMER_PLAYER_IMAGE_RESET,
  UPLOAD_PLAYER_TRANSFER_IMAGE_SUCCESS,
  UPLOAD_PLAYER_TRANSFER_IMAGE_ERROR,
  UPLOAD_PLAYER_TRANSFER_IMAGE_RESET,
  UPLOAD_PLAYER_TRANSFER_IMAGE_WAITING,
  FETCH_PLAYERS_LIST_FOR_LINEUP_SUCCESS,
  FETCH_PLAYERS_LIST_FOR_LINEUP_WAITING,
  FETCH_PLAYERS_LIST_FOR_LINEUP_ERROR,
  FETCH_PLAYERS_LIST_FOR_LINEUP_RESET,
} from '../Types';
import ErrorAction from './ErrorAction';

const MODULE_ID = '4';
const CMS_MODULE_ID = '15';

export function addPerformerPlayer(values, authKey, ws_id) {
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .post(
        `${config.BASE_URL}/performer?module_id=${MODULE_ID}&ws_id=${ws_id}`,
        values
      )
      .then((response) => {
        dispatch({
          type: ADD_PERFORMERS_PLAYER_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_PERFORMERS_PLAYER_ERROR)
          : ADD_PERFORMERS_PLAYER_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetAddPerformerPlayer() {
  return {
    type: ADD_PERFORMERS_PLAYER_RESET,
  };
}

export const fetchPerformerPlayersList =
  (
    authKey,
    ws_id,
    parentId,
    activePage,
    searchterm,
    sortByColumn,
    sortDirection
  ) =>
  (dispatch) => {
    dispatch({ type: FETCH_PERFORMERS_PLAYERS_WAITING });
    let url = null;
    axios.defaults.headers.common['x-auth-key'] = authKey;

    url = `${config.BASE_URL}/performers/parent/${parentId}?ws_id=${ws_id}`;
    if (activePage) {
      url += `&start=${activePage}&season=true&paging=false`;
    }
    if (searchterm) {
      url += `&searchterm=${searchterm}`;
    }

    if (sortByColumn && sortDirection) {
      url += `&sort=${sortByColumn}:${sortDirection}`;
    } else if (sortByColumn) {
      url += `&sort=${sortByColumn}`;
    }
    // const connector = getConnector(paramAdded);
    url += `&module_id=${MODULE_ID}`;
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_PERFORMERS_PLAYERS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_PERFORMERS_PLAYERS_ERROR)
          : FETCH_PERFORMERS_PLAYERS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export function resetFetchPerformerPlayersList() {
  return {
    type: FETCH_PERFORMERS_PLAYERS_RESET,
  };
}

/** Depending on passed parameters, this function returns either list of players of list of Teams */

export const fetchPerformerPlayers =
  (
    authKey,
    ws_id,
    parentId,
    activePage,
    searchterm,
    sortByColumn,
    sortDirection
  ) =>
  (dispatch) => {
    dispatch({ type: FETCH_PERFORMERS_PLAYERS_WAITING });
    let url = null;
    axios.defaults.headers.common['x-auth-key'] = authKey;
    if (!parentId) {
      url = `${config.BASE_URL}/performers/type/team?limit=100`;
      url += `&module_id=${MODULE_ID}&ws_id=${ws_id}`;
    } else {
      url = `${config.BASE_URL}/performers/parent/${parentId}?`;
      url += `&module_id=${MODULE_ID}&ws_id=${ws_id}`;
      if (activePage) {
        url += `start=${activePage}&season=true&`;
      }
      if (searchterm) {
        url += `searchterm=${searchterm}`;
      }
    }
    if (sortByColumn && sortDirection) {
      url += `&sort=${sortByColumn}:${sortDirection}`;
    } else if (sortByColumn) {
      url += `&sort=${sortByColumn}`;
    }
    // const connector = getConnector(paramAdded);
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_PERFORMERS_PLAYERS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_PERFORMERS_PLAYERS_ERROR)
          : FETCH_PERFORMERS_PLAYERS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export function resetFetchPerformerPlayers() {
  return {
    type: FETCH_PERFORMERS_PLAYERS_RESET,
  };
}

export const fetchPerformerPlayer =
  (authKey, performerId, ws_id) => (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    //axios.get(`${config.BASE_URL}/performers/type/player`)
    axios
      .get(
        `${config.BASE_URL}/performer/${performerId}?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) => {
        dispatch({
          type: FETCH_PERFORMERS_PLAYER_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_PERFORMERS_PLAYER_ERROR)
          : FETCH_PERFORMERS_PLAYER_ERROR;
        dispatch({ type, payload: error });
      });
  };

export function resetFetchPerformerPlayer() {
  return {
    type: FETCH_PERFORMERS_PLAYER_RESET,
  };
}

export function editPerformerPlayer(id, authKey, values, ws_id) {
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .put(
        `${config.BASE_URL}/performer/${id}?module_id=${MODULE_ID}&ws_id=${ws_id}`,
        values
      )
      .then((response) => {
        dispatch({
          type: EDIT_PERFORMERS_PLAYER_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_PERFORMERS_PLAYER_ERROR)
          : EDIT_PERFORMERS_PLAYER_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetEditPerformerPlayer() {
  return {
    type: EDIT_PERFORMERS_PLAYER_RESET,
  };
}

export function deletePerformerPlayer(id, authKey, ws_id) {
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .delete(
        `${config.BASE_URL}/performer/${id}?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) => {
        dispatch({
          type: DELETE_PERFORMERS_PLAYER_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, DELETE_PERFORMERS_PLAYER_ERROR)
          : DELETE_PERFORMERS_PLAYER_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetDeletePerformerPlayer() {
  return {
    type: DELETE_PERFORMERS_PLAYER_RESET,
  };
}

export function uploadPerformerPlayerImage(performerData, authKey, ws_id) {
  // const inputValues = { ...performerData, 'target': 'performer'}
  // console.log('image data ', performerData);
  return (dispatch) => {
    dispatch({ type: UPLOAD_PERFORMERS_PLAYER_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .post(
        `${config.BASE_URL}/upload?module_id=${MODULE_ID}&ws_id=${ws_id}`,
        performerData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      )
      .then((response) => {
        dispatch({
          type: UPLOAD_PERFORMERS_PLAYER_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, UPLOAD_PERFORMERS_PLAYER_ERROR)
          : UPLOAD_PERFORMERS_PLAYER_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetUploadPlayerImage() {
  return { type: UPLOAD_PERFORMERS_PLAYER_RESET };
}

export function uploadPlayerTranferImage(performerData, authKey, ws_id) {
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: UPLOAD_PLAYER_TRANSFER_IMAGE_WAITING });
    axios
      .post(
        `${config.BASE_URL}/upload?module_id=${MODULE_ID}&ws_id=${ws_id}`,
        performerData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      )
      .then((response) => {
        dispatch({
          type: UPLOAD_PLAYER_TRANSFER_IMAGE_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              UPLOAD_PLAYER_TRANSFER_IMAGE_ERROR
            )
          : UPLOAD_PLAYER_TRANSFER_IMAGE_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetUploadPlayerTranferImage() {
  return { type: UPLOAD_PLAYER_TRANSFER_IMAGE_RESET };
}

export function fetchPerformerMetadataKeys(authkey, ws_id) {
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authkey;
    axios
      .get(
        `${config.BASE_URL}/performer/metadatakeys?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) =>
        dispatch({
          type: FETCH_PERFORMER_METADATA_KEYS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              FETCH_PERFORMER_METADATA_KEYS_ERROR
            )
          : FETCH_PERFORMER_METADATA_KEYS_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function addPerformerMetadata(performerId, metadata, authkey, ws_id) {
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authkey;
    axios
      .post(
        `${config.BASE_URL}/performer/${performerId}/metadata?module_id=${MODULE_ID}&ws_id=${ws_id}`,
        metadata
      )
      .then((response) =>
        dispatch({
          type: ADD_PERFORMER_METADATA_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_PERFORMER_METADATA_ERROR)
          : ADD_PERFORMER_METADATA_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetaddPerformerMetadata() {
  return {
    type: ADD_PERFORMER_METADATA_RESET,
  };
}

export function deletePerformerMetadata(
  performerId,
  metadataId,
  authKey,
  ws_id
) {
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .delete(
        `${config.BASE_URL}/performer/${performerId}/metadata/${metadataId}?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) => {
        dispatch({
          type: DELETE_PERFORMER_METADATA_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, DELETE_PERFORMER_METADATA_ERROR)
          : DELETE_PERFORMER_METADATA_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetdeletePerformerMetadata() {
  return {
    type: DELETE_PERFORMER_METADATA_RESET,
  };
}

export function editPerformerMetadata(
  performerId,
  metadataId,
  metadata,
  authKey,
  ws_id
) {
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .put(
        `${config.BASE_URL}/performer/${performerId}/metadata/${metadataId}?ws_id=${ws_id}&module_id=${MODULE_ID}`,
        metadata
      )
      .then((response) => {
        dispatch({
          type: EDIT_PERFORMER_METADATA_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_PERFORMER_METADATA_ERROR)
          : EDIT_PERFORMER_METADATA_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetEditPerformerMetadata() {
  return {
    type: EDIT_PERFORMER_METADATA_RESET,
  };
}

export function transferPlayerToAnotherTeam(playerId, data, authKey, ws_id) {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    axios
      .post(
        `${config.BASE_URL}/performer/${playerId}/team?module_id=${MODULE_ID}&ws_id=${ws_id}`,
        data
      )
      .then((response) =>
        dispatch({
          type: TRANSFER_PLAYER_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, TRANSFER_PLAYER_ERROR)
          : TRANSFER_PLAYER_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetTransferPlayerToAnotherTeam() {
  return { type: TRANSER_PLAYER_RESET };
}

export function editTransferConnection(
  parentId,
  connectionId,
  data,
  authKey,
  approve = false,
  ws_id
) {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  let url = `${config.BASE_URL}/performer/${parentId}/team/${connectionId}`;
  if (approve) {
    url += '/approve';
  }
  url += `?module_id=${MODULE_ID}&ws_id=${ws_id}`;
  return (dispatch) => {
    axios
      .put(url, data)
      .then((response) =>
        dispatch({
          type: EDIT_TRANSFER_PLAYER_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_TRANSFER_PLAYER_ERROR)
          : EDIT_TRANSFER_PLAYER_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetEditTransferConnection() {
  return { type: EDIT_TRANSFER_PLAYER_RESET };
}

export function deleteTransferConnection(
  performerId,
  connectionId,
  authKey,
  ws_id
) {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    axios
      .delete(
        `${config.BASE_URL}/performer/${performerId}/team/${connectionId}?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) =>
        dispatch({
          type: DELETE_TRANSFER_CONNECTION_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, DELETE_TRANSFER_CONNECTION_ERROR)
          : DELETE_TRANSFER_CONNECTION_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetDeleteTransferConnection() {
  return { type: RESET_DELETE_TRANSFER_CONNECTION };
}

export function addPerformerCMSContent(content, authKey, ws_id) {
  // console.log('performer cms content adding ...', content);
  // return;
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    dispatch({ type: ADD_PERFORMER_CMS_CONTENT_WAITING });
    axios
      .post(
        `${config.BASE_URL}/cmscontent?module_id=${MODULE_ID}&ws_id=${ws_id}`,
        content
      )
      .then((response) =>
        dispatch({
          type: ADD_PERFORMER_CMS_CONTENT_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        console.log('Error: ', error);
        const type = error.response
          ? ErrorAction(error.response.status, ADD_PERFORMER_CMS_CONTENT_ERROR)
          : ADD_PERFORMER_CMS_CONTENT_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function editPerformerCMSContent(contentId, content, authKey, ws_id) {
  // console.log('performer cms content editing ...', contentId, content);
  // return;
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    axios
      .put(
        `${config.BASE_URL}/cmscontent/${contentId}?module_id=${MODULE_ID}&ws_id=${ws_id}`,
        content
      )
      .then((response) =>
        dispatch({
          type: EDIT_PERFORMER_CMS_CONTENT_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_PERFORMER_CMS_CONTENT_ERROR)
          : EDIT_PERFORMER_CMS_CONTENT_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function fetchPerformerCMSContent(performerId, authKey, ws_id) {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    axios
      .get(
        `${config.BASE_URL}/performer/${performerId}/cms?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) =>
        dispatch({
          type: FETCH_PERFORMER_CMS_CONTENT_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              FETCH_PERFORMER_CMS_CONTENT_ERROR
            )
          : FETCH_PERFORMER_CMS_CONTENT_ERROR;
        dispatch({ type, payload: error });
      });
  };
}
export function fetchPerformerCMSContents(performerId, authKey, ws_id) {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    axios
      .get(
        `${config.BASE_URL}/cmscontents/performer/${performerId}?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) => {
        dispatch({
          type: FETCH_PERFORMER_CMS_CONTENTS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              FETCH_PERFORMER_CMS_CONTENTS_ERROR
            )
          : FETCH_PERFORMER_CMS_CONTENTS_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function fetchPerformerCMSChildContent(parentId, lang, authKey, ws_id) {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    axios
      .get(
        `${config.BASE_URL}/cmscontent/${parentId}?language=${lang}&module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) => {
        dispatch({
          type: FETCH_CMS_CHILD_CONTENT_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_CMS_CHILD_CONTENT_ERROR)
          : FETCH_CMS_CHILD_CONTENT_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function fetchPerformerCMSChildContentList(parentId, authKey, ws_id) {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    dispatch({ type: FETCH_CMS_CHILD_CONTENT_LIST_WAITING });
    axios
      .get(
        `${config.BASE_URL}/cmscontents/parent/${parentId}?module_id=${CMS_MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) => {
        dispatch({
          type: FETCH_CMS_CHILD_CONTENT_LIST_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              FETCH_CMS_CHILD_CONTENT_LIST_ERROR
            )
          : FETCH_CMS_CHILD_CONTENT_LIST_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetFetchCMSContentList() {
  return { type: FETCH_CMS_CHILD_CONTENT_LIST_RESET };
}

export function deletePerformerCMSContent(contentId, authKey, ws_id) {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    axios
      .delete(
        `${config.BASE_URL}/cmscontent/${contentId}?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) =>
        dispatch({
          type: DELETE_PERFORMER_CMS_CONTENT_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              DELETE_PERFORMER_CMS_CONTENT_ERROR
            )
          : DELETE_PERFORMER_CMS_CONTENT_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetPerformerCMSContent() {
  return { type: FETCH_PERFORMER_CMS_CONTENT_RESET };
}

export function fetchPerformerCMSContentTypes(authKey, ws_id) {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    axios
      .get(
        `${config.BASE_URL}/cmscontenttypes/performer?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) =>
        dispatch({
          type: FETCH_CMS_CONTENT_TYPES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_CMS_CONTENT_TYPES_ERROR)
          : FETCH_CMS_CONTENT_TYPES_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export const fetchPerformerPlayerTransferList =
  (performerId, authKey, ws_id, isTeam = false, start) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    let url =
      `${config.BASE_URL}/performer/transfers/${performerId}?team=${isTeam}&ws_id=${ws_id}` +
      `&module_id=${MODULE_ID}`;
    if (start) {
      url += `&start=${start}`;
    }
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_PERFORMER_PLAYER_TRANSFER_LIST_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              FETCH_PERFORMER_PLAYER_TRANSFER_LIST_ERROR
            )
          : FETCH_PERFORMER_PLAYER_TRANSFER_LIST_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const fetchPerformerPlayerMetadata =
  (performerId, authKey, ws_id) => (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .get(
        `${config.BASE_URL}/performer/${performerId}/metadatas?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) => {
        dispatch({
          type: FETCH_PERFORMER_PLAYER_METADATA_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              FETCH_PERFORMER_PLAYER_METADATA_ERROR
            )
          : FETCH_PERFORMER_PLAYER_METADATA_ERROR;
        dispatch({ type, payload: error });
      });
  };

export function deletePerformerPlayerImage(performerId, url, authKey, ws_id) {
  return (dispatch) => {
    dispatch({ type: DELETE_PERFORMER_PLAYER_IMAGE_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const data = { url, target: 'performer' };
    axios
      .delete(
        `${config.BASE_URL}/file/${performerId}?module_id=${MODULE_ID}&ws_id=${ws_id}`,
        {
          data,
        }
      )
      .then((response) => {
        dispatch({
          type: DELETE_PERFORMER_PLAYER_IMAGE_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              DELETE_PERFORMER_PLAYER_IMAGE_ERROR
            )
          : DELETE_PERFORMER_PLAYER_IMAGE_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetDeletePerformerPlayerImage() {
  return { type: DELETE_PERFORMER_PLAYER_IMAGE_RESET };
}

export function getPlayersListForLineUp(teamId, authKey, ws_id) {
  return (dispatch) => {
    dispatch({ type: FETCH_PLAYERS_LIST_FOR_LINEUP_WAITING });
    let url = null;
    url = `${config.BASE_URL}/performers/parent/${teamId}`;
    url += `?module_id=${MODULE_ID}&ws_id=${ws_id}`;
    // axios.get(url)
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .get(url)
      .then((response) =>
        dispatch({
          type: FETCH_PLAYERS_LIST_FOR_LINEUP_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              FETCH_PLAYERS_LIST_FOR_LINEUP_ERROR
            )
          : FETCH_PLAYERS_LIST_FOR_LINEUP_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetGetPlayersListForLineUp() {
  return { type: FETCH_PLAYERS_LIST_FOR_LINEUP_RESET };
}
