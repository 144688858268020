import {
  FETCH_APP_STATISTICS_WAITING,
  FETCH_APP_STATISTICS_SUCCESS,
  FETCH_APP_STATISTICS_ERROR,
  FETCH_APP_STATISTICS_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  appStatisticStatus: 'init',
};

export default function StatisticReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_APP_STATISTICS_WAITING:
      return { ...state, appStatisticStatus: 'waiting' };
    case FETCH_APP_STATISTICS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        appStatisticStatus: 'success',
      };
    case FETCH_APP_STATISTICS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        appStatisticStatus: 'error',
      };
    case FETCH_APP_STATISTICS_RESET:
      return {
        ...state,
        data: null,
        message: undefined,
        appStatisticStatus: 'init',
      };
    default:
      return state;
  }
}
