import React, { useEffect, useRef, createRef } from 'react';
import { compose, withProps } from 'recompose';
import {
  Circle,
  withScriptjs,
  withGoogleMap,
  GoogleMap as Map,
} from 'react-google-maps';

// const URL = "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places";
const API_KEY = 'AIzaSyB5Aeun4laFvqX6g2mOYwutE97BEuYiTX8';
const GoogleMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '504px', width: '514px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const mapRef = useRef(createRef());
  const circRef = useRef(createRef());

  useEffect(() => {
    if (circRef && circRef.current && circRef.current.getBounds) {
      mapRef.current.fitBounds(circRef.current.getBounds());
    }
  }, [circRef]);
  const pLat = parseFloat(props.lat);
  const pLng = parseFloat(props.lng);
  const isValidCoord = !isNaN(pLat) && !isNaN(pLng);
  const lat = isValidCoord ? pLat : 62;
  const lng = isValidCoord ? pLng : 25;
  return (
    <Map
      ref={mapRef}
      defaultZoom={isValidCoord ? 13 : 4}
      defaultCenter={{ lat, lng }}
      options={{
        minZoom: '1',
        maxZoom: '20',
        rotateControl: 'true',
        gestureHandling: 'greedy',
        fullscreenControl: false,
        restriction: {
          latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
        },
      }}
      onClick={(e) => {
        props.setLocation && props.setLocation(e);
      }}
    >
      {isValidCoord && (
        <Circle
          ref={circRef}
          center={{ lat, lng }}
          radius={props.radius}
          options={{
            fillColor: '#008000',
            fillOpacity: 0.6,
            stokeColor: '#990022',
            stokeWeight: 2,
          }}
          draggable
          onDragEnd={(e) => props.setLocation(e)}
        />
      )}
    </Map>
  );
});

export default GoogleMap;
