import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchTicketingStatus } from '../../Redux/actions';
import { Table, Label, Message, Form, Button, Icon } from 'semantic-ui-react';
import moment from 'moment';
import 'moment/locale/fi';
import { Spinner } from '../Common/Spinner';
import { T } from '../Common/Helpers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import fi from 'date-fns/locale/fi';

registerLocale('fi', fi);
class TicketStatus extends Component {
  constructor(props) {
    super(props);
    const userLang =
      navigator.language?.substring(0, 2) ||
      navigator.userLanguage?.substring(0, 2) ||
      'en';
    moment.locale(userLang);

    this.state = {
      fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDate: null,
      dateError: {},
    };
  }
  componentDidMount() {
    this.reloadTicketStatus();
  }

  handleDateChange(name, value) {
    const newState = {
      [name]: value,
      [`${name}Type`]: value ? 'date' : 'text',
    };

    // Validate date range if both dates are set
    if (value && this.state[name === 'fromDate' ? 'toDate' : 'fromDate']) {
      const start = name === 'fromDate' ? value : this.state.fromDate;
      const end = name === 'toDate' ? value : this.state.toDate;

      if (moment(start).isAfter(moment(end))) {
        newState.dateError = {
          backgroundColor: '#FFF6F6',
          border: '1px solid #E0B4B4',
        };
      } else {
        newState.dateError = {};
      }
    }

    this.setState(newState, () => {
      // Check if dateError exists and has backgroundColor before preventing reload
      if (!newState.dateError?.backgroundColor) {
        this.reloadTicketStatus();
      }
    });
  }

  reloadTicketStatus() {
    const authKey = localStorage.getItem('x-auth-key');
    const { sortByColumn, direction, fromDate, toDate } = this.state;
    const { activeWorkspace } = this.props;
    const { node_collection_id } = activeWorkspace;

    this.props.fetchTicketingStatus({
      authKey,
      ws_id: node_collection_id,
      sortByColumn,
      direction,
      fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : null,
      toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : null,
    });
  }

  renderTicketSales(event_status_data) {
    const {
      event_id,
      sell_status,
      ev_name,
      ev_event_start,
      ev_selling_stop,
      event_ticket_sales,
      event_status, // 'ongoing', 'upcoming', 'ended'
    } = event_status_data;

    const str_selling_start =
      ev_event_start && moment(ev_event_start).format('l HH:mm');
    const str_selling_stop =
      ev_selling_stop && moment(ev_selling_stop).format('l HH:mm');
    let sell_status_text = '';
    let statusState = {};
    if (sell_status === 'available') {
      sell_status_text = T('Available');
      statusState = { positive: true };
      if (ev_selling_stop) {
        sell_status_text += ` ${T('$Until_prefix')}${str_selling_stop}${T(
          '$until_postfix'
        )}`;
      }
    } else if (sell_status === 'waiting') {
      statusState.warning = true;
      sell_status_text = str_selling_start;
    } else if (sell_status === 'ended') {
      statusState.negative = true;
      sell_status_text = `${T('Sales Ended')} ${str_selling_stop || ''}`;
    }

    const event_status_text =
      event_status === 'ongoing'
        ? T('Ongoing')
        : event_status === 'upcoming'
        ? T('Upcoming')
        : T('Ended');

    const eventStatusState =
      event_status === 'ongoing'
        ? { positive: true }
        : event_status === 'upcoming'
        ? { warning: true }
        : {};

    const event_start_date = moment(ev_event_start).format('l');
    return (
      <>
        <Table.Row>
          <Table.Cell colSpan={5} selectable>
            <Link to={`/app/events/match/edit/${event_id}`}>
              <Label color="grey" ribbon>
                {event_start_date} {ev_name}
              </Label>
            </Link>
          </Table.Cell>
        </Table.Row>

        {event_ticket_sales.map((ticket) => {
          const strStockLevel =
            ticket.ticket_product_saldo === 0
              ? T('Sold Out')
              : ticket.ticket_product_saldo;
          return (
            <Table.Row>
              <Table.Cell>{ticket.ticket_product_name}</Table.Cell>
              <Table.Cell {...eventStatusState}>{event_status_text}</Table.Cell>
              <Table.Cell {...statusState}>{sell_status_text}</Table.Cell>
              <Table.Cell textAlign="right">{strStockLevel}</Table.Cell>
              <Table.Cell textAlign="right">{ticket.n_sold}</Table.Cell>
              <Table.Cell textAlign="right">{ticket.n_validated}</Table.Cell>
            </Table.Row>
          );
        })}
      </>
    );
  }

  renderDateInput(name, placeholder) {
    const value = this.state[name];
    const dateValue = value ? new Date(value) : null;
    const userLang = moment.locale();

    return (
      <div className="date-input-wrapper" style={this.state.dateError}>
        <DatePicker
          selected={dateValue}
          onChange={(date) => {
            const formattedDate = date
              ? moment(date).format('YYYY-MM-DD')
              : null;
            this.handleDateChange(name, formattedDate);
          }}
          placeholderText={placeholder}
          maxDate={new Date()}
          dateFormat="dd.MM.yyyy"
          className="semantic-ui-datepicker"
          calendarClassName="semantic-ui-calendar"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          isClearable
          locale={userLang}
        />
      </div>
    );
  }

  render() {
    const { status_list } = this.props.ticketingStatus;
    const { ticketingFetchStatus } = this.props;

    console.log('Ticketing Status:', {
      ticketingFetchStatus,
      hasStatusList: !!status_list,
      statusList: status_list,
    });

    const dateRangeFilter = (
      <Form>
        <Form.Group>
          <Form.Field>
            <label>{T('Event End Date From')}</label>
            {this.renderDateInput('fromDate', T('Select start of date range'))}
          </Form.Field>
          <Form.Field>
            <label>{T('Event End Date To')}</label>
            {this.renderDateInput('toDate', T('Select end of date range'))}
          </Form.Field>
          <Form.Field style={{ alignSelf: 'flex-end' }}>
            <Button
              primary
              onClick={() => this.reloadTicketStatus()}
              disabled={!!this.state.dateError?.backgroundColor}
            >
              <Icon name="refresh" /> {T('Refresh')}
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    );

    // Only check the fetch status for showing spinner
    if (ticketingFetchStatus === 'waiting') {
      return (
        <>
          {dateRangeFilter}
          <div style={{ padding: '20px', textAlign: 'center' }}>
            <Spinner />
            <div>Loading...</div>
          </div>
        </>
      );
    }

    if (!status_list || status_list.length === 0) {
      return (
        <>
          {dateRangeFilter}
          <Message
            info
            header={T('No Active Tickets Found')}
            content={
              <div>
                <p>{T('By default, this page shows tickets for:')}</p>
                <ul>
                  <li>{T('Events that ended within the past week')}</li>
                  <li>{T('Ongoing events')}</li>
                  <li>{T('Upcoming events')}</li>
                </ul>
                <p>
                  {T(
                    'Use the event end date filter above to view different time periods.'
                  )}
                </p>
              </div>
            }
          />
        </>
      );
    }

    return (
      <>
        {dateRangeFilter}
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{T('Ticket Name')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Event Status')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Availability')}</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {T('Stock Level')}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">{T('Sold')}</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {T('Validated')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {status_list.map((event_status) =>
              this.renderTicketSales(event_status)
            )}
          </Table.Body>
        </Table>
      </>
    );
  }
}

function mapStateToProps({ ticketing, auth, workspace }) {
  return {
    ticketingStatus: ticketing.ticketingStatus,
    ticketingFetchStatus: ticketing.ticketingStatusStatus,
    auth,
    workspace,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
  };
}

export default connect(mapStateToProps, {
  fetchTicketingStatus,
})(TicketStatus);
