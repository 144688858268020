import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Header, Accordion, Icon } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { modifyProductStock, fetchProduct } from '../../Redux/actions';
import ProductStock from './ProductStock';
import { T, getErrorMessage } from '../Common/Helpers';
import { Spinner } from '../Common/Spinner';

class ProductStockContainer extends Component {
  state = { activeIndex: -1 };
  componentDidUpdate(prevProps) {
    if (prevProps.status !== 'success' && this.props.status === 'success') {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Product stock successfully updates'),
      });
      const authKey = localStorage.getItem('x-auth-key');
      const productId = this.props.parentId;
      const { ws_id } = this.props;
      this.props.fetchProduct({ id: productId, authKey, ws_id });
    } else if (prevProps.status !== 'error' && this.props.status === 'error') {
      const errorObj = getErrorMessage(
        this.props.error,
        T('Error occured while updating product stock')
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }
  handleClick = (i) => {
    const index = i;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  render() {
    if (this.props.status === 'waiting') {
      return <Spinner />;
    }
    const { activeIndex } = this.state;
    // console.log('Product Stocks: ', this.props);
    return (
      <div>
        <Header> {T('Product Stocks')} </Header>
        <Accordion>
          {_.map(this.props.stocks, (st, i) => (
            <React.Fragment key={`alt-container-${i}`}>
              <Accordion.Title
                active={activeIndex === i}
                index={i}
                onClick={() => this.handleClick(i)}
                key={`title-${i}`}
              >
                <Icon name="dropdown" />
                {st.productName}
              </Accordion.Title>
              <Accordion.Content
                key={`content-${i}`}
                active={activeIndex === i}
              >
                <ProductStock
                  key={`pl-${i}`}
                  stock={st.stock}
                  productId={st.productId}
                  units={this.props.units}
                  history={this.props.history}
                  modifyProductStock={this.props.modifyProductStock}
                  ws_id={this.props.ws_id}
                />
              </Accordion.Content>
            </React.Fragment>
          ))}
        </Accordion>
      </div>
    );
  }
}

function mapStateToProps({ workspace, stock }) {
  return { ws_id: workspace.ws_id, status: stock.status, error: stock.error };
}
export default connect(mapStateToProps, {
  modifyProductStock,
  fetchProduct,
})(ProductStockContainer);
