export const FETCH_EVENTS_WAITING = 'fetch_events_waiting';
export const FETCH_EVENTS_SUCCESS = 'fetch_events_success';
export const FETCH_EVENTS_SUCCESS_NEW = 'fetch_events_success_new';
export const FETCH_EVENTSX_SUCCESS = 'fetch_eventsx_success';
export const FETCH_EVENTS_RESET = 'fetch_events_reset';
export const FETCH_EVENTS_ERROR = 'fetch_events_error';

export const FETCH_EVENT_WAITING = 'fetch_event_waiting';
export const FETCH_EVENT_SUCCESS = 'fetch_event_success';
export const FETCH_EVENT_ERROR = 'fetch_event_error';
export const FETCH_EVENT_RESET = 'fetch_event_reset';

export const CREATE_EVENT_WAITING = 'create_event_waiting';
export const CREATE_EVENT_SUCCESS = 'create_event_success';
export const CREATE_EVENT_ERROR = 'create_event_error';

export const DELETE_EVENT_WAITING = 'delete_event_waiting';
export const DELETE_EVENT_SUCCESS = 'delete_event_success';
export const DELETE_EVENT_ERROR = 'delete_event_error';

export const EDIT_EVENT_WAITING = 'edit_event_waiting';
export const EDIT_EVENT_SUCCESS = 'edit_event_success';
export const EDIT_EVENT_ERROR = 'edit_event_error';

export const FETCH_EVENTS_BY_CATEGORY_WAITING =
  'fetch_events_by_category_waiting';
export const FETCH_EVENTS_BY_CATEGORY_SUCCESS =
  'fetch_events_by_category_success';
export const FETCH_EVENTS_BY_CATEGORY_RESET = 'fetch_events_by_category_reset';
export const FETCH_EVENTS_BY_CATEGORY_ERROR = 'fetch_events_by_category_error';

export const FETCH_EVENTS_BY_TYPE_WAITING = 'fetch_events_by_type_waiting';
export const FETCH_EVENTS_BY_TYPE_SUCCESS = 'fetch_events_by_type_success';

export const ADD_EVENT_PRICE_WAITING = 'add_event_price_waiting';
export const ADD_EVENT_PRICE_SUCCESS = 'add_event_price_success';
export const ADD_EVENT_PRICE_ERROR = 'add_event_price_error';
export const ADD_EVENT_PRICE_RESET = 'add_event_price_reset';

export const EDIT_EVENT_PRICE_WAITING = 'edit_event_price_waiting';
export const EDIT_EVENT_PRICE_SUCCESS = 'edit_event_price_success';
export const EDIT_EVENT_PRICE_ERROR = 'edit_event_price_error';
export const EDIT_EVENT_PRICE_RESET = 'edit_event_price_reset';

export const FETCH_MATCHES_WAITING = 'fetch_matches_waiting';
export const FETCH_MATCHES_SUCCESS = 'fetch_matches_success';
export const FETCH_MATCHES_ERROR = 'fetch_matches_error';
export const FETCH_MATCHES_RESET = 'fetch_matches_reset';

export const FETCH_CATEGORIES_WAITING = 'fetch-categories-waiting';
export const FETCH_CATEGORIES_SUCCESS = 'fetch-categoryies-success';
export const FETCH_CATEGORIES_SUCCESS_NEW = 'fetch-categories-success-new';
export const FETCH_CATEGORIES_ERROR = 'fetch-categoryies-error';
export const FETCH_CATEGORIES_RESET = 'fetch-categoryies-reset';

export const FETCH_SEASONCATEGORY_WAITING = 'fetch-seasoncategory-waiting';
export const FETCH_SEASONCATEGORY_SUCCESS = 'fetch-seasoncategory-success';
export const FETCH_SEASONCATEGORY_ERROR = 'fetch-seasoncategory-error';
export const FETCH_SEASONCATEGORY_RESET = 'fetch-seasoncategory-reset';

export const FETCH_MATCHCATEGORY_WAITING = 'fetch-matchcategory-waiting';
export const FETCH_MATCHCATEGORY_SUCCESS = 'fetch-matchcategory-success';
export const FETCH_MATCHCATEGORY_ERROR = 'fetch-matchcategory-error';
export const FETCH_MATCHCATEGORY_RESET = 'fetch-matchcategory-reset';

export const FETCH_CATEGORY_ERROR = 'fetch-category-error';
export const UPDATE_EVENT = 'update_event';

export const SET_EVENTPERFORMER_SUCCESS = 'fetch_eventperformer_success';
export const GET_EVENTPERFORMER_SUCCESS = 'fetch_eventperformer_success';

export const COPY_EVENT_WAITING = 'copy_event_waiting';
export const COPY_EVENT_SUCCESS = 'copy_event_success';
export const COPY_EVENT_ERROR = 'copy_event_error';
