import axios from 'axios';
import config from '../../Config/AEConfig';
import ErrorAction from './ErrorAction';
import {
  FETCH_DEFAULT_SETTINGS_WAITING,
  FETCH_DEFAULT_SETTINGS_SUCCESS,
  FETCH_DEFAULT_SETTINGS_ERROR,
  FETCH_DEFAULT_SETTINGS_RESET,
  ADD_DEFAULT_SETTINGS_WAITING,
  ADD_DEFAULT_SETTINGS_SUCCESS,
  ADD_DEFAULT_SETTINGS_ERROR,
  ADD_DEFAULT_SETTINGS_RESET,
  EDIT_DEFAULT_SETTINGS_WAITING,
  EDIT_DEFAULT_SETTINGS_SUCCESS,
  EDIT_DEFAULT_SETTINGS_ERROR,
  EDIT_DEFAULT_SETTINGS_RESET,
  DELETE_DEFAULT_SETTINGS_WAITING,
  DELETE_DEFAULT_SETTINGS_SUCCESS,
  DELETE_DEFAULT_SETTINGS_ERROR,
  DELETE_DEFAULT_SETTINGS_RESET,
} from '../Types';

//const MODULE_ID = 17;
const MODULE_ID = 32;

export const addDefaultTicketSettings =
  (authKey, data, appId, merchantId, ws_id) => (dispatch) => {
    dispatch({ type: ADD_DEFAULT_SETTINGS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    // axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .post(
        `${config.BASE_URL}/ticketing?module_id=${MODULE_ID}&application_id=${appId}&merchant_id=${merchantId}&ws_id=${ws_id}`,
        data
      )
      .then((response) =>
        dispatch({
          type: ADD_DEFAULT_SETTINGS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_DEFAULT_SETTINGS_ERROR)
          : ADD_DEFAULT_SETTINGS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export function fetchDefaultTicketSettings(
  authKey,
  appId,
  merchantId,
  ws_id,
  all_items
) {
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_DEFAULT_SETTINGS_WAITING });
    axios
      .get(
        `${config.BASE_URL}/ticketing/defaults?module_id=${MODULE_ID}&ws_id=${ws_id}&application_id=${appId}&merchant_id=${merchantId}&all_items=${all_items}`
      )
      .then((response) =>
        dispatch({
          type: FETCH_DEFAULT_SETTINGS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_DEFAULT_SETTINGS_ERROR)
          : FETCH_DEFAULT_SETTINGS_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export const editDefaultTicketSettings =
  (authKey, ticketId, data, appId, ws_id) => (dispatch) => {
    dispatch({ type: EDIT_DEFAULT_SETTINGS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .put(
        `${config.BASE_URL}/ticketing/${ticketId}?module_id=${MODULE_ID}&ws_id=${ws_id}&application_id=${appId}`,
        data
      )
      .then((response) =>
        dispatch({
          type: EDIT_DEFAULT_SETTINGS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_DEFAULT_SETTINGS_ERROR)
          : EDIT_DEFAULT_SETTINGS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const deleteDefaultTicketSettings =
  (authKey, ticketId, ws_id) => (dispatch) => {
    dispatch({ type: DELETE_DEFAULT_SETTINGS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    axios
      .delete(
        `${config.BASE_URL}/ticketing/${ticketId}?module_id=${MODULE_ID}&ws_id=${ws_id}`
      )
      .then((response) =>
        dispatch({
          type: DELETE_DEFAULT_SETTINGS_SUCCESS,
          payload: { data: response.data, id: ticketId },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, DELETE_DEFAULT_SETTINGS_ERROR)
          : DELETE_DEFAULT_SETTINGS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetAddDefaultTicketSettings = {
  type: FETCH_DEFAULT_SETTINGS_RESET,
};
export const resetFetchDefaultTicketSettings = {
  type: ADD_DEFAULT_SETTINGS_RESET,
};
export const resetEditDefaultTicketSettings = {
  type: EDIT_DEFAULT_SETTINGS_RESET,
};
export const resetDeleteDefaultTicketSettings = {
  type: DELETE_DEFAULT_SETTINGS_RESET,
};
