import _ from 'lodash';
import {
  CREATE_SERIES_WAITING,
  CREATE_SERIES_SUCCESS,
  CREATE_SERIES_ERROR,
  CREATE_SEASON_WAITING,
  CREATE_SEASON_SUCCESS,
  CREATE_SEASON_ERROR,
  EDIT_SERIES_WAITING,
  EDIT_SERIES_SUCCESS,
  EDIT_SERIES_ERROR,
  EDIT_SEASON_WAITING,
  EDIT_SEASON_SUCCESS,
  EDIT_SEASON_ERROR,
  DELETE_SERIES_WAITING,
  DELETE_SERIES_SUCCESS,
  DELETE_SERIES_ERROR,
  DELETE_SEASON_WAITING,
  DELETE_SEASON_SUCCESS,
  DELETE_SEASON_ERROR,
  FETCH_EVENTS_BY_CATEGORY_WAITING,
  FETCH_EVENTS_BY_CATEGORY_SUCCESS,
  FETCH_EVENTS_BY_CATEGORY_ERROR,
  FETCH_EVENTS_BY_CATEGORY_RESET,
  FETCH_EVENTS_BY_TYPE_WAITING,
  FETCH_EVENTS_BY_TYPE_SUCCESS,
  FETCH_SEASONS_SUCCESS,
  FETCH_SEASONS_WAITING,
  FETCH_SEASONS_ERROR,
  FETCH_SEASONS_RESET,
  FETCH_SERIES_WAITING,
  FETCH_SERIES_SUCCESS,
  FETCH_SERIES_ERROR,
  FETCH_SERIES_RESET,
  FETCH_MATCHES_SUCCESS,
  FETCH_MATCHES_RESET,
  UPDATE_PARENTS,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  parents: null,
  statusParents: 'init',
};

export default function ParentEventReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case CREATE_SERIES_WAITING:
      return { ...state, createSeriesStatus: 'waiting' };
    case CREATE_SERIES_SUCCESS: {
      const seriesList = {
        ...state.seriesList,
        [action.payload.data.event_id]: action.payload.data,
      };
      return { ...state, seriesList, createSeriesStatus: 'success' };
    }
    case CREATE_SERIES_ERROR:
      return {
        ...state,
        createSeriesStatus: 'error',
        error: action.payload.error,
      };

    case CREATE_SEASON_WAITING:
      return { ...state, createSeasonStatus: 'waiting' };
    case CREATE_SEASON_SUCCESS: {
      const key = action.payload.data.ev_parent;
      const id = action.payload.data.event_id;
      const seasonsByParentId = { ...state[key], [id]: action.payload.data };
      // console.log('CREATE SEASON REDUCER: ', seasonsByParentId);
      return {
        ...state,
        [key]: { ...seasonsByParentId },
        createSeasonStatus: 'success',
      };
    }
    case CREATE_SEASON_ERROR:
      return {
        ...state,
        createSeasonStatus: 'error',
        error: action.payload.error,
      };

    case EDIT_SERIES_WAITING:
      return { ...state, editSeriesStatus: 'waiting' };
    case EDIT_SERIES_SUCCESS: {
      const seriesList = {
        ...state.seriesList,
        [action.payload.data.event_id]: action.payload.data,
      };
      return { ...state, seriesList, editSeriesStatus: 'success' };
    }
    case EDIT_SERIES_ERROR:
      return {
        ...state,
        editSeriesStatus: 'error',
        error: action.payload.error,
      };

    case EDIT_SEASON_WAITING:
      return { ...state, editSeasonStatus: 'waiting' };
    case EDIT_SEASON_SUCCESS: {
      const key = action.payload.data.ev_parent;
      const seasons = {
        ...state[key],
        [action.payload.data.event_id]: action.payload.data,
      };
      // console.log('KEY: ', key, '\n Computed Seasons: ', seasons);
      return { ...state, [key]: { ...seasons }, editSeasonStatus: 'success' };
    }
    case EDIT_SEASON_ERROR:
      return {
        ...state,
        editSeasonStatus: 'error',
        error: action.payload.error,
      };

    case DELETE_SERIES_WAITING:
      return { ...state, deleteSeriesStatus: 'waiting' };
    case DELETE_SERIES_SUCCESS: {
      const seriesList = _.omit(state.seriesList, action.payload.event_id);
      return { ...state, seriesList, deleteSeriesStatus: 'success' };
    }
    case DELETE_SERIES_ERROR:
      return {
        ...state,
        deleteSeriesStatus: 'error',
        error: action.payload.error,
      };

    case DELETE_SEASON_WAITING:
      return { ...state, deleteSeasonStatus: 'waiting' };
    case DELETE_SEASON_SUCCESS: {
      const key = action.payload.parentId;
      const seasons = _.omit(state[key], action.payload.event_id);
      // console.log('DELETE SEASON KEY: ', key, '\n Computed Seasons: ', seasons, '\n', state[key]);
      return { ...state, [key]: seasons, deleteSeasonStatus: 'success' };
    }
    case DELETE_SEASON_ERROR:
      return {
        ...state,
        deleteSeasonStatus: 'error',
        error: action.payload.error,
      };

    case FETCH_EVENTS_BY_CATEGORY_WAITING:
      return { ...state, statusParents: 'waiting' };
    case FETCH_EVENTS_BY_CATEGORY_SUCCESS: {
      const parents = _.mapKeys(action.payload.data, 'event_id');
      return { ...state, parents, statusParents: 'success' };
    }
    case FETCH_EVENTS_BY_CATEGORY_ERROR:
      return { ...state, statusParents: 'error', error: action.payload.error };
    case FETCH_EVENTS_BY_CATEGORY_RESET:
      return { ...state, parents: null, statusParents: 'init' };

    case FETCH_EVENTS_BY_TYPE_WAITING:
      return { ...state, statusParents: 'waiting' };
    case FETCH_EVENTS_BY_TYPE_SUCCESS: {
      //const parents = _.mapKeys(action.payload.data, 'event_id');
      return {
        ...state,
        parents: action.payload.data,
        statusParents: 'success',
      };
      //  return { ...state, status, voting: action.payload.data }; }
    }

    case FETCH_SEASONS_WAITING: {
      const val = action.payload
        ? { [action.payload.id]: { statusSeasons: 'waiting' } }
        : { statusSeasons: 'waiting' };
      return { ...state, ...val };
    }
    case FETCH_SEASONS_SUCCESS: {
      const seasons = _.mapKeys(action.payload.data, 'event_id');
      const val = action.payload.id
        ? { [action.payload.id]: { ...seasons, statusSeasons: 'success' } }
        : { seasons, statusSeasons: 'success' };
      return { ...state, ...val };
    }
    case FETCH_SEASONS_ERROR: {
      const val = action.payload
        ? { [action.payload.id]: { statusSeasons: 'error' } }
        : { statusSeasons: 'error' };
      return { ...state, seasons: null, ...val, error: action.payload.error };
    }
    case FETCH_SEASONS_RESET:
      return { ...state, seasons: null, statusSeasons: 'init' };

    case FETCH_SERIES_WAITING:
      return { ...state, statusSeries: 'waiting' };
    case FETCH_SERIES_SUCCESS: {
      const seriesList = _.mapKeys(action.payload.data, 'event_id');
      return { ...state, seriesList, statusSeries: 'success' };
    }
    case FETCH_SERIES_ERROR:
      return { ...state, statusSeries: 'error', error: action.payload.error };

    case FETCH_MATCHES_SUCCESS: {
      const matches = _.mapKeys(action.payload.data, 'event_id');
      return { ...state, matches, statusMatches: 'success' };
    }
    case FETCH_SERIES_RESET:
      return { ...state, series: null, statusSeries: 'init' };
    case FETCH_MATCHES_RESET:
      return { ...state, matches: null, statusMatches: 'init' };
    case UPDATE_PARENTS: {
      const parents = null;
      return { ...state, parents, statusParents: 'update' };
    }
    default:
      return state;
  }
}
