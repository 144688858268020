import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Header, Accordion, Icon } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { fetchProduct } from '../../Redux/actions';
import { T } from '../Common/Helpers';
import ProductPricelist from './ProductPricelist';

class ProductPricelistContainer extends Component {
  state = { activeIndex: -1, value: [] };
  componentDidUpdate(prevProps) {
    if (prevProps.status !== 'success' && this.props.status === 'success') {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Product prices updated successfully'),
      });
      const authKey = localStorage.getItem('x-auth-key');
      const productId = this.props.productId;
      const { ws_id } = this.props;
      this.props.fetchProduct({ id: productId, authKey, ws_id });
    } else if (prevProps.status !== 'error' && this.props.status === 'error') {
      toast({
        type: 'error',
        title: T('Error'),
        description: T('Error updating product prices'),
      });
    }
  }

  priceListUpdate(pricelists) {
    this.props.priceListCallback(pricelists);
  }

  handleClick = (i) => {
    const index = i;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  renderOriginal() {
    const { activeIndex } = this.state;
    return (
      <div>
        <Header> {T('Product Pricelists')} </Header>
        <Accordion>
          {_.map(this.props.priceLists, (pl, i) => (
            <React.Fragment key={`alt-container-${i}`}>
              <Accordion.Title
                active={activeIndex === i}
                index={i}
                onClick={() => this.handleClick(i)}
                key={`title-${i}`}
              >
                <Icon name="dropdown" />
                {pl.productName}
              </Accordion.Title>
              <Accordion.Content
                key={`content-${i}`}
                active={activeIndex === i}
              >
                <ProductPricelist
                  key={`pl-${i}`}
                  productName={pl.productName}
                  productId={pl.productId}
                  pricelists={pl.pricelists}
                  plOptions={this.props.plOptions}
                  currencyList={this.props.currencyList}
                />
              </Accordion.Content>
            </React.Fragment>
          ))}
        </Accordion>
      </div>
    );
  }
  render() {
    const { activeIndex } = this.state;
    return (
      <div>
        {_.map(this.props.priceLists, (pl, i) => (
          <React.Fragment key={`alt-container-${i}`}>
            <Accordion.Content key={`content-${i}`} active={activeIndex === i}>
              <ProductPricelist
                key={`pl-${i}`}
                productName={pl.productName}
                productId={pl.productId}
                pricelists={pl.pricelists}
                plOptions={this.props.plOptions}
                currencyList={this.props.currencyList}
                priceListCallback={this.priceListUpdate.bind(this)}
              />
            </Accordion.Content>
          </React.Fragment>
        ))}
      </div>
    );
  }
}

function mapStateToProps({ product, workspace }) {
  return { status: product.productPl, ws_id: workspace.ws_id };
}
export default connect(mapStateToProps, { fetchProduct })(
  ProductPricelistContainer
);
