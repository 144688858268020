import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Segment, Container, Message } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import EventAddSeason from '../components/Events/EventAddSeason';
import EventManageGameContainer from '../components/Events/EventManageGameContainer';
import EventEditContainer from '../components/Events/EventEditContainer';
import EventMenuManager from '../components/Events/EventMenuManager';
import { T } from '../components/Common/Helpers';
import { Spinner } from '../components/Common/Spinner';
import {
  fetchCategories,
  fetchAllTeams,
  fetchVenues,
  fetchSeasons,
} from '../Redux/actions';

class EventRoute extends Component {
  state = {
    selectedEvent: null,
    event: null,
  };

  componentDidMount() {
    if (this.props.workspace.activeWorkspace.cnc_event) {
      const { ws_id } = this.props;
      const key = localStorage.getItem('x-auth-key');
      this.props.fetchCategories(key, ws_id);
      this.props.fetchAllTeams(key, ws_id);
      this.props.fetchVenues(key, ws_id);
      this.props.fetchSeasons(key, null, this.props.ws_id);
    }
  }

  setSelectedEvent(id, event) {
    this.setState({ selectedEvent: id, event });
  }

  render() {
    if (!this.props.workspace.activeWorkspace.cnc_event) {
      return (
        <Container fluid style={{ marginBottom: '150px' }}>
          <Segment>
            <Message negative>
              <Message.Header>{T('Workspace Misconfiguration')}</Message.Header>
              <p>{T('No root event found.')}</p>
            </Message>
          </Segment>
        </Container>
      );
    }

    if (
      this.props.eventcategories.status !== 'success' ||
      this.props.performers.fetchTeamsStatus !== 'success' ||
      this.props.venues.fetchAll !== 'success' ||
      this.props.parents.statusSeasons !== 'success'
    ) {
      return <Spinner />;
    }

    const { match } = this.props;
    const showSeasonMenuButton = this.props.location.pathname.startsWith(
      '/app/events/season/'
    );
    return (
      <Container fluid style={{ marginBottom: '150px' }}>
        {showSeasonMenuButton && (
          <EventMenuManager
            history={this.props.history}
            ws_id={this.props.ws_id}
          />
        )}
        <Switch>
          <Route exact path={`${match.path}/season/home`}>
            <EventEditContainer />
          </Route>
          <Route exact path={`${match.path}/season/add`}>
            <EventAddSeason />
          </Route>
          <Route exact path={`${match.path}/season/edit/:eventId`}>
            <EventEditContainer
              selectedEvent={this.state.selectedEvent}
              setSelectedEvent={this.setSelectedEvent}
            />
          </Route>
          <Route exact path={`${match.path}/match/add/:parentId`}>
            <EventManageGameContainer />
          </Route>
          <Route exact path={`${match.path}/match/edit/:eventId`}>
            <EventManageGameContainer />
          </Route>
        </Switch>
      </Container>
    );
  }
}

function mapStateToProps({
  eventcategories,
  performers,
  venues,
  workspace,
  parents,
}) {
  return {
    eventcategories,
    performers,
    venues,
    parents,
    workspace,
    ws_id: workspace.ws_id,
  };
}

export default connect(mapStateToProps, {
  fetchCategories,
  fetchAllTeams,
  fetchVenues,
  fetchSeasons,
})(withRouter(EventRoute));
