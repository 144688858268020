import axios from 'axios';
import config from '../../Config/AEConfig';
import ErrorAction from './ErrorAction';
import {
  FETCH_WORKSPACES_SUCCESS,
  FETCH_WORKSPACES_WAITING,
  FETCH_WORKSPACES_ERROR,
  FETCH_WORKSPACES_RESET,
  ADD_WORKSPACES_SUCCESS,
  ADD_WORKSPACES_WAITING,
  ADD_WORKSPACES_ERROR,
  ADD_WORKSPACES_RESET,
  FETCH_WORKSPACE_MODULES_WAITING,
  FETCH_WORKSPACE_MODULES_SUCCESS,
  FETCH_WORKSPACE_MODULES_ERROR,
  FETCH_WORKSPACE_MODULES_RESET,
  SET_ACTIVE_WORKSPACE,
} from '../Types';

export const setActiveWorkspace = ({ id, name, contextId }) => ({
  type: SET_ACTIVE_WORKSPACE,
  payload: { id, name, contextId },
});

export function fetchWorkspaces(authKey) {
  return (dispatch) => {
    dispatch({ type: FETCH_WORKSPACES_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/workspaces`;
    axios
      .get(url)
      .then((response) => {
        // console.log('workspaces response: ', response);
        dispatch({
          type: FETCH_WORKSPACES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_WORKSPACES_ERROR)
          : FETCH_WORKSPACES_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export function resetFetchWorkspaces() {
  return { type: FETCH_WORKSPACES_RESET };
}

export function addWorkspace(data, authKey) {
  return (dispatch) => {
    dispatch({ type: ADD_WORKSPACES_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/workspace`;
    axios
      .post(url, data)
      .then((response) =>
        dispatch({
          type: ADD_WORKSPACES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_WORKSPACES_ERROR)
          : ADD_WORKSPACES_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export const fetchWorkspaceModules =
  ({ authKey, id }) =>
  (dispatch) => {
    //console.log(' fetchWorkspaceModules: ', id);
    dispatch({ type: FETCH_WORKSPACE_MODULES_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/modules/workspace/${id}`;
    axios
      .get(url)
      .then((response) =>
        dispatch({
          type: FETCH_WORKSPACE_MODULES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_WORKSPACE_MODULES_ERROR)
          : FETCH_WORKSPACE_MODULES_ERROR;
        dispatch({ type, payload: error });
      });
  };

export function resetAddWorkspace() {
  return { type: ADD_WORKSPACES_RESET };
}

export const resetWorkspaceModules = () => ({
  type: FETCH_WORKSPACE_MODULES_RESET,
});
