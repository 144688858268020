/* eslint-env browser */
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { toast } from 'react-semantic-toasts';
import {
  Table,
  Button,
  Icon,
  Menu,
  Pagination,
  Confirm,
  Image,
  Message,
  Header,
} from 'semantic-ui-react';
import {
  fetchProducts,
  deleteProduct,
  deleteProductReset,
} from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import { T, StatusIcon, getErrorMessage } from '../Common/Helpers';

class ListProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDelete: false,
      sortByColumn: 'product_id',
      direction: 'asc',
    };

    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
  }

  componentDidMount() {
    // console.log('opening with props ', this.props);
    // to come back to this page after login if session expires
    // localStorage.setItem('route', this.props.history.location.pathname);
    const authKey = localStorage.getItem('x-auth-key');
    const { sortByColumn, direction } = this.state;
    const { ws_id } = this.props;
    this.props.fetchProducts({
      authKey,
      sortByColumn,
      direction,
      ws_id,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.product.deleteStatus === 'success' &&
      prevProps.product.deleteStatus !== 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Product deleted successfully'),
        time: 5000,
      });
      this.props.deleteProductReset();
    } else if (
      this.props.product.deleteStatus === 'error' &&
      prevProps.product.deleteStatus !== 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.product.error,
        T('Error while deleting product')
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.deleteProductReset();
    }
  }

  onDelete(e, { value }) {
    this.setState({ confirmDelete: true, selectedProduct: value });
  }

  onDeleteCancel() {
    this.setState({ confirmDelete: false });
  }

  onDeleteConfirm() {
    const { selectedProduct } = this.state;
    if (selectedProduct) {
      const authKey = localStorage.getItem('x-auth-key');
      this.props.deleteProduct(selectedProduct, authKey, this.props.ws_id);
    }
    this.setState({ confirmDelete: false });
  }

  navigateToLink = (product) => {
    this.props.history.push({
      pathname: `/app/product/manage/${product.product_id}`,
    });
  };

  handlePaginationChange(e, { activePage }) {
    const { headers } = this.props.product;
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    this.props.fetchProducts({
      authKey,
      sortByColumn: headers.sort,
      direction: null,
      start: activePage,
      searchterm: headers.searchterm,
      ws_id,
    });
  }

  renderProducts() {
    return _.map(this.props.product.products, (product) => (
      <Table.Row
        key={product.product_id}
        onClick={() => this.navigateToLink(product)}
        style={{ cursor: 'pointer' }}
        className="tablerow"
      >
        <Table.Cell>{product.product_id}</Table.Cell>
        <Table.Cell textAlign="center">
          <Image
            src={product.pr_main_image}
            size="tiny"
            style={{ width: '60px', height: '60px' }}
            verticalAlign="middle"
          />
        </Table.Cell>
        <Table.Cell>{product.pr_name}</Table.Cell>
        <Table.Cell>
          {product.pr_long_description || product.pr_short_description}
        </Table.Cell>
        <Table.Cell>{product.pr_code}</Table.Cell>
        <Table.Cell>
          <StatusIcon status={product.pr_active} />
        </Table.Cell>
        <Table.Cell
          textAlign="right"
          singleLine
          onClick={(e) => e.stopPropagation()}
          style={{ cursor: 'default' }}
        >
          <Button
            color="red"
            type="button"
            icon="trash"
            value={product.product_id}
            onClick={this.onDelete}
            content={T('Delete')}
          />
          {/*  <Link
                to={{
                  pathname: '/app/shop/products/manage',
                  state: { parentId: product.product_id }
                }}
            >
                <Button
                  primary
                  icon='add'
                  content={T('Add sub product')}
                />
           </Link>*/}
        </Table.Cell>
      </Table.Row>
    ));
  }

  render() {
    // console.log('List product re-rendered');
    if (this.props.product.fetchStatus === 'waiting') {
      return <Spinner />;
    } else if (this.props.product.fetchStatus === 'error') {
      return (
        <Message
          header={T('Error')}
          error
          icon="exclamation triangle"
          content={T('Could not list products')}
        />
      );
    }

    return (
      <React.Fragment>
        <Header> {T('Products List')} </Header>
        <Table sortable compact padded celled stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{T('Id')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Image')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Name')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Description')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Code')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Active')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderProducts()}
            <Table.Row>
              <Confirm
                header={T('Delete content')}
                content={T(
                  'Are you sure you want to delete the selected product?'
                )}
                confirmButton={T('Delete')}
                cancelButton={T('Cancel')}
                open={this.state.confirmDelete}
                onCancel={this.onDeleteCancel}
                onConfirm={this.onDeleteConfirm}
                size="tiny"
              />
            </Table.Row>
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="7">
                <Link to="/app/product/manage">
                  <Button icon="plus" primary content={T('Add')} />
                </Link>
                {this.props.product.headers &&
                  this.props.product.headers.total_pages > 1 && (
                    <Menu floated="right" pagination>
                      <Pagination
                        onPageChange={this.handlePaginationChange}
                        defaultActivePage={
                          this.props.product.headers.current_page
                        }
                        ellipsisItem={{
                          content: <Icon name="ellipsis horizontal" />,
                          icon: true,
                        }}
                        firstItem={{
                          content: <Icon name="angle double left" />,
                          icon: true,
                        }}
                        lastItem={{
                          content: <Icon name="angle double right" />,
                          icon: true,
                        }}
                        prevItem={{
                          content: <Icon name="angle left" />,
                          icon: true,
                        }}
                        nextItem={{
                          content: <Icon name="angle right" />,
                          icon: true,
                        }}
                        totalPages={this.props.product.headers.total_pages}
                      />
                    </Menu>
                  )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ auth, product, workspace }) {
  return { auth, product, ws_id: workspace.ws_id };
}

export default connect(mapStateToProps, {
  fetchProducts,
  deleteProduct,
  deleteProductReset,
})(withRouter(ListProducts));
