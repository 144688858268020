import {
  FETCH_TRANSLATION_WAITING,
  FETCH_TRANSLATION_SUCCESS,
  FETCH_TRANSLATION_ERROR,
  FETCH_TRANSLATION_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  status: 'init',
  translations: [],
};

export default function TranslationReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_TRANSLATION_WAITING:
      // console.log('translations waiting ');
      return { ...state, status: 'waiting' };
    case FETCH_TRANSLATION_SUCCESS:
      // console.log('translations success ');
      return { ...state, translations: action.payload.data, status: 'success' };
    case FETCH_TRANSLATION_ERROR:
      // console.log('translations error ');
      return { ...state, status: 'error', error: action.payload };
    case FETCH_TRANSLATION_RESET:
      // console.log('translations reset ');
      return { ...state, status: 'init' };
    default:
      return state;
  }
}
