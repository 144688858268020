import axios from 'axios';
import config from '../../Config/AEConfig';

import {
  FETCH_CARDS_WAITING,
  FETCH_CARDS_SUCCESS,
  FETCH_CARDS_ERROR,
  FETCH_CARDS_RESET,
} from '../Types';

import ErrorAction from './ErrorAction';

//const MODULE_ID = 37;

export const fetchCards =
  (
    moduleId,
    authKey,
    ws_id,
    application_id,
    cd_type,
    valid = true,
    all_cards = false
  ) =>
  (dispatch) => {
    dispatch({ type: FETCH_CARDS_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    let url = `${config.BASE_URL}/cards?module_id=${moduleId}&ws_id=${ws_id}&application_id=${application_id}&valid=${valid}&all_cards=${all_cards}`;
    if (cd_type) {
      url += `&cd_type=${cd_type}`;
    }
    axios
      .get(url)
      .then((response) => {
        // console.log('actions fetch success');
        dispatch({
          type: FETCH_CARDS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        // console.log(error);
        const payloadType = error.response
          ? ErrorAction(error.response.status, FETCH_CARDS_ERROR)
          : FETCH_CARDS_ERROR;
        dispatch({ type: payloadType, payload: error });
      });
  };

export function fetchCardsOld(moduleId, authKey, ws_id, cd_type) {
  return (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_CARDS_WAITING });
    let url = `${config.BASE_URL}/cards?module_id=${moduleId}&ws_id=${ws_id}`;
    if (cd_type) {
      url += `&cd_type=${cd_type}`;
    }
    axios
      .get(url)
      .then((response) =>
        dispatch({
          type: FETCH_CARDS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_CARDS_ERROR)
          : FETCH_CARDS_ERROR;
        dispatch({ type, payload: error });
      });
  };
}

export const resetFetchCards = () => ({ type: FETCH_CARDS_RESET });
