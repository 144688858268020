/* eslint-env browser */
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import { Form, Table, Button, Confirm, Icon } from 'semantic-ui-react';
import {
  deleteProductPrice,
  resetEditProductPrice,
  editMultipleProductPrices,
  editPriceListProductOrder,
} from '../../Redux/actions';
import {
  T,
  StatusIcon,
  getErrorMessage,
  convertPrice,
} from '../Common/Helpers';

// const pp_currency = 'pts';
// const pp_price_list = 27;

class RenderPrices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDelete: false,
      delProdId: null,
      delProdPriceId: null,
      priceLists: this.props.priceLists,
      transferedProduct: null,
      previousProduct: null,
      settingPriceListOrder: false,
      listing: true,
    };
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (
      nextProps.deletePPStatus === 'error' &&
      this.props.deletePPStatus !== 'error'
    ) {
      this.props.resetEditProductPrice();
      const errorObj = getErrorMessage(
        this.props.error,
        'Could not edit ticket price'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      nextProps.deletePPStatus === 'success' &&
      this.props.deletePPStatus !== 'success'
    ) {
      this.resetState(nextProps.priceLists);
      this.props.resetEditProductPrice();
      toast({
        type: 'success',
        animation: 'scale',
        title: T('Success'),
        description: T('Ticket price edited successfully'),
        time: 5000,
      });
    }
    if (
      this.props.editPriceListProductOrderStatus !== 'success' &&
      nextProps.editPriceListProductOrderStatus === 'success'
    ) {
      this.setPriceListProductOrder(nextProps.updatedPriceListOrder);
    }
  }

  onDelete = (e, delProdId, delProdPriceId) => {
    e.stopPropagation();
    this.setState({ confirmDelete: true, delProdId, delProdPriceId });
  };

  onDeleteCancel = () => {
    this.setState({ confirmDelete: false });
  };

  onDeleteConfirm = () => {
    const { delProdId, delProdPriceId } = this.state;
    const authKey = localStorage.getItem('x-auth-key');
    // const ws_id = this.props.workspace.ws_id;
    let product = {
      ..._.filter(
        this.props.product.products,
        (prod) => parseInt(prod.product_id, 10) === parseInt(delProdId, 10)
      )[0],
    };
    let priceList = [...product.pricelists];
    priceList = priceList.filter(
      (pl) => parseInt(pl.product_price_id, 10) !== parseInt(delProdPriceId, 10)
    );
    product = { ...product, pricelists: priceList };
    //console.log('Price List: ', priceList, '\n Filtered Id: ', delProdPriceId);
    this.props.deleteProductPrice(
      delProdPriceId,
      authKey,
      delProdId,
      this.props.ws_id
    );
    this.setState({
      confirmDelete: false,
      delProdId: null,
      delProdPriceId: null,
    });
  };

  onDragStart = (e, pricelist) => {
    this.setState({ transferProduct: pricelist });
  };

  onDragOver = (pricelist) => {
    this.setState({ previousProduct: pricelist });
  };

  onDragEnd = () => {
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    const p_product_price_id = this.state.transferProduct.product_price_id;
    const p_pp_list_order = this.state.previousProduct.pp_list_order;
    const data = {
      product_price_id: p_product_price_id,
      pp_list_order: p_pp_list_order,
      pp_price: this.state.transferProduct.pp_price,
    };
    this.props.editPriceListProductOrder(
      authKey,
      data,
      this.state.transferProduct.pp_price_list,
      ws_id
    );
    this.setState({ settingPriceListOrder: true });
  };

  setPriceListProductOrder(updatedlist) {
    let tmp = {};
    _.forEach(updatedlist, (productPrice) => {
      tmp = {
        ...tmp,
        [productPrice.pp_list_order]: {
          //parsedValue = parseFloat(price, 10);
          price: productPrice.pp_price,
          pp_price_list: productPrice.pp_price_list,
          product_price_id: productPrice.product_price_id,
          pr_id: productPrice.pr_id,
          pr_name: productPrice.pr_name,
          pr_short_description: productPrice.pr_short_description,
          // pr_code: product.pr_code,
          pr_active: productPrice.pr_active,
          pp_active: productPrice.pp_active,
          pp_currency: productPrice.pp_currency,
          pr_list_order: productPrice.pr_list_order,
          pp_list_order: productPrice.pp_list_order,
        },
      };
    });
    this.setState({ priceLists: tmp, settingPriceListOrder: false });
  }

  setPrice = (prices) => this.setState({ prices });

  resetState = (priceLists) => {
    this.setState({ priceLists });
  };

  handlePriceChange = (e, key) => {
    if (this.state.listing === true) {
      this.setState({ listing: false });
    }
    const price = e.target.value;
    let parsedValue;
    let priceList = { ...this.state.priceLists[key] };
    if (priceList.pp_currency === 'pts') {
      parsedValue = parseInt(price, 10);
    } else {
      parsedValue = parseFloat(price, 10);
    }
    if (Object.is(parsedValue, NaN)) {
      //NaN
      parsedValue = priceList.pp_price;
    }
    // listpriceUpdated = { ...listprice, pp_price: parsedValue };
    // console.log('updated Price: ', price);
    priceList = { ...priceList, price: parsedValue };
    const priceLists = { ...this.state.priceLists, [key]: priceList };
    this.setState({ priceLists });
  };

  handlePriceChangeOriginal = (e, product_price_id) => {
    const price = e.target.value;
    let parsedValue;
    let priceList = { ...this.state.priceLists[product_price_id] };
    if (priceList.pp_currency === 'pts') {
      parsedValue = parseInt(price, 10);
    } else {
      parsedValue = parseFloat(price, 10);
    }
    if (Object.is(parsedValue, NaN)) {
      //NaN
      parsedValue = priceList.pp_price;
    }
    // listpriceUpdated = { ...listprice, pp_price: parsedValue };
    // console.log('updated Price: ', price);
    priceList = { ...priceList, price: parsedValue };
    const priceLists = {
      ...this.state.priceLists,
      [product_price_id]: priceList,
    };
    this.setState({ priceLists });
  };

  recordPriceChange = () => {
    const propList = this.props.priceLists;
    const stateList = this.state.priceLists;
    const changedPriceLists = [];
    Object.keys(stateList).forEach((key) => {
      if (stateList[key].price !== propList[key].price) {
        changedPriceLists.push({
          pp_price: parseFloat(stateList[key].price, 10),
          //product_price_id: parseInt(key, 10),
          product_price_id: parseInt(stateList[key].product_price_id, 10),
          product_id: parseInt(stateList[key].pr_id, 10),
          pp_price_list: parseInt(stateList[key].pp_price_list, 10),
          pp_currency: stateList[key].pp_currency,
          pp_list_order: stateList[key].pp_list_order,
        });
      }
    });
    return changedPriceLists;
  };

  updatePrices = () => {
    const { priceListId } = this.props;
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    const prices = this.recordPriceChange();
    let data;
    if (Object.keys(prices).length > 0) {
      data = { prices };
      this.props.editMultipleProductPrices(
        authKey,
        data,
        parseInt(priceListId, 10),
        ws_id
      );
    } else {
      toast({
        type: 'info',
        animation: 'scale',
        title: T('Info'),
        description: T('There is no modified price'),
        time: 2500,
      });
    }
  };

  navigateToLink = (productId) => {
    if (productId) {
      this.props.history.push({
        pathname: `/app/product/manage/${productId}`,
        state: { ticketList: true },
      });
    } else {
      this.props.history.push({
        pathname: '/app/product/manage',
        state: { ticketList: true, priceListId: this.props.priceListId },
      });
    }
  };

  renderProducts = () => {
    const tableBody = [];
    const { priceLists } = this.state;
    const colSpan = Object.keys(priceLists).length > 0 ? 6 : 7;
    const draggable = true;
    _.map(priceLists, (pricelist, key) =>
      tableBody.push(
        <Table.Row
          draggable={draggable}
          key={key}
          className="tablerow"
          onDragStart={(e) => this.onDragStart(e, pricelist)}
          onDragOver={() => this.onDragOver(pricelist)}
          onDragEnd={this.onDragEnd}
          onClick={() => this.navigateToLink(pricelist.pr_id)}
        >
          <Table.Cell>{pricelist.pr_id}</Table.Cell>
          <Table.Cell>{pricelist.pp_list_order}</Table.Cell>
          <Table.Cell>{pricelist.pr_name}</Table.Cell>
          <Table.Cell>{pricelist.pr_short_description}</Table.Cell>
          <Table.Cell>{pricelist.pp_currency}</Table.Cell>
          <Table.Cell>
            <StatusIcon status={pricelist.pp_active} />
          </Table.Cell>
          <Table.Cell
            textAlign="right"
            style={{ cursor: 'default' }}
            onClick={(e) => e.stopPropagation()}
          >
            <Form>
              <Form.Group key={`price-${pricelist.pr_id}`} widths="equal">
                <Form.Input
                  id={pricelist.pr_id.toString()}
                  pp_product={pricelist.pr_id}
                  product_price_id={pricelist.product_price_id}
                  price_list={pricelist.pp_price_list}
                  name="product_price"
                  //value={this.state.priceLists[pricelist.product_price_id].price || ''}
                  value={
                    this.state.listing
                      ? convertPrice(
                          pricelist.pp_currency,
                          pricelist.price ? pricelist.price : null
                        )
                      : pricelist.price
                  }
                  step={0.5}
                  min={0}
                  type="number"
                  disabled={this.state.settingPriceListOrder}
                  //onChange={(e) => this.handlePriceChange(e, pricelist.product_price_id)}
                  onChange={(e) => this.handlePriceChange(e, key)}
                />
                <Icon
                  color="red"
                  value={pricelist.pp_price_list}
                  name="trash"
                  size="big"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) =>
                    this.onDelete(
                      e,
                      pricelist.pr_id,
                      pricelist.product_price_id
                    )
                  }
                />
              </Form.Group>
            </Form>
          </Table.Cell>
        </Table.Row>
      )
    );
    tableBody.push(
      <Table.Row key="row-buttons">
        <Confirm
          header={T('Delete product')}
          content={T('Are you sure you want to remove product?')}
          confirmButton={T('Delete')}
          open={this.state.confirmDelete}
          onCancel={this.onDeleteCancel}
          onConfirm={this.onDeleteConfirm}
          size="tiny"
        />
        <Table.Cell colSpan={colSpan}>
          <Button
            icon="plus"
            content={T('Add product')}
            primary
            onClick={() => this.navigateToLink(null)}
          />
        </Table.Cell>
        {colSpan === 6 && (
          <Table.Cell>
            <Button
              icon="save"
              content={T('Save prices')}
              color="blue"
              primary
              onClick={this.updatePrices}
            />
          </Table.Cell>
        )}
      </Table.Row>
    );

    // console.log(tableBody.length);
    /*
  {colSpan === 7 &&
    (<Table.Cell>
      <Button icon='save' content={T('Update prices')} primary onClick={this.updatePrices} />
    </Table.Cell>)}
  */
    return tableBody;
  };
  render() {
    return this.renderProducts();
  }
}

function mapStateToProps({ auth, pricelist, product, workspace }) {
  return {
    auth,
    priceEditStatus: pricelist.priceEditStatus,
    product,
    workspace,
    ws_id: workspace.ws_id,
    error: pricelist.error,
    updatedPriceListOrder: pricelist.updatedPriceListsOrder,
    editPriceListProductOrderStatus: pricelist.editPriceListProductOrderStatus,
  };
}

export default connect(mapStateToProps, {
  deleteProductPrice,
  resetEditProductPrice,
  editMultipleProductPrices,
  editPriceListProductOrder,
})(RenderPrices);
