import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import {
  Sidebar,
  Menu,
  Icon,
  Segment,
  Header,
  Accordion,
  List,
  Dropdown,
  Message,
} from 'semantic-ui-react';
// import Footer from '../layout/Footer';
import Dashboard from './Dashboard';
import Welcome from './Welcome';
import PerformerRoute from '../../Routes/PerformerRoute';
import EventRoute from '../../Routes/EventRoute';
import VenueRoute from '../../Routes/VenueRoute';
import PersonRoute from '../../Routes/PersonRoute';
import ApplicationRoute from '../../Routes/ApplicationRoute';
import NotificationRoute from '../../Routes/NotificationRoute';
import CmsContentRoute from '../../Routes/CmsContentRoute';
import PrometheusRoute from '../../Routes/PrometheusRoute';
import ShopRoute from '../../Routes/ShopRoute';
import ProductRoute from '../../Routes/ProductRoute';
import TicketingRoute from '../../Routes/TicketingRoute';
import PricelistRoute from '../../Routes/PricelistRoute';
import CompanyRoute from '../../Routes/CompanyRoute';
import ReportRoute from '../../Routes/ReportRoute';
import LotteryRoute from '../../Routes/LotteryRoute';
import VotingRoute from '../../Routes/VotingRoute';
import MembershipRoute from '../../Routes/MembershipRoute';
import ProductRegistrationRoute from '../../Routes/ProductRegistrationRoute';
import WorkCaseLoggingRoute from '../../Routes/WorkCaseLoggingRoute';
import CampaignRoute from '../../Routes/CampaignRoute';
import AEConfig from '../../Config/AEConfig';

// import Wizard from './Wizard/Wizard';
import {
  authReset,
  setActiveWorkspace,
  fetchWorkspaceModules,
  setPrimaryLanguage,
} from '../../Redux/actions';
import { T, getActiveApplication, getLanguageObject } from '../Common/Helpers';
import '../../Style/App.css';

const INITIAL_SUBMENU = 'apps';
const INITIAL_SUBMENU_NAME = 'Apps';
const DEFAULT_LANGUAGE = 15;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.mainMenuItems = [];
    this.menuItems = [];

    this.state = {
      width: '',
      menuWidth: 20,
      display: 'none',
      event: '',
      expanded: false,
      selectedMainmenu: 'apps',
      selectedSubmenu: INITIAL_SUBMENU,
      selectedSubmenuName: INITIAL_SUBMENU_NAME,
      innerSidebarVisible: false,
      activeIndex: -1,
    };

    const application = getActiveApplication(
      props.auth,
      props.workspace,
      props.workspace.activeWorkspace.id
    );
    const primaryLanguage = getLanguageObject(
      props.languages.languages,
      application === undefined
        ? DEFAULT_LANGUAGE
        : application.ap_primary_language
    );
    this.props.setPrimaryLanguage({ primaryLanguage });

    // console.log('Event Path: ', this.props.eventPath);
    this.mainMenuItems.top = this.getMenuList();

    this.mainMenuItems.userbuttons = [
      {
        name: T('Profile'),
        menuId: 'profile',
        type: 'link',
        subPath: `persons/${this.props.auth.pe_public_id}/edit`,
        icon: 'user outline',
        showInnerSidebar: false,
        superadminOnly: false,
        modules: [10],
      },
      {
        name: T('Logout'),
        type: 'logout',
        icon: 'log out',
        showInnerSidebar: false,
        superadminOnly: false,
      },
    ];

    this.menuItems.workspace = [];

    this.menuItems.apps = [
      //{ name: T('App Settings'), menuId: 'submenu_shop', type: 'link', subPath: 'shop/home', icon: 'setting', modules: [17, 18] },
      {
        name: T('Manage App Shops'),
        menuId: 'submenu_shop',
        type: 'link',
        subPath: 'application/shops',
        icon: 'shop',
        modules: [17, 18],
      },
      //{ name: T('Dashboard'), menuId: 'submenu_dashboard', type: 'link', subPath: 'welcome', icon: 'home' },
      {
        name: T('Push Messaging'),
        menuId: 'submenu_push_messaging',
        type: 'link',
        subPath: 'advertising/list/false',
        icon: 'paper plane outline',
        modules: [13],
      },
      //{ name: T('Lottery'), menuId: 'submenu_dashboard', type: 'link', subPath: 'lottery/list', icon: 'gift', modules: [27, 28] },
      {
        name: T('Lottery'),
        menuId: 'submenu_dashboard',
        type: 'link',
        subPath: 'lottery/list',
        icon: 'gift',
        modules: [901],
      },
      {
        name: T('Voting'),
        menuId: 'submenu_dashboard',
        type: 'link',
        subPath: 'voting/list',
        icon: 'gift',
        modules: [1001],
      },
      {
        name: T('Manage company'),
        menuId: 'submenu_push_messaging',
        type: 'link',
        subPath: 'company/manage',
        icon: 'briefcase',
        modules: [13],
      },
      // { name: T('Application Content'), menuId: 'submenu_cms_content', type: 'link', subPath: 'cmscontent/list', icon: 'content', modules: [15, 16] },
    ];

    this.menuItems.events_and_teams = [
      {
        name: T('Teams'),
        menuId: 'submenu_teams',
        type: 'link',
        subPath: 'performers/list',
        icon: 'address book',
        modules: [1, 2, 4],
      },
      {
        name: T('Events'),
        menuId: 'submenu_events',
        type: 'link',
        subPath: '/events/home',
        icon: 'calendar alternate outline',
        modules: [3],
      },
      {
        name: T('Venues'),
        menuId: 'submenu_venues',
        type: 'link',
        subPath: 'venues/list',
        icon: 'map marker alternate',
        modules: [9],
      },
    ];

    this.menuItems.shops_and_products = [
      // { name: 'Prometheus One', type: 'link', subPath: 'prometheusone', icon: 'hand point up outline' },
      // { name: T('Shop'), menuId: 'submenu_products', type: 'link', subPath: 'shop/manageshop', icon: 'dollar sign', modules: [17, 18] },
      // {
      //   name: T('Point of Sale'),
      //   menuId: 'submenu_pos',
      //   type: 'link',
      //   subPath: 'shop/sales',
      //   icon: 'payment',
      //   modules: [22],
      // },
      {
        name: T('Shops'),
        menuId: 'submenu_shop_list',
        type: 'link',
        subPath: 'shop/list',
        icon: 'shop',
        modules: [17],
      },
      {
        name: T('Products'),
        menuId: 'submenu_products',
        type: 'link',
        subPath: 'product/list',
        icon: 'qrcode',
        modules: [17],
      },
      {
        name: T('Price Lists'),
        menuId: 'submenu_pricelists',
        type: 'link',
        subPath: 'pricelist/list',
        icon: 'dollar sign',
        modules: [17],
      },
      {
        name: T('Product Registrations'),
        menuId: 'submenu_product_registrations',
        type: 'link',
        subPath: 'productregistration/list',
        icon: 'id card',
        modules: [38],
      },
      {
        name: T('Waiter Calls'),
        menuId: 'submenu_waiter_calls',
        type: 'link',
        subPath: 'shop/waitercalls',
        icon: 'bell',
        modules: [22],
      },
    ];

    this.menuItems.campaigns = [
      {
        name: T('Etukupongit'),
        menuId: 'submenu_campaigns',
        type: 'link',
        subPath: 'campaign/campaigns',
        icon: 'percent',
        modules: [802],
      },
      {
        name: T('Kampanjat ja koodit'),
        menuId: 'submenu_campaign_codes',
        type: 'link',
        subPath: 'campaign/campaigncodes',
        icon: 'percent',
        modules: [802],
      },
    ];

    this.menuItems.ticketing = [
      {
        name: T('Default Ticket Settings'),
        menuId: 'submenu_default_tickets',
        type: 'link',
        subPath: 'ticketing/default/list',
        icon: 'ticket alternate',
        modules: [32, 33, 34],
      },
      {
        name: T('Tickets'),
        menuId: 'submenu_tickets',
        type: 'link',
        subPath: 'ticketing/tickets/list',
        icon: 'ticket',
        modules: [32, 33, 34],
      },
      {
        name: T('Ticket Sales Status'),
        menuId: 'submenu_tickets',
        type: 'link',
        subPath: 'ticketing/tickets/status',
        icon: 'ticket',
        modules: [32, 33, 34],
      },
      {
        name: T('Season Cards'),
        menuId: 'submenu_cards',
        type: 'link',
        subPath: 'ticketing/seasoncard/list',
        icon: 'id card',
        modules: [37],
      },
    ];

    this.menuItems.membership = [
      {
        name: T('Membership Register'),
        menuId: 'submenu_memberships',
        type: 'link',
        subPath: 'membership/list',
        icon: 'id card',
        modules: [39],
      },
    ];

    this.menuItems.superuser = [
      {
        name: T('Users'),
        menuId: 'submenu_users',
        type: 'link',
        subPath: 'persons/list',
        icon: 'users',
        showInnerSidebar: false,
        superadminOnly: true,
      },
      {
        name: T("Clients' Apps"),
        menuId: 'submenu_clients_apps',
        type: 'link',
        subPath: 'application/list',
        icon: 'mobile alternate',
        showInnerSidebar: false,
        superadminOnly: true,
      },
    ];

    this.menuItems.report = [
      {
        name: T('Event Report'),
        menuId: 'submenu_sales',
        type: 'link',
        subPath: 'report/event/list',
        icon: 'file alternate',
        showInnerSidebar: false,
        superadminOnly: false,
        modules: [36],
      },
      {
        name: T('Event Graphs'),
        menuId: 'submenu_sales',
        type: 'link',
        subPath: 'report/event/moment/statistic',
        icon: 'file alternate',
        showInnerSidebar: false,
        superadminOnly: false,
        modules: [36],
      },
      {
        name: T('Sales Report'),
        menuId: 'submenu_sales',
        type: 'link',
        subPath: 'report/sales/list',
        icon: 'file alternate',
        showInnerSidebar: false,
        superadminOnly: true,
        modules: [24],
      },
      {
        name: T('App Report'),
        menuId: 'submenu_sales',
        type: 'link',
        subPath: 'report/app/list',
        icon: 'file alternate',
        showInnerSidebar: false,
        modules: [40],
      },
    ];

    this.menuItems.workcaselogging = [
      {
        name: T('Work Case List'),
        menuId: 'submenu_sales',
        type: 'link',
        subPath: 'workcaselogging/list',
        icon: 'numbered list',
        showInnerSidebar: false,
        modules: [41],
      },
      // { name: T('Add Work Case'), menuId: 'submenu_sales', type: 'link', subPath: 'workcaselogging/add', icon: 'add', showInnerSidebar: false, modules: [40] },
    ];

    this.menuItems.contents = [];
    this.handleOnUpdate = this.handleOnUpdate.bind(this);
    this.toggleWidth = this.toggleWidth.bind(this);
  }

  // componentDidMount() {
  //   const { workspaces } = this.props.workspace;
  //   const workspaceIds = Object.keys(workspaces);
  //   const key = workspaceIds.length ? workspaceIds[0] : null;
  //   const activeWs = key ? workspaces[key] : null;
  //   const id = activeWs ? activeWs.node_collection_id : null;
  //   const name = activeWs ? activeWs.cnc_name : null;
  //   // const { workspaces } = this.props.workspace;
  //   // const id = workspaces[0] ? workspaces[0].node_collection_id : null;
  //   // const name = workspaces[0] ? workspaces[0].cnc_name : '';
  //   this.props.setActiveWorkspace({ id, name });
  // }

  // UNSAFE_componentWillUpdate(nextProps) {
  //   const authKey = localStorage.getItem('x-auth-key');
  //   if (this.props.ws_id !== nextProps.ws_id) {
  //       this.props.fetchWorkspaceModules({ authKey, id: nextProps.ws_id });
  //     const apps = this.props.auth.apps;
  //     const { workspaces, ws_id } = nextProps.workspace;
  //     const currentApp = apps.filter(app => app.application_id === workspaces[ws_id].cnc_application)[0];
  //     this.ap_key = currentApp.ap_key;
  //       this.props.history.push('/app/dashboard');
  //   }
  //   if (this.props.ap_key !== nextProps.ap_key) {
  //       this.setState({ ap_key: nextProps.ap_key });
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.auth.status !== this.props.auth.status) {
      if (this.props.auth.status === 'error') {
        this.props.history.replace('/auth');
      }
    }
  }

  //To call on the render block for updating application key
  getMenuList() {
    return [
      //{ name: T('Workspace'), type: 'accordion', menuId: 'workspace', icon: 'dropdown', showInnerSidebar: true, superadminOnly: false, modules: [] },
      {
        name: T('Application'),
        type: 'submenu',
        menuId: 'apps',
        icon: 'rocket',
        showInnerSidebar: true,
        superadminOnly: false,
      },
      {
        name: T('Events & Participants'),
        type: 'submenu',
        menuId: 'events_and_teams',
        icon: 'table',
        showInnerSidebar: true,
        superadminOnly: false,
      },
      {
        name: T('Shops & Products'),
        type: 'submenu',
        menuId: 'shops_and_products',
        icon: 'shop',
        showInnerSidebar: true,
        superadminOnly: false,
      },
      {
        name: T('Etukupongit ja kampanjat'),
        type: 'submenu',
        menuId: 'campaigns',
        icon: 'bullhorn',
        showInnerSidebar: true,
        superadminOnly: false,
      },
      {
        name: T('Ticketing'),
        type: 'submenu',
        menuId: 'ticketing',
        icon: 'ticket',
        showInnerSidebar: true,
        superadminOnly: false,
      },
      {
        name: T('Membership'),
        type: 'submenu',
        menuId: 'membership',
        icon: 'id card',
        showInnerSidebar: true,
        superadminOnly: false,
      },
      {
        name: T('Contents'),
        type: 'submenu',
        menuId: 'contents',
        icon: 'content',
        showInnerSidebar: true,
        superadminOnly: false,
      },
      {
        name: T('App Wizard'),
        type: 'link',
        menuId: 'wizard',
        subPath: `prometheusone/wizard/${this.props.ap_key}`,
        icon: 'wizard',
        showInnerSidebar: false,
        superadminOnly: true,
        modules: [20],
      },
      {
        name: T('Superuser'),
        type: 'submenu',
        menuId: 'superuser',
        icon: 'superpowers',
        showInnerSidebar: true,
        superadminOnly: true,
      },
      {
        name: T('Work Case Logging'),
        type: 'submenu',
        menuId: 'workcaselogging',
        icon: 'write',
        showInnerSidebar: true,
        superadminOnly: false,
      },
      {
        name: T('Report'),
        type: 'submenu',
        menuId: 'report',
        icon: 'paperclip',
        showInnerSidebar: true,
        superadminOnly: false,
      },
    ];
  }

  handleOnUpdate(e, { width }) {
    this.setState({ width });
  }

  logout() {
    this.props.authReset();
  }

  toggleWidth(eventName) {
    const cssProp =
      this.state.expanded === false
        ? { menuWidth: 100, display: 'block', expanded: true }
        : { menuWidth: 20, display: 'none', expanded: false };
    this.setState({
      menuWidth: cssProp.menuWidth,
      display: cssProp.display,
      event: eventName,
      expanded: cssProp.expanded,
    });
  }

  handleSegmentRef(c) {
    this.segmentRef = c;
  }

  handleSidebarHide() {
    if (this.state.innerSidebarVisible) {
      this.setState({ innerSidebarVisible: false });
    }
  }

  handleApplicationAndLanguageChange(value) {
    const application = getActiveApplication(
      this.props.auth,
      this.props.workspace,
      value
    );
    const primaryLanguage = getLanguageObject(
      this.props.languages.languages,
      application === undefined
        ? DEFAULT_LANGUAGE
        : application.ap_primary_language
    );
    this.props.setPrimaryLanguage({ primaryLanguage });
  }

  renderWorkspaces() {
    const { workspaces, activeWorkspace } = this.props.workspace;
    const workspaceItem = _.map(workspaces, (ws) => (
      <List.Item key={ws.node_collection_id}>
        <List.Content>
          <List.Item
            onClick={() =>
              this.props.setActiveWorkspace(
                { id: ws.node_collection_id, name: ws.cnc_name },
                this.setState({ activeIndex: -1 })
              )
            }
          >
            {T(ws.cnc_name)}
          </List.Item>
        </List.Content>
      </List.Item>
    ));

    const content = (
      <List selection verticalAlign="middle">
        {workspaceItem}
      </List>
    );
    const activeWorkspaceName = activeWorkspace ? activeWorkspace.name : '';
    return [
      {
        key: 'workspace-key',
        title: { content: T(activeWorkspaceName) },
        content: { content },
      },
    ];
  }

  renderWorkspaceSelector() {
    // <Accordion
    //   activeIndex={this.state.activeIndex}
    //   onClick={() => this.setState({ activeIndex: this.state.activeIndex === -1 ? 0 : -1 })}
    //   key='workspace'
    //   panels={this.renderWorkspaces()}
    //   fluid
    //   inverted
    // />);
    //
    const { workspaces, activeWorkspace } = this.props.workspace;
    const options = _.map(workspaces, (ws) => {
      const value = ws.node_collection_id;
      return { key: value, text: ws.cnc_name, value };
    });
    return (
      <Dropdown
        onChange={(e, { value, text }) =>
          this.props.setActiveWorkspace(
            { id: value, name: text },
            this.handleApplicationAndLanguageChange(value),
            this.setState({ activeIndex: -1 })
          )
        }
        options={options}
        placeholder={T('Choose workspace')}
        selection
        value={activeWorkspace.id}
      />
    );
  }

  renderUserMainMenu(menu) {
    const { workspace } = this.props;
    const menuItems = this.mainMenuItems[menu].filter(
      (mi) => !mi.superadminOnly
    );
    const workspaceModules = Object.values(workspace.workspaceModules).map(
      (wm) => wm.module_id
    );
    const userMenus = menuItems.filter((mi) => {
      if (mi.type === 'submenu') {
        const submenuModules = this.menuItems[mi.menuId].reduce(
          (a, b) => [...a, ...(b.modules || [])],
          []
        );
        return workspaceModules.some((m_id) => submenuModules.includes(m_id));
      }
      if (!mi.modules) {
        return true;
      }
      return workspaceModules.some((m_id) => mi.modules.includes(m_id));
    });
    return userMenus;
  }

  renderMainMenuItems(menu) {
    const { match, isSuperAdmin, location } = this.props;
    const { pathname } = location;
    // if (pathname.endsWith('/wizard')) {
    //   return null;
    // }
    //
    const menuItems = isSuperAdmin
      ? this.mainMenuItems[menu]
      : this.renderUserMainMenu(menu);
    return menuItems.map((mi) => {
      switch (mi.type) {
        case 'submenu': {
          return (
            <Menu.Item
              active={this.state.selectedMainmenu === mi.menuId}
              key={mi.menuId}
              onClick={() =>
                this.setState({
                  selectedSubmenu: mi.menuId,
                  selectedSubmenuName: mi.name,
                  innerSidebarVisible:
                    mi.showInnerSidebar &&
                    (!this.state.innerSidebarVisible ||
                      this.state.selectedSubmenu !== mi.menuId),
                })
              }
            >
              <Icon name={mi.icon} />
              {T(mi.name)}
            </Menu.Item>
          );
        }
        case 'link': {
          return (
            <Menu.Item
              as={Link}
              key={mi.subPath}
              to={`${match.path}/${mi.subPath}`}
              active={pathname === `${match.path}/${mi.subPath}`}
              onClick={() =>
                this.setState({
                  innerSidebarVisible: false,
                  selectedMainmenu: mi.subPath,
                })
              }
            >
              <Icon name={mi.icon} />
              {T(mi.name)}
            </Menu.Item>
          );
        }
        case 'accordion':
          return (
            <Accordion
              activeIndex={this.state.activeIndex}
              onClick={() =>
                this.setState({
                  activeIndex: this.state.activeIndex === -1 ? 0 : -1,
                })
              }
              key="workspace"
              panels={this.renderWorkspaces()}
              fluid
              inverted
            />
          );
        case 'logout': {
          return (
            <Menu.Item onClick={this.logout.bind(this)} key={mi.name}>
              <Icon name={mi.icon} />
              {T(mi.name)}
            </Menu.Item>
          );
        }
        default: {
          return null;
        }
      }
    });
  }

  renderUserSubMenu() {
    const { workspace } = this.props;
    const workspaceModules = Object.values(workspace.workspaceModules).map(
      (wm) => wm.module_id
    );
    return this.menuItems[this.state.selectedSubmenu].filter((mi) =>
      workspaceModules.some((m_id) => !mi.modules || mi.modules.includes(m_id))
    );
  }

  renderSubMenuItems() {
    const { match, location, isSuperAdmin } = this.props;
    const { pathname } = location;
    const userSubMenus = isSuperAdmin
      ? this.menuItems[this.state.selectedSubmenu]
      : this.renderUserSubMenu();
    return userSubMenus.map((mi) => (
      <Menu.Item
        as={Link}
        key={mi.subPath}
        to={`${match.path}/${mi.subPath}`}
        active={pathname === `${match.path}/${mi.subPath}`}
        onClick={() =>
          this.setState({
            innerSidebarVisible: false,
            selectedMainmenu: this.state.selectedSubmenu,
          })
        }
      >
        <Icon name={mi.icon} />
        {T(mi.name)}
      </Menu.Item>
    ));
  }

  renderInnerPage() {
    const { activeWorkspace } = this.props.workspace;
    if (!activeWorkspace.id) {
      return (
        <Segment id="innerPage">
          <Message
            info
            icon="attention"
            header={T('Please select a workspace to continue')}
          />
        </Segment>
      );
    }
    const { match } = this.props;
    const { workspaces, ws_id } = this.props.workspace;
    const appId = workspaces[ws_id] ? workspaces[ws_id].cnc_application : null;
    return (
      <Sidebar.Pushable
        as={Segment.Group}
        width="thin"
        style={{ overflow: 'hidden' }}
      >
        <Sidebar
          as={Menu}
          color="grey"
          inverted
          animation="overlay"
          vertical
          visible={this.state.innerSidebarVisible}
          id="innerSideMenu"
        >
          <Menu.Item header>
            <Header as="h4" inverted>
              {this.state.selectedSubmenuName}
            </Header>
          </Menu.Item>
          {this.renderSubMenuItems()}
        </Sidebar>
        <Sidebar.Pusher className="fullHeight">
          <Segment id="innerPage" onClick={() => this.handleSidebarHide()}>
            <Switch>
              <Route path={`${match.path}/advertising`}>
                <NotificationRoute />
              </Route>
              <Route path={`${match.path}/application`}>
                <ApplicationRoute />
              </Route>
              <Route path={`${match.path}/campaign`}>
                <CampaignRoute />
              </Route>
              <Route path={`${match.path}/cmscontent`}>
                <CmsContentRoute
                  config={{
                    content_category: 'application',
                    category_id: appId,
                  }}
                />
              </Route>
              <Route path={`${match.path}/company`}>
                <CompanyRoute />
              </Route>
              <Route path={`${match.path}/dashboard`} exact>
                <Dashboard />
              </Route>
              <Route path={`${match.path}/events`}>
                <EventRoute />
              </Route>
              <Route path={`${match.path}/lottery`}>
                <LotteryRoute />
              </Route>
              <Route path={`${match.path}/voting`}>
                <VotingRoute />
              </Route>
              <Route path={`${match.path}/performers`}>
                <PerformerRoute />
              </Route>
              <Route path={`${match.path}/persons`}>
                <PersonRoute />
              </Route>
              <Route path={`${match.path}/pricelist`}>
                <PricelistRoute />
              </Route>
              <Route path={`${match.path}/productregistration`}>
                <ProductRegistrationRoute />
              </Route>
              <Route path={`${match.path}/prometheusone`}>
                <PrometheusRoute />
              </Route>
              <Route path={`${match.path}/product`}>
                <ProductRoute />
              </Route>
              <Route path={`${match.path}/report`}>
                <ReportRoute />
              </Route>
              <Route path={`${match.path}/shop`}>
                <ShopRoute />
              </Route>
              <Route path={`${match.path}/ticketing`}>
                <TicketingRoute />
              </Route>
              <Route path={`${match.path}/membership`}>
                <MembershipRoute />
              </Route>
              <Route path={`${match.path}/venues`}>
                <VenueRoute />
              </Route>
              <Route path={`${match.path}/workcaselogging`}>
                <WorkCaseLoggingRoute />
              </Route>
              <Route path={`${match.path}/welcome`} exact>
                <Welcome />
              </Route>
            </Switch>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
  render() {
    // console.log('App.js render()', this.state, this.props);
    // console.log(' application props : ', this.props.auth);
    const lastVisitedSeasonId = this.props.lastActiveEventId;
    // console.log('last Visited season id: ', lastVisitedSeasonId);
    const eventPath = lastVisitedSeasonId
      ? `events/season/edit/${lastVisitedSeasonId}`
      : 'events/season/home';
    this.menuItems.events_and_teams[1] = {
      name: T('Events'),
      menuId: 'submenu_events',
      type: 'link',
      subPath: eventPath,
      icon: 'calendar alternate outline',
      modules: [3],
    };
    //Editing event path
    this.mainMenuItems.top = this.getMenuList();
    this.menuItems.contents = _.filter(
      this.props.ctypes,
      (type) =>
        type.dk_key !== 'hand_program' && type.dk_key !== 'privacypolicy'
    ).map((type) => ({
      name: T(type.dk_description),
      menuId: 'submenu_contents',
      type: 'link',
      subPath: `cmscontent/${type.dk_key}/list`,
      icon: 'file image outline',
      modules: [15, 16],
    }));
    return (
      <>
        <Menu
          vertical={false}
          as={Segment}
          inverted
          color={AEConfig.TITLEBAR_COLOR}
          size="small"
          fixed="top"
        >
          {this.renderWorkspaceSelector()}
          <Menu.Item header>{AEConfig.TITLEBAR_TEXT}</Menu.Item>
          <Menu.Menu position="right" icon="labeled">
            {this.renderMainMenuItems('userbuttons')}
          </Menu.Menu>
        </Menu>
        <div id="content-container">
          <Sidebar.Pushable id="pushable-container" width="thin">
            <Sidebar
              id="menuContainer"
              color="grey"
              inverted
              as={Menu}
              animation="push"
              borderless
              stackable
              vertical
              icon="labeled"
              visible
              width="thin"
              fixed="left"
            >
              <div id="resizingMenu">{this.renderMainMenuItems('top')}</div>
            </Sidebar>
            <Sidebar.Pusher
              className="fullHeight"
              style={{ overflow: 'hidden' }}
            >
              {this.renderInnerPage()}
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </div>
      </>
    );
  }
}

const stateToProps = ({
  auth,
  workspace,
  cmsContent,
  globalStates,
  languages,
}) => ({
  isSuperAdmin: auth.isSuperAdmin,
  auth,
  workspace,
  ws_id: workspace.ws_id,
  ctypes: cmsContent.ctypes,
  lastActiveEventId: globalStates.lastActiveEventId,
  languages,
});

export default connect(stateToProps, {
  authReset,
  setActiveWorkspace,
  fetchWorkspaceModules,
  setPrimaryLanguage,
})(withRouter(App));
