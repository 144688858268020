import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Button,
  Icon,
  Pagination,
  Confirm,
  Menu,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
import 'moment/locale/fi';
import { Spinner } from '../Common/Spinner';
import {
  fetchVotings,
  deleteVoting,
  resetDeleteVoting,
  fetchVotingReport,
} from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';

const ALL = true;
const PAGING = true;
const DETAILS = true;
const MODULE_ID = 1001;

class VotingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmAction: false,
      confirmTitle: '',
      confirmText: '',
      type: '',
    };
    this.deleteVoting = this.deleteVoting.bind(this);
    /*this.cancelLottery = this.cancelLottery.bind(this);*/
    this.onCancelConfirm = this.onCancelConfirm.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.sortVotings = this.sortVotings.bind(this);
  }

  componentDidMount() {
    // to come back to this after login
    // localStorage.setItem('route', this.props.history.location.pathname);

    const authKey = localStorage.getItem('x-auth-key');
    const module_id = MODULE_ID;
    const { ws_id } = this.props;
    const lang = this.props.auth.lang;
    const all = ALL;
    const paging = PAGING;
    const details = DETAILS;
    const column = 'game_id';
    const sortOrder = 'desc';

    this.props.fetchVotings({
      authKey,
      module_id,
      ws_id,
      start: 1,
      lang,
      all,
      paging,
      details,
      column,
      sortOrder,
    });
  }

  componentDidUpdate(props) {
    const { fetchAll } = this.props.voting.status;
    const prevFetchAll = props.voting.status.fetchAll;
    if (prevFetchAll !== 'error' && fetchAll === 'error') {
      const errorObj = getErrorMessage(
        this.props.voting.error,
        T('Error, fetching votings')
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }

    // delete voting
    if (this.props.voting.status.delete === 'success') {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Voting deleted successfully'),
        time: 5000,
      });
      this.props.resetDeleteVoting();
    } else if (this.props.voting.status.delete === 'error') {
      const errorObj = getErrorMessage(
        this.props.voting.error,
        T('Error while deleting voting')
      );
      toast({
        type: 'error',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
      this.props.resetDeleteVoting();
    }
    // cancel lottery
    /*if (this.props.lottery.status.cancel === 'success' && prevProps.lottery.status.cancel !== 'success') {
      toast({ type: 'success', title: T('Success'), description: T('Lottery status changed to draft'), time: 5000 });
      this.props.resetCancelLottery();
    } else if (this.props.lottery.status.cancel === 'error' && prevProps.lottery.status.cancel !== 'error') {
      const errorObj = getErrorMessage(this.props.lottery.error, T('Error while cancelling lottery'));
      toast({ type: 'error', title: T('Error'), description: T(errorObj.message), time: 5000 });
      this.props.resetCancelLottery();
    }*/
  }

  onCancelConfirm() {
    this.setState({ confirmAction: false });
  }

  onConfirm() {
    const { type, selectedVoting } = this.state;
    const authKey = localStorage.getItem('x-auth-key');
    const { ws_id } = this.props;
    if (type === 'delete' && selectedVoting) {
      this.props.deleteVoting(authKey, selectedVoting, MODULE_ID, ws_id);
    } else if (type === 'cancel' && selectedVoting) {
      this.props.cancelVoting(authKey, selectedVoting, ws_id);
    }
    this.setState({ confirmAction: false });
  }

  getDirection(dirn) {
    return dirn === 'asc' ? 'ascending' : 'descending';
  }

  sortVotings(column) {
    const { direction, colName } = this.state;
    const authKey = localStorage.getItem('x-auth-key');
    const { searchterm } = this.props.voting.headers;
    const module_id = MODULE_ID;
    const { ws_id } = this.props;
    const lang = this.props.auth.lang;
    const all = ALL;
    const paging = PAGING;
    const details = DETAILS;

    if (colName === column) {
      const sortOrder = direction === 'asc' ? 'desc' : 'asc';
      this.props.fetchVotings({
        authKey,
        module_id,
        ws_id,
        start: 1,
        lang,
        all,
        paging,
        details,
        searchterm,
        column,
        sortOrder,
      });
      this.setState({ direction: sortOrder });
    } else {
      const sortOrder = 'asc';
      this.props.fetchVotings({
        authKey,
        module_id,
        ws_id,
        start: 1,
        lang,
        all,
        paging,
        details,
        searchterm,
        column,
        sortOrder,
      });
      this.setState({ colName: column, direction: sortOrder });
    }
  }

  handlePaginationChange(e, { activePage }) {
    const authKey = localStorage.getItem('x-auth-key');
    const { searchterm, sort } = this.props.voting.headers;
    const module_id = MODULE_ID;
    const { ws_id } = this.props;
    const lang = this.props.auth.lang;
    const all = ALL;
    const paging = PAGING;
    const details = DETAILS;
    const columns = sort.split(':');
    const column = columns[0];
    const sortOrder = columns[1];

    this.props.fetchVotings({
      authKey,
      module_id,
      ws_id,
      start: activePage,
      lang,
      all,
      paging,
      details,
      searchterm,
      column,
      sortOrder,
    });
  }

  navigateToLink = (game_id) => {
    this.props.history.push({ pathname: `/app/voting/manage/${game_id}` });
  };

  downloadReport = (id, type) => {
    const authKey = localStorage.getItem('x-auth-key');
    const module_id = MODULE_ID;
    const { ws_id } = this.props;
    const lang = this.props.auth.lang;
    this.props.fetchVotingReport(authKey, module_id, ws_id, id, type, lang);
  };

  deleteVoting = (id, ga_name) => {
    this.setState({
      confirmAction: true,
      selectedVoting: id,
      type: 'delete',
      confirmTitle: 'Delete',
      confirmText: `Are you sure you want to delete the ${ga_name} voting?`,
    });
  };

  /*cancelLottery = (id) => {
    this.setState({
      confirmAction: true,
      selectedLottery: id,
      type: 'cancel',
      confirmTitle: 'Cancel',
      confirmText: 'Are you sure you want to cancel the selected lottery?',
    });
  }*/

  renderVotingsList() {
    const { data } = this.props.voting;
    //const { ws_id } = this.props;
    // some locale formatting might be good
    return _.map(data, (ga) => {
      //const isEditable = true;
      const isEditable = ga.status !== 'finalized';
      //const canCancel = lo.lo_status !== 'draft';
      //const canCancel = true;
      const cursor = isEditable ? { cursor: 'pointer' } : { cursor: 'default' };
      return (
        <Table.Row
          key={ga.game_id}
          //onClick={() => (isEditable ? this.navigateToLink(ga.game_id) : null)}
          onClick={() => this.navigateToLink(ga.game_id)}
          style={cursor}
          warning={isEditable}
        >
          <Table.Cell>{T(ga.game_id)}</Table.Cell>
          <Table.Cell>{T(ga.ga_name)}</Table.Cell>
          <Table.Cell>
            {ga.ga_game_start ? moment(ga.ga_game_start).format('l HH:mm') : ''}
          </Table.Cell>
          <Table.Cell>
            {ga.ga_game_stop ? moment(ga.ga_game_stop).format('l HH:mm') : ''}
          </Table.Cell>
          <Table.Cell>{T(ga.status)}</Table.Cell>
          <Table.Cell textAlign="right">{ga.participated}</Table.Cell>
          <Table.Cell textAlign="right">{ga.targets}</Table.Cell>
          <Table.Cell textAlign="right">{ga.winner}</Table.Cell>
          <Table.Cell
            collapsing
            textAlign="left"
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              icon="file excel"
              label={T('XLS')}
              size="small"
              onClick={() => this.downloadReport(ga.game_id, 'xls')}
            />
            {isEditable ? (
              <Button
                type="button"
                color="red"
                icon="trash"
                size="small"
                content={T('Delete')}
                onClick={() => this.deleteVoting(ga.game_id, ga.ga_name)}
              />
            ) : null}
          </Table.Cell>
        </Table.Row>
      );
    });
  }
  render() {
    const { fetchAll } = this.props.voting.status;
    if (fetchAll === 'waiting' || fetchAll === 'init') {
      return <Spinner />;
    }
    const { direction, colName } = this.state;
    return (
      <React.Fragment>
        <Table sortable compact celled padded stackable selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={
                  colName === 'game_id' ? this.getDirection(direction) : null
                }
                onClick={() => this.sortVotings('game_id')}
              >
                {T('#')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  colName === 'ga_name' ? this.getDirection(direction) : null
                }
                onClick={() => this.sortVotings('ga_name')}
              >
                {T('Name')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  colName === 'ga_game_start'
                    ? this.getDirection(direction)
                    : null
                }
                onClick={() => this.sortVotings('ga_game_start')}
              >
                {T('Voting starts')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  colName === 'ga_game_stop'
                    ? this.getDirection(direction)
                    : null
                }
                onClick={() => this.sortVotings('ga_game_stop')}
              >
                {T('Voting ends')}
              </Table.HeaderCell>
              <Table.HeaderCell>{T('Status')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Participated')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Targets')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Winner target')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderVotingsList()}
            <Table.Row>
              <Confirm
                header={T(this.state.confirmTitle)}
                content={T(this.state.confirmText)}
                cancelButton={T('Cancel')}
                confirmButton={T(this.state.confirmTitle)}
                open={this.state.confirmAction}
                onCancel={this.onCancelConfirm}
                onConfirm={this.onConfirm}
                size="tiny"
              />
            </Table.Row>
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="8">
                <Link to="/app/voting/manage">
                  <Button icon="plus" primary content={T('Add')} />
                </Link>
                {this.props.voting.headers &&
                  this.props.voting.headers.total_pages > 1 && (
                    <Menu floated="right" pagination>
                      <Pagination
                        onPageChange={this.handlePaginationChange.bind(this)}
                        defaultActivePage={
                          this.props.voting.headers.current_page
                        }
                        ellipsisItem={{
                          content: <Icon name="ellipsis horizontal" />,
                          icon: true,
                        }}
                        firstItem={{
                          content: <Icon name="angle double left" />,
                          icon: true,
                        }}
                        lastItem={{
                          content: <Icon name="angle double right" />,
                          icon: true,
                        }}
                        prevItem={{
                          content: <Icon name="angle left" />,
                          icon: true,
                        }}
                        nextItem={{
                          content: <Icon name="angle right" />,
                          icon: true,
                        }}
                        totalPages={this.props.voting.headers.total_pages}
                      />
                    </Menu>
                  )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ auth, workspace, voting }) {
  return { auth, voting, ws_id: workspace.ws_id };
}

export default connect(mapStateToProps, {
  fetchVotings,
  deleteVoting,
  resetDeleteVoting,
  fetchVotingReport,
})(withRouter(VotingList));
