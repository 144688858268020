import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Segment, Message, Menu, Form, Button } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import {
  fetchSalesReport,
  downloadSalesReport,
  downloadSoldItemsReport,
  fetchSoldItemsReport,
  resetFetchSalesReport,
  resetFetchSoldItemsReport,
} from '../../../Redux/actions';
import { T, getErrorMessage } from '../../Common/Helpers';
import { Spinner } from '../../Common/Spinner';
import SoldItemsGrid from './SoldItemsGrid';
import SalesSummary from './SalesSummary';
import SalesGrid from './SalesGrid';

const styles = {
  errorStyle: {
    backgroundColor: '#FFF6F6',
    border: ' solid 1px #E0B4B4',
    color: '#9F3A38',
    WebkitBoxShadow: 'none',
    oxShadow: 'none',
  },
};

class SalesReportContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'sales',
      activeSubItem: 'month',
      pricelist: null,
      shop: null,
      sl_status: null,
      searchterm: '',
      lang: 'en',
      includeCancelled: false,
      startError: {},
      endError: {},
      reportRange: {
        day: {
          start: moment().startOf('day').toISOString(),
          end: moment().startOf('day').toISOString(),
        },
        month: {
          start: moment().startOf('month').subtract(1, 'months').toISOString(),
          end: moment().startOf('month').subtract(1, 'days').toISOString(),
        },
        isoweek: {
          start: moment().startOf('isoweek').subtract(1, 'weeks').toISOString(),
          end: moment().startOf('isoweek').subtract(1, 'days').toISOString(),
        },
        custom: {
          start: moment().startOf('day').toISOString(),
          end: moment().startOf('day').toISOString(),
        },
      },
    };
    this.fetchReportOnSearchTerm = _.debounce(
      this.fetchReportOnSearchTerm,
      2000
    );
  }

  componentDidMount() {
    this.props.resetFetchSalesReport();
    this.props.resetFetchSoldItemsReport();
  }

  componentDidUpdate(prevProps) {
    const { activeItem } = this.state;
    // if (prevProps.status[activeItem] !== 'success' && this.props.status[activeItem] === 'success') {
    //   toast({ type: 'success', title: T('Success'), description: T('Report fetched successfully') });
    // } else
    if (
      prevProps.status[activeItem] !== 'error' &&
      this.props.status[activeItem] === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.error,
        'Error while fetching report'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(`${errorObj.status}: ${errorObj.message}`),
        time: 5000,
      });
      // toast({ type: 'error', title: T('Error'), description: T('Error while fetching report') });
    } else if (
      prevProps.status[`${activeItem}Download`] !== 'success' &&
      this.props.status[`${activeItem}Download`] === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Report downloaded successfully'),
      });
    } else if (
      prevProps.status[`${activeItem}Download`] !== 'error' &&
      this.props.status[`${activeItem}Download`] === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.error,
        'Error while downloading report'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(`${errorObj.status}: ${errorObj.message}`),
        time: 5000,
      });
      // toast({ type: 'error', title: T('Error'), description: T('Error while downloading report') });
    }
  }

  getPriceLists = (pricelists) =>
    _.map(pricelists, (pl) => ({ text: pl.pl_name, value: pl.price_list_id }));
  getShops = (shops) =>
    _.map(shops, (sh) => ({ text: sh.sh_name, value: sh.shop_id }));

  getRequestParms = () => {
    const { activeSubItem, searchterm, lang, activeItem, sl_status } =
      this.state;
    const { ws_id } = this.props;
    const companyId = this.props.workspace.workspaces[ws_id].cnc_company;
    const all = this.state.includeCancelled;
    const sl_price_list =
      this.state.pricelist === -1 ? null : this.state.pricelist;
    const sl_shop = this.state.shop === -1 ? null : this.state.shop;
    const header = this.props.headers[activeItem] || {};
    const sort = header.sort || 'sales_id:asc';
    let dateRange = _.cloneDeep(this.state.reportRange);
    dateRange = dateRange[activeSubItem];
    const tempRange = {
      start: moment(dateRange.start).format('YYYY-MM-DD'),
      end: moment(dateRange.end).format('YYYY-MM-DD'),
    };
    return {
      ws_id,
      companyId,
      all,
      sl_price_list,
      sl_shop,
      sort,
      reportRange: tempRange,
      searchterm,
      lang,
      sl_status,
    };
  };

  getReportStatus = (status) =>
    _.map(status, (st) => ({ text: st.name, value: st.status }));

  setActiveMenu = (key, activeItem) => this.setState({ [key]: activeItem });

  handleSearch = (e, searchterm) => {
    this.setState({ searchterm });
    this.fetchReportOnSearchTerm();
  };

  handleKeyPress = (e) => {
    // console.log(e.key);
    if (e.key === 'Enter' && this.state.searchterm.trim() !== '') {
      this.fetchReportOnSearchTerm.cancel();
      this.fetchReport(this.state.searchterm);
    }
  };

  fetchReportOnSearchTerm = () => {
    this.fetchReport(this.state.searchterm);
  };

  changeRange = (op, rangeType) => {
    let diff = 'days';
    if (rangeType === 'month') {
      diff = 'months';
    } else if (rangeType === 'isoweek') {
      diff = 'weeks';
    }
    const range = _.cloneDeep(this.state.reportRange); // deep copy needed for the nested object
    // console.log('Start before: ', range[rangeType].start, '\nEnd before: ', range[rangeType].end);
    if (op === 'add') {
      range[rangeType].start = moment(range[rangeType].start)
        .add(1, diff)
        .toISOString();
      range[rangeType].end = moment(range[rangeType].start)
        .endOf(rangeType)
        .toISOString();
    } else {
      range[rangeType].start = moment(range[rangeType].start)
        .subtract(1, diff)
        .toISOString();
      range[rangeType].end = moment(range[rangeType].start)
        .endOf(rangeType)
        .toISOString();
    }
    // console.log('Start after: ', range[rangeType].start, '\nEnd after: ', range[rangeType].end);
    if (moment(range[rangeType].end).isSameOrBefore(new moment())) {
      this.setState({ reportRange: range, startError: {}, endError: {} });
    }
  };

  handleChange = (name, value) => {
    if (name === 'sl_status') {
      if (value === 9) {
        this.setState({ [name]: value, includeCancelled: true });
      } else {
        this.setState({ [name]: value, includeCancelled: false });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  handleDateChange = (name, value) => {
    const { activeSubItem } = this.state;
    const reportRange = _.cloneDeep(this.state.reportRange);
    const dateTime = moment(value, 'l');
    if (dateTime.isValid()) {
      reportRange[activeSubItem][name] = dateTime.toISOString();
      if (
        moment(reportRange[activeSubItem].start) <=
        moment(reportRange[activeSubItem].end)
      ) {
        this.setState({ [`${name}Error`]: {}, reportRange });
      } else {
        this.setState({ [`${name}Error`]: styles.errorStyle });
      }
    }
  };

  fetchReport = (search) => {
    const { activeItem } = this.state;
    const authKey = localStorage.getItem('x-auth-key');
    const searchterm = search || this.state.searchterm;
    const startPage = 1;
    const {
      ws_id,
      companyId,
      all,
      sl_price_list,
      sl_shop,
      sort,
      reportRange,
      sl_status,
    } = this.getRequestParms();
    if (activeItem === 'sales') {
      this.props.fetchSalesReport({
        authKey,
        start: startPage,
        companyId,
        range: reportRange,
        all,
        sl_price_list,
        sl_shop,
        searchterm,
        sort,
        ws_id,
        status: sl_status,
      });
    } else if (activeItem === 'soldItems') {
      this.props.fetchSoldItemsReport({
        authKey,
        start: startPage,
        companyId,
        range: reportRange,
        all,
        sl_price_list,
        sl_shop,
        searchterm,
        sort,
        ws_id,
        status: sl_status,
      });
    }
  };

  render() {
    const { activeItem, activeSubItem } = this.state;
    if (
      this.props.status[activeItem] === 'waiting' ||
      this.props.status[`${activeItem}Download`] === 'waiting'
    ) {
      return <Spinner />;
    }
    const { shops, pricelists } = this.props;
    //console.log(this.props.shops);
    // if user wants the report of all shops combined
    // console.log('State: ', this.state);
    const { sales, totals } = this.props.summary[activeItem];
    const reportStatus = [
      { status: 1, name: T('Open') },
      { status: 2, name: T('Paid') },
      { status: 3, name: T('Picked up') },
      { status: 5, name: T('Ticket Validated') },
      { status: 9, name: T('Canceled') },
    ];
    return (
      <>
        <Menu attached="top" tabular>
          <Menu.Item
            name="Sale"
            content={T('Sales')}
            active={activeItem === 'sales'}
            onClick={() => this.setActiveMenu('activeItem', 'sales')}
          />
          <Menu.Item
            name="Sold Items"
            content={T('Sold Items')}
            active={activeItem === 'soldItems'}
            onClick={() => this.setActiveMenu('activeItem', 'soldItems')}
          />
        </Menu>
        <Segment attached="bottom">
          <Form>
            <Form.Group>
              <Button.Group basic>
                <Button
                  name="Month"
                  content={T('Month')}
                  active={activeSubItem === 'month'}
                  onClick={() => this.setActiveMenu('activeSubItem', 'month')}
                />
                <Button
                  name="Week"
                  content={T('Week')}
                  active={activeSubItem === 'isoweek'}
                  onClick={() => this.setActiveMenu('activeSubItem', 'isoweek')}
                />
                <Button
                  name="Day"
                  content={T('Day')}
                  active={activeSubItem === 'day'}
                  onClick={() => this.setActiveMenu('activeSubItem', 'day')}
                />
                <Button
                  name="Custom"
                  content={T('Custom')}
                  active={activeSubItem === 'custom'}
                  onClick={() => this.setActiveMenu('activeSubItem', 'custom')}
                />
              </Button.Group>
              <Button
                basic
                style={{ marginLeft: '1em' }}
                size="big"
                icon="angle left"
                onClick={() => this.changeRange('subtract', activeSubItem)}
              />
              <DateInput
                closable
                style={{ minWidth: '200px' }}
                name="start"
                label={T('Sales from')}
                onClick={() => this.setState({ activeSubItem: 'custom' })}
                onChange={(e, { name, value }) =>
                  this.handleDateChange(name, value)
                }
                value={moment(
                  this.state.reportRange[activeSubItem].start
                ).format('l')}
                dateFormat="l"
                maxDate={moment().format('l')}
              />
              <DateInput
                closable
                style={{ minWidth: '200px' }}
                name="end"
                label={T('Sales to')}
                onClick={() => this.setState({ activeSubItem: 'custom' })}
                onChange={(e, { name, value }) =>
                  this.handleDateChange(name, value)
                }
                value={moment(this.state.reportRange[activeSubItem].end).format(
                  'l'
                )}
                dateFormat="l"
                maxDate={moment().format('l')}
              />
              <Button
                basic
                style={{ marginRight: '.5em' }}
                size="big"
                icon="angle right"
                onClick={() => this.changeRange('add', activeSubItem)}
              />
              <Form.Input
                autoFocus
                // disabled={this.props.status[activeItem] !== 'success' || (sales.length === 0) || this.state.searchterm.trim() !== ''}
                name="searchterm"
                label={T('Search')}
                placeholder={T('Search')}
                value={this.state.searchterm}
                onChange={(e, { value }) => this.handleSearch(e, value)}
                onKeyPress={this.handleKeyPress}
                icon="search"
              />
            </Form.Group>
            <Form.Group>
              <Form.Select
                clearable
                search
                style={{ minWidth: '200px' }}
                placeholder={T('All statuses')}
                name="sl_status"
                label={T('Status')}
                value={this.state.sl_status}
                options={this.getReportStatus(reportStatus)}
                selectOnBlur={false}
                onChange={(e, { name, value }) =>
                  this.handleChange(name, value)
                }
              />
              <Form.Select
                clearable
                style={{ minWidth: '200px' }}
                search
                name="shop"
                placeholder={T('All shops')}
                label={T('Shop')}
                selectOnBlur={false}
                options={this.getShops(shops)}
                onChange={(e, { name, value }) =>
                  this.handleChange(name, value)
                }
                value={this.state.shop}
              />
              {activeItem === 'soldItems' && (
                <Form.Select
                  style={{ minWidth: '200px' }}
                  clearable
                  name="pricelist"
                  placeholder={T('All price lists')}
                  label={T('Price list')}
                  options={this.getPriceLists(pricelists)}
                  onChange={(e, { name, value }) =>
                    this.handleChange(name, value)
                  }
                  value={this.state.pricelist}
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Checkbox
                toggle
                name="includeCancelled"
                label={T('Include Cancelled')}
                checked={this.state.includeCancelled}
                onChange={(e, { name, checked }) =>
                  this.handleChange(name, checked)
                }
              />
            </Form.Group>
            <Button
              primary
              content={T('Display Report')}
              onClick={() => this.fetchReport()}
            />
          </Form>
        </Segment>

        {totals[0] ? (
          <SalesSummary totals={totals[0]} activeItem={activeItem} />
        ) : null}
        {this.props.status[activeItem] === 'success' &&
          (sales.length === 0 ? (
            <Message style={{ marginTop: '15px' }} info>
              <Message.Header> {T('Info')}</Message.Header>
              <p>{T('There is no report for the selected parameters')}</p>
            </Message>
          ) : activeItem === 'sales' ? (
            <SalesGrid
              sales={sales}
              searchParams={this.getRequestParms()}
              languages={this.props.languages}
            />
          ) : (
            <SoldItemsGrid
              sales={sales}
              searchParams={this.getRequestParms()}
              languages={this.props.languages}
            />
          ))}
      </>
    );
  }
}

function mapStateToProps({ sales, workspace }) {
  return {
    downloadStatus: sales.status.salesDownload,
    status: sales.status,
    ws_id: workspace.ws_id,
    summary: sales.summary,
    error: sales.error,
    headers: sales.headers,
    workspace,
  };
}

export default connect(mapStateToProps, {
  fetchSalesReport,
  downloadSalesReport,
  downloadSoldItemsReport,
  fetchSoldItemsReport,
  resetFetchSalesReport,
  resetFetchSoldItemsReport,
})(SalesReportContainer);
