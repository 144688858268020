/* eslint-env browser */
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-semantic-toasts';
import {
  Table,
  Message,
  Icon,
  Menu,
  Pagination,
  Header,
} from 'semantic-ui-react';
import {
  fetchProducts,
  resetProducts,
  addProductPrice,
  editProductPrice,
  resetAddProductPrice,
  resetEditProductPrice,
} from '../../Redux/actions';
import { Spinner } from '../Common/Spinner';
import { T, getErrorMessage } from '../Common/Helpers';
import RenderPrices from './RenderPrices';
// Todo: remove hardcoded values ...
const pr_type = 'entry';
// const pp_currency = 'pts';
// const pp_price_list = 27;

class ListTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByColumn: 'product_id',
      direction: 'asc',
      openDeleteConfirmation: false,
      // prices: [],
      // priceLists: []
    };
  }
  componentDidMount() {
    // console.log('opening with props ', this.props);
    if (!this.props.productsList) {
      const ws_id = this.props.workspace.activeWorkspace.id;
      // localStorage.setItem('route', this.props.history.location.pathname);
      const authKey = localStorage.getItem('x-auth-key');
      this.props.fetchProducts({
        authKey,
        sortByColumn: 'product_id',
        direction: 'asc',
        start: 1,
        searchterm: null,
        pr_type,
        ws_id,
        module_id: 522,
      });
    }
  }

  componentDidUpdate(prevProps, nextProps) {
    if (
      prevProps.product.fetchStatus !== 'error' &&
      this.props.product.fetchStatus === 'error'
    ) {
      // this.props.resetProducts();
      const errorObj = getErrorMessage(
        nextProps.product.error,
        'Could not fetch products'
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  componentWillUnmount() {
    this.props.resetProducts();
  }
  getDirection(dirn) {
    if (dirn === 'asc') {
      return 'ascending';
    }
    return 'descending';
  }

  handlePaginationChange = (e, { activePage }) => {
    // this should be updated to list price-list products for the current price-list
    const ws_id = this.props.workspace.activeWorkspace.id;
    const { headers } = this.props.product;
    const authKey = localStorage.getItem('x-auth-key');
    let params = {
      authKey,
      sortByColumn: headers.sort,
      direction: null,
      start: activePage,
      searchterm: headers.searchterm,
      pr_type,
      ws_id, //<price-list-id> will be included
      module_id: 522,
    };
    if (this.props.pp_price_list) {
      params = { ...params, pp_price_list: this.props.pp_price_list };
    }
    this.props.fetchProducts(params);
  };

  sortByColumn = (columnName) => {
    // this should be updated to list price-list products for the current price-list
    // console.log(' col name ', columnName);
    const { direction, sortByColumn } = this.state;
    const { searchterm } = this.props.product.headers;
    const ws_id = this.props.workspace.activeWorkspace.id;
    const authKey = localStorage.getItem('x-auth-key');
    let params = {
      authKey,
      sortByColumn: columnName,
      start: 1,
      searchterm,
      pr_type,
      ws_id,
      module_id: 522,
    };
    if (this.props.pp_price_list) {
      params = { ...params, pp_price_list: this.props.pp_price_list };
    }
    if (sortByColumn === columnName) {
      const modifiedDirn = direction === 'asc' ? 'desc' : 'asc';
      this.props.fetchProducts({ ...params, direction: modifiedDirn });
      this.setState({ direction: modifiedDirn });
    } else {
      const modifiedDirn = 'asc';
      this.props.fetchProducts({ ...params, direction: modifiedDirn });
      this.setState({ sortByColumn: columnName, direction: modifiedDirn });
    }
  };

  render() {
    if (
      this.props.product.fetchStatus === 'init' ||
      this.props.product.fetchStatus === 'waiting'
    ) {
      return <Spinner />;
    } else if (this.props.product.fetchStatus === 'error') {
      return (
        <Message
          error
          header={T('Error')}
          list={[T('Error while fetching tickets')]}
        />
      );
    }
    let priceLists = {};
    // console.log('Products prop: ', this.props.product.products);
    // const productData = this.state.internalSearch ? this.props.product.products : this.props.productsList;
    const productsWithPriceList = _.filter(
      this.props.products,
      (p) => p.pricelists !== null
    );
    _.map(productsWithPriceList, (product) =>
      _.forEach(product.pricelists, (pricelist) => {
        priceLists = {
          ...priceLists,
          [pricelist.product_price_id]: {
            price: pricelist.pp_price,
            pp_price_list: pricelist.pp_price_list,
            product_price_id: pricelist.product_price_id,
            pr_id: product.product_id,
            pr_name: product.pr_name,
            pr_code: product.pr_code,
            pr_active: product.pr_active,
            pr_short_description: product.pr_short_description,
            pp_currency: pricelist.pp_currency,
          },
        };
        // console.log('Product: ', products);
      })
    );

    const column = this.state.sortByColumn;
    const dirn = this.state.direction;

    return (
      <>
        <Header> {T('Ticket list')} </Header>
        <Table sortable compact celled padded stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={
                  column === 'product_id' ? this.getDirection(dirn) : null
                }
                onClick={() => this.sortByColumn('product_id')}
              >
                {T('Id')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'pr_name' ? this.getDirection(dirn) : null}
                onClick={() => this.sortByColumn('pr_name')}
              >
                {T('Name')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'pr_code' ? this.getDirection(dirn) : null}
                onClick={() => this.sortByColumn('pr_code')}
              >
                {T('Code')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {T('Name On Receipt (Short Description)')}
              </Table.HeaderCell>
              <Table.HeaderCell>{T('Price list id')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Product price id')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Currency')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Active')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Price')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <RenderPrices {...this.props} priceLists={priceLists} />
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="9">
                {this.props.product.headers &&
                  this.props.product.headers.total_pages > 1 && (
                    <Menu floated="right" pagination>
                      <Pagination
                        onPageChange={this.handlePaginationChange}
                        defaultActivePage={
                          this.props.product.headers.current_page
                        }
                        ellipsisItem={{
                          content: <Icon name="ellipsis horizontal" />,
                          icon: true,
                        }}
                        firstItem={{
                          content: <Icon name="angle double left" />,
                          icon: true,
                        }}
                        lastItem={{
                          content: <Icon name="angle double right" />,
                          icon: true,
                        }}
                        prevItem={{
                          content: <Icon name="angle left" />,
                          icon: true,
                        }}
                        nextItem={{
                          content: <Icon name="angle right" />,
                          icon: true,
                        }}
                        totalPages={this.props.product.headers.total_pages}
                      />
                    </Menu>
                  )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </>
    );
  }
}

function mapStateToProps({ auth, product, workspace }) {
  return { auth, product, workspace, products: product.products };
}

export default connect(mapStateToProps, {
  fetchProducts,
  resetProducts,
  addProductPrice,
  editProductPrice,
  resetAddProductPrice,
  resetEditProductPrice,
})(withRouter(ListTickets));
