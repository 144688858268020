import {
  UPLOAD_FILE_WAITING,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_RESET,
  DELETE_FILE_WAITING,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_ERROR,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  uploadStatus: 'init',
  ref: null,
};

export default function FileUploadReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case UPLOAD_FILE_WAITING:
      return { ...state, uploadStatus: 'waiting', ref: action.ref };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        uploadStatus: 'success',
        ref: action.ref,
      };
    case UPLOAD_FILE_ERROR:
      return {
        ...state,
        uploadStatus: 'error',
        error: action.payload.error,
        ref: action.ref,
      };
    case UPLOAD_FILE_RESET:
      return { ...INIT_STATE };
    case DELETE_FILE_WAITING:
      return { ...state, deleteStatus: 'waiting', ref: action.ref };
    case DELETE_FILE_SUCCESS:
      return { ...state, data: action.payload.data, deleteStatus: 'success' };
    case DELETE_FILE_ERROR:
      return {
        ...state,
        deleteStatus: 'error',
        error: action.payload.error,
        ref: action.ref,
      };
    default:
      return state;
  }
}
