import React from 'react';
import { Loader } from 'semantic-ui-react';
import { T } from './Helpers';

export const Spinner = (props) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 1000,
      background: 'rgba(255, 255, 255, 0.8)',
    }}
  >
    <Loader active size="large" content={T('Loading')} />
  </div>
);
