import _ from 'lodash';
import {
  FETCH_COMPANIES_WAITING,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_ERROR,
  FETCH_COMPANIES_RESET,
  FETCH_COMPANY_WAITING,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
  FETCH_COMPANY_RESET,
  ADD_COMPANY_WAITING,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_ERROR,
  ADD_COMPANY_RESET,
  EDIT_COMPANY_WAITING,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_ERROR,
  EDIT_COMPANY_RESET,
  DELETE_COMPANY_WAITING,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,
  DELETE_COMPANY_RESET,
  UPLOAD_COMPANY_LOGO_WAITING,
  UPLOAD_COMPANY_LOGO_SUCCESS,
  UPLOAD_COMPANY_LOGO_ERROR,
  UPLOAD_COMPANY_LOGO_RESET,
  DELETE_COMPANY_LOGO_WAITING,
  DELETE_COMPANY_LOGO_SUCCESS,
  DELETE_COMPANY_LOGO_ERROR,
  DELETE_COMPANY_LOGO_RESET,
  AUTH_RESET,
} from '../Types';

const INIT_STATE = {
  operationState: {
    add: 'init',
    delete: 'init',
    update: 'init',
    fetch: 'init',
    fetchAll: 'init',
  },
  companies: {},
  company: {},
};

export default function CompanyReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case AUTH_RESET:
      return { ...INIT_STATE };
    case FETCH_COMPANIES_WAITING: {
      const operationState = { ...state.operationState, fetchAll: 'waiting' };
      return { ...state, operationState };
    }
    case FETCH_COMPANIES_SUCCESS: {
      const companies = _.mapKeys(action.payload.data.items, 'company_id');
      const operationState = { ...state.operationState, fetchAll: 'success' };
      return {
        ...state,
        companies,
        operationState,
        header: action.payload.headers,
      };
    }
    case FETCH_COMPANIES_ERROR: {
      const operationState = { ...state.operationState, fetchAll: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case FETCH_COMPANIES_RESET: {
      const operationState = { ...state.operationState, fetchAll: 'init' };
      return { ...state, operationState };
    }

    case FETCH_COMPANY_WAITING: {
      const operationState = { ...state.operationState, fetch: 'waiting' };
      return { ...state, operationState };
    }
    case FETCH_COMPANY_SUCCESS: {
      const operationState = { ...state.operationState, fetch: 'success' };
      return { ...state, company: action.payload.data, operationState };
    }
    case FETCH_COMPANY_ERROR: {
      const operationState = { ...state.operationState, fetch: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case FETCH_COMPANY_RESET: {
      const operationState = { ...state.operationState, fetch: 'init' };
      return { ...state, operationState };
    }

    case ADD_COMPANY_WAITING: {
      const operationState = { ...state.operationState, add: 'waiting' };
      return { ...state, operationState };
    }
    case ADD_COMPANY_SUCCESS: {
      const operationState = { ...state.operationState, add: 'success' };
      return {
        ...state,
        companies: {
          ...state.companies,
          [action.payload.data.company_id]: action.payload.data,
        },
        company: action.payload.data,
        operationState,
      };
    }
    case ADD_COMPANY_ERROR: {
      const operationState = { ...state.operationState, add: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case ADD_COMPANY_RESET: {
      const operationState = { ...state.operationState, add: 'init' };
      return { ...state, operationState };
    }

    case EDIT_COMPANY_WAITING: {
      const operationState = { ...state.operationState, update: 'waiting' };
      return { ...state, operationState };
    }
    case EDIT_COMPANY_SUCCESS: {
      const operationState = { ...state.operationState, update: 'success' };
      // console.log('State: ', state, '\nPayload: ', action.payload);
      return {
        ...state,
        companies: {
          ...(state.companies || {}),
          [action.payload.data.company_id]: action.payload.data,
        },
        company: { ...state.company, ...action.payload.data },
        operationState,
      };
    }
    case EDIT_COMPANY_ERROR: {
      const operationState = { ...state.operationState, update: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case EDIT_COMPANY_RESET: {
      const operationState = { ...state.operationState, update: 'init' };
      return { ...state, operationState };
    }

    case DELETE_COMPANY_WAITING: {
      const operationState = { ...state.operationState, delete: 'waiting' };
      return { ...state, ...operationState };
    }
    case DELETE_COMPANY_SUCCESS: {
      const operationState = { ...state.operationState, delete: 'success' };
      const companies = _.omit(state.companies, action.payload.data.company_id);
      return { ...state, companies, operationState };
    }
    case DELETE_COMPANY_ERROR: {
      const operationState = { ...state.operationState, delete: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case DELETE_COMPANY_RESET: {
      const operationState = { ...state.operationState, delete: 'init' };
      return { ...state, operationState };
    }

    case UPLOAD_COMPANY_LOGO_WAITING: {
      const operationState = {
        ...state.operationState,
        uploadStatus: 'waiting',
      };
      return { ...state, operationState };
    }
    case UPLOAD_COMPANY_LOGO_SUCCESS: {
      const operationState = {
        ...state.operationState,
        uploadStatus: 'success',
      };
      const company_logo = {
        ...action.payload.data,
        fl_path_small: action.payload.data.urlSmall,
      };
      const company = { ...state.company, company_logo: [company_logo] };
      return { ...state, company, operationState };
    }
    case UPLOAD_COMPANY_LOGO_ERROR: {
      const operationState = { ...state.operationState, uploadStatus: 'error' };
      return { ...state, operationState, error: action.payload.error };
    }
    case UPLOAD_COMPANY_LOGO_RESET: {
      const operationState = { ...state.operationState, uploadStatus: 'init' };
      return { ...state, operationState };
    }

    case DELETE_COMPANY_LOGO_WAITING: {
      const operationState = {
        ...state.operationState,
        deleteLogoStatus: 'waiting',
      };
      return { ...state, operationState };
    }
    case DELETE_COMPANY_LOGO_SUCCESS: {
      const operationState = {
        ...state.operationState,
        deleteLogoStatus: 'success',
      };
      const company = { ...state.company, company_logo: [] };
      return { ...state, company, operationState };
    }
    case DELETE_COMPANY_LOGO_ERROR: {
      const operationState = {
        ...state.operationState,
        deleteLogoStatus: 'error',
      };
      return { ...state, operationState, error: action.payload.error };
    }
    case DELETE_COMPANY_LOGO_RESET: {
      const operationState = {
        ...state.operationState,
        deleteLogoStatus: 'init',
      };
      return { ...state, operationState };
    }

    default:
      return state;
  }
}
