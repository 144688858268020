import axios from 'axios';
import FileSaver from 'file-saver';
import config from '../../Config/AEConfig';
import {
  FETCH_CAMPAIGNCODES_WAITING,
  FETCH_CAMPAIGNCODES_SUCCESS,
  FETCH_CAMPAIGNCODES_ERROR,
  FETCH_CAMPAIGNCODES_RESET,
  ADD_CAMPAIGNCODE_WAITING,
  ADD_CAMPAIGNCODE_SUCCESS,
  ADD_CAMPAIGNCODE_ERROR,
  ADD_CAMPAIGNCODE_RESET,
  EDIT_CAMPAIGNCODE_WAITING,
  EDIT_CAMPAIGNCODE_SUCCESS,
  EDIT_CAMPAIGNCODE_ERROR,
  EDIT_CAMPAIGNCODE_RESET,
  DELETE_CAMPAIGNCODE_WAITING,
  DELETE_CAMPAIGNCODE_SUCCESS,
  DELETE_CAMPAIGNCODE_ERROR,
  DELETE_CAMPAIGNCODE_RESET,
  FETCH_CAMPAIGNS_WAITING,
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_ERROR,
  FETCH_CAMPAIGN_WAITING,
  FETCH_CAMPAIGN_SUCCESS,
  FETCH_CAMPAIGN_ERROR,
  FETCH_CAMPAIGN_RESET,
  ADD_CAMPAIGN_WAITING,
  ADD_CAMPAIGN_SUCCESS,
  ADD_CAMPAIGN_ERROR,
  ADD_CAMPAIGN_RESET,
  EDIT_CAMPAIGN_WAITING,
  EDIT_CAMPAIGN_SUCCESS,
  EDIT_CAMPAIGN_ERROR,
  EDIT_CAMPAIGN_RESET,
  DELETE_CAMPAIGN_WAITING,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_ERROR,
  DELETE_CAMPAIGN_RESET,
  FETCH_CAMPAIGNCATEGORIES_WAITING,
  FETCH_CAMPAIGNCATEGORIES_SUCCESS,
  FETCH_CAMPAIGNCATEGORIES_ERROR,
  FETCH_CAMPAIGNCATEGORIES_RESET,
  FETCH_CAMPAIGN_COUPON_CODES_WAITING,
  FETCH_CAMPAIGN_COUPON_CODES_SUCCESS,
  FETCH_CAMPAIGN_COUPON_CODES_ERROR,
  FETCH_CAMPAIGN_COUPON_CODES_RESET,
  GENERATE_CAMPAIGN_COUPON_CODES_WAITING,
  GENERATE_CAMPAIGN_COUPON_CODES_SUCCESS,
  GENERATE_CAMPAIGN_COUPON_CODES_ERROR,
  GENERATE_CAMPAIGN_COUPON_CODES_RESET,
  DOWNLOAD_CAMPAIGN_COUPON_CODES_WAITING,
  DOWNLOAD_CAMPAIGN_COUPON_CODES_SUCCESS,
  DOWNLOAD_CAMPAIGN_COUPON_CODES_ERROR,
  DOWNLOAD_CAMPAIGN_COUPON_CODES_RESET,
  DELETE_CAMPAIGN_COUPON_CODES_WAITING,
  DELETE_CAMPAIGN_COUPON_CODES_SUCCESS,
  DELETE_CAMPAIGN_COUPON_CODES_ERROR,
  DELETE_CAMPAIGN_COUPON_CODES_RESET,
} from '../Types';
import ErrorAction from './ErrorAction';

export const fetchCampaigncodes =
  ({ authKey, ws_id, sortByColumn, direction, module_id = 802 }) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_CAMPAIGNCODES_WAITING });
    let url = `${config.BASE_URL}/campaign/campaigncode?module_id=${module_id}&ws_id=${ws_id}`;
    if (sortByColumn && direction) {
      url += `&sort=${sortByColumn}:${direction}`;
    } else if (sortByColumn && !direction) {
      url += `&sort=${sortByColumn}`;
    }
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_CAMPAIGNCODES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_CAMPAIGNCODES_ERROR)
          : FETCH_CAMPAIGNCODES_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetFetchCampaigncodes = () => ({
  type: FETCH_CAMPAIGNCODES_RESET,
});

export const addCampaigncode = ({
  campaigncode,
  authKey,
  ws_id,
  module_id = 802,
}) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    dispatch({ type: ADD_CAMPAIGNCODE_WAITING });
    axios
      .post(
        `${config.BASE_URL}/campaign/campaigncode?module_id=${module_id}&ws_id=${ws_id}`,
        campaigncode
      )
      .then((response) =>
        dispatch({
          type: ADD_CAMPAIGNCODE_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_CAMPAIGNCODE_ERROR)
          : ADD_CAMPAIGNCODE_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const resetAddCampaigncode = () => ({ type: ADD_CAMPAIGNCODE_RESET });

export const editCampaigncode = ({
  campaigncode_id,
  campaigncode,
  authKey,
  ws_id,
  module_id = 802,
}) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    dispatch({ type: EDIT_CAMPAIGNCODE_WAITING });
    axios
      .put(
        `${config.BASE_URL}/campaign/campaigncode/${campaigncode_id}?ws_id=${ws_id}&module_id=${module_id}`,
        campaigncode
      )
      .then((response) =>
        dispatch({
          type: EDIT_CAMPAIGNCODE_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_CAMPAIGNCODE_ERROR)
          : EDIT_CAMPAIGNCODE_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const resetEditCampaigncode = () => ({ type: EDIT_CAMPAIGNCODE_RESET });

export const deleteCampaigncode = ({
  campaigncode_id,
  authKey,
  ws_id,
  module_id = 802,
}) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    dispatch({ type: DELETE_CAMPAIGNCODE_WAITING });
    axios
      .delete(
        `${config.BASE_URL}/campaign/campaigncode/${campaigncode_id}?ws_id=${ws_id}&module_id=${module_id}`
      )
      .then((response) =>
        dispatch({
          type: DELETE_CAMPAIGNCODE_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, DELETE_CAMPAIGNCODE_ERROR)
          : DELETE_CAMPAIGNCODE_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const resetDeleteCampaigncode = () => ({
  type: DELETE_CAMPAIGNCODE_RESET,
});

export const fetchCampaigns =
  ({ authKey, ws_id, sortByColumn, direction, module_id = 802 }) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_CAMPAIGNS_WAITING });
    let url = `${config.BASE_URL}/campaigns?module_id=${module_id}&ws_id=${ws_id}`;
    if (sortByColumn && direction) {
      url += `&sort=${sortByColumn}:${direction}`;
    } else if (sortByColumn && !direction) {
      url += `&sort=${sortByColumn}`;
    }
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_CAMPAIGNS_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_CAMPAIGNS_ERROR)
          : FETCH_CAMPAIGNS_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetFetchCampaigns = () => ({ type: FETCH_CAMPAIGN_RESET });

export const fetchCampaign =
  ({ authKey, ws_id, campaign_id, module_id = 802 }) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_CAMPAIGN_WAITING });
    let url = `${config.BASE_URL}/campaign/${campaign_id}?module_id=${module_id}&ws_id=${ws_id}`;
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_CAMPAIGN_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_CAMPAIGN_ERROR)
          : FETCH_CAMPAIGN_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetFetchCampaign = () => ({ type: FETCH_CAMPAIGN_RESET });

export const addCampaign = ({
  campaign,
  authKey,
  ws_id,
  coupon,
  module_id = 802,
}) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  const coupon_params = coupon
    ? `&description=${coupon.description}&n=${coupon.number_of_codes}`
    : '';
  return (dispatch) => {
    dispatch({ type: ADD_CAMPAIGN_WAITING });
    axios
      .post(
        `${config.BASE_URL}/campaign?module_id=${module_id}&ws_id=${ws_id}${coupon_params}`,
        campaign
      )
      .then((response) =>
        dispatch({
          type: ADD_CAMPAIGN_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, ADD_CAMPAIGN_ERROR)
          : ADD_CAMPAIGN_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const resetAddCampaign = () => ({ type: ADD_CAMPAIGN_RESET });

export const editCampaign = ({
  campaign_id,
  campaign,
  authKey,
  ws_id,
  module_id = 802,
}) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    dispatch({ type: EDIT_CAMPAIGN_WAITING });
    axios
      .put(
        `${config.BASE_URL}/campaign/${campaign_id}?ws_id=${ws_id}&module_id=${module_id}`,
        campaign
      )
      .then((response) =>
        dispatch({
          type: EDIT_CAMPAIGN_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, EDIT_CAMPAIGN_ERROR)
          : EDIT_CAMPAIGN_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const resetEditCampaign = () => ({ type: EDIT_CAMPAIGN_RESET });

export const deleteCampaign = ({
  campaign_id,
  authKey,
  ws_id,
  module_id = 802,
}) => {
  axios.defaults.headers.common['x-auth-key'] = authKey;
  return (dispatch) => {
    dispatch({ type: DELETE_CAMPAIGN_WAITING });
    axios
      .delete(
        `${config.BASE_URL}/campaign/${campaign_id}?ws_id=${ws_id}&module_id=${module_id}`
      )
      .then((response) =>
        dispatch({
          type: DELETE_CAMPAIGN_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        })
      )
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, DELETE_CAMPAIGN_ERROR)
          : DELETE_CAMPAIGN_ERROR;
        dispatch({ type, payload: error });
      });
  };
};

export const resetDeleteCampaign = () => ({ type: DELETE_CAMPAIGN_RESET });

export const fetchCampaigncategories =
  ({ authKey, ws_id, sortByColumn, direction, module_id = 802 }) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_CAMPAIGNCATEGORIES_WAITING });
    let url = `${config.BASE_URL}/campaigncategories?module_id=${module_id}&ws_id=${ws_id}`;
    if (sortByColumn && direction) {
      url += `&sort=${sortByColumn}:${direction}`;
    } else if (sortByColumn && !direction) {
      url += `&sort=${sortByColumn}`;
    }
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_CAMPAIGNCATEGORIES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_CAMPAIGNCATEGORIES_ERROR)
          : FETCH_CAMPAIGNCATEGORIES_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetFetchCampaigncategories = () => ({
  type: FETCH_CAMPAIGNCATEGORIES_RESET,
});

export const resetFetchCampaignCouponCodes = () => ({
  type: FETCH_CAMPAIGN_COUPON_CODES_RESET,
});

export const fetchCampaignCouponCodes =
  ({ authKey, ws_id, campaign_id, type, module_id = 802 }) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: FETCH_CAMPAIGN_COUPON_CODES_WAITING });
    let url = `${config.BASE_URL}/campaign/${campaign_id}/couponcodes?module_id=${module_id}&ws_id=${ws_id}`;
    url += type ? `&type=${type}` : '';
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_CAMPAIGN_COUPON_CODES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              FETCH_CAMPAIGN_COUPON_CODES_ERROR
            )
          : FETCH_CAMPAIGN_COUPON_CODES_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const resetGenerateCampaignCouponCodes = () => ({
  type: GENERATE_CAMPAIGN_COUPON_CODES_RESET,
});
/**
 * Generate coupon codes for a campaign
 */

export const generateCampaignCouponCodes =
  ({
    authKey,
    ws_id,
    campaign_id,
    n_codes,
    description,
    max_uses,
    module_id = 802,
  }) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: GENERATE_CAMPAIGN_COUPON_CODES_WAITING });
    let url = `${config.BASE_URL}/campaign/${campaign_id}/generatecouponcodes?module_id=${module_id}&ws_id=${ws_id}&n=${n_codes}&description=${description}`;
    url += max_uses ? `&type=${max_uses}` : '';
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: GENERATE_CAMPAIGN_COUPON_CODES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              GENERATE_CAMPAIGN_COUPON_CODES_ERROR
            )
          : GENERATE_CAMPAIGN_COUPON_CODES_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const downloadCampaignCouponCodes =
  ({ authKey, ws_id, campaign_id, type, module_id = 802 }) =>
  (dispatch) => {
    dispatch({ type: DOWNLOAD_CAMPAIGN_COUPON_CODES_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    let url = `${config.BASE_URL}/campaign/${campaign_id}/couponcodes?module_id=${module_id}&ws_id=${ws_id}&type=${type}`;
    axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        // console.log('Response: ', response);
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        console.log(
          'Response: ',
          response,
          '\nContent - disposition: ',
          response.headers['content-disposition'].split(';')[1].split('=')[1]
        );
        let fileName;
        try {
          fileName = response.headers['content-disposition']
            .split(';')[1]
            .split('=')[1];
        } catch {
          fileName = `sales-report-${new Date().getTime()}.${type}`;
        }
        FileSaver(blob, fileName);
        dispatch({
          type: DOWNLOAD_CAMPAIGN_COUPON_CODES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              DOWNLOAD_CAMPAIGN_COUPON_CODES_ERROR
            )
          : DOWNLOAD_CAMPAIGN_COUPON_CODES_ERROR;
        dispatch({ type, payload: error });
      });
  };
export const resetDownloadCampaignCouponCodes = () => ({
  type: DOWNLOAD_CAMPAIGN_COUPON_CODES_RESET,
});

export const deleteCampaignCouponCodes =
  ({ authKey, ws_id, campaign_id, coupon_series_id, module_id = 802 }) =>
  (dispatch) => {
    axios.defaults.headers.common['x-auth-key'] = authKey;
    dispatch({ type: DELETE_CAMPAIGN_COUPON_CODES_WAITING });
    let url = `${config.BASE_URL}/campaign/${campaign_id}/couponcodes?module_id=${module_id}&ws_id=${ws_id}&coupon_series_id=${coupon_series_id}`;
    axios
      .delete(url)
      .then((response) => {
        dispatch({
          type: DELETE_CAMPAIGN_COUPON_CODES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(
              error.response.status,
              DELETE_CAMPAIGN_COUPON_CODES_ERROR
            )
          : DELETE_CAMPAIGN_COUPON_CODES_ERROR;
        dispatch({ type, payload: error });
      });
  };
export const resetDeleteCampaignCouponCodes = () => ({
  type: DELETE_CAMPAIGN_COUPON_CODES_RESET,
});
