import axios from 'axios';
import config from '../../Config/AEConfig';
import {
  FETCH_TEMPLATES_WAITING,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_ERROR,
  FETCH_TEMPLATES_RESET,
  SET_TEMPLATE_SUCCESS,
  SET_TEMPLATE_ERROR,
  // ERROR_GATEWAY_TIMEOUT
} from '../Types';
import ErrorAction from './ErrorAction';

const MODULE_ID = 13;

export const fetchApplicationTemplates =
  (contextId, authKey, ws_id) => (dispatch) => {
    dispatch({ type: FETCH_TEMPLATES_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/application/templates/${contextId}?module_id=${MODULE_ID}&ws_id=${ws_id}`;
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_TEMPLATES_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, FETCH_TEMPLATES_ERROR)
          : FETCH_TEMPLATES_ERROR;
        dispatch({ type, payload: error });
      });
  };

export const setApplicationTemplate =
  (authKey, application, template, ws_id) => (dispatch) => {
    const values = {
      ap_template: template,
    };
    dispatch({ type: FETCH_TEMPLATES_WAITING });
    axios.defaults.headers.common['x-auth-key'] = authKey;
    const url = `${config.BASE_URL}/application/${application}?module_id=${MODULE_ID}&ws_id=${ws_id}`;
    axios
      .put(url, values)
      .then((response) => {
        dispatch({
          type: SET_TEMPLATE_SUCCESS,
          payload: {
            data: response.data,
            headers: JSON.parse(JSON.stringify(response?.headers || {})),
          },
        });
      })
      .catch((error) => {
        const type = error.response
          ? ErrorAction(error.response.status, SET_TEMPLATE_ERROR)
          : SET_TEMPLATE_ERROR;
        dispatch({ type, payload: error });
      });
  };

export function resetFetchApplicationTemplates() {
  return { type: FETCH_TEMPLATES_RESET };
}
