import { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Confirm,
  Button,
  Segment,
  Label,
  Icon,
  Modal,
  Table,
  Grid,
} from 'semantic-ui-react';
import { withRouter } from 'react-router';
// import { Form } from 'formsy-semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react-yz';
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
import 'moment/locale/fi';
import _ from 'lodash';
import {
  createEvent,
  addParticipantsToEvent,
  editEvent,
  deleteEvent,
  deleteProduct,
  addNotificationCategory,
  deleteNotificationCategory,
} from '../../Redux/actions';
import { T, getErrorMessage } from '../Common/Helpers';
import EventTicket from './EventTicket';
import EventFileUpload from './EventFileUpload';
import FileUpload from '../Common/FileUpload';
import EventHandProgramDisplay from './EventHandProgramDisplay';
import CmsContentList from '../CmsContent/CmsContentList';
import { Spinner } from '../Common/Spinner';
import { PopupContent } from '../Common/PopupContent';

const MODULE_ID = 3;
const imageStyle = {
  height: 200,
  width: 200,
  minHeight: 193.5,
  minWidth: 193.5,
};

const TARGET = 'event';
//const DATE_TIME_FORMAT = 'ddd, D MMM YYYY HH:mm';
const DATE_TIME_FORMAT = 'l HH:mm';
//const DATE_TIME_TIMEZONE_FORMAT = 'ddd, DD MMM YYYY HH:mm Z';
//const DATE_TIME_FORMAT_JSON = 'ddd, DD MMM YYYY HH:mm Z';
// const START_DATE = 'Start date';
// const END_DATE = 'End date';

// const TITLE_1 = 'Event Start And End Date';
const TITLE_2 = 'Event Publish Time';

const POPUP_CONTENT_1 =
  'Define the dates when event starts and ends. These dates are required.';
const POPUP_CONTENT_2 =
  'Define the date when event starts showing in events listing. If no date is defined event is shown immediately. Value should be less than event start date';

const DATE_TIME_PLACEHOLDER_1 = 'Before or same as event start time';
// const DATE_TIME_PLACEHOLDER_2 =
//   'Before or same as event start (and pre sale) time';

class EventManageGame extends Component {
  state = {
    isMounted: false,
  };
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.oncancelDeleteConfirmation =
      this.oncancelDeleteConfirmation.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.handleAdvancedSettings = this.handleAdvancedSettings.bind(this);
  }

  componentDidMount() {
    const matchToEdit = this.props.event;
    const eventperformers = [];
    const ev_event_start = matchToEdit
      ? moment(matchToEdit.ev_event_start).toISOString()
      : '';
    const ev_event_stop = matchToEdit
      ? moment(matchToEdit.ev_event_stop).toISOString()
      : '';
    const ev_visibility_start = matchToEdit
      ? moment(matchToEdit.ev_visibility_start).toISOString()
      : '';
    const ev_selling_start =
      matchToEdit && matchToEdit.ev_selling_start
        ? moment(matchToEdit.ev_selling_start).toISOString()
        : '';
    const cmsChildren = {};
    if (matchToEdit && this.props.event.hand_program.length > 0) {
      _.forEach(this.props.event.hand_program[0].children, (child) => {
        cmsChildren[child.cm_language] = { id: child.cms_content_id };
      });
    }
    const ev_parent = matchToEdit
      ? matchToEdit.ev_parent
      : this.props.match.params.parentId;

    this.setState({
      isMounted: true,
      ev_name: matchToEdit ? matchToEdit.ev_name : '',
      ev_description: matchToEdit ? matchToEdit.ev_description : '',
      ev_eventcategory: matchToEdit ? matchToEdit.ev_eventcategory : '',
      ev_place: matchToEdit ? matchToEdit.ev_place : '',
      addEventProduct: !!matchToEdit,
      ev_event_start,
      ev_event_stop,
      ev_visibility_start,
      ev_selling_start,
      ev_selling_start_error: false,
      ev_visibility_start_error: false,
      products:
        matchToEdit && matchToEdit.products && matchToEdit.products.length
          ? this.formatProducts(
              !!(matchToEdit && matchToEdit.products),
              matchToEdit.products
            )
          : this.formatProducts(!!(matchToEdit && matchToEdit.products), null),
      cms_content_id: this.setCmsContentId(
        matchToEdit ? matchToEdit.hand_program : [],
        true
      ),
      cmsChildren,
      datesAreInError: false,
      programLeafletModalOpen: false,
      showPerformers: matchToEdit
        ? this.handleShowPerformers(matchToEdit.ev_eventcategory)
        : false,
      eventperformers: matchToEdit
        ? this.listEventPerformers(matchToEdit.eventperformers)
        : eventperformers,
      host: this.setPerformer('host'),
      visitor: this.setPerformer('visitor'),
      eventmetadata: matchToEdit ? matchToEdit.eventmetadata : [],
      advanced_options: false,
      advanced_btn_text: 'Show',
      ev_parent,
    });
  }
  componentDidUpdate(prevProps) {
    //console.log(' this.props and this.props ', prevProps.events.deleteProgram, this.props.events.deleteProgram);
    if (
      prevProps.events.createStatus !== 'success' &&
      this.props.events.createStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Event Created successfully'),
        time: 5000,
      });
      const { event_id } = this.props.events.created;
      this.props.history.push(`/app/events/match/edit/${event_id}`);
      this.props.reloadEvent(event_id);
    } else if (
      prevProps.events.createStatus !== 'error' &&
      this.props.events.createStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.events.error,
        T('Could not create the event')
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      prevProps.events.editStatus !== 'success' &&
      this.props.events.editStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Event Edited successfully'),
        time: 5000,
      });
      this.props.reloadEvent();
    } else if (
      prevProps.events.editStatus !== 'error' &&
      this.props.events.editStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.events.error,
        T('Could not edit the event')
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      prevProps.events.deleteStatus !== 'success' &&
      this.props.events.deleteStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Event deleted successfully'),
        time: 5000,
      });
      this.props.history.push({
        pathname: `/app/events/season/edit/${this.state.ev_parent}`,
        state: { doNotFetchEvents: true },
      });
    } else if (
      prevProps.events.deleteStatus !== 'error' &&
      this.props.events.deleteStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.events.error,
        T('Could not delete the event')
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      prevProps.deleteStatus !== 'success' &&
      this.props.deleteStatus === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Ticket deleted successfully'),
        time: 5000,
      });
      const authKey = localStorage.getItem('x-auth-key');
      const { ws_id } = prevProps.workspace;
      const matchToEdit = prevProps.event;
      this.props.fetchEvent(authKey, matchToEdit.event_id, ws_id);
    } else if (
      prevProps.deleteStatus !== 'error' &&
      this.props.deleteStatus === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.events.error,
        T('Could not delete ticket')
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      prevProps.events.deleteProgram !== 'success' &&
      this.props.events.deleteProgram === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Hand program deleted successfully'),
        time: 5000,
      });
    } else if (
      prevProps.events.deleteProgram !== 'error' &&
      this.props.events.deleteProgram === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.events.error,
        T('Could not delete hand program')
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      prevProps.notifications.operationState.addCat !== 'success' &&
      this.props.notifications.operationState.addCat === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Message category created successfully'),
        time: 5000,
      });
      this.props.reloadEvent();
    } else if (
      prevProps.notifications.operationState.addCat !== 'error' &&
      this.props.notifications.operationState.addCat === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.notifications.error,
        T('Could not add message category')
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    } else if (
      prevProps.notifications.operationState.deleteCat !== 'success' &&
      this.props.notifications.operationState.deleteCat === 'success'
    ) {
      toast({
        type: 'success',
        title: T('Success'),
        description: T('Message category deleted successfully'),
        time: 5000,
      });
      this.props.reloadEvent();
    } else if (
      prevProps.notifications.operationState.deleteCat !== 'error' &&
      this.props.notifications.operationState.deleteCat === 'error'
    ) {
      const errorObj = getErrorMessage(
        this.props.notifications.error,
        T('Could not delete message category')
      );
      toast({
        type: 'error',
        animation: 'scale',
        title: T('Error'),
        description: T(errorObj.message),
        time: 5000,
      });
    }
  }

  onDeleteClick() {
    this.setState({ openDeleteConfirmation: true });
  }
  oncancelDeleteConfirmation() {
    this.setState({ openDeleteConfirmation: false });
  }

  onConfirmDelete() {
    const authKey = localStorage.getItem('x-auth-key');
    this.props.deleteEvent(
      this.props.location.state.match.event_id,
      authKey,
      this.props.workspace.ws_id
    );
    this.setState({ openDeleteConfirmation: false });
  }

  onDeleteEventTicket = (ticketId) => {
    const authKey = localStorage.getItem('x-auth-key');
    this.props.deleteProduct(ticketId, authKey, this.props.workspace.ws_id);
  };

  onValidSubmit = () => {
    const formIsValid = this.validateInputs();
    if (formIsValid) {
      const { ev_name, ev_description, ev_eventcategory, ev_place, ev_public } =
        this.state;
      const products = this.formatProductForSubmit(
        this.props.event ? this.props.event.event_id : null,
        ev_name
      );
      const { eventperformers } = this.state;
      if (products === false) {
        //Empty price discovered
        return;
      }
      const matchToEdit = this.props.event;
      // this.hostTeam = values.host_team;
      // this.visitorTeam = values.visitor_team;
      const key = localStorage.getItem('x-auth-key');
      const wsId = this.props.workspace.activeWorkspace.id;

      let formFields = {
        ev_name,
        ev_description,
        ev_eventcategory,
        ev_place,
        ev_public,
      };

      if (this.state.addEventProduct) {
        formFields = { ...formFields, products };
      }

      if (eventperformers) {
        formFields = { ...formFields, eventperformers };
      }

      const {
        ev_event_start,
        ev_event_stop,
        ev_visibility_start,
        ev_selling_start,
        ev_parent,
        eventmetadata,
      } = this.state;

      const inputValues = {
        ...formFields,
        ev_public: ev_public || false,
        ev_type: 'match',
        ev_event_start,
        ev_event_stop: ev_event_stop === '' ? null : ev_event_stop,
        ev_visibility_start:
          ev_visibility_start === '' ? null : ev_visibility_start,
        ev_selling_start: ev_selling_start === '' ? null : ev_selling_start,
        ev_parent,
        eventmetadata,
      };
      // console.log('inputValues', inputValues);
      if (!matchToEdit) {
        this.props.createEvent(inputValues, key, wsId);
      } else {
        this.props.editEvent(matchToEdit.event_id, key, wsId, inputValues);
      }
    }
  };

  onCancel() {
    this.props.history.push({
      pathname: `/app/events/season/edit/${this.props.match.params.parentId}`,
      state: { doNotFetchEvents: true },
    });
  }

  getLocationList(venueList, label) {
    return _.map(venueList, (venue) => ({
      text: venue[label],
      key: venue.place_id,
      value: venue.place_id,
    }));
  }

  // getTimeZoneFormattedDate = (date) => {
  //   const formattedDate = momentTimezone.tz(date, 'ddd, DD MMM YYYY HH:mm', timezone).format();
  //    return moment(formattedDate).format('ddd, DD MMM YYYY HH:mm');
  // }

  getCategoriesList(eventcategories) {
    const filterText = 'match';
    const seriesAndSeasonCategories = _.filter(
      eventcategories,
      (category) => category.ec_type === filterText
    );
    return (
      _.map(seriesAndSeasonCategories, (list) => ({
        text: T(list.ec_name),
        value: list.eventcategory_id,
      })) || { text: 'No category', value: null }
    );
  }

  setCmsContentId = (cms_content, caller) => {
    const cms_content_id = cms_content.length
      ? cms_content[0].cms_content_id
      : null;
    if (caller) {
      return cms_content_id;
    }
    this.setState({ cms_content_id });
  };

  handleAdvancedSettings() {
    let advanced_options = false;
    let advanced_btn_text = 'Show';
    if (this.state.advanced_options === false) {
      advanced_options = true;
      advanced_btn_text = 'Hide';
    }
    this.setState({ advanced_options, advanced_btn_text });
    //this.setState({ advanced: this.state.advanced_options !== true });
  }

  filterEventTicket = (templateId, ticketList) =>
    ticketList.filter((ticket) => ticket.pr_template === templateId);

  formatProducts = (event, eventProducts) => {
    let products = {};
    let enableDefaultTickets = false;
    if (event) {
      enableDefaultTickets = false;
    }
    const { ws_id } = this.props.workspace;
    const merchantId = this.props.workspace.workspaces[ws_id].cnc_company;
    const defaultTickets = _.map(
      this.props.defaultTickets,
      (ticket) => ticket.product_template_id
    );
    const tickets = _.map(this.props.defaultTickets, (ticket) => ({
      pr_active: true,
      pr_separate_ticket: ticket.pt_separate_ticket,
      pr_recommended: false,
      pr_recommended_from: ticket.pr_recommended_from,
      pr_recommended_to: ticket.pr_recommended_to,
      pr_lang: ticket.pt_language,
      pr_long_description: ticket.pt_long_description,
      pr_merchant_id: merchantId,
      pr_name: ticket.pt_name,
      pr_tax_class_id: ticket.pt_tax_class_id,
      tc_description: ticket.tc_description,
      pr_saldo: ticket.pt_saldo,
      pr_template: ticket.product_template_id,
      pr_max_person_qty: ticket.pt_max_person_qty,
      pr_max_purchase_qty: ticket.pt_max_purchase_qty,
      ptc_card_id: ticket.ptc_card_id,
      pricelists: [
        {
          pp_active: true,
          pp_currency: ticket.pt_currency,
          pp_price: ticket.pt_price,
          pp_price_list: ticket.pt_price_list,
          pp_priceError: false,
        },
      ],
      enabled: enableDefaultTickets,
    }));
    if (eventProducts) {
      const eventTickets = [];
      defaultTickets.forEach((templateId, index) => {
        const tempTicket = this.filterEventTicket(templateId, eventProducts); // is there already a ticket created from a template
        let ticket;
        let obj;
        if (tempTicket.length) {
          ticket = {
            pr_active: tempTicket[0].pr_active,
            pr_separate_ticket: tempTicket[0].pr_separate_ticket,
            pr_recommended: tempTicket[0].pr_recommended_from !== null,
            pr_recommended_from: tempTicket[0].pr_recommended_from,
            pr_recommended_to: tempTicket[0].pr_recommended_to,
            pr_name: tempTicket[0].pr_name,
            pr_lang: tempTicket[0].pr_lang,
            pp_currency: tempTicket[0].pp_currency,
            product_id: tempTicket[0].product_id,
            pr_merchant_id: tempTicket[0].pr_merchant_id,
            pr_saldo: tempTicket[0].pr_saldo,
            pr_long_description: tempTicket[0].pr_long_description,
            pr_short_description: tempTicket[0].pr_short_description,
            pr_max_purchase_qty: tempTicket[0].pr_max_purchase_qty,
            pr_max_person_qty: tempTicket[0].pr_max_person_qty,
            tc_description: tempTicket[0].tc_description,
            pr_tax_class_id: tempTicket[0].pr_tax_class_id,
            pricelists: tempTicket[0].pricelists,
            enabled: tempTicket[0].pricelists.length > 0,
          };
          obj = { ticket, type: 'ticket', index };
        } else {
          ticket = { ...tickets[index], enabled: false };
          obj = { ticket, type: 'template', index };
        }
        eventTickets.push(obj);
      });

      const currentCount = defaultTickets.length;
      const leftoutTickets = _.filter(
        eventProducts,
        (product) => defaultTickets.indexOf(product.pr_template) === -1
      );
      _.forEach(leftoutTickets, (ticket, index) =>
        eventTickets.push({
          ticket: {
            pr_active: true,
            pr_separate_ticket: ticket.pr_separate_ticket,
            pr_recommended: ticket.pr_recommended,
            pr_recommended_from: ticket.pr_recommended_from,
            pr_recommended_to: ticket.pr_recommended_to,
            pr_name: ticket.pr_name,
            pr_lang: ticket.pr_lang,
            pp_currency: ticket.pp_currency,
            product_id: ticket.product_id,
            pr_merchant_id: ticket.pr_merchant_id,
            pr_long_description: ticket.pr_long_description,
            pr_short_description: ticket.pr_short_description,
            pricelists: ticket.pricelists,
            pr_tax_class_id: ticket.pr_tax_class_id,
            pr_saldo: ticket.pr_saldo,
            enabled: ticket.pricelists.length > 0,
            pr_template: ticket.pr_template,
            pr_max_person_qty: ticket.pr_max_person_qty,
            pr_max_purchase_qty: ticket.pr_max_purchase_qty,
          },
          type: 'ticket',
          index: index + currentCount,
        })
      );

      products = eventTickets;
    } else {
      products = tickets.map((ele, index) => ({
        ticket: ele,
        type: 'template',
        index,
      }));
    }
    return products;
  };

  validateLimitPerPersonAndLimitPerSale = (
    pt_max_person_qty,
    pt_max_purchase_qty
  ) => {
    // If there's a limit per person, then limit per sale can not be empty
    // If both fields are not empty, then limit per sale should not be greater than limit per person
    if (
      (!pt_max_person_qty && !pt_max_purchase_qty) ||
      (!pt_max_person_qty && pt_max_purchase_qty)
    ) {
      return null;
    } else if (
      (pt_max_person_qty && !pt_max_purchase_qty) ||
      parseInt(pt_max_purchase_qty, 10) > parseInt(pt_max_person_qty, 10)
    ) {
      return 'pr_max_purchase_qtyError';
    }
  };

  formatDateTime(value) {
    if (!moment(value, DATE_TIME_FORMAT).isValid()) {
      value = moment(value).format(DATE_TIME_FORMAT);
    }
    return moment(value, DATE_TIME_FORMAT).toISOString();
  }

  formatProductForSubmit = (event_id, ev_name) => {
    const products = [];
    const tickets = this.state.products.slice();
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    let validPrices = true;
    for (let i = 0; i < tickets.length; i++) {
      if (tickets[i].ticket.enabled) {
        if (
          tickets[i].ticket.pricelists[0].pp_price === '' ||
          parseInt(tickets[i].ticket.pricelists[0].pp_price, 10) < 0
        ) {
          tickets[i].ticket.pricelists[0].pp_priceError = true;
          this.setState({ products: tickets });
          validPrices = false;
          break;
        } else if (
          tickets[i].ticket.pr_max_person_qty !== '' &&
          parseInt(tickets[i].ticket.pr_max_person_qty, 10) < 0
        ) {
          tickets[i].ticket.pr_max_person_qtyError = true;
          this.setState({ products: tickets });
          validPrices = false;
          break;
        } else if (
          tickets[i].ticket.pr_saldo !== '' &&
          parseInt(tickets[i].ticket.pr_saldo, 10) < 0
        ) {
          tickets[i].ticket.pr_saldoError = true;
          this.setState({ products: tickets });
          validPrices = false;
          break;
        } else {
          const errorField = this.validateLimitPerPersonAndLimitPerSale(
            tickets[i].ticket.pr_max_person_qty,
            tickets[i].ticket.pr_max_purchase_qty
          );
          if (errorField) {
            tickets[i].ticket[errorField] = true;
            this.setState({ products: tickets });
            validPrices = false;
            break;
          }
        }
        const pricelists = [...tickets[i].ticket.pricelists];
        pricelists[0].pp_price = parseFloat(pricelists[0].pp_price, 10);
        const {
          pr_max_person_qty,
          pr_max_purchase_qty,
          pr_saldo,
          pr_recommended_from,
          pr_recommended_to,
        } = tickets[i].ticket;
        const prSaldo = parseInt(pr_saldo, 10);
        const prMaxPerson =
          pr_max_person_qty !== null && pr_max_person_qty !== ''
            ? parseInt(pr_max_person_qty, 10)
            : null;
        const prMaxPurchase =
          pr_max_purchase_qty !== null && pr_max_purchase_qty !== ''
            ? parseInt(pr_max_purchase_qty, 10)
            : null;
        const tempTicket = {
          ...tickets[i].ticket,
          pr_ticket_event: event_id,
          pr_recommended_from: pr_recommended_from
            ? this.formatDateTime(pr_recommended_from)
            : null,
          pr_recommended_to: pr_recommended_to
            ? this.formatDateTime(pr_recommended_to)
            : null,
          pr_saldo: prSaldo,
          pr_max_person_qty: prMaxPerson,
          pr_max_purchase_qty: prMaxPurchase,
          pr_short_description: `${
            tickets[i].ticket.pr_name
          }, ${ev_name} : ${new Date(
            this.state.ev_event_start
          ).toLocaleDateString('fi', options)} `,
          pricelists,
        };
        delete tempTicket.pricelists[0].pp_priceError;
        delete tempTicket.pr_max_person_qtyError;
        delete tempTicket.pr_max_purchase_qtyError;
        delete tempTicket.pr_saldoError;
        delete tempTicket.enabled;
        products.push(tempTicket);
      }
    }
    return validPrices ? products : validPrices;
  };

  updateAttribute = (name, index, value) => {
    const products = this.state.products.slice();

    if (name === 'pp_price') {
      //const products = this.state.products.slice();
      products[index] = {
        ...products[index],
        ticket: {
          ...products[index].ticket,
          pricelists: [
            {
              ...products[index].ticket.pricelists[0],
              pp_price: value,
              pp_priceError: false,
            },
          ],
        },
      };
      this.setState({ products });
    } else if (
      name === 'pr_saldo' ||
      name === 'pr_max_person_qty' ||
      name === 'pr_max_purchase_qty'
    ) {
      //const products = this.state.products.slice();
      products[index].ticket[name] = value;
      products[index].ticket[`${name}Error`] = false;
      this.setState({ products });
    } else if (name === 'pr_recommended') {
      products[index].ticket.pr_recommended_to = null;
      products[index].ticket.pr_recommended = value;
      products[index].ticket.pr_recommended_from = value ? moment() : null;
      this.setState({ products });
    } else {
      //const products = this.state.products.slice();
      products[index].ticket[name] = value;
      this.setState({ products });
    }
  };

  handleProductAttributesChange = (productIndex, key, value) => {
    const product = { ...this.state.products[productIndex], [key]: value };
    const products = this.state.products.slice();
    products[productIndex] = product;
    this.setState({ products });
  };

  buildTeams(teams) {
    return _.map(teams, (team) => ({
      key: team.performer_id,
      text: team.pf_name,
      value: team.performer_id,
    }));
  }

  // not in use at the moment removes all already used teams from the list
  listTeams(teamObject) {
    if (teamObject) {
      return this.props.performers.teams
        .map((team) => ({
          key: team.performer_id,
          text: team.pf_name,
          value: team.performer_id,
        }))
        .filter((t) => t.key !== teamObject.performer_id);
    }
    return this.props.performers.teams.map((team) => ({
      key: team.performer_id,
      text: team.pf_name,
      value: team.performer_id,
    }));
  }

  renderMessageCategories() {
    const { event } = this.props;
    const { isWaiting } = this.state;
    // console.log('***EVENT', event);
    const { eventmessagecategories } = event;
    if (eventmessagecategories && eventmessagecategories.length > 0) {
      return (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{T('Id')}</Table.HeaderCell>
              <Table.HeaderCell>{T('Category Name')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {eventmessagecategories.map((mc) => (
              <Table.Row key={`mc${mc.message_category_id}`}>
                <Table.Cell>{mc.message_category_id}</Table.Cell>
                <Table.Cell>{mc.mc_name}</Table.Cell>
                <Table.Cell collapsing singleLine>
                  <Button
                    style={{ marginLeft: 2 }}
                    className="ui red button"
                    key={`button-${mc.message_category_id}`}
                    type="button"
                    content={T('Delete')}
                    onClick={(e) => {
                      const authKey = localStorage.getItem('x-auth-key');
                      this.props.deleteNotificationCategory({
                        categoryId: mc.message_category_id,
                        authKey,
                        ws_id: this.props.workspace.activeWorkspace.id,
                      });
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else {
      return (
        <Button
          icon
          primary
          disabled={isWaiting}
          onClick={() => {
            const authKey = localStorage.getItem('x-auth-key');
            const ws_id = this.props.workspace.activeWorkspace.id;
            const { event_id, ev_name } = event;
            const category = {
              mc_name: ev_name,
              mc_application: this.props.appId,
              mc_private: false,
              mc_type: 'normal',
              mc_event: event_id,
              mc_active_by_default: false,
            };
            this.props.addNotificationCategory({
              category,
              authKey,
              ws_id,
              event_id,
            });
          }}
        >
          {T('Lisää tapahtumalle viestikategoria')}
        </Button>
      );
    }
  }

  /*return this.props.cards.map((card) => ({
        key: card.card_id,
        text: `${card.cd_name}: ${localDate(card.cd_validfrom, 'fi')} - ${localDate(card.cd_validto, 'fi')}`,
        value: card.card_id
      }));*/

  // THIS MIGHT NOT BE IN USE ANYMORE
  addMoreProduct = () => {
    const products = this.state.products.slice();
    const product = {
      pr_active: true,
      pr_separate_ticket: false,
      pr_recommended: false,
      pr_recommended_from: null,
      pr_recommended_to: null,
      pr_lang: null,
      pr_merchant_id: null,
      pr_name: '',
      pr_short_description: '',
      pr_tax_class_id: null,
      pricelists: [{ pp_currency: null, pp_price: 0, pp_price_list: null }],
    };
    products.push(product);
    this.setState({ products });
  };
  // THIS MIGHT NOT BE IN USE ANYMORE
  removeProduct = (productIndex) => {
    const products = this.state.products.slice();
    products.splice(productIndex, 1);
    this.setState({ products });
  };

  handleChange = (event, { name, value }) => {
    let dateTime = moment(value, DATE_TIME_FORMAT);

    if (
      !dateTime.isValid() &&
      (name === 'ev_event_start' || name === 'ev_event_end')
    ) {
      console.err('invalid dateTime');
      return;
    }

    const start = moment(this.state.ev_event_start);
    const stop = moment(this.state.ev_event_stop);

    switch (name) {
      case 'ev_event_start': {
        if (dateTime.isAfter(stop)) {
          this.setState({
            ev_event_start: dateTime.toISOString(),
            datesAreInError: true,
          });
        } else {
          this.setState({
            ev_event_start: dateTime.toISOString(),
            datesAreInError: false,
          });
        }
        break;
      }
      case 'ev_event_stop': {
        if (dateTime.isBefore(start)) {
          this.setState({
            ev_event_stop: dateTime.toISOString(),
            datesAreInError: true,
          });
        } else {
          this.setState({
            ev_event_stop: dateTime.toISOString(),
            datesAreInError: false,
          });
        }
        break;
      }
      case 'ev_selling_start': {
        if (value === '') {
          this.setState({
            ev_selling_start: null,
            ev_selling_start_error: false,
          });
          break;
        }
        if (dateTime.isSameOrBefore(start)) {
          this.setState({
            ev_selling_start: dateTime.toISOString(),
            ev_selling_start_error: false,
          });
          break;
        }
        this.setState({
          ev_selling_start: dateTime.toISOString(),
          ev_selling_start_error: true,
        });
        break;
      }
      case 'ev_visibility_start': {
        if (value === '') {
          this.setState({
            ev_visibility_start: null,
            ev_visibility_start_error: false,
          });
          break;
        }

        if (this.state.ev_selling_start) {
          if (
            dateTime.isSameOrBefore(start) &&
            dateTime.isSameOrBefore(moment(this.state.ev_selling_start))
          ) {
            this.setState({
              ev_visibility_start: dateTime.toISOString(),
              ev_visibility_start_error: false,
            });
            break;
          }
        } else if (dateTime.isSameOrBefore(start)) {
          this.setState({
            ev_visibility_start: dateTime.toISOString(),
            ev_visibility_start_error: false,
          });
          break;
        }
        this.setState({ ev_visibility_start_error: true });
        break;
      }
      default:
        break;
    }
  };

  // handleChangeOld = (event, { name = undefined, value }) => {
  //   const date = moment(value, 'ddd, D MMM YYYY', true);
  //   const time = moment(value, DATE_TIME_FORMAT, true);
  //   const val = new Date(value);
  //   let eVal = value;
  //   const start = new Date(this.state.ev_event_start);
  //   const stop = new Date(this.state.ev_event_stop);
  //
  //   if ((!(date.isValid() || time.isValid())) && (name === 'ev_event_start' ||
  //    name === 'ev_event_end')) { console.log('inVALID'); return; }
  //
  //   switch (name) {
  //     case 'ev_event_start':
  //         if (!(date.isValid() || time.isValid())) { console.log('inVALID'); return; }
  //         if (value.length < 18) { eVal = `${value} 00:00`; }
  //         if (val > stop) {
  //             const endTime = moment(value).add(1, 'days').format(DATE_TIME_FORMAT);
  //             this.setState({ ev_event_start: eVal, ev_event_stop: endTime });
  //         } else {
  //           this.setState({ ev_event_start: eVal });
  //         }
  //         break;
  //     case 'ev_event_stop':
  //         if (!(date.isValid() || time.isValid())) { console.log('inVALID'); return; }
  //         if (value.length < 18) { eVal = `${value} 23:59`; }
  //         if (val < start) {
  //           console.log('Time machine needed: ends before starts!');
  //         } else {
  //           this.setState({ ev_event_stop: eVal });
  //         }
  //         break;
  //     case 'ev_selling_start':
  //         if (value === '') {
  //           this.setState({ ev_selling_start: value, ev_selling_start_error: false });
  //           break;
  //         }
  //         if (value.length < 18) { eVal = `${value} 00:00`; }
  //         if (val <= start) {
  //           this.setState({ ev_selling_start: eVal, ev_selling_start_error: false });
  //           break;
  //         }
  //         this.setState({ ev_selling_start_error: true });
  //         break;
  //     case 'ev_visibility_start':
  //         if (value === '') {
  //           this.setState({ ev_visibility_start: value, ev_visibility_start_error: false });
  //           break;
  //         }
  //         if (value.length < 18) { eVal = `${value} 00:00`; }
  //         if (this.state.ev_selling_start !== '') {
  //           const selling_start = new Date(this.state.ev_selling_start);
  //           if (val <= start && val <= selling_start) {
  //             this.setState({ ev_visibility_start: eVal, ev_visibility_start_error: false });
  //             break;
  //           }
  //         } else if (val <= start) {
  //           this.setState({ ev_visibility_start: eVal, ev_visibility_start_error: false });
  //           break;
  //         }
  //         this.setState({ ev_visibility_start_error: true });
  //         break;
  //     default:break;
  //       }
  // }

  setPerformer(pf_position) {
    if (this.props.event) {
      const tmp = this.props.event.eventperformers.filter(
        (ep) => ep.ep_position === pf_position
      );
      if (tmp && tmp.length > 0) {
        const value = tmp[0];
        return {
          ep_position: value.ep_position,
          performer_id: value.performer_id,
        };
      }
    }
    return null;
  }

  handleStateChange = (e, { name, value }) => {
    const fieldStatus = value === '' || false;
    this.setState({ [name]: value, [`${name}Error`]: fieldStatus });
    if (name === 'ev_eventcategory') {
      this.handleShowPerformers(value);
    }
  };

  handleShowPerformers(eventcategoryId) {
    let value = false;
    const eventCategory = _.filter(
      this.props.eventcategories.eventcategories,
      (category) => category.eventcategory_id === eventcategoryId
    );
    if (eventCategory && eventCategory.length > 0) {
      value = eventCategory[0].cce_performer_class === 'two_teams';
      this.setState({ showPerformers: value });
    }
    return value;
  }

  listEventPerformers(eventperformers) {
    return eventperformers.map((ep) => ({
      key: ep.performer_id,
      ep_performer: ep.performer_id,
      ep_position: ep.ep_position,
    }));
  }

  handlePerformerChange = (e, { name, value }) => {
    if (this.validatePerformers(name, value)) {
      const tmp = this.state.eventperformers.filter(
        (ep) => ep.ep_position !== name
      );
      if (value) {
        tmp.push({ ep_performer: value, ep_position: name });
      }
      //this.setState({ eventperformers: [...this.state.eventperformers, {ep_performer: value, ep_position: name}]})
      this.setState({ eventperformers: tmp });
    }
  };

  validatePerformers(pf_type, value) {
    this.setState({ hostError: false, visitorError: false });
    switch (pf_type) {
      case 'host': {
        if (
          value &&
          this.state.visitor &&
          this.state.visitor.performer_id === value
        ) {
          this.setState({ hostError: true });
          return false;
        }
        this.setState({
          [pf_type]: { ep_position: pf_type, performer_id: value },
        });
        return true;
      }
      case 'visitor': {
        if (
          value &&
          this.state.host &&
          this.state.host.performer_id === value
        ) {
          this.setState({ visitorError: true });
          return false;
        }
        this.setState({
          [pf_type]: { ep_position: pf_type, performer_id: value },
        });
        return true;
      }
      default:
        return false;
    }
  }

  validateInputs = () => {
    const requiredFields = [
      'ev_name',
      'ev_eventcategory',
      'ev_place',
      'ev_event_start',
      'ev_event_stop',
    ];
    const invalidFields = _.filter(
      requiredFields,
      (field) => this.state[field] === '' || false
    );

    if (invalidFields.length) {
      const errorFields = _.reduce(
        invalidFields,
        (acc, field) => ({ ...acc, [`${field}Error`]: true }),
        {}
      );
      this.setState({ ...errorFields });
      return false;
    }
    return true;
  };

  hasProgramLeaflets = () => {
    const { hand_program } = this.props.event;
    return (
      hand_program &&
      hand_program[0] &&
      hand_program[0].children &&
      hand_program[0].children.length > 0
    );
  };
  renderTicketPortion() {
    if (!this.props.haveTicketing || !this.props.event) {
      return null;
    }
    return (
      <>
        <Form.Group>
          <Form.Checkbox
            label={T('Enable Ticket Sales in the App')}
            name="chek_add_product"
            checked={this.state.addEventProduct}
            className="ui toggle checkbox"
            onChange={() =>
              this.setState({ addEventProduct: !this.state.addEventProduct })
            }
            style={{ marginTop: '1.5em', marginBottom: '1.5em' }}
          />
        </Form.Group>
        {this.state.addEventProduct && (
          <EventTicket
            updateAttribute={this.updateAttribute}
            products={this.state.products}
            onDeleteEventTicket={this.onDeleteEventTicket}
            taxList={this.props.taxList}
            ev_event_stop={this.state.ev_event_stop}
            ev_selling_start={this.state.ev_selling_start}
            ev_selling_start_error={this.state.ev_selling_start_error}
            handleChange={this.handleChange}
            date_time_format={DATE_TIME_FORMAT}
            date_time_placeholder={T(DATE_TIME_PLACEHOLDER_1)}
            advanced_options={this.state.advanced_options}
          />
        )}
      </>
    );
  }

  imageUpdate = (urlSmall, urlLarge, ref, file_id, target) => {
    // console.log('imageUpdate', {
    //   urlSmall,
    //   urlLarge,
    //   ref,
    //   file_id,
    //   target,
    // });
    const eventmetadata = this.state.eventmetadata.filter(
      (em) => em.em_key !== 'imageurl' && em.em_key !== 'image_file_id'
    );
    this.setState({
      eventmetadata: [
        ...eventmetadata,
        { event_metadata_id: null, em_key: 'imageurl', em_value: urlLarge },
        { event_metadata_id: null, em_key: 'image_file_id', em_value: file_id },
      ],
    });
  };
  imageDelete = ({ authKey, file_id, module_id, target, id }) => {
    // console.log('imageDelete', { authKey, file_id, module_id, target, id });
    const eventmetadata = this.state.eventmetadata.filter(
      (em) => em.em_key !== 'imageurl' && em.em_key !== 'image_file_id'
    );
    this.setState({ eventmetadata });
  };

  render() {
    if (
      this.props.events.createStatus === 'waiting' ||
      this.props.events.editStatus === 'waiting' ||
      this.props.events.deleteStatus === 'waiting' ||
      this.props.deleteStatus === 'waiting' ||
      !this.state.isMounted
    ) {
      return <Spinner />;
    }
    const {
      ev_event_stop,
      ev_event_start,
      ev_visibility_start,
      ev_visibility_start_error,
    } = this.state;
    const matchToEdit = this.props.event;
    // console.log('matchToEdit', matchToEdit);

    const event_id = matchToEdit ? matchToEdit.event_id : null;
    const imageurl_metadata = this.state.eventmetadata.find(
      (em) => em.em_key === 'imageurl'
    );
    const image_file_id_metadata = this.state.eventmetadata.find(
      (em) => em.em_key === 'image_file_id'
    );
    const file_id = image_file_id_metadata
      ? parseInt(image_file_id_metadata.em_value, 10)
      : null;
    const file_url = imageurl_metadata ? imageurl_metadata.em_value : '';
    const allTeams = this.buildTeams(this.props.performers.teams);

    const { activeWorkspace } = this.props;
    const { node_collection_id, cnc_context } = activeWorkspace;

    return (
      <>
        <Segment>
          <Label attached="top">
            {matchToEdit ? T('Event Basics') : T('Add Event')}
          </Label>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                label={T('Name')}
                name="ev_name"
                defaultValue={matchToEdit ? matchToEdit.ev_name : ''}
                onChange={this.handleStateChange.bind(this)}
                placeholder={T('Name')}
                error={this.state.ev_nameError}
                required
              />
              <Form.Select
                label={T('Event Category')}
                name="ev_eventcategory"
                options={this.getCategoriesList(
                  this.props.eventcategories.eventcategories
                )}
                defaultValue={matchToEdit ? matchToEdit.ev_eventcategory : null}
                onChange={this.handleStateChange.bind(this)}
                search
                error={this.state.ev_eventcategoryError}
                required
              />
              <Form.Select
                style={this.state.locationError}
                name="ev_place"
                label={T('Venue')}
                options={this.getLocationList(
                  this.props.venues.venues,
                  'pl_name'
                )}
                defaultValue={matchToEdit ? matchToEdit.ev_place : null}
                onChange={this.handleStateChange.bind(this)}
                error={this.state.ev_placeError}
                search
                required
              />
            </Form.Group>
            <Grid columns="equal" stackable>
              <Grid.Row stretched>
                <Grid.Column>
                  <Form.Group widths="equal">
                    <Form.TextArea
                      rows={2}
                      name="ev_description"
                      error={this.state.ev_descriptionError || false}
                      label={T('Event List Short Description')}
                      placeholder={T(
                        'You can add an optional short description of event here'
                      )}
                      value={this.state.ev_description}
                      onChange={this.handleStateChange.bind(this)}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <DateTimeInput
                      closable
                      name="ev_event_start"
                      label={T('Start Time')}
                      value={
                        ev_event_start
                          ? moment(ev_event_start).format(DATE_TIME_FORMAT)
                          : ''
                      }
                      onChange={this.handleChange}
                      dateTimeFormat={DATE_TIME_FORMAT}
                      required
                      autoComplete="off"
                      history="off"
                    />
                    <DateTimeInput
                      closable
                      minDate={ev_event_start}
                      name="ev_event_stop"
                      label={T('End Time')}
                      value={
                        ev_event_stop
                          ? moment(ev_event_stop).format(DATE_TIME_FORMAT)
                          : ''
                      }
                      onChange={this.handleChange}
                      dateTimeFormat={DATE_TIME_FORMAT}
                      autoComplete="off"
                      history="off"
                      required
                    />
                    <PopupContent content={POPUP_CONTENT_1} />
                    <DateTimeInput
                      closable
                      initialDate={
                        ev_event_start
                          ? moment(ev_event_start).format(DATE_TIME_FORMAT)
                          : ''
                      }
                      maxDate={
                        ev_event_start
                          ? moment(ev_event_start).format(DATE_TIME_FORMAT)
                          : ''
                      }
                      name="ev_visibility_start"
                      label={T(TITLE_2)}
                      value={
                        ev_visibility_start
                          ? moment(ev_visibility_start).format(DATE_TIME_FORMAT)
                          : ''
                      }
                      onChange={this.handleChange}
                      dateTimeFormat={DATE_TIME_FORMAT}
                      timeFormat="24"
                      autoComplete="off"
                      history="off"
                      error={ev_visibility_start_error}
                      clearable
                    />
                    <PopupContent content={POPUP_CONTENT_2} />
                  </Form.Group>
                </Grid.Column>
                <Grid.Column>
                  {this.props.event && (
                    <FileUpload
                      id={event_id}
                      key={`ev_file_upload_${event_id}`}
                      file_key={`ev_img_${event_id}`}
                      file_url={file_url}
                      file_id={file_id}
                      ws_id={node_collection_id}
                      target={'event'}
                      module_id={MODULE_ID}
                      callback={this.imageUpdate}
                      deletecallback={this.imageDelete}
                      style={imageStyle}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {this.state.showPerformers && (
              <Form.Group widths="equal">
                <Form.Select
                  label={T('Home Team')}
                  name="host"
                  options={allTeams}
                  placeholder={T('Select Team')}
                  //defaultValue={matchToEdit ? matchToEdit.ev_eventcategory : null}
                  onChange={this.handlePerformerChange.bind(this)}
                  search
                  error={this.state.hostError}
                  defaultValue={
                    this.state.host ? this.state.host.performer_id : null
                  }
                  clearable
                />
                <Form.Select
                  name="visitor"
                  label={T('Visitor')}
                  options={allTeams}
                  placeholder={T('Select Team')}
                  //defaultValue={matchToEdit ? matchToEdit.ev_place : null}
                  onChange={this.handlePerformerChange.bind(this)}
                  error={this.state.visitorError}
                  search
                  defaultValue={
                    this.state.visitor ? this.state.visitor.performer_id : null
                  }
                  clearable
                />
              </Form.Group>
            )}
          </Form>
        </Segment>
        {matchToEdit && cnc_context === 9610 && (
          <Segment>
            <Label attached="top">{T('Push-ilmoituskategoria')}</Label>
            {this.renderMessageCategories()}
          </Segment>
        )}
        {matchToEdit && (
          <Segment>
            <Label attached="top">{T('Program Leaflet')}</Label>
            {this.hasProgramLeaflets() && (
              <EventHandProgramDisplay
                handProgram={this.props.event.hand_program}
              />
            )}
            <Form>
              <Form.Group>
                <Modal
                  onClose={() =>
                    this.setState({ programLeafletModalOpen: false })
                  }
                  onOpen={() =>
                    this.setState({ programLeafletModalOpen: true })
                  }
                  open={this.state.programLeafletModalOpen}
                  trigger={
                    <Button primary>
                      <Icon name="upload" />
                      {T('Upload Program Leaflet')}
                    </Button>
                  }
                >
                  <Modal.Header>{T('Upload Program Leaflet')}</Modal.Header>
                  <Modal.Content>
                    <EventFileUpload
                      closeModal={() =>
                        this.setState({ programLeafletModalOpen: false })
                      }
                      event_id={
                        this.props.event ? this.props.event.event_id : null
                      }
                      matchToEdit={matchToEdit}
                      cms_content_id={this.state.cms_content_id}
                      languages={this.props.languages.languages}
                      appId={this.props.appId}
                      setCmsContentId={this.setCmsContentId}
                      cmsChildren={this.state.cmsChildren}
                    />
                  </Modal.Content>
                </Modal>
              </Form.Group>
            </Form>
          </Segment>
        )}
        <Form.Group>
          {matchToEdit && (
            <Segment>
              <Label attached="top">{T('Event Content')}</Label>
              <CmsContentList
                event_props={this.props.event}
                content_type={TARGET}
                history={{ location: { pathname: null } }}
                config={{
                  content_category: TARGET,
                  category_id: this.props.event.event_id,
                }}
                multiple_options
              />
            </Segment>
          )}
        </Form.Group>
        {this.renderTicketPortion()}

        <Button.Group>
          <Button
            icon="save"
            positive
            type="submit"
            content={T('Save')}
            onClick={this.onValidSubmit.bind(this)}
          />
          {matchToEdit && (
            <>
              <Button
                negative
                icon="trash"
                type="button"
                content={T('Delete')}
                onClick={this.onDeleteClick.bind(this)}
              />
              <Confirm
                header={T('Delete Event')}
                content={T('Are you sure you want to delete this event?')}
                confirmButton={T('Delete')}
                cancelButton={T('Cancel')}
                open={this.state.openDeleteConfirmation}
                onCancel={this.oncancelDeleteConfirmation}
                onConfirm={this.onConfirmDelete}
                size="tiny"
              />
            </>
          )}
          <Button
            type="button"
            icon="ban"
            secondary
            onClick={() => this.onCancel()}
            content={T('Cancel')}
          />
        </Button.Group>
        {this.haveTicketing && (
          <Button.Group>
            <Button
              type="button"
              icon="external alternate"
              primary
              content={T(`${this.state.advanced_btn_text} advanced settings`)}
              onClick={() => this.handleAdvancedSettings()}
            />
          </Button.Group>
        )}
      </>
    );
  }
}

function mapStateToProps({
  eventcategories,
  parents,
  performers,
  events,
  venues,
  workspace,
  languages,
  product,
  notifications,
}) {
  return {
    eventcategories,
    parents,
    performers,
    events,
    venues,
    workspace,
    languages,
    notifications,
    appId: workspace.workspaces[workspace.ws_id].cnc_application,
    deleteStatus: product.deleteStatus,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
  };
}

export default connect(mapStateToProps, {
  createEvent,
  addParticipantsToEvent,
  editEvent,
  deleteEvent,
  deleteProduct,
  addNotificationCategory,
  deleteNotificationCategory,
})(withRouter(EventManageGame));
